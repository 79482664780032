import React, { useState, useEffect, useContext } from 'react'
import './Schutle.css'
import { useTimer } from 'react-timer-hook'
import Context from '../../Context'
import * as API from '../../Apisurl'
import psyduck from '../../assets/images/psyduck.svg'
import schutletable from '../../assets/images/The-Schulte-Table.jpg'
import { Col, Row } from 'react-bootstrap'

const shuffle = (array) => {
  return [...array].sort(() => Math.random() - 0.5)
}

const numbers = Array.from({ length: 25 }, (_, i) => i + 1)
const alphabets = Array.from({ length: 25 }, (_, i) => String.fromCharCode(65 + i))
const alphaNumeric = [
  ...Array.from({ length: 13 }, (_, i) => i + 1),
  ...Array.from({ length: 12 }, (_, i) => String.fromCharCode(65 + i)),
]

const Schutle = () => {
  const { selectValue } = useContext(Context)
  const [cards, setCards] = useState([])
  const [score, setScore] = useState('')
  const [avg, setAvg] = useState('')
  const [isPlaying, setIsPlaying] = useState(false)
  const [reports, setReports] = useState([])

  const [settings, setSettings] = useState({
    type: 'Normal',
    level: '1',
    no_of_questions: 10,
    curr_question: 0,
  })

  const time = new Date()
  time.setSeconds(time.getSeconds() + 30)
  const { minutes, seconds, restart, pause } = useTimer({
    expiryTimestamp: time,
    autoStart: false,
    onExpire: () => stopGame(),
  })

  const newGame = () => {
    setIsPlaying(true)
    setCards((oldCards) => shuffle(oldCards))

    const currTime = new Date()
    currTime.setSeconds(currTime.getSeconds() + 30)
    restart(currTime)
  }

  const stopGame = () => {
    setIsPlaying(false)
    setScore()

    const currTime = new Date()
    currTime.setSeconds(currTime.getSeconds() + 30)
    restart(currTime)
    pause()
  }

  useEffect(() => {
    const getReports = async () => {
      try {
        const response = await fetch(`${API.Fetchurl}games_data?user_id=${selectValue.user_id}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('session')}` },
        })
        const data = await response.json()

        const modifiedData = data.ques.filter((d) => d[2] === 'Schutle').map((d) => JSON.parse(d[6][0]))

        setReports(modifiedData)
      } catch (error) {
        console.log(error)
      }
    }

    getReports()
  }, [selectValue.user_id])

  useEffect(() => {
    if (settings.level === '1') {
      setCards(() => shuffle(numbers))
    } else if (settings.level === '2') {
      setCards(() => shuffle(alphabets))
    } else {
      setCards(() => shuffle(alphaNumeric))
    }
  }, [settings.level])

  const handleChange = (e) => {
    const { name, value } = e.target
    setSettings((old) => ({ ...old, [name]: value }))
  }

  const saveReport = async (res) => {
    try {
      await fetch(`${API.Fetchurl}games_data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('session')}`,
        },
        body: JSON.stringify(res),
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await saveReport({
      user_id: selectValue.user_id,
      username: selectValue.username,
      bet: settings.level,
      time: parseInt(avg),
      category: 'Schutle',
      score: parseInt(score),
      bet_array: [
        JSON.stringify({
          type: settings.type,
          level: settings.level,
          rate: (parseInt(score) / 25) * 100,
          duration: minutes * 60 + seconds,
          avg: parseInt(avg),
        }),
      ],
    })

    setScore('')
    setAvg('')
  }

  return (
    <div className='h-100vh w-100'>
      <Row className='h-100' style={{ maxHeight: '100%' }}>
        <Col lg={8} xl={9} xxl={9} className='p-0 h-100'>
          <div
            className='d-flex align-items-center justify-content-between bg-white shadow-sm p-4 mb-3'
            style={{ height: '75px' }}
          >
            <div className='d-flex align-items-center gap-3'>
              <img src={schutletable} alt='mental-maths' height={48} width={48} />
              <h1 className='m-0 fs-2'>Schutle Table</h1>
            </div>

            <div className='d-flex align-items-center gap-4'>
              <div>
                <label htmlFor='type'>Type</label>
                <select
                  id='type'
                  name='type'
                  value={settings.type}
                  onChange={handleChange}
                  required
                  disabled={isPlaying}
                  style={{ width: 'fit-content', padding: 0, height: '25px' }}
                  className='ms-2 border border-2 rounded-pill'
                >
                  <option value='Normal'>Normal</option>
                  <option value='Reverse'>Reverse</option>
                </select>
              </div>
              <div>
                <label htmlFor='level'>Level</label>
                <select
                  id='level'
                  name='level'
                  value={settings.level}
                  onChange={handleChange}
                  required
                  disabled={isPlaying}
                  style={{ width: 'fit-content', padding: 0, height: '25px' }}
                  className='ms-2 border border-2 rounded-pill'
                >
                  {[1, 2, 3].map((lvl) => (
                    <option key={lvl} value={lvl}>
                      {lvl}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className='mx-auto' style={{ width: 'min-content' }}>
            <div className='d-flex align-items-center justify-content-between gap-4 mb-3'>
              <div
                className='rounded-circle bg-warning position-relative'
                style={{
                  width: '40px',
                  height: '40px',
                  background: `conic-gradient(orange ${(seconds / 30) * 360}deg, #e9e9e9 0deg)`,
                }}
              >
                <div
                  className='position-absolute start-50 top-50 translate-middle bg-white rounded-circle d-flex justify-content-center align-items-center shadow-sm'
                  style={{ width: '25px', height: '25px', fontSize: '12px' }}
                >
                  {seconds}
                </div>
              </div>

              {!isPlaying ? (
                <button type='button' onClick={newGame} className='py-2 px-4 border-0 rounded-3 text-black bg-warning'>
                  Start
                </button>
              ) : (
                <button type='button' onClick={stopGame} className='py-2 px-4 border-0 rounded-3 bg-black'>
                  Stop
                </button>
              )}
            </div>

            <div
              className='Container p-2 shadow rounded-3'
              style={{ display: 'grid', gap: '6px', gridTemplateColumns: `repeat(5, auto)` }}
            >
              {cards.map((value, i) => {
                return (
                  <div key={i} className={i === 12 ? 'CorrectSquare' : 'FillSquare'}>
                    {value}
                  </div>
                )
              })}
            </div>
            <form className='d-flex gap-2 mt-3' onSubmit={handleSubmit}>
              <input
                required
                type='number'
                min={0}
                max={25}
                placeholder='items counted'
                value={score}
                onChange={(e) => setScore(e.target.value)}
              />
              <input
                required
                type='number'
                min={0}
                placeholder='time taken'
                value={avg}
                onChange={(e) => setAvg(e.target.value)}
              />
              <button className='py-2 px-4 border-0 rounded-3 bg-black text-white'>Save</button>
            </form>
          </div>
        </Col>

        <Col
          lg={4}
          xl={3}
          xxl={3}
          className='h-100 bg-white p-4'
          style={{ opacity: isPlaying ? 0 : 1, transition: 'opacity 0.2s ease' }}
        >
          <h6>Game Info</h6>
          <table className='table table-borderless table-sm'>
            <tbody>
              <tr>
                <td>Mode</td>
                <td className='fw-bold'>{settings.type}</td>
              </tr>
              <tr>
                <td>Level</td>
                <td className='fw-bold'>{settings.level}</td>
              </tr>
              <tr>
                <td>Number of questions</td>
                <td className='fw-bold'>{settings.no_of_questions}</td>
              </tr>
            </tbody>
          </table>

          <h6>How to play</h6>
          <ul>
            <li>To start the game press start button.</li>
            <li>Look at the highlighted item in the center and find the item in correct order</li>
            <li>Once done enter the number of item you could find and the time taken</li>
            <li>Press enter or click to save</li>
          </ul>

          <h6>Reports</h6>
          {reports.length === 0 && 'No reports yet'}
          <div style={{ overflowY: 'auto', height: '500px' }}>
            <table className='table table-sm table-hover' style={{ fontSize: '10px' }}>
              <tbody className='table-group-divider align-middle'>
                {reports.map((report, index) => (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{report.type}</td>
                    <td>{report.level}</td>
                    <td>{report.rate}%</td>
                    <td>{report.avg}</td>
                    <td>{report.duration}s</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Schutle

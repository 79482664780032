import React from "react";
import { Container } from "react-bootstrap";
import Activity from "../dashboard/Activity";
import MeetTable from "./Meetable";

const Meet = () => {
  return (
    <>
      <div className="content">
        <Container fluid>
          <div className="dashboard-heading">
            <div class="content-container">
              <h2>Meetup</h2>
              <p>Here’s a list of your upcoming meetups</p>
            </div>
            <Activity />
          </div>

          <div className="content-container meet-table-container">
            <div className="two-partition">
              <p className="text-secondary">
                Here’s a list of your upcoming meetups
              </p>
              <button className="common-btn">+ New Meeting </button>
            </div>
            <MeetTable />
          </div>
        </Container>
      </div>
    </>
  );
};

export default Meet;

import React from "react";
import nextArrow from "../assets/images/nextarrow.svg";
import { useState, useRef } from "react";
const PaginationContainer = (props) => {
  console.log(props);
  return (
    <>
      <div class="pagination-container">
        <figure onClick={props.prevClick}>
          <img src={nextArrow} alt="next" />
        </figure>

        <figure onClick={props.nextClick}>
          <img src={nextArrow} alt="next" />
        </figure>
      </div>
    </>
  );
};
export default PaginationContainer;

import react from 'react'
import { useState } from 'react'
import FormHeader from '../includes/FormHeader'
import { Col, Row, Container } from 'react-bootstrap'
import FormComponent from '../teacher/Form'
import RoleformHeader from '../teacher/RoleFormHeading.jsx'
import ChildImg from './Childillustation'
import childrenCss from './cssModule/children.module.css'
import Button from 'react-bootstrap/Button'
import FormModal from './FormModal'
import '@fortawesome/fontawesome-free/css/all.min.css'
import { NavLink, useLocation } from 'react-router-dom'

const Children = (props) => {
  return (
    <>
      <Container>
        <Row>
          <Col md={4} className='d-flex flex-column justify-content-between'>
            <div className={childrenCss.addButton} onClick={() => props.handleShow(2)}>
              +
            </div>

            {/* <div className={childrenCss.btnContainer}>
              <div className={childrenCss.redirectoption}>
                <NavLink to='/'>
                  <Button variant='primary' className='common-btn full-btn disable-btn' type='button'>
                    Skip
                  </Button>
                </NavLink>
              </div>
              <p>Don’t worry, you can always add more children later</p>
            </div> */}
          </Col>
          <Col md={8} className={childrenCss.imageContainer}>
            <ChildImg />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Children

import react from 'react'
import { Row, Container, Col } from 'react-bootstrap'
import backbtn from '../../../assets/images/backbtn.svg'
import Focus from '../../../includes/Focus'
import Progress from '../Testprogrsscom'
import refreshbtn from '../../../assets/images/refresh.svg'
import Draggable, { DraggableCore } from 'react-draggable' // Both at the same time
import { useState, useRef, useEffect, useContext } from 'react'
import StartModal from '../../SelectgameStart'
import { NavLink, useLocation, useParams, useNavigate } from 'react-router-dom'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import * as Currdate from '../../../CurrDate'
import * as Apiurl from '../../../Apisurl'
import { useSpeechSynthesis } from 'react-speech-kit'
import { SocketConfig } from '../../../SocketConfig'
import Speech from '../../Speech'

const Calculation = (props) => {
  let headingname = useParams()

  let DraggableCore = Draggable.DraggableCore
  const { speak } = useSpeechSynthesis()
  const [score, setScore] = useState(0)
  const location = useLocation()
  const [isActive, setIsActive] = useState(false)
  let initialArray = []
  const [theArray, setTheArray] = useState(initialArray)
  const [mistakes, setMistakes] = useState(0)
  const [userAnswer, setUserAnswer] = useState('')
  const resetButton = useRef(null)
  const [smShow, setsmShow] = useState(false)
  const [parsedata, setparsedata] = useState(false)
  let mathsdatavalue = props.mathsdata
  let datalength = props.datalength
  let date = Currdate.GetCurrentDate()
  props.time(date)
  let datalengthi = datalength
  const [callday, setcallday] = useState(1)
  let navigate = useNavigate()

  useEffect(() => {
    SocketConfig.on('action_data', (data) => {
      console.log('Datta ', data)
      let parseddata = data.result
      if (parseddata.length != 0) {
        setparsedata(true)
        handleSubmit(parseddata.action, true)
      }
      console.log(parseddata)
    })
    //
  }, [])
  let update
  // checkanswers
  function handleSubmit(e, via) {
    let userAnswer = via == true ? e : e.target.innerText

    Speech.Speech(userAnswer)

    let correctAnswer
    if (
      mathsdatavalue.ans == userAnswer ||
      mathsdatavalue.direction[0] == userAnswer ||
      mathsdatavalue.direction[1] == userAnswer
    ) {
      props.setPage((prev) => {
        props.setTheArray((oldArray) => {
          update = [
            ...oldArray,
            {
              via: 'correct',
              answer: userAnswer,
            },
          ]
          localStorage.setItem('useranswer', JSON.stringify(update))
          return update
        })
        //       alert(update.length, update.length);
        //       if (update.length === datalengthi.length) {
        //         props.setTheArray([]);
        //         SocketConfig.emit("common", { result: "disconnect" });

        //         setTimeout(() => {
        //           props.submitResult(date);
        //           navigate("mathresult", {
        //             state: { ...location.state, date: date },
        //           });
        //         }, 1000);
        //       } else {
        //         props.setScore((prev) => prev + 1);
        //         return prev + 1;
        //       }
        //     });
        //     console.log(props.page);
        //   } else {
        //     console.log(datalengthi, userAnswer);
        //     props.setPage((prev) => {
        //       props.setTheArray((oldArray) => {
        //         const update = [
        //           ...oldArray,
        //           {
        //             via: "incorrect",
        //             answer: userAnswer,
        //           },
        //         ];
        //         console.log(update);
        //         localStorage.setItem("useranswer", JSON.stringify(update));
        //         return update;
        //       });
        //       return prev + 1;
        //     });
        //     if (update.length != undefined ? "" : "" == datalengthi.length) {
        //       console.log(datalengthi, userAnswer);
        //       SocketConfig.emit("common", { result: "disconnect" });
        //       setTimeout(() => {
        //         props.submitResult(date);
        //         navigate("mathresult", {
        //           state: { ...location.state, date: date },
        //         });
        //       }, 100);
        //     }
        //   }
        // }
        if (update.length === datalengthi.length) {
          props.setTheArray([])
          // socketemitdata();
          props.submitResult(date)

          // navigate(
          //   'mathresult',
          //   {
          //     state: { ...location.state, date: date },
          //   },
          //   1000
          // )
        } else {
          props.setScore((prev) => prev + 1)

          return prev + 1
        }
      })
    } else {
      props.setPage((prev) => {
        props.setTheArray((oldArray) => {
          update = [
            ...oldArray,
            {
              via: 'incorrect',
              answer: userAnswer,
            },
          ]
          localStorage.setItem('useranswer', JSON.stringify(update))
          return update
        })

        if (update.length === datalengthi.length) {
          props.setTheArray([])
          props.submitResult(date)
          // navigate(
          //   'mathresult',
          //   {
          //     state: { ...location.state, date: date },
          //   },
          //   100
          // )
        } else {
          return prev + 1
        }
      })
    }
  }

  function resetGame() {
    setScore(0)
    setMistakes(0)
    setTheArray([])
    setUserAnswer('')
    setsmShow(false)
  }

  useEffect(() => {
    SocketConfig.on('action', (data) => {
      let parseddata = data.result
      handleSubmit(parseddata.action, true)
    })
  }, [])

  if (mathsdatavalue && mathsdatavalue.length != 0) {
    return (
      <>
        <Container fluid>
          <Row className='colors-main-container'>
            <Col md={12}>
              <p>Your Problem</p>
              <Focus />

              <div className='addition-container '>
                <h5>{mathsdatavalue.no1}</h5>
                <h5>
                  <span className='operatortxt'>{props.operator}</span>
                </h5>
                <h5>{mathsdatavalue.no2}</h5>
              </div>
              {/* <div className="color question-container">?</div> */}

              {/* <h6 className="score-txt">Score : {score}</h6> */}
              <p>Select a result to fill in the blank</p>
              <div className='measure-container'>
                {mathsdatavalue.mcq.map((o, i) => {
                  return (
                    <div className='measure-item' onClick={handleSubmit}>
                      <h5>{o}</h5>
                    </div>
                  )
                })}
              </div>
            </Col>
          </Row>
        </Container>
        <StartModal
          calldelay={setcallday}
          socket={SocketConfig}
          letquesLength={datalengthi.length}
          type={['gesture', 'focus']}
          date={date}
          calldaynum={callday}
        />
      </>
    )
  }
}
export default Calculation

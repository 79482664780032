import React, { useRef, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'

const ADHD = ({ data, submit, options, limit }) => {
  const [formData, setFormData] = useState({})
  const [validated, setValidated] = useState(false)
  const [step, setStep] = useState(1)
  const formRef = useRef()

  const last_page = Math.ceil(data.length / limit)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((old) => ({ ...old, [name]: { ...old[name], ans: value } }))
  }

  const handleNotesChange = (e) => {
    const { name, value } = e.target
    setFormData((old) => ({ ...old, [name]: { ...old[name], notes: value } }))
  }

  const nextStep = (e) => {
    e.preventDefault()
    if (formRef.current.checkValidity() === false) {
      e.stopPropagation()
    } else {
      if (step === last_page) return finish()
      setStep((s) => s + 1)
    }
    setValidated(true)
  }

  const finish = () => {
    const scoreCard = {
      0: 'not at all',
      1: 'just a little',
      3: 'pretty much',
      4: 'very much',
    }
    const score = Object.values(formData).reduce((total, curr) => (total += parseInt(curr.ans)), 0)
    const report = {
      ques: {
        ques: data.map((d) => d.ques),
        ans: Object.values(formData).map((d) => ({ ans: scoreCard[d.ans] })),
        isADHD: score >= 12 ? true : false,
      },
      ans: [],
      score: score,
      total: data.length * 4,
    }

    submit(report)
  }

  return (
    <Container>
      <Row className='my-3 dashboard-cards p-4'>
        <Col>
          <Form noValidate validated={validated} ref={formRef}>
            <ul className='list-unstyled'>
              {data.slice((step - 1) * limit, (step - 1) * limit + limit).map((d) => {
                return (
                  <li key={d.q_no} className='d-flex my-3'>
                    <div className='me-2 text-secondary'>{d.q_no}.</div>
                    <div className='w-100 d-lg-flex justify-content-between'>
                      <div style={{ textAlign: 'justify' }}>{d.ques}</div>

                      <div className='d-lg-flex gap-2 justify-content-lg-end w-50'>
                        {d.choices.map((choice, idx) => {
                          return (
                            <Form.Group>
                              <Form.Check
                                key={idx}
                                type='radio'
                                label={choice}
                                id={`${choice}_${d.q_no}`}
                                name={d.q_no}
                                value={options[idx]}
                                checked={formData[d.q_no]?.ans === options[idx]}
                                onChange={handleChange}
                                required
                              />
                              <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                            </Form.Group>
                          )
                        })}
                        {/* <Form.Group className='w-50'>
                          <Form.Control
                            type='text'
                            placeholder='add notes'
                            name={d.q_no}
                            value={formData[d.q_no]?.notes}
                            onChange={handleNotesChange}
                            className='p-0 h-auto'
                          />
                        </Form.Group> */}
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
            <div className='d-flex justify-content-between'>
              <button
                onClick={() => setStep((s) => s - 1)}
                type='button'
                className='d-flex justify-content-center align-items-center py-1 px-3 rounded-pill border border-2 text-secondary'
                style={{ backgroundColor: 'transparent' }}
                disabled={step === 1}
              >
                Prev
              </button>
              <button
                onClick={nextStep}
                type='submit'
                className={`d-flex justify-content-center align-items-center py-1 px-3 rounded-pill text-white`}
                style={{ backgroundColor: 'var(--green-color)', border: 'none' }}
              >
                {step !== last_page ? 'Next' : 'Finish'}
              </button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default ADHD

import React, { useEffect, useState } from 'react'
import { DragDropContainer, DropTarget } from 'react-drag-drop-container'
import body from '../../../assets/images/bodypartsgames/body.png'
import './PartsOfBody.css'

const PartsOfBody2 = ({ submit }) => {
  const [options, setOptions] = useState({
    hair: '',
    hand: '',
    mouth: '',
    nose: '',
    leg: '',
    eyes: '',
    ear: '',
    elbow: '',
    knee: '',
    foot: '',
  })
  const [data, setData] = useState([])
  const [result, setResult] = useState([])

  const shuffle = () => {
    let array = Object.keys(options)
    let currentIndex = array.length,
      randomIndex

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--

      // And swap it with the current element.
      ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
    }

    return array
  }
  useEffect(() => {
    const res = shuffle()
    setData(res)
  }, [])

  useEffect(() => {
    if (result.length === Object.keys(options).length) {
      localStorage.setItem('useranswer', JSON.stringify(result))
      submit()
    }
  }, [result])

  const handleHit = (e) => {
    let parentContainer = e.target
    parentContainer.innerText = e.dragData
    parentContainer.style.setProperty('color', 'gray', 'important')
    parentContainer.style.setProperty('border-color', 'gray', 'important')
  }

  const handleDrop = (e) => {
    let ques = e.dropData
    let ans = e.dragData

    setOptions({ ...options, [ques]: ans })
  }

  const reset = () => {
    let el = document.getElementsByClassName('body-part')
    Object.values(el).forEach((e) => {
      e.innerText = ''
      e.style.setProperty('border-color', 'gray', 'important')
      e.style.setProperty('color', 'gray', 'important')
    })
    setOptions({
      hair: '',
      hand: '',
      mouth: '',
      nose: '',
      leg: '',
      eyes: '',
      ear: '',
      elbow: '',
      knee: '',
      foot: '',
    })
    const res = shuffle()
    setData(res)
  }

  const handleSubmit = () => {
    let el = document.getElementsByClassName('body-part')

    Object.entries(options).forEach(([ques, ans], i) => {
      setResult((old) => [
        ...old,
        {
          ques: ques,
          ans: ans,
          via: ques === ans ? 'correct' : 'incorrect',
        },
      ])

      if (ques === ans) {
        el[i].style.setProperty('color', 'green', 'important')
        el[i].style.setProperty('border-color', 'green', 'important')
      } else {
        el[i].style.setProperty('color', 'red', 'important')
        el[i].style.setProperty('border-color', 'red', 'important')
      }
    })

    // localStorage.setItem('useranswer', JSON.stringify(result))
    // submit()
  }

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between'>
        <h2 className='m-0'>Find Body Parts</h2>
        <div style={{ marginInlineEnd: '150px' }}>
          <button type='button' onClick={reset} className='btn common-btn mx-2'>
            Reset
          </button>
          <button type='button' onClick={handleSubmit} className='btn common-btn'>
            Submit
          </button>
        </div>
      </div>
      <div className='d-flex flex-column align-items-center'>
        <div className='d-flex align-items-center gap-4 my-4'>
          {data.map((op) => {
            return (
              <DragDropContainer targetKey='items' dragData={op} onDrop={handleDrop}>
                <div
                  className='px-4 py-2 rounded-circle d-flex align-items-center justify-content-center shadow overflow-hidden'
                  style={{ width: '100px', height: '100px' }}
                >
                  <h2 className='m-0 p-0 text-capitalize text-black'>{op}</h2>
                </div>
              </DragDropContainer>
            )
          })}
        </div>
        <div className='position-relative d-flex justify-content-center' style={{ width: '1000px' }}>
          <div>
            {Object.keys(options)
              .slice(0, 5)
              .map((op, i) => {
                return (
                  <DropTarget targetKey='items' dropData={op} onHit={handleHit}>
                    <div
                      className={`body-part body-left-${i +
                        1} px-4 py-2 border border-4 rounded-pill d-flex align-items-center justify-content-center text-capitalize`}
                      style={{ width: '100px', height: '100px', fontSize: '20px', fontWeight: 'bold' }}
                    ></div>
                  </DropTarget>
                )
              })}
          </div>
          <img src={body} height={650} alt='human' />
          <div>
            {Object.keys(options)
              .slice(5, 10)
              .map((op, i) => {
                return (
                  <DropTarget targetKey='items' dropData={op} onHit={handleHit}>
                    <div
                      className={`body-part body-right-${i +
                        1} px-4 py-2 border border-4 rounded-pill d-flex align-items-center justify-content-center text-capitalize`}
                      style={{ width: '100px', height: '100px', fontSize: '20px', fontWeight: 'bold' }}
                    ></div>
                  </DropTarget>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PartsOfBody2

import 'chartjs-plugin-datalabels'
import { useContext } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2'
import Context from '../Context'

const EmotionalModal = (props) => {
  const selectData = useContext(Context)

  return (
    <Modal size='md' show={props.via} onHide={() => props.setvia(false)}>
      <Modal.Header closeButton>
        <h2 className='m-0'>Emotional Analysis</h2>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <Bar
              data={{
                labels: ['Contempt', 'Happy', 'Relaxed', 'Tired'],
                datasets: [
                  {
                    axis: 'y',
                    barPercentage: 0.5,
                    barThickness: 40,
                    maxBarThickness: 50,
                    borderRadius: 8,
                    minBarLength: 2,
                    data: selectData.emotData,
                    backgroundColor: (context) => {
                      const ctx = context.chart.ctx
                      const gradient = ctx.createLinearGradient(0, 0, 0, 200)
                      gradient.addColorStop(0, '#eaf6f6')
                      gradient.addColorStop(1, '#e0f3f1')
                      return gradient
                    },
                  },
                ],
              }}
              options={{
                indexAxis: 'y',

                plugins: {
                  legend: {
                    display: false,
                    labels: {
                      color: 'rgb(255, 99, 132)',
                      position: 'right',
                    },

                    title: {
                      display: false,
                      text: 'Custom Chart Title',
                    },
                  },
                },

                scales: {
                  x: {
                    display: false,
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    display: true,
                    position: 'left',
                    grid: {
                      display: false,
                    },
                  },
                },
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default EmotionalModal

import react from 'react'
const EmotivePlugin=(data,typeValue,i,express)=>{
	console.log(data,typeValue)

	let expressionHtml="";
         switch(typeValue){
         	case "1":
         	 	expressionHtml=i == 0 ? (
                         <div  className="measure-item subobj" onClick={express}>
                             <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
                              <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
                            </svg>
                            <h5  >{data}</h5> 
                          </div>                      
                             ) : i == 1 ? (
                          <div className="measure-item subobj"  onClick={express}>
                                <h5>{data}</h5>
                                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                              <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                              </svg> 
                            </div>) : (
                          ""
                        )
         	break;
         	case "2":
         	expressionHtml=i == 0 ? (

                        <div  className="measure-item subobj"  onClick={express}>
                              <img src="https://c.tenor.com/FmGKWOVEXjQAAAAj/smile.gif" />
                            <h5>{data}</h5> 
                          </div>         
                         
                        ) : i == 1 ? (
                         <div className="measure-item subobj"  onClick={express}>
                                <h5>{data}</h5>
                            <img src="https://c.tenor.com/xh620f6skRQAAAAj/beautiful-eyes-blink.gif" />
 
                            </div>
                         
                        ) : (
                          ""
                        )
            break;
            default:expressionHtml=i == 0 ?  (

                        <div  className="measure-item subobj"  onClick={express}>
                             
                            <h5  >{data}</h5> 
                          </div>         
                         
                        ) : i == 1 ? (
                         <div className="measure-item subobj"  onClick={express}>
                                <h5>{data}</h5>
 
                            </div>
                         
                        ) : (
                          ""
                        )

         }

	return expressionHtml
}
export default EmotivePlugin
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useSpeechSynthesis } from 'react-speech-kit'
import * as Apiurl from '../../../Apisurl'
import * as Currdate from '../../../CurrDate'
import headersReq from '../../../HeadersJson'
import { SocketConfig } from '../../../SocketConfig'
import Userjson from '../../../Userjson'
import refreshbtn from '../../../assets/images/refresh.svg'
import Focus from '../../../includes/Focus'
import StartModal from '../../SelectgameStart'
import Speech from '../../Speech'
import Progress from '../Testprogrsscom'

let socket

const ColorGame = () => {
  const reqJson = headersReq()
  const [page, setPage] = useState(0)
  const { speak } = useSpeechSynthesis()
  const [parsedata, setparsedata] = useState('')
  const [score, setScore] = useState(0)
  const [timer, setTimer] = useState(0)
  const [callday, setcallday] = useState(1)
  const [counter, setCounter] = useState(30)
  const [play, setplay] = useState(true)
  const [smShow, setsmShow] = useState(false)
  let initialArray = []
  const location = useLocation()
  let date = Currdate.GetCurrentDate()
  const [theArray, setTheArray] = useState(initialArray)
  let navigate = useNavigate()

  let colorjson = require('../../../datasets/colors_level.json')
  let leveldata = location.state.link
  let jsonData = colorjson[leveldata][page]
  let datalengthi = colorjson[leveldata].length
  let uData = Userjson()

  const submitResult = () => {
    let answer = JSON.parse(localStorage.getItem('useranswer'))
    let getScore = answer.filter((o, i) => {
      return o.via == 'correct'
    })

    let alldata = {
      ...uData,
      result: JSON.stringify({
        ques: colorjson != undefined ? colorjson[leveldata] : '',
        score: getScore.length,
        total: colorjson[leveldata].length,
        ques_length: colorjson[leveldata].length,
        ans: localStorage.getItem('useranswer'),
      }),
      date: date,
    }
    const esc = encodeURIComponent

    const query = Object.keys(alldata)
      .map((k) => `${esc(k)}=${esc(alldata[k])}`)
      .join('&')

    async function levelgame() {
      const response = await fetch(`${Apiurl.Fetchurl + 'manual_report_fc?' + query + ''}`, {
        method: 'GET',
        headers: reqJson,
      })
      const childdata = await response.json()
      return childdata
    }
    levelgame()
      .then((data) => {
        navigate(`colresult?id=${data.serial_num}`, { state: { ...location.state, date: date } })
      })
      .catch((error) => {
        alert(error)
      })
  }

  let update
  function nextQuestion(e) {
    let checkanswer = e

    Speech.Speech(checkanswer)
    let isCorrect = jsonData.ans.colorName.toUpperCase() == checkanswer.toUpperCase()

    setPage((prevPage) => {
      setTheArray((oldArray) => {
        update = [
          ...oldArray,
          {
            via: isCorrect ? 'correct' : 'incorrect',
            answer: checkanswer,
          },
        ]

        localStorage.setItem(
          'overview',
          JSON.stringify({
            answer: update,
            ques: colorjson[leveldata],
            score: score,
          })
        )
        localStorage.setItem('useranswer', JSON.stringify(update))

        return update
      })

      if (prevPage == datalengthi - 1) {
        setTimeout(() => {
          submitResult()
          // navigate('colresult', { state: { ...location.state, date: date } })
          setTheArray([])
        }, 1000)
      } else {
        setScore((s) => s + 1)
        return prevPage + 1
      }
    })
  }

  useEffect(() => {
    SocketConfig.on('action', (data) => {
      console.log('Datta ', data)
      let parseddata = data.result.action
      setparsedata(parseddata)
      nextQuestion(false)
    })
    //
  }, [])

  useEffect(() => {
    counter > 0 &&
      setTimeout(() => {
        setCounter(counter - 1)
        if (counter == 0) {
        }
      }, 1000)
    if (counter == 0) {
      // nextQuestion(false);
    }
  }, [counter])

  function resetGame() {
    setTheArray([])
    setScore()
    setPage(0)
    setsmShow(false)
  }

  const renderquestion = () => {
    let questionvia
    switch (leveldata) {
      case 'level1':
        questionvia = (
          <div
            className='color-preview'
            data-attr={jsonData.ques.colorName}
            style={{ backgroundColor: jsonData.ques.color }}
          >
            <h6 data-attr={jsonData.ques.colorName} style={{ color: jsonData.ques.color }}></h6>
          </div>
        )
        break
      case 'level2':
        questionvia = (
          <div className='color-preview'>
            <h6 data-attr={jsonData.ques.colorName} style={{ color: jsonData.ques.color }}>
              {jsonData.ques.colorName}
            </h6>
          </div>
        )
        break
      case 'level3':
        questionvia = (
          <div data-attr={jsonData.ques.colorName} style={{ border: '1px solid #000' }} className='color-preview'>
            <h6 style={{ color: '#000' }}>{jsonData.ques.colorName}</h6>
          </div>
        )
    }
    return questionvia
  }
  const renderOption = (o, i) => {
    let renderhtml
    switch (leveldata) {
      case 'level1':
        renderhtml = (
          <Col md={jsonData.mcq.length == 3 ? 4 : 6}>
            <div
              className='box-item'
              onClick={() => nextQuestion(i.colorName)}
              data-attr={i.colorName}
              style={{
                backgroundColor: i.color,
              }}
            ></div>
          </Col>
        )
        break
      case 'level2':
        renderhtml = (
          <Col md={jsonData.mcq.length == 3 ? 4 : 6}>
            <div
              className='box-item'
              onClick={() => nextQuestion(i.colorName)}
              data-attr={i.colorName}
              style={{
                backgroundColor: i.color,
              }}
            >
              <p>{i.colorName}</p>
            </div>
          </Col>
        )
        break
      case 'level3':
        renderhtml = (
          <Col md={jsonData.mcq.length == 3 ? 4 : 6}>
            <div
              className='box-item'
              data-attr={i.colorName}
              onClick={() => nextQuestion(i.colorName)}
              style={{
                backgroundColor: i.color,
              }}
            ></div>
          </Col>
        )
        break
    }
    return renderhtml
  }

  if (jsonData != undefined) {
    return (
      <>
        <Row className=' colors-main-container'>
          <div className='question-card'>
            {renderquestion()}
            <Focus />
          </div>
          <h4 className='findmetxt'>Find Me ?</h4>
          <Row className='option-color-box'>
            {jsonData.mcq.map((i, o) => {
              return renderOption(o, i)
            })}
            {/* <div
              className="box-item"
              onClick={nextQuestion}
              style={{
                backgroundColor: jsonData.option1.color,
              }}
            >
              <p>{jsonData.option1.colorName}</p>
            </div> */}

            {/* <Col md={4}>
            <div
              className="box-item"
              onClick={nextQuestion}
              style={{
                backgroundColor: colorjson[location.level][page].option2.color,
              }}
            >
              <p>{colorjson[location.level][page].option2.colorName}</p>
            </div>
          </Col>
          <Col md={4}>
            <div
              className="box-item"
              onClick={nextQuestion}
              style={{
                backgroundColor: colorjson[location.level][page].option3.color,
              }}
            >
              <p>{colorjson[location.level][page].option3.colorName}</p>
            </div>
          </Col> */}
          </Row>
          <div className='activity-listing'>
            <ul>
              <li></li>
              <li className='active'>
                Score:<b>{colorjson[leveldata].length}</b>/<span>{score}</span>
              </li>
            </ul>

            <ul>
              <li>
                <Progress json={colorjson[leveldata]} currindex={page} getarray={theArray} />
              </li>
              <li className='refresh-btn'>
                <NavLink to='/school/game/level/colorresult'>
                  <button>
                    <img src={refreshbtn} alt='refresh' />
                    Start Another Level
                  </button>
                </NavLink>
                <button>
                  <img src={refreshbtn} alt='refresh' />
                  Start Over
                </button>
              </li>
            </ul>
          </div>
        </Row>

        <StartModal
          calldelay={setcallday}
          socket={SocketConfig}
          type={['focus']}
          date={date}
          letquesLength={colorjson[leveldata].length}
          calldaynum={callday}
        />
      </>
    )
  }
}
export default ColorGame

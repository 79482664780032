import React, { useCallback, useMemo } from 'react'
import { Spinner } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'

const baseStyle = {
  minHeight: '100px',
  width: '100%',
  padding: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#54baaf',
  overflow: 'hidden',
  borderRadius: '4px',
  outline: 'none',
  transition: 'background-color .24s ease-in-out',
}

const focusedStyle = {
  backgroundColor: '#54baaf',
}

const acceptStyle = {
  backgroundColor: '#193834',
}

const rejectStyle = {
  backgroundColor: '#ff1744',
}

const CustomDropzone = ({ isMultiple, onSubmit, loading }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (isMultiple) {
        onSubmit(acceptedFiles)
      } else {
        onSubmit(acceptedFiles[0])
      }
    },
    [isMultiple, onSubmit]
  )

  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'text/csv': [],
    },
    multiple: isMultiple,
    onDrop,
    disabled: loading,
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(loading ? { backgroundColor: '#d2d2d2' } : {}),
    }),
    [isFocused, isDragAccept, isDragReject, loading]
  )

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />

      <p style={{ margin: 0, fontSize: '22px', textAlign: 'center' }} className='text-white'>
        {loading ? (
          <div className='d-flex gap-2'>
            <Spinner animation='border' /> {isMultiple ? `Uploading ${acceptedFiles.length} Images` : 'Uploading'}
          </div>
        ) : isDragReject ? (
          'Some Files Might Be Reject'
        ) : isDragAccept ? (
          'All Files Accepted'
        ) : (
          "Drag or Upload Multiple CSV's"
        )}
      </p>
    </div>
  )
}

export default CustomDropzone

import react from 'react'
import { Row, Container, Col } from 'react-bootstrap'
import backbtn from '../../../assets/images/backbtn.svg'
import Focus from '../../../includes/Focus'
import Progress from '../Testprogrsscom'
import refreshbtn from '../../../assets/images/refresh.svg'
import Draggable, { DraggableCore } from 'react-draggable' // Both at the same time
import { useState, useRef, useEffect, useContext } from 'react'
import StartModal from '../../SelectgameStart'
import { NavLink, useLocation, useParams, useNavigate } from 'react-router-dom'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import * as Currdate from '../../../CurrDate'
import * as Apiurl from '../../../Apisurl'

import { useSpeechSynthesis } from 'react-speech-kit'
import leftarrow from '../../../assets/images/left.svg'
import rightarrow from '../../../assets/images/right.svg'
import { SocketConfig } from '../../../SocketConfig'
import Speech from '../../Speech'

const Fewmany = (props) => {
  let headingname = useParams()
  const { speak } = useSpeechSynthesis()
  // let date = (UserJson().date = GetCurrentDate());
  let DraggableCore = Draggable.DraggableCore
  const [score, setScore] = useState(0)
  const location = useLocation()
  const [isActive, setIsActive] = useState(false)
  let initialArray = []
  const [parsedata, setparsedata] = useState(false)

  const [mistakes, setMistakes] = useState(0)
  const [userAnswer, setUserAnswer] = useState('')
  const resetButton = useRef(null)
  const [smShow, setsmShow] = useState(false)
  const [img1, setimg1] = useState()
  const [img2, setimg2] = useState()

  let englishdatavalue = props.englishData
  console.log(englishdatavalue)
  let datalength = props.datalength
  let date = Currdate.GetCurrentDate()
  console.log(props.submitResult)
  props.time(date)
  let datalengthi = datalength
  const [callday, setcallday] = useState(1)
  let navigate = useNavigate()

  // function emitSocket() {
  //   let edudate = localStorage.setItem("edudate", date);

  //   Apiurl.Socketurl.emit("common", {
  //     result: "connect",
  //     l_video: [],
  //     modulename: "game",
  //     ...location.state,
  //   });
  // }

  // checkanswers
  function handleSubmit(e, via) {
    console.log(e)
    let userAnswer = e
    console.log(userAnswer)

    // alert(englishdatavalue.ans);
    // alert(userAnswer);
    console.log(englishdatavalue.ans, userAnswer)
    console.log(englishdatavalue.ans == userAnswer)
    // speak({ text: userAnswer });
    Speech.Speech(userAnswer)
    let correctAnswer
    if (englishdatavalue.ans == userAnswer || englishdatavalue.direction == userAnswer) {
      props.setPage((prev) => {
        props.setTheArray((oldArray) => {
          const update = [
            ...oldArray,
            {
              via: 'correct',
              answer: userAnswer,
            },
          ]
          console.log(update)
          localStorage.setItem('useranswer', JSON.stringify(update))

          return update
        })

        if (props.page === datalengthi.length - 1) {
          props.setTheArray([])
          // socketemitdata();
          props.submitResult(date)

          // navigate('enresult', {
          //   state: { ...location.state, date: date },
          // })
        } else {
          props.setScore((prev) => prev + 1)

          return prev + 1
        }
      })
      console.log(props.page)
    } else {
      props.setPage((prev) => {
        props.setTheArray((oldArray) => {
          const update = [
            ...oldArray,
            {
              via: 'incorrect',
              answer: userAnswer,
            },
          ]
          console.log(update)
          localStorage.setItem('useranswer', JSON.stringify(update))

          return update
        })
        if (props.page === datalengthi.length - 1) {
          SocketConfig.disconnect()
          props.submitResult(date)
          // navigate('enresult', {
          //   state: { ...location.state, date: date },
          // })
        } else {
          return prev + 1
        }
      })
    }
  }
  // console.log(englishdatavalue);
  function resetGame() {
    setScore(0)
    setMistakes(0)
    props.setTheArray([])
    setUserAnswer('')
    setsmShow(false)
  }

  useEffect(() => {
    SocketConfig.on('gesture_data', (data) => {
      console.log('Datta ', data)
      let parseddata = data.result.gesture
      if (parseddata.length != 0) {
        setparsedata(true)

        switch (parseddata.gesture) {
          case 'blink':
            handleSubmit(parseddata, true)
            break
          case 'smile':
            handleSubmit(parseddata, true)
            break
        }
      }
    })
    //
  }, [])
  console.log(englishdatavalue)

  if (englishdatavalue != undefined) {
    return (
      <>
        <Container fluid>
          <Row className='colors-main-container comparing-game  objgame '>
            <Col md={12}>
              <h5 style={{ textAlign: 'center', fontSize: '40px' }}>Find {englishdatavalue.type}</h5>
              <div className='addition-container  option_img d-flex justify-content-center flex-wrap  b-none'>
                <Row>
                  <Col md={4} className='m-auto'>
                    {[...Array(parseInt(englishdatavalue.occ_img1))].map((e, i) => {
                      console.log(englishdatavalue.image_1)
                      return <img src={englishdatavalue.image_1} className='img-item' alt='' />
                    })}
                  </Col>

                  <Col md={2} className='m-auto'>
                    <p className='color question-container w-100'>?</p>
                  </Col>
                  {/* <Col md={2}>
                    <div className="operator"> {props.operator}</div>
                  </Col> */}
                  <Col md={4}>
                    {[...Array(parseInt(englishdatavalue.occ_img2))].map((e, i) => {
                      return <img src={englishdatavalue.image_2} className='img-item' alt='' />
                    })}
                  </Col>
                </Row>
              </div>
            </Col>
            <Row className='measure-container'>
              {englishdatavalue.mcq.map((obj, index) => {
                return (
                  <Col
                    md={6}
                    className='measure-item'
                    onClick={() => handleSubmit(obj, false)}
                    style={{ fontSize: '40px' }}
                  >
                    {obj}
                  </Col>
                )
              })}
            </Row>
          </Row>
        </Container>
        <StartModal
          calldelay={setcallday}
          socket={SocketConfig}
          type={['action', 'focus']}
          date={date}
          letquesLength={datalength.length}
          calldaynum={callday}
        />
      </>
    )
  }
}
export default Fewmany

import { Col } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import Placeholder from './Placeholder'
import Speech from './Speech'

const ModuleCard = ({
  images,
  percentage,
  isActive,
  title,
  buttontxt,
  text,
  phase,
  phase1,
  onClick,
  loaderVia,
  link,
}) => {
  return (
    <>
      <Col md={4}>
        {loaderVia == 0 ? (
          <Placeholder />
        ) : (
          <li onClick={onClick} className={isActive == true ? 'active' : ''}>
            {percentage !== undefined ? (
              <div
                className='position-absolute d-flex justify-content-center align-items-center rounded-circle'
                style={{
                  width: '50px',
                  height: '50px',
                  right: '15px',
                  top: '15px',
                  background: `conic-gradient(var(--primary-color) ${(percentage / 100) * 360}deg, #e9e9e9 0deg)`,
                }}
              >
                <div
                  className='d-flex justify-content-center align-items-center bg-white text-black rounded-circle shadow'
                  style={{ width: '35px', height: '35px', fontSize: '12px', fontWeight: 800 }}
                >
                  {Math.round(percentage)}%
                </div>
              </div>
            ) : null}

            <figure>
              <img src={images} alt='education' />
            </figure>
            <figcaption>
              <h6>{title}</h6>
              <p className='text-center'>{text}</p>
              <div class='btn-container'>
                <NavLink
                  to={link}
                  onClick={(e) => Speech.Speech(title, link)}
                  state={{
                    modulename: title,
                    modulename1: phase,
                    modulename3: phase1,
                    modulename2: link,
                  }}
                >
                  <button class='common-btn m-0'>{buttontxt} </button>
                </NavLink>
              </div>
            </figcaption>
          </li>
        )}
      </Col>
    </>
  )
}

export default ModuleCard

import React, { useContext } from 'react'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions'
import { NavLink } from 'react-router-dom'
// import "react-data-table-component-extensions/dist/index.css";

import Context from '../Context'
function ReportTable(props) {
  const selectData = useContext(Context)
  
  const customStyles = {
    rows: {
      style: {
        minHeight: '80px', // override the row height
      },
    },

    cells: {
      style: {
        marginLeft: '35px',
        fontSize: '14px',
        padding: '0px',
        color: '#414141',
      },
    },
  }







  
  let data = selectData.reportTable.reports_table

  const redirectReport = (res) => {
    let redriectUrl = ''
    switch (res.toLowercase) {
      case 'education':
        redriectUrl = '/report/ereport'
        break
      case 'maths':
        redriectUrl = '/report/maths'
        break
      case 'colors':
        redriectUrl = '/report/colors'
        break
      case 'english':
        redriectUrl = '/report/english'
        break
      default:
        redriectUrl = '/report/' + res + ''
    }
    return redriectUrl
  }
  const columns = [
    {
      title: 'Module',
      selector: (row) => row.modulename,
    },
    {
      title: 'Category',
      selector: (row) => row.category,
    },
    {
      title: 'Level',
      selector: (row) => row.level,
    },
    {
      title: 'Date',
      selector: (row) => {
        return row.date
      },
    },
    {
      title: 'Score',
      selector: (row) => {
        return (
          <p className='score-badge'>
            <span>{row.score}</span>/{row.total}
          </p>
        )
      },
    },
    {
      title: 'Action',
      selector: (row) => {
        return (
          <NavLink
            // to={redirectReport(row.modulename)}
            to={`/report/${row.modulename}?id=${row.uid}`}
            state={{
              modulename2: row.modulename,
              modulename3: row.modulename1,
              username: row.username,
              category: row.category,
              level: row.level,
              userid: row.userid,
              date: row.date,
            }}
          >
            <button type='button' className='btn common-btn report-button w-100'>
              View Report
            </button>
          </NavLink>
        )
      },
    },
    // {
    //   title: 'Download',
    //   selector: (row) => {
    //     return (
    //       <svg
    //         xmlns='http://www.w3.org/2000/svg'
    //         width='32'
    //         height='32'
    //         fill='currentColor'
    //         class='bi bi-three-dots-vertical share-dots'
    //         viewBox='0 0 16 16'
    //       >
    //         <path d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z' />
    //       </svg>
    //     )
    //   },
    // },
  ]

  const tableData = {
    columns,
    data,
  }

  return (
    <div className='report-datatable'>
      <DataTableExtensions {...tableData}>
        <DataTable
          data={props.reportdata}
          pagination={5}
          defaultSortField='id'
          customStyles={customStyles}
          defaultSortAsc={false}
          highlightOnHover
          columns={columns}
          export={true}
        />
      </DataTableExtensions>
    </div>
  )
}

export default ReportTable

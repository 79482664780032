import { useState } from 'react'
import { Form, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import LevelCard from './LevelCards'

const GameMainComponent = () => {
  let location = useLocation()
  const [typeValue, setTypeValue] = useState('0')

  return (
    <>
      <h2>Please select Level to play </h2>
      <div className='d-flex justify-content-between play-type-container'>
        <p className='txt-secondary'>
          This game works on your attentionand focus. Whenever you lose your attention the questions will fade away
          until you start focusing back. Time & question at each level will be noted,to generate a comprenhesive report.
        </p>

        <div className='form-container'>
          <label>Select Type</label>
          <Form.Select onChange={(e) => setTypeValue(e.target.value)} value={typeValue}>
            <option value='0'>Mouse</option>
            <option value='1'>Action</option>
            <option value='2'> Gesture</option>
          </Form.Select>
        </div>
      </div>
      <Row className='game-card-container educationCard'>
        <LevelCard title='Level 1' link='level1' category={location.state} setNewvia={true} typeValue={typeValue} />
        <LevelCard title='Level 2' link='level2' category={location.state} setNewvia={true} typeValue={typeValue} />
        <LevelCard title='Level 3' link='level3' category={location.state} setNewvia={true} typeValue={typeValue} />
      </Row>
    </>
  )
}
export default GameMainComponent

import React from 'react'
import socketio from 'socket.io-client'
import * as API from './Apisurl'

const SocketConfig =
  sessionStorage.getItem('headsetVia') == 2
    ? socketio.connect.io(API.Fetchurl, {
        upgrade: true,
      })
    : socketio.connect.io(API.Fetchurl, {
        transports: ['websocket', 'polling'],
      })

export { SocketConfig }

import React, { useRef } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import BackComp from '../BackComp'
import EducationCard from './EducationCards'

const Education = () => {
  let locationdata = useLocation()
  let data = JSON.parse(localStorage.getItem('modulejson'))

  return (
    <>
      <h3>Please select a module to proceed</h3>
      <BackComp title={locationdata.state.category.toUpperCase()} />
      <Container>
        <Row className='educationCard'>
          {data != undefined && data != ''
            ? data[locationdata.state.modulename1].map((obj, index) => {
                if (index != 0) {
                  if (obj.module.toUpperCase() == locationdata.state.modulename.toUpperCase()) {
                    return obj.categories.map((obj, index) => {
                      if (obj.name.toUpperCase() == locationdata.state.category.toUpperCase()) {
                        return obj.levels.map((obj, index) => {
                          console.log(obj)
                          return (
                            <EducationCard
                              title={'Level' + ' ' + parseInt(index + 1)}
                              setNewvia={obj.attempted}
                              level={obj.name}
                              images={obj.image}
                              video={obj.video}
                            />
                          )
                        })
                      }
                    })
                  }
                }
              })
            : ''}
        </Row>
      </Container>
    </>
  )
}
export default Education

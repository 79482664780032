import React from "react";
import { Col } from "react-bootstrap";
const CardPlaceholder = (props) => {
  return (
    <li className=" card--skeleton">
      <figure className="image">
        <img
          src="https://ui-nema-database.s3.ap-south-1.amazonaws.com/thumbnails/games.svg"
          alt="education"
        />
      </figure>
      <figcaption className="">
        <h6>
          <span>.</span>
        </h6>
        <p className="text-center">
          <span>.</span>
        </p>
        <div
          className="btn-container"
          style={props.btnVia == 1 ? { display: "block" } : { display: "none" }}
        >
          <button class="common-btn">.....</button>
        </div>
      </figcaption>
    </li>
  );
};
export default CardPlaceholder;

import react from "react";
import { Row, Container, Col } from "react-bootstrap";
import backbtn from "../../../assets/images/backbtn.svg";
import { useEffect } from "react";
import Draggable, { DraggableCore } from "react-draggable"; // Both at the same time
import { useState } from "react";
import ChartistGraph from "react-chartist";
import { NavLink } from "react-router-dom";
import refreshbtn from "../../../assets/images/refresh.svg";
import Focus from "../../../includes/Focus";
import * as Apiurl from "../../../Apisurl";
import Progress from "../Testprogrsscom";
import { Bar, getDatasetAtEvent } from "react-chartjs-2";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Form } from "react-bootstrap";
import { SocketConfig } from "../../../SocketConfig";

import "chartjs-plugin-datalabels";
import StartModal from "../../SelectgameStart";

const Gif = () => {
  const [play1, setplay1] = useState(false);
  const [timer, setTimer] = useState();
  const [showtimer1, setshowtimer1] = useState(false);
  const [smile, setSmileStatus] = useState(false);
  const [clinch, setclinch] = useState(false);
  const [frowning, setfrowning] = useState(false);
  const [flushedface, setflushedface] = useState(false);
  const [teethSmile, setteethSmile] = useState(false);
  const [blinkeye, setblinkeye] = useState(false);
  const [score, setScore] = useState(0);
  const [smilestat, setsmilestate] = useState("");
  const [callday, setcallday] = useState(1);
  const [pm_data, setpm_data] = useState([10, 30, 40, 50, 60]);
  const [clenchstat, setclenchstat] = useState("");
  const [page, setPage] = useState(0);
  const initialArray = [];
  const [theArray, setTheArray] = useState(initialArray);
  let emojiquestion = {
    level1: [
      {
        emoji: "https://c.tenor.com/pkQdRNIFqoUAAAAi/happy-smile.gif",
        figcaption: "Smile",
      },
      {
        emoji:
          "https://cliply.co/wp-content/uploads/2021/03/392103110_ANGRY_EMOJI_400px.gif",
        figcaption: "Frown",
      },
      {
        emoji:
          "https://c.tenor.com/_u8OV-NlmLEAAAAi/flushed-face-joypixels.gif",
        figcaption: "Surprise",
      },
      {
        emoji: "https://c.tenor.com/NUdIBGkCQ1gAAAAi/teeth-smile.gif",
        figcaption: "Clench teeth",
      },
      {
        emoji: "https://c.tenor.com/xh620f6skRQAAAAi/beautiful-eyes-blink.gif",
        figcaption: "Blinking Eye",
      },
    ],
  };

  const [callDelay1, setCalldelay1] = useState(0);
  const handleChange = (e) => {
    setCalldelay1(e.target.value);
    setplay1(true);
  };
  const startCountDown = (e) => {
    let timeleft = e.target.value;
    let downloadTimer = setInterval(function () {
      timeleft--;
      setTimer(timeleft);
      if (timeleft <= 0) {
        clearInterval(downloadTimer);
        setTimer(0);
        nextQuestion("");
      }
    }, 1000);
  };

  function nextQuestion(e) {
    console.log(e);
    let checkanswer = e;

    if (emojiquestion.level1[page].figcaption.toLowerCase() == checkanswer) {


 setScore((prev) => prev + 1);
      setPage((prev) => {
        if (prev === emojiquestion.level1.length - 1) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    } else {
      // setTheArray((oldArray) => {
      //   const update = [
      //     ...oldArray,
      //     {
      //       via: "incorrect",
      //       answer: checkanswer,
      //     },
      //   ];
      // });

      setPage((prev) => {
        if (prev === emojiquestion.level1.length - 1) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    }
  }
  useEffect(() => {
    SocketConfig.on("gesture_data", (data) => {
      console.log("Datta ", data);
      let parseddata = data.result;
      nextQuestion(parseddata);
      console.log(parseddata);
      switch (parseddata.gesture) {
        case "smile":
          setSmileStatus(true);

          break;
        case "clench":
          setclinch(true);
          break;
        case "frown":
          setfrowning(true);
          break;
        case "surprise":
          setflushedface(true);
          break;
        // case "teethSmile":setteethSmile(true);
        // break;
        case "blink":
          setblinkeye(true);
          break;
        default:
          setSmileStatus(false);
          setclinch(false);
          setfrowning(false);
          setflushedface(false);
          setblinkeye(false);
      }
    });
    //
  }, []);

  var data = {
    labels: ["Attention", "Focus", "Interest", "Relaxed"],
    series: [pm_data],
  };
  const onDrawHandler = (e) => {
    if (e.element._node.classList[0] == "ct-bar") {
      e.element.attr({
        style: "stroke-width: 60px;stroke:#E4F4F2",
      });
    }
    if (e.type == "bar") {
      e.element.animate({
        y2: {
          dur: "0.2s",
          from: e.y1,

          to: e.y2,
        },
      });
    }
  };
  var options = {
    height: 300,
  };

  var type = "Bar";
  return (
    <Container fluid>
      <Row className=" colors-main-container">
        <Col md={6} style={{ borderRight: "1px solid #c4c4c4" }}>
          <div className="d-flex align-items-center justify-content-between"></div>
          <select className="profile-container" onChange={startCountDown}>
            <option value="6">6 Seconds</option>
            <option value="10">10 Seconds</option>
            <option value="15">15 Seconds</option>
            <option value="20">20 Seconds</option>
            <option value="25">25 Seconds</option>
            <option value="30">30 Seconds</option>
            <option value="35">35 Seconds</option>
            <option value="40">40 Seconds</option>
            <option value="45">45 Seconds</option>
            <option value="50">50 Seconds</option>
            <option value="55">55 Seconds</option>
            <option value="60">60 Seconds</option>
          </select>

          <p>00:{timer}</p>
          <figure>
            <img src={emojiquestion.level1[page].emoji} />
            <figcaption>{emojiquestion.level1[page].figcaption}</figcaption>
            <p>Current Expression</p>
          </figure>
        </Col>
        <Col md={6} className="mt-auto">
          <Focus />
          <div className="my-2 w-30">
            <Bar
              style={{ marginTop: "20px" }}
              data={{
                labels: ["Smile", "Clench", "Frown", "Surprise", "Blink Eye"],

                datasets: [
                  {
                    barPercentage: 1,
                    barThickness: 80,
                    maxBarThickness: 60,
                    borderRadius: 8,

                    maintainAspectRatio: false,
                    data: [20, 30, 40, 100],
                    backgroundColor: (context: ScriptableContext<"line">) => {
                      const ctx = context.chart.ctx;
                      const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                      gradient.addColorStop(0, "#eaf6f6");
                      gradient.addColorStop(1, "#e0f3f1");
                      return gradient;
                    },
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,

                    labels: {
                      color: "rgb(255, 99, 132)",

                      font: {
                        size: "30px",
                      },
                    },

                    title: {
                      display: false,
                      text: "Custom Chart Title",
                    },
                  },
                },

                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    type: "linear",
                    display: false,
                    position: "left",
                    grid: {
                      display: false,
                    },
                  },
                },
              }}
            />
          </div>
          <p style={{ textAlign: "center" }}>
            Facial Expression : Live Summary{" "}
            <span
              style={{
                borderRadius: "50%",
                backgroundColor: "green",
                height: "14px",
                width: "14px",
              }}
            ></span>
          </p>
        </Col>
      </Row>

      {/* <CountdownCircleTimer
        isPlaying={play1}
        duration={parseInt(callDelay1)}
        colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
        colorsTime={[7, 5, 2, 0]}
        onComplete={() => {
          setshowtimer1(false);
        }}
      >
        {({ remainingTime }) => "Starting In" + "   " + remainingTime}
      </CountdownCircleTimer> */}
      <div className="activity-listing">
        <ul>
          <li>
            <Progress
              json={emojiquestion.level1}
              currindex={page}
              getarray={theArray}
            />
          </li>
          <li className="refresh-btn">
            <NavLink to="/school/game/level/emoresult">
              <button>
                <img src={refreshbtn} alt="refresh" />
                Start Another Level
              </button>
            </NavLink>
            <button>
              <img src={refreshbtn} alt="refresh" />
              Start Over
            </button>
          </li>
        </ul>
      </div>
      <StartModal
        calldelay={setcallday}
        socket={SocketConfig}
        type={["gesture", "focus"]}
        calldaynum={callday}
      />
    </Container>
  );
};
export default Gif;

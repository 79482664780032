import React, { useEffect, useState } from 'react'
import { Col, Container, FloatingLabel, Form, Modal, Row } from 'react-bootstrap'
import Formstyle from '../../teacher/cssModule/Formstyle.module.css'
import * as Apiurl from '../../Apisurl'
import headersReq from '../../HeadersJson'

import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions'
import Activity from '../../dashboard/Activity'
import { useParams } from 'react-router-dom'

const Users = () => {
  const reqJson = headersReq()
  const { role } = useParams()
  const [show, setShow] = useState(false)
  const [validated, setValidated] = useState(false)
  const [formData, setFormData] = useState({
    username: '',
    email_id: '',
    password: '',
    phone_number: '',
    duration: '',
    max_student: '',
    secret_key: '',
    module_start: '',
    module_end: '',
    person_type: role,
    activation: '',
    deactivation_date: '',
    status: '',
  })
  const [teacherData, setTeacherData] = useState({
    teacher_uid: '',
    school_name: '',
    speciality: '',
  })
  const [parentData, setParentData] = useState({
    parent_uid: '',
    location: '',
  })
  const [data, setData] = useState()

  const getUsers = async () => {
    try {
      const response = await fetch(`${Apiurl.Fetchurl}user_data?person_type=${role}`, {
        method: 'GET',
        headers: reqJson,
      })
      if (response.ok) {
        const data = await response.json()
        setData(data.user_data)
        console.log(data)
      } else {
        throw new Error('Something went wrong...')
      }
    } catch (error) {
      console.log('user data \n', error.message)
    }
  }

  useEffect(() => {
    getUsers()
  }, [])

  const handleClose = () => {
    setShow(false)
  }

  const handleShow = (data) => {
    setFormData({
      username: data.username,
      email_id: data.email_id,
      password: data.password,
      phone_number: data.phone_number,
      duration: data.duration,
      max_student: data.max_student,
      secret_key: data.secret_key,
      module_start: data.module_start,
      module_end: data.module_end,
      person_type: role,
      activation: data.activation,
      deactivation_date: data.deactivation_date,
      status: data.status,
    })
    setTeacherData({
      teacher_uid: data.teacher_uid,
      school_name: data.school_name,
      speciality: data.speciality,
    })
    setParentData({
      parent_uid: data.parent_uid,
      location: data.location,
    })
    setShow(true)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((old) => ({ ...old, [name]: value }))
  }
  const handleTeacherChange = (e) => {
    const { name, value } = e.target
    setTeacherData((old) => ({ ...old, [name]: value }))
  }
  const handleParentChange = (e) => {
    const { name, value } = e.target
    setParentData((old) => ({ ...old, [name]: value }))
  }

  const update = async () => {
    let extra = role === 'parent' ? { ...parentData } : { ...teacherData }
    try {
      const response = await fetch(`${Apiurl.Fetchurl}user_data`, {
        method: 'POST',
        headers: reqJson,
        body: JSON.stringify({
          ...formData,
          ...extra,
        }),
      })
      if (response.ok) {
        getUsers()
      } else {
        throw new Error('Something went wrong...')
      }
    } catch (error) {
      console.log('user data \n', error.message)
    }

    handleClose()
  }

  const submit = (e) => {
    e.preventDefault()
    if (e.currentTarget.checkValidity() === false) {
      e.stopPropagation()
    } else {
      update()
    }
    setValidated(true)
  }

  const extra_col =
    role === 'parent'
      ? [{ name: 'location', selector: (row) => row.location, sortable: true }]
      : [
          { name: 'school', selector: (row) => row.school_name, sortable: true },
          { name: 'speciality', selector: (row) => row.speciality, sortable: true },
        ]

  const tableData = {
    columns: [
      { name: 'username', selector: (row) => row.username, sortable: true },
      { name: 'phone number', selector: (row) => row.phone_number, sortable: true },
      { name: 'email', selector: (row) => row.email_id, sortable: true },
      ...extra_col,
      { name: 'duration', selector: (row) => row.duration, sortable: true },
      { name: 'max students', selector: (row) => row.max_student, sortable: true },
      { name: 'module start', selector: (row) => row.module_start, sortable: true },
      { name: 'module end', selector: (row) => row.module_end, sortable: true },
      { name: 'person type', selector: (row) => row.person_type, sortable: true },
      { name: 'activation', selector: (row) => row.activation },
      { name: 'deactivation_date', selector: (row) => row.deactivation_date },
      { name: 'status', selector: (row) => row.status },
      {
        name: 'action',
        selector: (row) => {
          const data = { ...row }
          return (
            <button
              onClick={() => handleShow(data)}
              type='submit'
              className='d-flex justify-content-center align-items-center py-2 px-4 border-0 rounded-pill text-white'
              style={{ backgroundColor: 'var(--primary-color)' }}
            >
              edit
            </button>
          )
        },
      },
    ],
    data: data,
  }

  return (
    <div className='content'>
      <Container fluid className='option-main-container report-tab'>
        <div className='dashboard-heading'>
          <div class='content-container'>
            <h2 className='text-capitalize'>{role}</h2>
            <p>Edit {role} data</p>
          </div>
          <Activity />
        </div>

        <div className='border rounded-3 my-3'>
          <DataTableExtensions {...tableData}>
            <DataTable pagination customStyles={customStyles} highlightOnHover />
          </DataTableExtensions>
        </div>

        <Modal size='lg' centered show={show} onHide={handleClose} className='FormModal'>
          <Form noValidate validated={validated} onSubmit={submit}>
            <Modal.Header closeButton className='px-4'>
              <h2 className='m-0'>Edit</h2>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <FloatingLabel controlId='floatingInput' label='User name' required className={Formstyle.labelinput}>
                    <Form.Control
                      type='text'
                      placeholder='xcvvx'
                      maxLength='40'
                      required
                      name='username'
                      value={formData.username}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <div className={Formstyle.mobContainer}>
                    <FloatingLabel
                      controlId='floatingInput'
                      label='Phone Number'
                      required
                      className={Formstyle.labelinput}
                    >
                      <Form.Control
                        type='number'
                        maxLength='40'
                        placeholder='asdasd'
                        value={formData.phone_number}
                        onChange={handleChange}
                        name='phone_number'
                        required
                      />
                      <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                    </FloatingLabel>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FloatingLabel controlId='floatingInput' label='Email' className='mb-3'>
                    <Form.Control
                      type='email'
                      value={formData.email_id}
                      onChange={handleChange}
                      name='email_id'
                      placeholder='name@example.com'
                      maxLength='40'
                      required
                    />
                    <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId='floatingInput' label='Status' className={Formstyle.labelinput}>
                    <Form.Select
                      required
                      aria-label='Floating label select example'
                      value={formData.status}
                      onChange={handleChange}
                      name='status'
                    >
                      <option value='Active'>Active</option>
                      <option value='Inactive'>Inactive</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
              </Row>
              {role === 'teacher' ? (
                <Row>
                  <Col>
                    <FloatingLabel controlId='floatingInput' label='School' required className='mb-3'>
                      <Form.Control
                        type='text'
                        placeholder='asdsa'
                        name='school_name'
                        value={teacherData.school_name}
                        onChange={handleTeacherChange}
                        maxLength='40'
                        required
                      />
                      <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <FloatingLabel controlId='floatingSelect' label='Speciality'>
                      <Form.Select
                        required
                        aria-label='Floating label select example'
                        value={teacherData.speciality}
                        onChange={handleTeacherChange}
                        name='speciality'
                      >
                        <option value=''>Select</option>
                        <option value='educator'>Special educator</option>
                        <option value='therapis'>Speech therapist</option>
                        <option value='assessor'>Technical assessor</option>
                        <option value='other'>Other</option>
                      </Form.Select>
                      <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col>
                    <FloatingLabel controlId='floatingSelect' label='Location'>
                      <Form.Control
                        type='text'
                        placeholder='location'
                        name='location'
                        value={parentData.location}
                        onChange={handleParentChange}
                        maxLength='40'
                        required
                      />
                      <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <FloatingLabel
                    controlId='floatingInput'
                    label='Subscription (Months)'
                    required
                    className={Formstyle.labelinput}
                  >
                    <Form.Control
                      type='number'
                      max={12}
                      min={1}
                      placeholder='1'
                      value={formData.duration}
                      onChange={handleChange}
                      name='duration'
                      required
                    />
                    <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel
                    controlId='floatingInput'
                    label='No of Students'
                    required
                    className={Formstyle.labelinput}
                  >
                    <Form.Control
                      type='number'
                      max={100}
                      min={1}
                      placeholder='1'
                      value={formData.max_student}
                      onChange={handleChange}
                      name='max_student'
                      required
                    />
                    <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FloatingLabel controlId='floatingInput' label='Module end' required className={Formstyle.labelinput}>
                    <Form.Control
                      type='number'
                      min={1}
                      placeholder='1'
                      value={formData.module_start}
                      onChange={handleChange}
                      name='module_start'
                      required
                    />
                    <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId='floatingInput' label='Module end' required className={Formstyle.labelinput}>
                    <Form.Control
                      type='number'
                      min={1}
                      placeholder='1'
                      value={formData.module_end}
                      onChange={handleChange}
                      name='module_end'
                      required
                    />
                    <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FloatingLabel controlId='floatingInput' label='Activation Date' className={Formstyle.labelinput}>
                    <Form.Control
                      required
                      type='text'
                      placeholder='******'
                      name='activation'
                      value={formData.activation}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId='floatingInput' label='Deactivation Date' className={Formstyle.labelinput}>
                    <Form.Control
                      required
                      type='text'
                      placeholder='******'
                      name='deactivation_date'
                      value={formData.deactivation_date}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FloatingLabel controlId='floatingInput' label='Access Key' className={Formstyle.labelinput}>
                    <Form.Control
                      type='text'
                      placeholder='******'
                      name='secret_key'
                      value={formData.secret_key}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <button
                type='button'
                className='d-flex justify-content-center align-items-center py-1 px-3 rounded-pill border border-2 text-secondary bg-transparent'
                onClick={() => setShow(false)}
              >
                Close
              </button>
              <button
                type='submit'
                className='d-flex justify-content-center align-items-center py-1 px-3 border-0 rounded-pill text-white'
                style={{ backgroundColor: 'var(--primary-color)' }}
              >
                Done
              </button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Container>
    </div>
  )
}

export default Users

const customStyles = {
  headRow: {
    style: {
      minHeight: '65px',
      color: 'var(--primary-color)',
      fontSize: '14px',
      textTransform: 'capitalize',
      letterSpacing: '0.5px',
    },
  },
  headCells: {
    style: { width: '200px' },
  },
  rows: {
    style: {
      minHeight: '65px',
    },
  },
  cells: {
    style: {
      fontSize: '14px',
      color: '#414141',
    },
  },
}

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { Line } from 'react-chartjs-2'
import { useTimer } from 'react-timer-hook'
import * as API from '../../Apisurl'
import Context from '../../Context'
import { SocketConfig } from '../../SocketConfig'
import coin from '../../assets/audio/coin.mp3'
import rocket from '../../assets/images/rocket2.png'
import ArrowLeft from '../../assets/images/arrow_left_icon.svg'
import ArrowRight from '../../assets/images/right_arrow_icon.svg'
import './rocket2.css'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const Rocket2 = () => {
  const { selectValue } = useContext(Context)

  const [running, setRunning] = useState(false)
  const [attention, setAttention] = useState(0)
  const [totalAttention, setTotalAttention] = useState([])
  const [score, setScore] = useState(0)
  const [scoreLR, setScoreLR] = useState(0)

  const [bottom, setBottom] = useState(-4)
  const [showCoin, setShowCoin] = useState(false)
  const [showCoin1, setShowCoin1] = useState(false)
  const [showCoin2, setShowCoin2] = useState(false)

  const [show, setShow] = useState(false)
  const [showT, setShowT] = useState(false)
  const [showR, setShowR] = useState(false)

  const [validated, setValidated] = useState(false)
  const [data, setData] = useState({
    name: '',
    duration: 1,
    bet: 1,
    category: '',
  })

  const { minutes, seconds, restart, pause } = useTimer({
    autoStart: false,
    expiryTimestamp: data.duration,
  })

  const { seconds: secs, restart: rstrt, isRunning: isRunningT } = useTimer({
    autoStart: false,
    expiryTimestamp: 10,
    onExpire: () => gameStart(),
  })

  useEffect(() => {
    isRunningT &&
      SocketConfig.on('rocket_data', (data) => {
        if (data && data?.result !== 'pause' && data.data_store !== undefined) {
          setAttention(parseInt(data.data_store))
        }
      })
  }, [isRunningT])

  let interval

  const handleChange = (e) => {
    const { name, value } = e.target
    setData((old) => ({ ...old, [name]: value }))
  }

  const check = (e) => {
    e.preventDefault()
    if (e.currentTarget.checkValidity() === false) {
      e.stopPropagation()
    } else {
      // socket
      SocketConfig.emit('rocket', {
        result: 'connect',
        username: data.name,
        bet: data.bet,
        time: data.duration,
        category: data.category,
      })

      // gameStart()
      setShow(false)
      setShowT(true)

      let time = new Date()
      let expiryTimestamp = time.setSeconds(time.getSeconds() + 10)
      rstrt(expiryTimestamp)
    }
    setValidated(true)
  }

  const gameStart = () => {
    setShowT(false)
    // reset
    setScore(0)
    setTotalAttention([])

    // start game timer
    let time = new Date()
    restart(time.setMinutes(time.getMinutes() + data.duration))
    setRunning(true)
    stars()

    // stop after X mins
    setTimeout(() => {
      gameOver()
    }, [data.duration * (1000 * 60)])
  }

  const saveReport = async (res) => {
    try {
      await fetch(`${API.Fetchurl}games_data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('session')}`,
        },
        body: JSON.stringify(res),
      })
    } catch (error) {
      console.log(error)
    }
  }

  const gameOver = async () => {
    console.log(score, scoreLR)
    SocketConfig.off('rocket_data')

    let report = {
      user_id: selectValue.user_id,
      username: data.name,
      bet: data.bet,
      time: data.duration,
      category: data.category,
      score: JSON.stringify({ score_game: scoreLR, score_brain: score }),
      bet_array: totalAttention,
    }

    await saveReport(report)

    // stop game n remove stars
    setRunning(false)
    clearInterval(interval)
    removeStars()

    // reset values
    setAttention(0)
    setBottom(-4)
    setShowCoin(false)

    //play again modal
    setShowR(true)
  }

  const stars = () => {
    let count = 100
    let scene = document.querySelector('.scene')
    let i = 0
    while (i < count) {
      let star = document.createElement('i')
      let x = Math.floor(Math.random() * window.innerWidth)
      let duration = Math.random() * 2
      let h = Math.random() * 200
      star.style.left = x + 'px'
      star.style.width = 1.5 + 'px'
      star.style.height = 50 + h + 'px'
      star.style.animationDuration = duration + 's'
      scene.appendChild(star)
      i++
    }
  }

  const removeStars = () => {
    let stars = document.getElementsByTagName('i')
    Array.from(stars).forEach((star) => {
      star.remove()
    })
  }

  useEffect(() => {
    running &&
      SocketConfig.on('rocket_data', (data) => {
        console.log(data)
        if (data?.data_store?.toLowerCase() === 'please wear the headset correctly') {
          pause()
          setRunning(false)
        } else {
          if (data && data?.result !== 'pause' && data.data_store !== undefined) {
            console.log(data.data_store)
            setAttention(parseInt(data.data_store))
            setTotalAttention((old) => {
              return [...old, parseInt(data.data_store)]
            })
          }
        }
      })
  }, [running, pause])

  useEffect(() => {
    if (running && totalAttention.at(-1) >= data.bet) {
      setShowCoin(true)
      setShowCoin1(true)
      setShowCoin2(true)
      let coinSound = new Audio(coin)
      coinSound.play()

      setTimeout(() => {
        setShowCoin(false)
      }, 400)
      setTimeout(() => {
        setShowCoin1(false)
      }, 600)
      setTimeout(() => {
        setShowCoin2(false)
      }, 800)

      let increase = 0

      if (totalAttention.length >= 2) {
        let curr = totalAttention.at(-1),
          prev = totalAttention.at(-2)
        let total = (curr + prev) / 2,
          diff = curr - prev

        let perc = Math.floor((diff / total) * 100)

        increase = perc <= 20 ? 3 : perc <= 60 ? 7 : perc <= 80 ? 12 : 15
      }

      setBottom((old) => {
        if (old >= 80) {
          return 80
        } else {
          return old + increase
        }
      })
      setScore((s) => s + 3)
    }
  }, [totalAttention, totalAttention.length, data.bet, running])

  return (
    <div className='flex-grow-1'>
      <Row>
        <Col lg={4}>
          <div className='scene'>
            {/* Attention meter */}
            <div className='z-3 position-absolute top-0 start-0 m-2 '>
              <div
                className='position-relative rounded-pill border border-3'
                style={{
                  height: '200px',
                  width: '50px',
                  background:
                    'linear-gradient(0deg, rgba(255,0,0,1) 0%, rgba(255,145,0,1) 33%, rgba(254,255,0,1) 66%, rgba(9,255,0,1) 100%)',
                }}
              >
                <div
                  className='position-absolute text-center fw-bold fs-3 text-black'
                  style={{ zIndex: 4, left: '50%', transform: 'translateX(-50%)' }}
                >
                  {attention}
                </div>
                <div
                  style={{
                    height: '4px',
                    width: '60px',
                    position: 'absolute',
                    backgroundColor: 'white',
                    borderRadius: '12ch',
                    left: '-5%',
                    bottom: attention <= 10 ? '10%' : attention >= 90 ? '90%' : `${attention}%`,
                  }}
                />
              </div>
            </div>

            {!running && (
              <div className='position-absolute w-50'>
                <h1 className='text-white'>A Calm and focus mindset can fly the rocket for longer</h1>
              </div>
            )}

            {/* Score for rocket :: Coins collected */}
            <div className='z-3 position-absolute top-0 end-0 d-flex align-items-center gap-3 m-2 rounded-3 px-3 py-1'>
              <div
                className='d-flex justify-content-center align-items-center'
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  height: '65px',
                  width: '65px',
                  background: '#ffbd0b',
                  borderRadius: '50%',
                  borderTop: '4px solid #ffd84c',
                  borderLeft: '4px solid #ffd84c',
                  borderRight: '4px solid #d57e08',
                  borderBottom: '4px solid #d57e08',
                  transform: 'rotate(45deg)',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '35px',
                    height: '35px',
                    background: '#f0a608',
                    borderRadius: '50%',
                    borderBottom: '3px solid #ffd84c',
                    borderRight: '3px solid #ffd84c',
                    borderLeft: '3px solid #d57e08',
                    borderTop: '3px solid #d57e08',
                    fontSize: '22px',
                    textAlign: 'center',
                    textShadow: '0 3px 0 #cb7407',
                    color: '#ffbd0b',
                    transform: 'rotate(-45deg)',
                  }}
                >
                  $
                </div>
                <div
                  style={{
                    position: 'absolute',
                    height: '200px',
                    width: '40px',
                    left: '-60%',
                    boxShadow:
                      '50px -23px 0 -10px rgba(255, 255, 255, 0.22), 85px -10px 0 -16px rgba(255, 255, 255, 0.19)',
                  }}
                />
              </div>
              <h1 className='text-white m-0'>{score}</h1>
            </div>

            {/* Rocket */}
            <div className='rocket' style={{ bottom: `${bottom}%` }}>
              <img className='rocket-img' src={rocket} alt='rocket' height={187.5} width={75} />
              <Coin show={showCoin} top={0} />
              <Coin show={showCoin1} top={20} />
              <Coin show={showCoin2} top={40} />
            </div>
          </div>
        </Col>

        <Col lg={8} className='p-4'>
          <div
            className='h-100 d-flex flex-column flex-wrap justify-content-center align-items-center mx-auto'
            style={{ maxWidth: '50%' }}
          >
            <h4 className=''>Click the correct key on your keyboard to gain points.</h4>
            <LeftRightGame isPlaying={running} setScore={setScoreLR} />

            <button
              type='button'
              onClick={() => setShow(true)}
              className={`game-btn bg-warning text-black ${running && 'opacity-50'} mt-2`}
              disabled={running}
            >
              {running ? 'Playing' : 'Play'}
            </button>

            <h1 className='dashboard-cards p-2 fs-2'>
              Time Left :
              <span className='ms-2 '>
                {minutes <= 9 && '0'}
                {minutes} : {seconds <= 9 && '0'}
                {seconds}
              </span>
            </h1>
            <h1 className='dashboard-cards p-2 fs-2'>
              Score : <span className='ms-2 '>{scoreLR}</span>
            </h1>
          </div>
        </Col>
      </Row>

      {/* countdown modal 10 9 8 ..... */}
      <Modal backdrop='static' keyboard={false} centered show={showT} onHide={setShowT} className='FormModal'>
        <div className='d-flex justify-content-center align-items-center rounded-circle'>
          <h1 className='m-0' style={{ fontSize: '160px' }}>
            {secs}
          </h1>
        </div>
      </Modal>

      {/* Game Start Modal */}
      <Modal size='md' backdrop='static' keyboard={false} centered show={show} onHide={setShow} className='FormModal'>
        <Form noValidate validated={validated} onSubmit={check}>
          <Modal.Header className='px-4'>
            <h2 className='m-0'>Attention Fueled Rocket</h2>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Form.Group className='mb-2' controlId='name'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    name='name'
                    value={data.name}
                    onChange={handleChange}
                    maxLength='30'
                  />
                  <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId='category' className='mb-2'>
                  <Form.Label>Bet on</Form.Label>
                  <Form.Select name='category' value={data.category} onChange={handleChange} required>
                    <option value=''>Select your category of bet</option>
                    <option value='Attention'>Attention</option>
                    <option value='Focus'>Focus</option>
                    <option value='Interest'>Interest</option>
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId='bet'>
                  <Form.Label>
                    Attention Level <span className='text-black'>(1-100)*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type='number'
                    name='bet'
                    value={data.bet}
                    onChange={handleChange}
                    min={1}
                    max={100}
                  />
                  <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId='duration'>
                  <Form.Label>
                    Play for <span className='text-black'>(mins)*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type='number'
                    name='duration'
                    value={data.duration}
                    onChange={handleChange}
                    min={1}
                    max={10}
                  />
                  <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => setShow(false)}
              type='button'
              className='d-flex justify-content-center align-items-center py-1 px-3 border rounded-pill bg-transparent text-black'
            >
              Close
            </button>
            <button
              type='submit'
              className='d-flex justify-content-center align-items-center py-1 px-3 border-0 rounded-pill text-white'
              style={{ backgroundColor: 'var(--green-color)' }}
            >
              Start
            </button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Game Over Modal */}
      <Modal size='lg' centered show={showR} onHide={setShowR} className='FormModal'>
        <Modal.Header closeButton className='px-4'>
          <h2 className='m-0'>Game Over</h2>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={4}>
              <div className='d-flex justify-content-between align-items-center border border-2 border-dark rounded-pill p-1'>
                <h4 className='m-0 ms-2'>Score</h4>
                <h2 className='m-0 rounded-pill bg-info px-3 py-1 text-white'>{score}</h2>
              </div>
            </Col>
            <Col md={8}>
              <div className='d-flex gap-4 justify-content-between align-items-center border border-2 border-dark rounded-pill p-1'>
                <h4 className='m-0 ms-2 text-capitalize'>{data.category}</h4>
                <div className='d-flex gap-2'>
                  <h2 className='m-0 bg-success rounded-pill px-3 py-1 text-white'>
                    H : {Math.max(...totalAttention)}
                  </h2>
                  <h2 className='m-0 bg-danger rounded-pill px-3 py-1 text-white'>L : {Math.min(...totalAttention)}</h2>
                  <h2 className='m-0 bg-warning rounded-pill px-3 py-1 text-white'>
                    A : {(totalAttention.reduce((total, curr) => total + curr, 0) / totalAttention.length).toFixed(0)}
                  </h2>
                </div>
              </div>
            </Col>
            <Col md={12} className='mt-4'>
              <Line data={getChartData(totalAttention, data.category)} options={options} />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setShowR(false)}
            type='button'
            className='d-flex justify-content-center align-items-center py-1 px-3 border rounded-pill bg-transparent text-black'
          >
            Close
          </button>
          <button
            onClick={() => {
              setShowR(false)
              setShow(true)
            }}
            type='submit'
            className='d-flex justify-content-center align-items-center py-1 px-3 border-0 rounded-pill text-white'
            style={{ backgroundColor: 'var(--green-color)' }}
          >
            Play Again
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Rocket2

export const Coin = ({ show, top }) => {
  return (
    <div
      className={`${show ? 'd-flex' : 'd-none'} justify-content-between align-items-center rounded-circle`}
      style={{
        position: 'absolute',
        left: '125%',
        top: top + '%',
        height: '50px',
        width: '50px',
        background: '#FFDE00',
        border: '1px solid #FF9900',
        boxShadow: 'inset -3px -1px 0px 0px #FF9900',
      }}
    >
      <span className='ms-2' style={{ fontSize: '22px' }}>
        +
      </span>
      <span className='me-3' style={{ fontSize: '32px' }}>
        1
      </span>
    </div>
  )
}

export const LeftRightGame = ({ isPlaying, setScore }) => {
  const choices = useMemo(() => ['arrowleft', 'arrowright'], [])

  const [keyPressed, setKeyPressed] = useState('')
  const [randomKey, setRandomKey] = useState('arrowleft')
  const [show, setShow] = useState(true)
  const timeout = useRef()

  // useCallback is used because every re-render all fns are recreated
  // so old reference to handleKeyPress is lost
  // therefore removeEventListener can't work
  const handleKeyPress = useCallback(
    (e, btnPressed) => {
      setKeyPressed(e.key?.toLowerCase() || btnPressed)

      // After 0.5s of key press hide and reset keyPressed state
      setTimeout(() => {
        setShow(false)
        setKeyPressed('')

        // After 0.7s of hiding, show new random key
        timeout.current = setTimeout(() => {
          let randomIdx = Math.round(Math.random())
          setRandomKey(choices[randomIdx])
          setShow(true)
        }, 700)
      }, 500)
    },
    [choices]
  )

  useEffect(() => {
    if (isPlaying) {
      setScore(0)
      document.addEventListener('keyup', handleKeyPress)
      let randomIdx = Math.round(Math.random())
      setRandomKey(choices[randomIdx])
      setShow(true)
    } else {
      clearTimeout(timeout.current)
      document.removeEventListener('keyup', handleKeyPress)

      setKeyPressed('')
    }

    return () => document.removeEventListener('keyup', handleKeyPress)
  }, [isPlaying, handleKeyPress, setScore, choices])

  useEffect(() => {
    if (keyPressed !== '' && randomKey !== '' && keyPressed === randomKey) {
      setScore((s) => s + 1)
    }
  }, [randomKey, keyPressed, setScore])

  return (
    <>
      <div
        style={{ height: 'max(40%,350px)' }}
        className={`d-flex justify-content-center align-items-center rounded-3 p-2 w-100 ${
          keyPressed === '' || randomKey === ''
            ? 'border border-4 bg-white'
            : keyPressed === randomKey
            ? 'bg-success text-white'
            : 'bg-danger text-white'
        }`}
      >
        <img
          className={`${show ? 'd-block' : 'd-none'}`}
          src={`${randomKey === 'arrowleft' ? ArrowLeft : randomKey === 'arrowright' ? ArrowRight : ''}`}
          width='256'
          height='256'
          alt='keyboard-key'
        />
      </div>
      {isPlaying && (
        <div className='d-flex flex-wrap mt-2'>
          {choices.map((key, i) => (
            <button
              key={i}
              type='button'
              onClick={(e) => handleKeyPress(e, key)}
              className={`bg-transparent border w-${(100 / choices.length).toString()}`}
            >
              <img
                src={`${key === 'arrowleft' ? ArrowLeft : key === 'arrowright' ? ArrowRight : ''}`}
                width='64'
                height='64'
                alt='keyboard-key'
              />
            </button>
          ))}
        </div>
      )}
    </>
  )
}

export const colorsUser = ['#6AB04C', '#F0932B', '#BC1CA4', '#22A6B3', '#6AB04C', '#BC1CA4']
export const colorsOthers = ['#558d3d', '#c07622', '#961683', '#1b858f', '#558d3d', '#961683']

export const getChartData = (data, label) => {
  let labels = data.map((_, i) => i + 1)
  return {
    labels: labels,
    datasets: [{ label: label, data: data, borderColor: colorsUser[0], backgroundColor: colorsUser[0] }],
  }
}

export const options = {
  responsive: true,
  scales: {
    x: {
      display: false,
      title: {
        display: true,
        text: 'Attention',
      },
      grid: {
        display: false,
      },
    },
    y: {
      min: 0,
      max: 100,
      ticks: {
        stepSize: 20,
      },
    },
  },

  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      labels: {
        value: {
          anchor: 'end',
          align: 'top',
          display: true,
          color: 'black',
          font: {
            weight: 'bold',
            size: 12,
          },
          offset: 0,
        },
      },
    },
  },
}

import react from "react";
import childrensvg from "../assets/images/children.svg";
const Childillustation = () => {
  return (
    <>
      <figure>
        <img src={childrensvg} alt="" />
      </figure>
    </>
  );
};
export default Childillustation;

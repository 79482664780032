import { Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import GameCard from './GameCardComp'

const GameMainComponent = () => {
  const location = useLocation()
  const data = JSON.parse(localStorage.getItem('modulejson'))

  const modulename = localStorage.getItem('modulename')
  const formattedModuleName = modulename ? modulename.charAt(0).toUpperCase() + modulename.slice(1).toLowerCase() : ''

  const findAndSortCategories = (module) => {
    if (module.module.toUpperCase() === location.state.modulename.toUpperCase()) {
      const matchingCategory = module.categories.find(
        (category) => category.name.toUpperCase() === location.state.title.toUpperCase()
      )

      if (matchingCategory) {
        return matchingCategory.sub_cat.slice().sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
      }
    }
    return []
  }

  const renderGameCards = () => {
    if (!data || data[formattedModuleName] === undefined) {
      return null
    }

    return data[formattedModuleName].map(
      (module, moduleIndex) =>
        moduleIndex !== 0 &&
        findAndSortCategories(module).map((subCategory, subCategoryIndex) => (
          <GameCard
            key={subCategoryIndex}
            title={subCategory.name}
            img={subCategory.image}
            content={subCategory.text}
            classes='game-items'
            redirect='level'
            percentage={subCategory.attempted_percentage || 0}
            attempted={subCategory.attempted || { level1: false, level2: false, level3: false }}
            link={subCategory.name_title.replace(/ /g, '_')}
            modulename={
              location.state.title === 'gesture'
                ? module.title === 'Emotion'
                  ? 'gifs'
                  : 'action'
                : location.state.name_title
            }
            modulename1={location.state.modulename1}
          />
        ))
    )
  }

  return (
    <>
      <h3>Please select games to play </h3>
      <Row className='game-card-container'>{renderGameCards()}</Row>
    </>
  )
}

export default GameMainComponent

import moment from 'moment'
import React, { useEffect, useState, useContext } from 'react'
import { Form } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions'
import * as Apiurl from '../Apisurl'
import Context from '../Context'
import headersReq from '../HeadersJson'

const ReportTable = (props) => {
  const reqJson = headersReq()
  const [sortByDate, setSortByDate] = useState(1)
  const [brainReports, setBrainReports] = useState()
  const [filteredReports, setFilteredReports] = useState()
  const { selectValue } = useContext(Context)

  const getBrainScanReports = async () => {
    let user_id = selectValue.user_id
    try {
      const response = await fetch(`${Apiurl.Fetchurl}report_table_brainscan`, {
        method: 'POST',
        headers: reqJson,
        body: JSON.stringify({
          user_id: user_id,
        }),
      })
      const data = await response.json()
      setBrainReports(data.brain_scan_report)
      setFilteredReports(data.brain_scan_report)
    } catch (error) {
      console.log('brain_scan reports.... \n', error)
    }
  }

  useEffect(() => {
    getBrainScanReports()
  }, [selectValue])

  const viewBrainScanReport = async (data) => {
    try {
      const response = await fetch(
        `${Apiurl.Fetchurl}report_table_brainscan?user_id=${data.user_id}&condition=${data.condition}&date=${data.date}&headset_name=${data.headset}`,
        {
          method: 'GET',
          headers: reqJson,
        }
      )
      const { brain_scan } = await response.json()

      props.setCoordinates(brain_scan.brain_scan[0].eeg_position)
      props.setInsights(brain_scan.insights.insights)
      props.setHeadset(data.headset)
      props.setCondition(data.condition)
      props.setDate(data.date)
    } catch (error) {
      console.log('view brain_scan report....\n', error)
    }
  }

  const tableData = {
    columns: [
      {
        name: 'Condition',
        selector: (row) => row.condition,
        sortable: true,
      },
      {
        name: 'Date',
        selector: (row) => row.date,
        sortable: true,
      },
      {
        name: 'Headset',
        selector: (row) => row.headset_name,
        sortable: true,
      },
      {
        name: '',
        selector: (row) => {
          const data = {
            user_id: row.user_id,
            condition: row.condition,
            date: row.date,
            headset: row.headset_name,
          }
          return (
            <button
              type='button'
              className='btn common-btn w-100 report-button'
              onClick={() => viewBrainScanReport(data)}
            >
              View Report
            </button>
          )
        },
      },
    ],
    data: filteredReports,
  }

  const filterDate = (date, sortType, item) => {
    let formatedDate = moment(date).format('DD/MM/YYYY')
    let currentDate = moment().format('DD/MM/YYYY')
    let startWeek = moment()
      .startOf('week')
      .format('DD/MM/YYYY')
    let endWeek = moment()
      .endOf('week')
      .format('DD/MM/YYYY')

    switch (sortType) {
      case 1:
        return item

      case 2:
        return moment(formatedDate).isBetween(startWeek, endWeek) ? item : ''

      case 3:
        return moment(formatedDate).isSame(currentDate, 'month') ? item : ''

      default:
        break
    }
  }

  const sortDate = (sortType) => {
    setSortByDate(sortType)
    let d = brainReports.filter((item) => {
      let date = item.date.split(',')[0]
      date = date + ' GMT'
      date = new Date(date)
      return filterDate(date, sortType, item)
    })
    setFilteredReports(d)
  }

  return (
    <>
      {/* <div className='week-report'>
        <button type='button' className={sortByDate === 1 ? 'btn btn-all ' : 'btn'} onClick={() => sortDate(1, 'All')}>
          All
        </button>
        <button
          type='button'
          className={sortByDate === 2 ? 'btn btn-all ' : 'btn btn-grey'}
          onClick={() => sortDate(2, 'This Week')}
        >
          This Week
        </button>
        <button
          type='button'
          className={sortByDate === 3 ? 'btn btn-all ' : 'btn btn-grey'}
          onClick={() => sortDate(3, 'This Month')}
        >
          This Month
        </button>
        <Form.Select aria-label='Default select example' className='download-drop'>
          <option>Download here</option>
          <option value='csv'>CSV</option>
          <option value='excel'>Excel</option>
        </Form.Select>
      </div> */}

      <DataTableExtensions {...tableData}>
        <DataTable pagination customStyles={customStyles} highlightOnHover />
      </DataTableExtensions>
    </>
  )
}

export default ReportTable

const customStyles = {
  rows: {
    style: {
      minHeight: '80px',
    },
  },
  cells: {
    style: {
      fontSize: '14px',
      color: '#414141',
    },
  },
}

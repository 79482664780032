const URL = 'https://api.brevo.com/v3/smtp/email'

const sendMail = async (data) => {
  const { email, username, password } = data

  const headers = {
    accept: 'application/json',
    'content-type': 'application/json',
    'api-key': process.env.BREVO_API,
  }

  const body = JSON.stringify({
    to: [{ email: email, name: email }],
    templateId: 3,
    params: {
      name: email,
      username: username,
      password: password,
    },
  })

  try {
    const response = await fetch({ URL, method: 'POST', headers, body })
    if (!response.ok) throw new Error('Failed to send welcome mail')
  } catch (error) {
    console.log(error)
  }
}

export default sendMail

import { Col, Row } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Currdate from '../../../CurrDate'
import * as Apiurl from '../../../Apisurl'
import { useSpeechSynthesis } from 'react-speech-kit'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { SocketConfig } from '../../../SocketConfig'
import Speech from '../../Speech'

const Adjectives = (props) => {
  const { speak } = useSpeechSynthesis()
  const { transcript, listening, resetTranscript } = useSpeechRecognition()

  const location = useLocation()
  const navigate = useNavigate()
  let cat = location.state.category
  const [parsedata, setparsedata] = useState(false)
  const [currPage, setCurrPage] = useState(1)
  const [question, setQuestion] = useState('A')
  const [inputValue1, setinputValue1] = useState('')
  const [inputValue3, setinputValue3] = useState('')

  const [inputValue2, setinputValue2] = useState('')
  const [disabledVia1, setdisabledVia1] = useState(false)

  const [disabledVia, setdisabledVia] = useState(false)
  const [disabledVia2, setdisabledVia2] = useState(false)

  let singleQuestion = props.englishData
  let allQuestions = props.datalength

  let date = Currdate.GetCurrentDate()
  props.time(date)

  useEffect(() => {
    SocketConfig.on('action_data', (data) => {
      let parseddata = data.result
      if (parseddata.length !== 0) {
        setparsedata(true)
        handleSubmit(parseddata.action, true, props.page)
      }
      console.log(parseddata)
    })
  }, [])

  // populate level text
  let update
  // checkanswers
  function handleSubmit(userAnswer, via, idx, btnvia) {
    // alert(userAnswer);
    Speech.Speech(userAnswer)
    setCurrPage((prev) => prev + 1)

    let isCorrectAnswer
    //if correctAnswer then increase score
    switch (btnvia) {
      case 1:
        setdisabledVia(true)
        setinputValue1(userAnswer)
        break
      case 2:
        setdisabledVia2(true)
        setinputValue2(userAnswer)
        break
      case 3:
        setinputValue3(userAnswer)
        break
      case 4:
        isCorrectAnswer =
          inputValue1 === allQuestions[props.page].ans.trim() && inputValue2 === allQuestions[props.page + 1].ans.trim()

        setinputValue1('')
        setinputValue2('')
        setinputValue3('')

        // add current answer to final result.
        setdisabledVia2(false)
        setdisabledVia(false)

        setdisabledVia1(false)
        props.setTheArray((oldArray) => {
          update = [
            ...oldArray,
            {
              via: isCorrectAnswer ? 'correct' : 'incorrect',
              answer1: inputValue1,
              answer: inputValue2,
            },
          ]
          localStorage.setItem('useranswer', JSON.stringify(update))

          return update
        })
        isCorrectAnswer && props.setScore((prev) => prev + 1)
        if (parseInt(update.length) == parseInt(allQuestions.length / 2)) {
          props.setTheArray([])

          props.submitResult()
          // setTimeout(() => {
          //   navigate('enresult', {
          //     state: { ...location.state, date: date },
          //   })
          // })
        } else {
          isCorrectAnswer && props.setScore((prev) => prev + 1)
          props.setPage((prev) => prev + 2)
        }
        break
    }
  }

  const disabledcss =
    disabledVia == false
      ? {
          opacity: '0.4',
          pointerEvents: 'none',
        }
      : {
          opacity: '1',
        }

  const disabledcss1 =
    disabledVia2 == false
      ? {
          opacity: '0.4',
          pointerEvents: 'none',
        }
      : {
          opacity: '1',
        }
  const disabledcss2 =
    disabledVia2 == false
      ? {
          opacity: '0.4',
          pointerEvents: 'none',
        }
      : {
          opacity: '1',
        }

  if (singleQuestion !== undefined) {
    return (
      <>
        <h2>Choose {cat}</h2>

        <Row className='english-main-container question-container'>
          <Col className='sub-question-a'>
            <div className='english-container'>
              <input type='text' value={inputValue1} readOnly />

              <img src={allQuestions[props.page].images} alt='apple' height={200} />
            </div>

            <div className='option-container'>
              {allQuestions[props.page].mcq.map((text, i) => {
                return (
                  <h5 key={i} onClick={() => handleSubmit(text, false, props.page + 1, 1)}>
                    {text}
                  </h5>
                )
              })}
            </div>
          </Col>
          {/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
          <Col className='sub-question-b ' style={disabledcss}>
            <div className='english-container'>
              <input type='text' value={inputValue2} readOnly />

              <img src={allQuestions[props.page + 1].images} alt='apple' height={200} />
            </div>

            <div className='option-container'>
              {allQuestions[props.page + 1].mcq.map((text, i) => {
                return (
                  // 'radial-gradient(98.07% 73.14% at 3.51% 19.05%, #97D5CD 8.51%, #54BAAF 100%)'
                  <h5 key={i} data-attr={text} onClick={() => handleSubmit(text, false, props.page + 2, 2)}>
                    {text}
                  </h5>
                )
              })}
            </div>
            <button
              className='common-btn'
              style={{
                fontSize: '16px',
                padding: '4px',
                width: '100%',
                marginBottom: '20px',
                padding: '10px',
                marginTop: '30px',
                ...disabledcss1,
              }}
              onClick={() => handleSubmit('', false, props.page + 2, 4)}
            >
              Submit
            </button>
          </Col>
        </Row>
      </>
    )
  }
}
export default Adjectives

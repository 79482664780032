import { useEffect, useState } from 'react'
import { Col, Modal, Row, Spinner } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import Select from 'react-select'
import * as Apiurl from '../Apisurl'
import Formstyle from '../teacher/cssModule/Formstyle.module.css'
import headersReq from '../HeadersJson'

const FormModalGroup = (props) => {
  const reqJson = headersReq()

  const role = localStorage.getItem('role')
  const [hrList, setHrList] = useState()
  const [companyList, setCompanyList] = useState()
  const [specialities, setSpecialities] = useState([])

  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState('')
  const [validated, setValidated] = useState(false)
  const [members, setMembers] = useState([])

  const options = props.children.map((c) => ({
    label: c.username,
    value: c.user_uid,
  }))

  const defaultOptions = props.groupData?.members?.map((member) => ({
    label: member.childname,
    value: member.child_userid,
  }))

  useEffect(() => {
    setMembers(defaultOptions || [])
  }, [props.groupData.members])

  useEffect(() => {
    const getSpecialities = async () => {
      try {
        const response = await fetch(`${Apiurl.Fetchurl}get_all_speciality`, {
          method: 'GET',
          headers: reqJson,
        })
        const data = await response.json()
        setSpecialities(data)
      } catch (error) {
        console.log(error)
      }
    }
    getSpecialities()
  }, [])

  useEffect(() => {
    setHrList([])
    const getHRList = async () => {
      try {
        const response = await fetch(`${Apiurl.Fetchurl}hr_list?company_name=${props.groupData.school_name?.trim()}&person_type=${role}`, {
          method: 'GET',
          headers: reqJson,
        })
        const data = await response.json()
        setHrList(data.hr_data)
      } catch (error) {
        console.log(error)
      }
    }

    role === 'admin' && props.groupData.school_name?.trim().length > 0 && getHRList()
  }, [props.groupData.school_name, role])

  useEffect(() => {
    const getCompanyList = async () => {
      try {
        const response = await fetch(`${Apiurl.Fetchurl}company_list?person_type=${role}`, {
          method: 'GET',
          headers: reqJson,
        })
        const data = await response.json()
        setCompanyList(data.company_list)
      } catch (error) {
        console.log(error)
      }
    }

    role === 'admin' && getCompanyList()
  }, [role])

  const handleChange = (e) => {
    setErr('')
    const { name, value } = e.target
    props.setGroupData({ ...props.groupData, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (e.currentTarget.checkValidity() === false) {
      e.stopPropagation()
    } else {
      if (members.length === 0) return setErr('Select atleast one member to create a group')
      const mem = members.map((member) => {
        return {
          child_userid: member.value,
          childname: member.label,
        }
      })
      setMembers([])

      async function create() {
        setLoading(true)
        const response = await fetch(`${Apiurl.Fetchurl + 'make_group'}`, {
          method: 'POST',
          headers: reqJson,
          body: JSON.stringify({
            ...props.groupData,
            members: mem,
            group_image: 'image',
            teacher_user_id: window.sessionStorage.getItem('session'),
          }),
        })
        return response
      }

      async function update() {
        setLoading(true)
        const response = await fetch(`${Apiurl.Fetchurl + 'edit_group'}`, {
          method: 'POST',
          headers: reqJson,
          body: JSON.stringify({
            ...props.groupData,
            members: mem,
            group_image: 'image',
            teacher_user_id: window.sessionStorage.getItem('session'),
          }),
        })
        return response
      }

      if (props.groupData?.group_user_id) {
        update()
          .then((data) => {
            setLoading(false)
            props.getGroupData()
            console.log('after editing group...', data)
          })
          .catch((error) => {
            setLoading(false)
            console.log(error)
          })
      } else {
        create()
          .then((data) => {
            setLoading(false)
            props.getGroupData()
            console.log('after making group...', data)
          })
          .catch((error) => {
            setLoading(false)
            console.log(error)
          })
      }

      props.handleClose()
    }
    setValidated(true)
  }

  return (
    <>
      <Modal show={props.open} onHide={props.handleClose} className='GropFormModalGroup'>
        <Modal.Body>
          <Form className={Formstyle.formContainer} noValidate validated={validated} onSubmit={handleSubmit}>
            <label>Upload Image</label>
            <Dropzone
              // getUploadParams={getUploadParams}
              name='group_image'
              maxFiles={1}
              accept='image/*'
            />
            <div className={Formstyle.profileContainer}>
              {/* <figure>
                <img src={userImg} alt="userimage" />
              </figure> */}
              <FloatingLabel controlId='floatingInput' label='Name' className={Formstyle.labelinput}>
                <Form.Control
                  required
                  type='text'
                  placeholder='name'
                  maxLength='50'
                  name='group_name'
                  onChange={handleChange}
                  value={props.groupData.group_name}
                />
                <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
              </FloatingLabel>
            </div>
            <Row>
              <Col md={6}>
                {role === 'admin' ? (
                  <FloatingLabel controlId='Company' label='Company'>
                    <Form.Select required value={props.groupData.school_name} onChange={handleChange} name='school_name'>
                      <option value=''>select company</option>
                      {companyList?.map((name, i) => (
                        <option key={i} value={name}>
                          {name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                  </FloatingLabel>
                ) : (
                  <FloatingLabel controlId='School' label='School'>
                    <Form.Control
                      required
                      type='text'
                      placeholder='School'
                      name='school_name'
                      onChange={handleChange}
                      maxLength='50'
                      value={props.groupData.school_name}
                    />
                    <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                  </FloatingLabel>
                )}
              </Col>
              <Col md={6}>
                {role === 'admin' ? (
                  <FloatingLabel controlId='HR' label='HR'>
                    <Form.Select required value={props.groupData.student_class} onChange={handleChange} name='student_class'>
                      <option value=''>select hr</option>
                      {hrList?.map((name, i) => (
                        <option key={i} value={name}>
                          {name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                  </FloatingLabel>
                ) : (
                  <FloatingLabel controlId='Class' label='Class'>
                    <Form.Control
                      required
                      type='text'
                      placeholder='Class'
                      name='student_class'
                      onChange={handleChange}
                      maxLength='50'
                      value={props.groupData.student_class}
                    />
                    <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                  </FloatingLabel>
                )}
              </Col>
            </Row>

            <FloatingLabel controlId='floatingSelect' label={role === 'admin' ? 'Department' : 'Speciality'}>
              <Form.Select
                required
                aria-label='Floating label select example'
                value={props.groupData.speciality}
                onChange={handleChange}
                name='speciality'
              >
                <option value=''>Select Special Need</option>
                {specialities?.map((name, i) => (
                  <option key={i} value={name}>
                    {name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
            </FloatingLabel>

            <Select
              options={options}
              defaultValue={defaultOptions}
              onChange={(e) => {
                setErr('')
                setMembers(e)
              }}
              isMulti
              isSearchable
              noOptionsMessage={() => 'No Children Found'}
            />
            {err && (
              <p className='text-danger' style={{ fontSize: '0.875em' }}>
                {' '}
                {err}
              </p>
            )}

            <Button variant='primary' className='common-btn full-btn' type='submit'>
              {!loading ? (
                'Submit'
              ) : (
                <div className='d-flex align-items-center justify-content-center gap-2'>
                  <Spinner animation='border' /> {props.groupData?.group_user_id ? 'Updating' : 'Adding'}
                </div>
              )}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default FormModalGroup

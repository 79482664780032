import { useContext } from 'react'
import Context from '../../Context'
import Rating from '../Rating'
import HeaderPdf from './pdfparts/header'

const Page6 = ({ data }) => {
  const { selectValue } = useContext(Context)
  const progressData = [
    {
      title: 'Reasoning',
      percentage: data.charts.Brain_Strength.thinking[0],
      optimal: data.charts.Brain_Strength.thinking[2],
      description: 'Efficient utilization of acquired information, encompassing organization and correlation.',
    },
    {
      title: 'Memory',
      percentage: data.charts.Brain_Strength.memory[0],
      optimal: data.charts.Brain_Strength.memory[2],
      description: 'Ability to retain and manipulate new information and recover past memories.',
    },
    {
      title: 'Association',
      percentage: data.charts.Brain_Strength.association[0],
      optimal: data.charts.Brain_Strength.association[2],
      description: 'Facility in forging mental connections between ideas through similarity, experiences, emotions.',
    },
    {
      title: 'Visualization',
      percentage: data.charts.Brain_Strength.vision[0],
      optimal: data.charts.Brain_Strength.vision[2],
      description: `Capacity to generate and control mental images, transcending current sensory input.`,
    },
    {
      title: 'Attention',
      percentage: data.charts.Performance_Metric.attention[0],
      optimal: data.charts.Performance_Metric.attention[2],
      description: 'Capacity to selectively attend to specific information, filtering out distractions efficiently.',
    },
    {
      title: 'Focus',
      percentage: data.charts.Performance_Metric.focus[0],
      optimal: data.charts.Performance_Metric.focus[2],
      description: 'The act of deliberately concentrating your attention on a specific task or object.',
    },
  ]

  const cognition = progressData.reduce((total, curr) => (total += curr.percentage), 0) / progressData.length
  const cognitionOptimal = progressData.reduce((total, curr) => (total += curr.optimal), 0) / progressData.length

  return (
    <div className='main-container page8-container'>
      <HeaderPdf />
      <div className='pdf-content-container'>
        <div className='heading-seprator'>
          <h1>
            <span>COGNITIVE PROFILE</span>
          </h1>
          <div className='report-content'>
            <p>
              In this section, results will be shown based on the standard level required for individuals to achieve in their respective cognition
              levels. NEMA AI's values are calculated in percentiles and adjusted on a scale of 0-100. Therefore, the higher the score, the better.
            </p>
            <div className='listing-container'>
              <h2 className='m-0'>{selectValue.username}'s results</h2>
              <p className='m-0'>EEG Scans show a Low Performance cognitive profile</p>

              <div className='progress_cntr'>
                {progressData.map((item, index) => (
                  <div className='progress_card_box bg-white shadow' key={index}>
                    <div className='top_bar_box'>
                      <div className='progress_detail_box' style={{ height: '120px' }}>
                        <h3 className='m-0 fs-6' style={{ fontFamily: 'sorabold' }}>
                          {item.title}
                        </h3>
                        <span
                          className={item.percentage >= item.optimal ? 'green_text' : item.percentage >= 40 ? 'yellow_text' : 'red_text'}
                          style={{ fontFamily: 'sorabold', fontSize: '14px' }}
                        >
                          {Math.round(item.percentage)}%/ 100%
                        </span>
                        <p className='py-1 text-black' style={{ fontFamily: 'soralight' }}>
                          {item.description}
                        </p>
                      </div>
                      <div
                        className='flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center'
                        style={{
                          height: '90px',
                          width: '90px',
                          border: `8px solid ${
                            item.percentage >= item.optimal
                              ? 'rgba(75, 170, 80,0.3)'
                              : item.percentage >= 40
                              ? 'rgba(248, 210, 53, 0.3)'
                              : 'rgba(238, 67, 54, 0.3)'
                          }`,
                        }}
                      >
                        <div
                          className='rounded-circle d-flex align-items-center justify-content-center fs-6 text-white'
                          style={{
                            height: '50px',
                            width: '50px',
                            fontFamily: 'sorabold',
                            background:
                              item.percentage >= item.optimal
                                ? 'rgba(75, 170, 80,1)'
                                : item.percentage >= 40
                                ? 'rgba(248, 210, 53, 1)'
                                : 'rgba(238, 67, 54, 1)',
                          }}
                        >
                          {Math.round(item.percentage)}%
                        </div>
                      </div>
                    </div>
                    <p className='botom_row m-0' style={{ fontFamily: 'sorabold', fontSize: '12px' }}>
                      Optimal: {Math.round(item.optimal)}% - 100%
                    </p>
                  </div>
                ))}
              </div>

              <ul className='list_item_cntr mb-2 mt-2'>
                <li>
                  <div className='heading-seprator'>
                    <h1>
                      <span>User Insights</span>
                    </h1>
                    <div className='report-content d-flex gap-2 align-items-center'>
                      <p style={{ fontSize: '14px', fontFamily: 'soraregular', color: 'black', opacity: 1, fontWeight: 'bold' }}>
                        The user's productivity, assessed via EEG headset, signifies efficient output, while burnout results from prolonged stress and
                        exhaustion.
                      </p>
                      <div>
                        <Rating value={data.ProducitvityScale[0]} />
                        <p className='m-0 mt-2 text-center'>Productivity</p>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className='heading-seprator'>
                    <h1>
                      <span>Global Insights</span>
                    </h1>
                    <div className='report-content d-flex gap-2 align-items-center'>
                      <p style={{ fontSize: '14px', fontFamily: 'soraregular', color: 'black', opacity: 1, fontWeight: 'bold' }}>
                        The average productivity of users who have taken our assessments to date. Productivity reflects the efficiency of prioritizing
                        high-impact tasks.
                      </p>
                      <div>
                        <Rating value={data.ProducitvityScale[1]} />
                        <p className='m-0 mt-2 text-center'>Productivity</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <h2 className='my-1'>Conclusion</h2>
              <p>
                {selectValue.username} exhibits{' '}
                <span style={{ fontFamily: 'sorabold', color: 'var(--button-color)' }}>
                  {cognition >= cognitionOptimal ? 'good' : cognition >= 40 ? 'average' : 'weak'}
                </span>{' '}
                cognitive abilities. The assessment highlights visualization as a strength, suggesting a need for active mental exercises to enhance
                reasoning and associations. Notably, areas for improvement include memorization skills. Improvement in these aspects is achievable
                within 1-3 months through NEMA AI's customized brain training modules.
              </p>
            </div>
          </div>
        </div>
        <footer>
          <h6>06</h6>
        </footer>
      </div>
    </div>
  )
}
export default Page6

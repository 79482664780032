import Table from 'react-bootstrap/Table'
import { MDBIcon } from 'mdb-react-ui-kit'
import ReactPaginate from 'react-paginate'
import UserJson from '../../../Userjson'
import { useLocation, useOutletContext } from 'react-router-dom'
import react, { useRef } from 'react'
import Pagination from '../../Pagination'
import { useEffect, useState, useContext } from 'react'
import * as Apiurl from '../../../Apisurl'
import Context from '../../../Context'
import InputRange from 'react-input-range'
import TabularResult from './TabularResult'
import Parser from 'html-react-parser'
import { Card as SocialMediaCard } from './SocialMedia'
import { Card } from 'react-bootstrap'
import headersReq from '../../../HeadersJson'
import csvicon from '../../../assets/images/csv.svg'
import xlsxicon from '../../../assets/images/xlsx.svg'
import xlsicon from '../../../assets/images/xls.svg'
import pdficon from '../../../assets/images/pdf.svg'
import pngicon from '../../../assets/images/png.svg'
import jpgicon from '../../../assets/images/jpg.svg'
import txticon from '../../../assets/images/txt.svg'

import downloadicon from '../../../assets/images/download_2.svg'

const Colortable = () => {
  const reqJson = headersReq()
  const [overview1, setOverview] = useState()
  const [val, setval] = useState()

  const props1 = useContext(Context)
  let tableref = useRef()
  let user = UserJson()
  let tlengthdata = tableref.current

  let category = UserJson().category
  let overview = props1[2].Overview

  let icons = {
    xls: xlsicon,
    xlsx: xlsxicon,
    pdf: pdficon,
    png: pngicon,
    jpg: jpgicon,
    csv: csvicon,
    txt: txticon,
  }

  function lat() {
    if (overview != undefined) {
      let data
      if (category == 'excel_typing' || category == 'qr_code') {
        if (overview.ans != undefined) {
          data = overview.ans.map((obj, index) => {
            console.log(obj.useranswers)
            return obj.useranswers
          })
        }
        async function levelgame() {
          const response = await fetch(`${Apiurl.Fetchurl + 'compare'}`, {
            method: 'POST',
            headers: reqJson,
            body: JSON.stringify({
              userAnswer: data,
              ans: overview.ques,
            }),
          })
          const childdata = await response.json()
          return childdata
        }
        levelgame()
          .then((data) => {})
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }

  const populateResult = () => {
    let resultComp
    switch (category) {
      case 'excel_typing':
      case 'qr_code':
        resultComp = <TabularResult ques={overview.ques} answ={overview.ans} />
        break

      case 'paragraph_type':
        resultComp = (
          <Table className='result-table' ref={tableref}>
            <div className='range'>
              <p>
                Your speed is <span>{localStorage.getItem('speed')}</span>
              </p>
              <input
                type='range'
                value={localStorage.getItem('speed')}
                min='1'
                onInput={(e) => setval(e.val)}
                max='70'
              />
              <output>70</output>
            </div>

            <thead>
              <tr>
                <th></th>
                {overview.ques.map((o, i) => {
                  // console.log(overview.ques);
                  return <th>Q{i + 1}</th>
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Asked</td>
                {overview.ques.map((o, i) => {
                  return (
                    <div className='asked-color-box d-flex vocational-para'>
                      <p>{o.ques}</p>
                    </div>
                  )
                })}
              </tr>
              <tr>
                <td>Your Answer</td>
                {overview.ans.map((o, i) => {
                  return (
                    <td>
                      <div className='asked-color-box paraans'>
                        <p style={o.via == 'correct' ? { color: '#77C63A' } : { color: 'red' }}>{o.inputvalue}</p>
                      </div>
                    </td>
                  )
                })}
              </tr>
              {/* <tr>
                <td>Percentage</td>
              </tr> */}
            </tbody>
          </Table>
        )
        break

      case 'social_media':
        resultComp = <SocialMediaCard post={overview.ques[0]} />
        break

      case 'aidesign':
        resultComp = (
          <div
            className='dashboard-cards p-2 d-flex align-items-center justify-content-between mx-auto my-3'
            style={{ maxWidth: '30%' }}
          >
            <div className='d-flex align-items-center gap-2'>
              <div>
                <img
                  src={icons[overview.ques[0].file.split('.').at(-1)] || icons.txt}
                  alt='file-icon'
                  style={{
                    height: '50px',
                    width: '50px',
                    objectFit: 'contain',
                  }}
                />
              </div>
              <div className='text-break'>
                <p className='m-0 p-0'>{overview.ques[0].game}</p>
                <p className='m-0 p-0'>{overview.ques[0].topic} </p>
              </div>
            </div>
            <div>
              <a href={overview.ques[0].file} download target='_blank'>
                <img
                  src={downloadicon}
                  alt='download-icon'
                  style={{
                    height: '40px',
                    width: '40px',
                    objectFit: 'contain',
                  }}
                />
              </a>
            </div>
          </div>
        )
        break

      case 'content_writing':
        resultComp = <div className='result-content-container'>{Parser(overview.ans.text)}</div>
        break

      case 'nemabot':
        resultComp = (
          <Table className='result-table' ref={tableref}>
            <thead>
              <tr>
                <th></th>
                {overview.ques.map((o, i) => {
                  return <th key={i}>Q{i + 1}</th>
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Asked</td>
                {overview.ques.map((o, i) => {
                  return (
                    <td key={i}>
                      <div className='asked-color-box d-flex '>
                        <p>{o.ques}</p>
                      </div>
                    </td>
                  )
                })}
              </tr>
              <tr>
                <td>Your Answer</td>
                {overview.ques.map((o, i) => {
                  return (
                    <td key={i}>
                      <div className='asked-color-box'>{o.ans}</div>
                    </td>
                  )
                })}
              </tr>
            </tbody>
          </Table>
        )
        break

      case 'ecommerce_training':
        resultComp = (
          <div className='d-flex gap-3 overflow-x-scroll'>
            {overview.ques.map((product) => (
              <Card className='flex-shrink-0'>
                <Card.Img src={product.thumbnail} style={{ height: '160px', width: '100%', objectFit: 'contain' }} />
                <Card.Body className='pt-1 text-black'>
                  <span className='text-uppercase' style={{ fontSize: '10px', letterSpacing: '1.5px' }}>
                    {product.category}
                  </span>
                  <Card.Title
                    className='d-flex justify-content-between gap-4 fs-6 text-lowercase text-capitalize'
                    style={{ fontWeight: '600' }}
                  >
                    {product.title} <span>₹{product.price}</span>
                  </Card.Title>
                </Card.Body>
                <Card.Footer className='pe-2'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex'></div>
                    <button className='default-btn border-0 px-4 py-1' type='button'>
                      Buy now
                    </button>
                  </div>
                </Card.Footer>
              </Card>
            ))}
          </div>
        )
        break

      default:
        resultComp = (
          <Table className='result-table' ref={tableref}>
            <thead>
              <tr>
                <th></th>
                {overview.ques.map((o, i) => {
                  // console.log(overview.ques);
                  return <th>Q{i + 1}</th>
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Asked</td>

                {overview.ques.map((o, i) => {
                  return (
                    <td>
                      <div className='asked-color-box d-flex '>
                        <p>{o.ques}</p>
                      </div>
                    </td>
                  )
                })}
              </tr>
              <tr>
                <td>Your Answer</td>
                {overview.ans.map((o, i) => {
                  return (
                    <td>
                      <div className='asked-color-box'>
                        <p style={o.via == 'correct' ? { color: '#77C63A' } : { color: 'red' }}>{o.inputvalue}</p>
                      </div>
                    </td>
                  )
                })}
              </tr>
              <tr>
                <td>Percentage</td>

                {overview.ans.map((o, i) => {
                  console.log(o)
                  return (
                    <td>
                      <div className='asked-color-box'>{o.percentage}%</div>
                    </td>
                  )
                })}
              </tr>
            </tbody>
          </Table>
        )
        break
    }
    return resultComp
  }

  if (overview != undefined && overview.length != 0) {
    lat()
    return <>{populateResult()}</>
  }
}

export default Colortable

import React, { useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Dropzone from 'react-dropzone-uploader'
import swal from 'sweetalert'
import * as Apiurl from '../Apisurl'
import Context from '../Context'
import Formstyle from '../teacher/cssModule/Formstyle.module.css'
import CustomDropzone from './CustomDropzone'
import jwtDecode from 'jwt-decode'

const NewReport = (props) => {
  const selectData = useContext(Context)
  let user_id = selectData.selectValue.user_id

  const [loading, setLoading] = useState(false)
  const [radios, setRadios] = useState([])
  const [open, setOpen] = useState(false)
  const [condition, setCondition] = useState('')
  const [headsetList, setHeadsetList] = useState()

  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await fetch(`${Apiurl.Fetchurl}get_all_category`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + ' ' + sessionStorage.getItem('session'),
          },
        })
        const data = await response.json()
        const modifiedData = data.map((d) => d.toLowerCase())
        setRadios(modifiedData)
      } catch (error) {
        console.log(error)
      }
    }

    getCategories()
  }, [])

  useEffect(() => {
    const getHeadsets = async () => {
      const response = await fetch(Apiurl.Fetchurl + 'headset_list', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()
      setHeadsetList(data.headset)
    }
    getHeadsets()
  }, [])

  const handleChangeStatus = ({ meta, file }, status, data) => {
    if (status === 'done') {
      let result = JSON.parse(data[0].xhr.response)
      props.setFilePath(result.file_path)
      props.populateBrainData(result.file_path)
    }
  }

  const getUploadParams = ({ meta, file }) => {
    let user = jwtDecode(sessionStorage.getItem('session'))
    const body = new FormData()

    body.append('file', file)
    body.append('user_id', user_id)
    body.append('minutes', props.mins)
    body.append('seconds', props.secs)
    body.append('thresshold', props.counter)
    body.append('condition', props.condition)
    body.append('headset_name', props.headset)
    body.append('person_type', user.person_type)

    return {
      body,
      url: `${Apiurl.Fetchurl + 'brain_scan'}`,
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('session'),
      },
    }
  }

  const uploadMultiple = async (files) => {
    if (!files?.length) return

    setLoading(true)

    const formData = new FormData()
    formData.append('user_id', user_id)
    formData.append('condition', props.condition)
    formData.append('minutes', props.mins)
    formData.append('seconds', props.secs)
    formData.append('thresshold', props.counter)
    formData.append('headset_name', props.headset)
    files.map((file) => formData.append('file', file))

    try {
      await fetch(`${Apiurl.Fetchurl}brain_scan_multiple`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('session'),
        },
      })
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log('brain multiple upload', err)
    }

    const data = await getUploadMessage()
    swal('Success', data.file_path, 'success')
  }

  const getUploadMessage = async () => {
    try {
      const response = await fetch(`${Apiurl.Fetchurl}brain_scan_multiple?user_id=${user_id}`)
      return response
    } catch (err) {
      console.log('brain upload multiple get', err)
    }
  }

  const styles = {
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'soraregular',
    lineHeight: '18px',
    marginBottom: '8px',
  }

  return (
    <Container>
      <Row className={`dashboard-cards ${props.via == false ? 'form-card-design' : ''}`}>
        <Col>
          <div className='cat-input'>
            <p style={styles}>Condition</p>
            <div className='d-flex flex-wrap radio-container'>
              {radios?.map((name, idx) => {
                return (
                  <button
                    key={idx}
                    onClick={() => props.setCondition(name)}
                    className={`${props.condition == name ? 'active' : 'text-black'} text-capitalize`}
                  >
                    {name}
                  </button>
                )
              })}

              <button className='common-btn text-white' onClick={() => setOpen(true)}>
                Add
              </button>
            </div>
          </div>
          <div className='d-flex flex-wrap justify-content-center align-items-center my-4 gap-4 w-100'>
            <div>
              <p style={styles}>Threshold</p>
              <div className='counter-container'>
                <button onClick={() => props.increment('counter')}>+</button>

                <span>{props.counter / 10}</span>

                <button onClick={() => props.decrement('counter')}>-</button>
              </div>
            </div>
            <div>
              <p style={styles}>Minutes</p>
              <div className='counter-container d-flex'>
                <button
                  onClick={() => props.increment('mins')}
                  className='d-flex align-items-center justify-content-center p-3'
                >
                  +
                </button>
                <input
                  className='text-center'
                  type='number'
                  name='mins'
                  min={0}
                  max={60}
                  value={props.mins}
                  onChange={(e) => props.setMins(e.target.value > 60 ? 60 : e.target.value < 0 ? 0 : e.target.value)}
                />
                <button
                  onClick={() => props.decrement('mins')}
                  className='d-flex align-items-center justify-content-center p-3'
                >
                  -
                </button>
              </div>
            </div>
            <div>
              <p style={styles}>Seconds</p>
              <div className='counter-container d-flex'>
                <button
                  onClick={() => props.increment('secs')}
                  className='d-flex align-items-center justify-content-center p-3'
                >
                  +
                </button>
                <input
                  className='display-inline text-center'
                  type='number'
                  name='secs'
                  min={0}
                  max={60}
                  value={props.secs}
                  onChange={(e) => props.setSecs(e.target.value > 60 ? 60 : e.target.value < 0 ? 0 : e.target.value)}
                />
                <button
                  onClick={() => props.decrement('secs')}
                  className='d-flex align-items-center justify-content-center p-3'
                >
                  -
                </button>
              </div>
            </div>
            <div>
              <p style={styles}>Speed</p>
              <div className='counter-container'>
                <button onClick={() => props.increment('speed')}>+</button>
                <span>{props.speed}</span>
                <button onClick={() => props.decrement('speed')}>-</button>
              </div>
            </div>
            <div>
              <p style={styles}>Headset</p>
              <div className='counter-container'>
                <Form.Group
                  controlId='headset'
                  className='m-0 rounded-pill'
                  style={{ minWidth: '150px', border: '3px solid var(--primary-color)' }}
                >
                  <Form.Select
                    name='headset'
                    value={props.headset}
                    onChange={(e) => props.setHeadset(e.target.value)}
                    required
                    className='border-0 ps-2 pe-5 m-0'
                  >
                    <option value=''>Select Your Headset</option>
                    {headsetList?.map((name) => {
                      return (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      )
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          </div>

          <Row>
            <Col xl={6}>
              <label>Upload CSV</label>
              <Dropzone
                inputContent={'Drag or Upload CSV'}
                getUploadParams={getUploadParams}
                name='userImage'
                maxFiles={1}
                onChangeStatus={handleChangeStatus}
                accept={'.csv'}
              />
            </Col>
            <Col xl={6}>
              <label>Upload Multiple CSV</label>
              <CustomDropzone isMultiple={true} onSubmit={uploadMultiple} loading={loading} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal show={open} onHide={() => setOpen(false)}>
        <Modal.Header>
          <Modal.Title>Add Condition</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId='Condition' label='Condition' className={Formstyle.labelinput}>
            <Form.Control
              required
              type='text'
              placeholder='condition'
              maxLength='20'
              name='condition'
              onChange={(e) => setCondition(e.target.value)}
              value={condition}
            />
            <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <button className='common-btn px-3 py-2' onClick={() => setOpen(false)}>
            Close
          </button>
          <button
            className='common-btn px-3 py-2'
            onClick={() => {
              setRadios((prev) => [...prev, condition])
              setOpen(false)
            }}
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default NewReport

import { Col, Row } from 'react-bootstrap'
import { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Currdate from '../../../CurrDate'
import * as Apiurl from '../../../Apisurl'
import { useSpeechSynthesis } from 'react-speech-kit'
import * as Icon from 'react-bootstrap-icons'
import AudioAnalyser from 'react-audio-analyser-js'
import { SocketConfig } from '../../../SocketConfig'

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

const Read_word = (props) => {
  const { speak } = useSpeechSynthesis()
  const { transcript, listening, resetTranscript } = useSpeechRecognition({
    onResult: (result) => {
      console.log(result)
      // setValue(result);
    },
    // interimResults: true,
  })

  // const { transcript1, , resetTranscript1 } = useSpeechRecognition();

  const inputRef = useRef()
  const inputRef1 = useRef()

  const location = useLocation()
  const navigate = useNavigate()
  let cat = location.state.category
  const [parsedata, setparsedata] = useState(false)
  const [currPage, setCurrPage] = useState(1)
  const [question, setQuestion] = useState('A')
  const [inputValue1, setinputValue1] = useState('')
  const [inputValue2, setinputValue2] = useState('')
  const [disabledVia1, setdisabledVia1] = useState(false)
  const [disabledVia, setdisabledVia] = useState(false)
  const [record, setRecord] = useState(false)
  const [speechTextA, setSpeechTextA] = useState('')
  const [speechTextB, setSpeechTextB] = useState('')

  let singleQuestion = props.englishData
  let allQuestions = props.datalength

  let date = Currdate.GetCurrentDate()
  props.time(date)

  useEffect(() => {
    SocketConfig.on('action_data', (data) => {
      let parseddata = data.result
      if (parseddata.length !== 0) {
        setparsedata(true)
        handleSubmit(parseddata.action, true, props.page)
      }
      // console.log(parseddata);
    })
  }, [])

  const startListen = (textInput) => {
    captureUserMedia()
    setRecord(true)
    console.log(SpeechRecognition)
    SpeechRecognition.startListening()
    let word = transcript

    inputRef.current.value = word
    console.log(inputRef.current.value)
    setSpeechTextA(word)

    // console.log(transcript);
    if (transcript != '') {
    }
  }
  const stop = () => {
    SpeechRecognition.stopListening()
  }

  const captureUserMedia = (callback) => {
    navigator.permissions.query({ name: 'microphone' }).then((permissionStatus) => {
      console.log(permissionStatus.state) // granted, denied, prompt
      if (permissionStatus.state != 'granted') {
        SpeechRecognition.stopListening()
        alert('Please allow your microphone')
      } else {
        this.mic_permission = true
      }
      let self = this
      permissionStatus.onchange = function() {
        if (this.state != 'granted') {
          SpeechRecognition.stopListening()
          self.mic_permission = false
        }
        console.log('Permission changed to ' + this.state)
      }
    })
  }
  // checkanswers
  let update
  // checkanswers
  function handleSubmit(userAnswer, via, idx, btnvia) {
    speak({ text: userAnswer })
    setCurrPage((prev) => prev + 1)

    let isCorrectAnswer
    //if correctAnswer then increase score
    switch (btnvia) {
      case 1:
        setdisabledVia(true)

        setinputValue1(userAnswer)
        break
      case 2:
        setdisabledVia1(true)
        setinputValue2(userAnswer)

        setdisabledVia1(true)

        break
      case 3:
        console.log(inputRef.current.value.toUpperCase() + '.', allQuestions[props.page].ans.toUpperCase())
        isCorrectAnswer = inputRef.current.value.toUpperCase().trim() + '.' === allQuestions[props.page].ans.toUpperCase().trim()

        setinputValue1('')
        setinputValue2('')
        // add current answer to final result.

        setdisabledVia(false)

        setdisabledVia1(false)
        props.setTheArray((oldArray) => {
          update = [
            ...oldArray,
            {
              via: isCorrectAnswer ? 'correct' : 'incorrect',
              answer: inputRef.current.value,
            },
          ]
          localStorage.setItem('useranswer', JSON.stringify(update))

          return update
        })
        isCorrectAnswer && props.setScore((prev) => prev + 1)

        if (update.length === allQuestions.length) {
          props.setTheArray([])
          props.submitResult(date)

          // navigate(
          //   'enresult',
          //   {
          //     state: { ...location.state, date: date },
          //   },
          //   1000
          // )
        } else {
          isCorrectAnswer && props.setScore((prev) => prev + 1)

          props.setPage((prev) => prev + 1)
          resetTranscript()
          setSpeechTextA('')
        }
        break
    }
  }

  const disabledcss =
    disabledVia == false
      ? {
          opacity: '0.4',
          pointerEvents: 'none',
        }
      : {
          opacity: '1',
        }

  const disabledcss1 =
    transcript == ''
      ? {
          opacity: '0.4',
          pointerEvents: 'none',
        }
      : {
          opacity: '1',
        }

  if (singleQuestion !== undefined) {
    return (
      <>
        <h2>Choose {cat}</h2>

        <Row className='english-main-container py-4 question-container'>
          <p className='question-no'>{props.page + 1}</p>
          <Col>
            <Row className='english-container'>
              <Col md={6} className='questionImage  reading-lvl phrase-image'>
                <p>{allQuestions[props.page].ques}</p>
              </Col>
              <Col md={3} className='questionImage phrase-image'>
                {/* <input type='text' value={transcript.toUpperCase()} ref={inputRef} readOnly /> */}
                <input type='text' value={speechTextA || transcript.toUpperCase()} onChange={(e) => setSpeechTextA(e.target.value)} ref={inputRef} />
              </Col>
              <Col md={3}>
                <div className='option-container'>
                  {listening ? (
                    <button
                      type='button'
                      className='common-btn activity m-auto stop-btn'
                      // onMouseDown={() => startListen("A")}
                      onClick={SpeechRecognition.stopListening}
                    >
                      <div className='pulse-bg'></div>
                      <Icon.Stop />
                    </button>
                  ) : (
                    <button
                      type='button'
                      className='common-btn  m-auto  activity'
                      onClick={() => startListen('A')}
                      //  onMouseUp={SpeechRecognition.stopListening}
                    >
                      <Icon.Mic />
                    </button>
                  )}
                </div>
                <button
                  className='common-btn'
                  style={{
                    fontSize: '16px',
                    padding: '4px',
                    width: '100%',
                    marginBottom: '20px',
                    padding: '10px',
                    marginTop: '30px',
                    ...disabledcss1,
                  }}
                  onClick={() => handleSubmit('', false, props.page + 1, 3)}
                >
                  Submit
                </button>
              </Col>
            </Row>
          </Col>
          {/* <Col style={disabledcss}>
            <div className="english-container d-block">
              <figure className="questionImage">
                <img src={allQuestions[props.page + 1].image} alt="apple" />
              </figure>
              <input type="text" ref={inputRef1} value="" readOnly />
            </div>

            <div className="option-container">
              {listening1 ? (
                <button
                  type="button"
                  className="common-btn m-auto activity stop-btn"
                  onMouseDown={() => startListen1("B")}
                  onMouseUp={SpeechRecognition.stopListening}
                >
                  <div className="pulse-bg"></div>
                  <Icon.Stop />
                </button>
              ) : (
                <button
                  type="button"
                  className="common-btn  m-auto  activity"
                  onMouseDown={() => startListen1("B")}
                  onMouseUp={SpeechRecognition.stopListening}
                >
                  <Icon.Mic />
                </button>
              )}
            </div>
         
          </Col> */}
        </Row>
      </>
    )
  }
}

export default Read_word

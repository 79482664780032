import ChartDataLabels from 'chartjs-plugin-datalabels'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2'
import * as Apiurl from './Apisurl'
import Context from './Context'
import Activity from './dashboard/Activity'
import Showresult from './dashboard/Showresult'
import { NavLink } from 'react-router-dom'
import headersReq from './HeadersJson'

const Personalised = () => {
  const reqJson = headersReq()
  const context = useContext(Context)
  const { selectValue } = context

  let user_id = selectValue.user_id
  let username = selectValue.username

  const [teachWith, setTeachWith] = useState({})
  const [aggregate, setAggregate] = useState({})

  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)

  const [conditions, setConditions] = useState([])
  const [topic, setTopic] = useState()
  const [teach, setTeach] = useState()
  const [module, setModule] = useState()
  const [recommended, setRecommended] = useState()
  const [game, setGame] = useState()

  const [moduleData, setModuleData] = useState()
  const [recommendedData, setRecommendedData] = useState()

  useEffect(() => {
    const getCummulativeReport = async () => {
      try {
        const response = await fetch(`${Apiurl.Fetchurl}brain_scan_aggregate`, {
          method: 'POST',
          headers: reqJson,
          body: JSON.stringify({
            user_id: user_id,
          }),
        })
        const { aggregate, teach_with } = await response.json()
        setAggregate(aggregate)
        setTeachWith(teach_with)
      } catch (error) {
        console.log('teach_with...aggregate.. \n', error)
      }
    }

    getCummulativeReport()
  }, [user_id])

  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await fetch(`${Apiurl.Fetchurl}get_all_category`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + ' ' + sessionStorage.getItem('session'),
          },
        })
        const data = await response.json()
        const modifiedData = data.map((d) => d.toLowerCase())
        setConditions(modifiedData)
      } catch (error) {
        console.log(error)
      }
    }

    getCategories()
  }, [])

  const getModules = async (topic, teach) => {
    setLoading(true)
    try {
      const response = await fetch(
        `${Apiurl.Fetchurl}get_module_from_topic_and_teach_with?topic=${topic}&teach=${teach}`,
        {
          method: 'GET',
          headers: reqJson,
        }
      )
      const data = await response.json()
      setModuleData(data.module)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('get sug mods', error)
    }
  }

  const getRecommended = async (topic, teach, module) => {
    setLoading1(true)
    module = module.split(' ').join('')
    try {
      const response = await fetch(
        `${Apiurl.Fetchurl}type_topic_teach_module?topic=${topic}&teach=${teach}&module_type=${module}`,
        {
          method: 'GET',
          headers: reqJson,
        }
      )
      const data = await response.json()
      Object.keys(data).forEach((k) => data[k].length === 0 && delete data[k])
      setRecommendedData(data)
      setLoading1(false)
    } catch (error) {
      setLoading1(false)
      console.log('get suggestion', error)
    }
  }

  return (
    <>
      <div className='content flex-grow-1'>
        <Container fluid className='option-main-container report-tab'>
          <div className='dashboard-heading'>
            <div class='content-container'>
              <h2>Personalized</h2>
              <p>Customised recommendations based on student data.</p>
            </div>
            <Activity />
          </div>
          <Showresult />

          <Row>
            <Col xl={8}>
              <div className='dashboard-cards stat-container'>
                <div class='performance-heading mb-4'>
                  <div class='performance-content'>
                    <h4 className='text-black'>Find Your Suggestions Here</h4>
                  </div>
                </div>

                <Pills
                  title={'Topic'}
                  data={conditions}
                  active={topic}
                  setActive={setTopic}
                  API={(v) => console.log(v)}
                  reset={() => {
                    setTeach()
                    setModule()
                    setRecommended()
                    setGame()
                  }}
                />
                {topic && Object.keys(teachWith).length !== 0 && (
                  <>
                    {teachWith[topic] && Object.keys(teachWith[topic]).length !== 0 ? (
                      <>
                        <Pills
                          title={'Teach with'}
                          data={Object.keys(teachWith[topic])}
                          active={teach}
                          setActive={setTeach}
                          API={(teach) => getModules(topic, teach)}
                          reset={() => {
                            setModule()
                            setRecommended()
                            setGame()
                          }}
                        />
                        {teach && moduleData && moduleData.length !== 0 && (
                          <>
                            {loading ? (
                              <Loading title={'Module'} />
                            ) : (
                              <Pills
                                title={'Module'}
                                data={moduleData}
                                active={module}
                                setActive={setModule}
                                API={(module) => getRecommended(topic, teach, module)}
                                reset={() => {
                                  setRecommended()
                                  setGame()
                                }}
                              />
                            )}
                            {module && recommendedData && recommendedData.length !== 0 && (
                              <>
                                {loading1 ? (
                                  <Loading title={'Recommedation For'} />
                                ) : (
                                  <Pills
                                    title={'Recommedation For'}
                                    data={Object.keys(recommendedData)}
                                    active={recommended}
                                    setActive={setRecommended}
                                    API={(v) => console.log(v)}
                                    reset={() => setGame()}
                                  />
                                )}
                                {recommended && (
                                  <>
                                    {recommended.toLowerCase() === 'games' ? (
                                      <>
                                        <Pills
                                          title={'Choose Game Type'}
                                          data={Object.keys(recommendedData[recommended])}
                                          active={game}
                                          setActive={setGame}
                                          API={(v) => console.log(v)}
                                          reset={() => {}}
                                        />
                                        {game && (
                                          <div>
                                            <div className='d-flex align-items-center'>
                                              <div className='border-top w-100' />
                                              <h4 className='text-center w-50'>Suggestions</h4>
                                              <div className='border-top w-100' />
                                            </div>

                                            <div className='d-flex flex-wrap'>
                                              {recommendedData[recommended][game].map((data, idx) => {
                                                return (
                                                  <SuggestionCard
                                                    key={idx}
                                                    data={data}
                                                    url={{ user_id, username, module, recommended, game }}
                                                  />
                                                )
                                              })}
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <div>
                                        <div className='d-flex align-items-center'>
                                          <div className='border-top w-100' />
                                          <h4 className='text-center w-50'>Suggestions</h4>
                                          <div className='border-top w-100' />
                                        </div>
                                        <div className='d-flex flex-wrap'>
                                          {recommendedData[recommended].map((data, idx) => {
                                            return (
                                              <SuggestionCard
                                                key={idx}
                                                data={data}
                                                url={{ user_id, username, module, recommended }}
                                              />
                                            )
                                          })}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <div className='d-flex align-items-center my-4'>
                        <div className='border-top w-100' />
                        <h4 className='text-center w-100'>No Suggestions Available</h4>
                        <div className='border-top w-100' />
                      </div>
                    )}
                  </>
                )}
              </div>
            </Col>
            <Col xl={4}>
              <div className='dashboard-cards stat-container'>
                <div class='performance-heading'>
                  <div class='performance-content'>
                    <h4 className='text-black'>Aggregate Result</h4>
                  </div>
                </div>
                <Bar
                  plugins={[ChartDataLabels]}
                  data={{
                    labels: Object.keys(aggregate),
                    datasets: [
                      {
                        data: Object.values(aggregate),
                        backgroundColor: ['#c8d1fc', '#b3ddf6', '#95d8a5', '#c5e49e', '#fedf6b', '#eea08c', '#B7A1C7'],
                        barPercentage: 0.5,
                        barThickness: 40,
                        maxBarThickness: 50,
                        borderRadius: 8,
                        minBarLength: 2,
                        maintainAspectRatio: false,
                      },
                    ],
                  }}
                  options={{
                    layout: {
                      padding: {
                        top: 30,
                      },
                    },
                    plugins: {
                      tooltip: {
                        enabled: false,
                      },
                      legend: {
                        display: false,
                      },
                      datalabels: {
                        labels: {
                          value: {
                            anchor: 'end',
                            align: 'top',
                            display: true,
                            color: 'black',
                            font: {
                              weight: 'bold',
                              size: 16,
                            },
                            offset: 0,
                            formatter: (value, ctx) => {
                              return value
                            },
                          },
                        },
                      },
                    },

                    scales: {
                      x: {
                        grid: {
                          display: false,
                        },
                      },
                      y: {
                        display: false,
                        grid: {
                          display: false,
                        },
                      },
                    },
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Personalised

export const Pills = ({ title, data, active, setActive, API, reset }) => {
  return (
    <div className='chips-container'>
      <span>{title}</span>
      <ul className='chips-ul'>
        {data.map((val, idx) => {
          return (
            <li
              key={idx}
              className={active === val ? 'active' : ''}
              onClick={() => {
                setActive(val)
                reset()
                API(val)
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                fill='none'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                className='feather feather-check'
              >
                <path d='M20 6 9 17l-5-5' />
              </svg>

              {val}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export const Loading = ({ title }) => {
  return (
    <div className='chips-container'>
      <span>{title}</span>
      <ul className='chips-ul'>
        <li>Loading...</li>
      </ul>
    </div>
  )
}

export const SuggestionCard = ({ data, url }) => {
  let { user_id, username, module, recommended, game = '' } = url
  let modname = module.replace('module', 'Module ')
  recommended = recommended.toLowerCase()
  let redirect =
    recommended === 'games'
      ? `/school/${module}/${recommended}/${game}/${data.name}`
      : `/school/${module}/${recommended}/${data.name}`

  let extra = recommended === 'games' ? { title: game } : { category2: data.name.trim() }

  return (
    <div className='my-3' style={{ minWidth: `${(1 / 4) * 100}%` }}>
      <NavLink
        to={redirect}
        state={{
          attempted: data.attempted,
          category: data.name.trim(),
          modulename: recommended.charAt(0).toUpperCase() + recommended.slice(1),
          modulename1: modname,
          modulename2: recommended === 'games' ? game.charAt(0).toUpperCase() + game.slice(1) : recommended,
          modulename3: module,
          userid: user_id,
          username: username,
          ...extra,
        }}
      >
        <div className='d-flex align-items-center gap-2'>
          <div
            className='d-flex align-items-center justify-content-center rounded-circle p-2'
            style={{
              width: '80px',
              height: '80px',
              background: `conic-gradient(var(--primary-color) ${(data.attempted_percentage / 100) *
                360}deg, #c9c9c9 0deg)`,
            }}
          >
            <img
              src={data.image_url}
              height={60}
              width={60}
              alt='student'
              className='rounded-circle bg-white shadow'
              style={{ objectFit: 'contain' }}
            />
          </div>
          <div>
            <h4 className='text-secondary text-capitalize m-0'>{data.name}</h4>
            <p className='m-0'>{data.attempted_percentage}%</p>
          </div>
        </div>
      </NavLink>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { Col, FloatingLabel, Form, Row, Spinner } from 'react-bootstrap'
import CustomDropzone from '../CustomDropzone'
import '../steps.css'
import * as API from '../../../Apisurl'

const StepTwo = ({ formRef, validated, data, setData }) => {
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState()

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks
    return () => URL.revokeObjectURL(file)
  }, [file])

  const handleSelect = (e) => {
    const { value } = e.target
    let image =
      value === 'Education'
        ? 'https://ui-nema-database.s3.ap-south-1.amazonaws.com/thumbnails/education.svg'
        : 'https://ui-nema-database.s3.ap-south-1.amazonaws.com/thumbnails/flashcard.svg'
    setData((old) => ({ ...old, module: value, module_title: value, image: image }))
  }

  const upload = async (e) => {
    if (!file) return

    try {
      setLoading(true)

      const formData = new FormData()
      formData.append('user_id', sessionStorage.getItem('session'))
      formData.append('folder_type', 'module_category')
      formData.append('file', file)

      const response = await fetch(`${API.Fetchurl}file_upload_1`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('session'),
        },
      })

      const data = await response.json()
      let imageURL = data.file_path[0]
      setData((old) => ({ ...old, image: imageURL }))
      setFile()

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <Row>
      <Col>
        <Form className='mt-2' ref={formRef} noValidate validated={validated}>
          <FloatingLabel controlId='module-category-title' label='Module Category'>
            <Form.Select required name='module' value={data.module} onChange={handleSelect}>
              <option value='Education'>Education</option>
              <option value='Flashcard'>Flashcard</option>
            </Form.Select>
            <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel controlId='module-category-about' label='About' className='mb-3'>
            <Form.Control
              required
              type='text'
              name='text'
              placeholder='About'
              value={data.text}
              onChange={(e) => setData((old) => ({ ...old, text: e.target.value }))}
              maxLength='30'
            />
            <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
          </FloatingLabel>

          <div className='d-flex gap-1 mb-4'>
            <CustomDropzone setData={setFile} isMultiple={false} />
            <button
              onClick={upload}
              disabled={!file || loading}
              type='button'
              className='d-flex justify-content-center align-items-center py-1 px-3 border-0 rounded-3 text-white'
              style={{ backgroundColor: 'var(--green-color)' }}
            >
              {loading ? <Spinner animation='border' /> : 'Upload'}
            </button>
          </div>
        </Form>
      </Col>
      <Col>
        <div className='d-flex flex-column align-items-center gap-2'>
          <h4>Preview</h4>
          <div className='preview-module-card'>
            <div className='text-center'>
              {!file ? <p>uploaded</p> : <p>preview only, click upload to save</p>}
              <img
                src={file ? URL.createObjectURL(file) : data.image}
                alt='module-img'
                onLoad={() => URL.revokeObjectURL(file)}
              />
            </div>
            <div className='preview-info'>
              <h4 style={{ fontSize: '20px' }}>{data.module}</h4>
              <p>{data.text || 'Some Description About Module Coategory.'}</p>
            </div>
            <button class='common-btn'>Start</button>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default StepTwo

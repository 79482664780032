import { Outlet } from 'react-router-dom'
import Sidebar from '../includes/Sidebar'
const Dashboard = () => {
  return (
    <div className='d-flex'>
      <Sidebar />
      <Outlet />
    </div>
  )
}
export default Dashboard

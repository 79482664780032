import React, { useRef, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'

const Quiz = ({ data, submit, options }) => {
  const [formData, setFormData] = useState({})
  const [validated, setValidated] = useState(false)
  const [step, setStep] = useState(1)
  const formRef = useRef()

  const titles = Object.keys(data)
  const values = Object.values(data)

  const last_page = titles.length

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((old) => ({ ...old, [name]: { ...old[name], ans: value } }))
  }

  const handleNotesChange = (e) => {
    const { name, value } = e.target
    setFormData((old) => ({ ...old, [name]: { ...old[name], notes: value } }))
  }

  const nextStep = (e) => {
    e.preventDefault()
    if (formRef.current.checkValidity() === false) {
      e.stopPropagation()
    } else {
      if (step === last_page) return finish()
      setStep((s) => s + 1)
    }
    setValidated(true)
  }

  const finish = () => {
    let ans = Object.values(formData).map((d) => ({ ...d, ans: d.ans.split('_')[1] }))
    const ques = []
    Object.values(data).forEach((obj) => obj.forEach((d) => ques.push(d.ques)))

    let results = { total: 0, score: 0, percentage: 0 }

    Object.values(formData).forEach((d) => {
      const key = d.ans.split('_')[0],
        val = d.ans.split('_')[1] === 'Yes' ? 1 : 0

      if (results[key]) {
        results[key].score += val
        results[key].total += 1
      } else {
        results[key] = { score: val, total: 1 }
      }

      results.score += val
      results.total += 1
    })

    results.percentage = Math.round((results.score / results.total) * 100)

    const report = {
      ques: { ques, ans, summary: results },
      ans: [],
      score: results.percentage,
      total: 100,
    }

    submit(report)
  }

  return (
    <Container>
      <Row className='my-3 dashboard-cards p-4'>
        <Col>
          <p className='text-black'>
            <strong>{titles[step - 1]}</strong>
          </p>
          <Form noValidate validated={validated} ref={formRef}>
            <ul className='list-unstyled'>
              {values[step - 1].map((d) => {
                return (
                  <li key={d.q_no} className='d-flex my-3'>
                    <div className='me-2 text-secondary'>{d.q_no}.</div>
                    <div className='w-100 d-lg-flex justify-content-between'>
                      <div style={{ textAlign: 'justify' }} className='w-75'>
                        {d.ques}
                      </div>

                      <div className='d-flex gap-3 justify-content-lg-end '>
                        {d.choices.map((_, idx) => {
                          const val = titles[step - 1] + '_' + options[idx]
                          return (
                            <Form.Group>
                              <Form.Check
                                key={idx}
                                type='radio'
                                label={options[idx]}
                                id={`${options[idx]}_${d.q_no}`}
                                name={d.q_no}
                                value={val}
                                checked={formData[d.q_no]?.ans === val}
                                onChange={handleChange}
                                required
                              />
                              <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                            </Form.Group>
                          )
                        })}
                        <Form.Group className='w-50'>
                          <Form.Control
                            type='text'
                            placeholder='add notes'
                            name={d.q_no}
                            value={formData[d.q_no]?.notes}
                            onChange={handleNotesChange}
                            className='p-0 h-auto'
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
            <div className='d-flex justify-content-between'>
              <button
                onClick={() => setStep((s) => s - 1)}
                type='button'
                className='d-flex justify-content-center align-items-center py-1 px-3 rounded-pill border border-2 text-secondary'
                style={{ backgroundColor: 'transparent' }}
                disabled={step === 1}
              >
                Prev
              </button>
              <button
                onClick={nextStep}
                type='submit'
                className={`d-flex justify-content-center align-items-center py-1 px-3 rounded-pill text-white`}
                style={{ backgroundColor: 'var(--green-color)', border: 'none' }}
              >
                {step !== last_page ? 'Next' : 'Finish'}
              </button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default Quiz

import React from 'react'
import image from '../assets/images/global.svg'
import image2 from '../assets/images/gujrati.svg'
import Button from 'react-bootstrap/Button'
import { Row, Col } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import Selectedlang from '../Selectedlang'

const Language = () => {
  const role = localStorage.getItem('role')

  Selectedlang()
  localStorage.setItem('getlang', 'en')

  function setlang(index, langkey) {
    localStorage.setItem('getlang', langkey)

    var select = document.getElementsByClassName('goog-te-combo')[0]
    select.value = langkey

    select.dispatchEvent(new Event('change'))
  }

  return (
    <>
      <div className='content module-section' style={{ margin: 'auto' }}>
        <div id='google_translate_element'></div>
        <h2>Please select a Language to proceed</h2>
        <Row className='language-row  justify-content-center'>
          <Col md={3}>
            <div className='Card1'>
              <div className='cardlang'>
                <img src={image} alt='Card image cap' />
                <div class='card-body'>
                  <h5 class='card-title notranslate'>English (default)</h5>
                  <button class='common-btn module-edit' value='en' id='english' onClick={() => setlang(28, 'en')}>
                    Select
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className='Card1'>
              <div className='cardlang'>
                <img src={image2} alt='Card image cap' />
                <div class='card-body'>
                  <h5 class='card-title notranslate'>ગુજરાતી</h5>
                  <button class='common-btn module-edit' value='gu' id='gujrat' onClick={() => setlang(41, 'gu')}>
                    Select
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className='Card1'>
              <div className='cardlang'>
                <img src={image2} alt='Card image cap' />
                <div class='card-body'>
                  <h5 class='card-title notranslate'>Hindi</h5>
                  <button class='common-btn module-edit' value='gu' id='gujrat' onClick={() => setlang(46, 'hi')}>
                    Select
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className='Card1'>
              <div className='cardlang'>
                <img src={image2} alt='Card image cap' />
                <div class='card-body'>
                  <h5 class='card-title notranslate'>Marathi</h5>
                  <button class='common-btn module-edit' value='gu' id='gujrat' onClick={() => setlang(81, 'mr')}>
                    Select
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <div className='language-btn'>
            <NavLink to={role === 'hr' ? '/brain' : '/'}>
              <Button variant='primary' className='common-btn module-edit' type='button'>
                Submit
              </Button>
            </NavLink>
          </div>
        </Row>
      </div>
    </>
  )
}

export default Language

import Table from "react-bootstrap/Table";
import { MDBIcon } from "mdb-react-ui-kit";
import ReactPaginate from "react-paginate";
import { useEffect, useState, useContext } from "react";
import Context from "../../../Context";
import UserJson from "../../../Userjson";
import { Player, ControlBar, BigPlayButton } from "video-react";
const SentanceTable = () => {
  const props1 = useContext(Context);
  let overview = props1[2].Overview;
  if (overview != undefined && overview.length != 0) {
    let createPairJson = () => {
      const pairedJson = [];
      for (let i = 0; i < overview.ques.length; i += 3) {
        pairedJson.push({
          question: overview.ques[i],
          answer1: overview.ques[i + 1],
          answer2: overview.ques[i + 2],
        });
      }
      return pairedJson;
    };
    let createPairJson2 = createPairJson();

    return (
      <Table className="result-table">
        <thead>
          <tr>
            <th>asdasdsa</th>
            {createPairJson2.map((o, i) => {
              return <th>Q{i + 1}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Asked</td>

            {createPairJson2.map((o, i) => {
              console.log(o);
              return (
                <td>
                  <td>
                    <div className="asked-color-box d-flex ">
                      <img
                        src={o.question.image}
                        style={{ marginLeft: "10px " }}
                        height="80"
                      />
                      <img src={o.answer1.image} height="80" />
                      <img
                        src={o.answer2.image}
                        style={{ marginLeft: "10px " }}
                        height="80"
                      />
                    </div>
                    <p style={{ textAlign: "center", marginTop: "10px" }}>
                      {o.type}
                    </p>
                  </td>
                </td>
              );
            })}
          </tr>
          <tr>
            <td>Your Answer</td>
            {overview.ans.map((o, i) => {
              console.log(o);
              return (
                <td>
                  <div className="asked-color-box">
                    <p
                      style={
                        o.via == "correct"
                          ? { color: "#77C63A" }
                          : { color: "red" }
                      }
                    >
                      {o.answer1} {o.answer} {o.answer2}
                    </p>
                  </div>
                </td>
              );
            })}
          </tr>
          <tr>
            <td>Correct Answer</td>
            {createPairJson2.map((o, i) => {
              console.log(o.ques);
              return (
                <td>
                  <div className="asked-color-box">
                    {o.question.ans} {o.answer1.ans} {o.answer2.ans}
                  </div>
                </td>
              );
            })}
          </tr>
        </tbody>
      </Table>
    );
  }
};

export default SentanceTable;

import React from 'react'
import { Outlet } from 'react-router-dom'
import image from './assets/images/401.jpg'

const SchoolAccess = () => {
  if (sessionStorage.getItem('session') === '123456') {
    return <Outlet />
  }

  const role = localStorage.getItem('role')
  return role === 'parent' || role === 'teacher' ||role== 'admin'? (
    <Outlet />
  ) : (
    <div className='content bg-white d-flex justify-content-center align-items-center'>
      <div className='text-center'>
        <img src={image} alt='401-unauthorized' height='600px' />
        <h4>Access Unauthourised</h4>
      </div>
    </div>
  )
}

export default SchoolAccess

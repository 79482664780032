import React from 'react'
import Activity from '../dashboard/Activity'
import TeamView from './TeamView'

const Team = () => {
  return (
    <div className='content flex-grow-1 d-flex flex-column'>
      <div className='dashboard-heading'>
        <div class='content-container'>
          <h2>Team Analysis</h2>
          <p>Make teams based on individual productivity.</p>
        </div>
        <Activity />
      </div>

      <TeamView />
    </div>
  )
}

export default Team

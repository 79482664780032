import React, { useState, useRef, useEffect } from 'react'

const OTPInput = ({ length, onOTPChange, resendOtp }) => {
  const [otp, setOtp] = useState(Array(length).fill(''))
  const [timer, setTimer] = useState(30)
  const otpInputs = Array(length)
    .fill(0)
    .map(() => React.createRef())

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0))
    }, 1000)
    return () => clearInterval(countdown)
  }, [])

  const handleChange = (value, index) => {
    if (isNaN(value)) return
    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)

    if (index === length - 1) {
      const otpValue = newOtp.join('')
      onOTPChange(otpValue)
    }

    if (value !== '' && index < length - 1) {
      otpInputs[index + 1].current.focus()
    }
  }

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
      otpInputs[index - 1].current.focus()
    }
  }

  const handleResend = () => {
    setOtp(Array(length).fill(''))
    setTimer(30)
    resendOtp()
  }

  return (
    <div>
      <div className='otp-container'>
        {otp.map((digit, index) => (
          <input
            key={index}
            ref={otpInputs[index]}
            type='text'
            maxLength='1'
            value={digit}
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            style={{ width: '30px', marginRight: '5px' }}
          />
        ))}
      </div>
      <div>
        {timer === 0 ? (
          <a href='#' onClick={handleResend}>
            Resend OTP
          </a>
        ) : (
          <span className='ml-2'>Resend OTP in {timer} seconds</span>
        )}
      </div>
    </div>
  )
}

export default OTPInput

import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()
  return (
    <main
      style={{
        height: '75vh',
      }}
    >
      <div className='d-flex h-100 justify-content-center align-items-center'>
        <div className='mx-auto text-center'>
          <h1
            style={{
              fontSize: '256px',
              fontWeight: 'bolder',
              letterSpacing: '15px',
              background: 'var(--primary-gradient)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Oops!
          </h1>
          <h4 className='text-capitalize text-bold mt-4'>page not found</h4>
          <p className='text-capitalize text-secondary'>the page you are looking for is currently unavailable or might have been removed</p>
          <button
            onClick={() => navigate('loginmain')}
            className='common-btn px-4 py-3 w-25'
            style={{
              fontSize: '22px',
            }}
          >
            Home
          </button>
        </div>
      </div>
    </main>
  )
}

export default NotFound

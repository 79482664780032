import { useCallback, useEffect, useRef, useState } from 'react'
import { Col, FormLabel, Row, Spinner } from 'react-bootstrap'
import Select from 'react-select'
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'
import 'react-dropzone-uploader/dist/styles.css'
import { useNavigate, useParams } from 'react-router-dom'
import * as Apiurl from '../Apisurl'
import userImg from '../assets/images/user5.svg'
import Formstyle from './cssModule/Formstyle.module.css'
import logo from '../assets/images/logo.svg'
import jwtDecode from 'jwt-decode'
import sendMail from './sendMail'
import PasswordStrengthMeter from "../login/PasswordStrengthMeter.jsx"

const CollegeForm = () => {
  const { type: role } = useParams()
  const navigate = useNavigate()

  const [services, setServices] = useState([])
  const [service, setService] = useState([])
  const [isStrongPassword, setIsStrongPassword] = useState(false)

  const typingTimeoutRef = useRef()

  const [uservalues, setInputValue] = useState({
    username: '',
    email_id: '',
    password: '',
    phonenumber: '',
    school_name: '',
    speciality: '',
    duration: '1',
    max_students: '1',
    secret_key: '',
    module_start: '1',
    module_end: '5',
    person_type: role,
  })

  const [childData, setChildData] = useState({
    username: '',
    class: '',
    school_name: '',
    special_need: '',
    parent_name: 'NA',
    person_type: 'child',
    dob: '',
    gender: '',
  })

  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [err, seterr] = useState('')
  const [err1, seterr1] = useState('')
  const formElement = useRef()

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputValue({ ...uservalues, [name]: value })
  }

  const teacherValidate = (event) => {
    event.preventDefault()
    const isFormInvalid = formElement.current.checkValidity() === false
    const isServiceEmpty = !service.length > 0

    if (isFormInvalid || isServiceEmpty) {
      event.stopPropagation()
      isServiceEmpty && seterr1('Please enter required field')
    } else {
      if(!isStrongPassword){
        alert('Please use a stronger password')
        return
      }
      processSignup().catch((error) => {
        console.error(error)
      })
    }
    setValidated(true)
  }

  const addchild = async (session) => {
    setLoading(true)
    try {
      const response = await fetch(`${Apiurl.Fetchurl + 'signup'}`, {
        method: 'POST',
        body: JSON.stringify({
          ...childData,
          person_uid: jwtDecode(session).user_id,
          person_type_og: jwtDecode(session).person_type,
          username: uservalues.username,
          school_name: uservalues.school_name,
          special_need: uservalues.speciality,
        }),
      })
      const data = await response.json()
      return data
    } catch (error) {
      console.error(error)
      // throw new Error('Error in addchild')
    } finally {
      setLoading(false)
    }
  }

  const signup = async () => {
    setLoading(true)
    try {
      const response = await fetch(Apiurl.Fetchurl + 'signup', {
        method: 'POST',
        body: JSON.stringify({ ...uservalues, person_type: service.map((s) => parseInt(s.value)).includes(7) ? `demo_${role}` : role }),
      })
      if (!response.ok) {
        const error = await response.json()
        throw new Error(error?.error || 'Something Went Wrong....')
      }
      const data = await response.json()
      sessionStorage.setItem('session', data.session)
      sessionStorage.setItem('max_students', data.max_students)
      await addchild(data.session) // both student and college calls this

      return data
    } catch (error) {
      seterr(error.message)
      throw new Error('Unable to sign up user')
    } finally {
      setLoading(false)
    }
  }

  const initiatePayment = async () => {
    try {
      const response = await fetch(Apiurl.Fetchurl + 'order', {
        method: 'POST',
        body: JSON.stringify({
          service_id: service.map((s) => parseInt(s.value)),
          month: parseInt(uservalues.duration),
          person_type: role,
        }),
      })
      const data = await response.json()
      openPaymentGateway(data.Success)
    } catch (error) {
      console.error(error)
      throw new Error('Error in initiatePayment')
    }
  }

  const openPaymentGateway = (paymentData) => {
    const paymentOptions = {
      key: process.env.REACT_APP_RAZORPAY,
      amount: paymentData.amount,
      currency: paymentData.currency,
      name: 'NEMA AI',
      description: 'Test Transaction',
      image: logo,
      order_id: paymentData.id,

      handler: async (response) => {
        try {
          const verificationResponse = await fetch(Apiurl.Fetchurl + 'verify', {
            method: 'POST',
            body: JSON.stringify({
              ...response,
              service_id: service.map((s) => parseInt(s.value)),
              month: parseInt(uservalues.duration),
              person_type: role,
            }),
          })

          if (!verificationResponse.ok) throw new Error('Something went wrong')

          const verificationData = await verificationResponse.json()
          sessionStorage.setItem('isPaymentSuccessful', 'true')
          sessionStorage.setItem('responseFromOrder', JSON.stringify(response))
          sessionStorage.setItem('paymentResponse', JSON.stringify(verificationData))

          const { user_id } = await signup()
          if (!user_id) throw new Error('User Creation failed')

          await verifyPayment({
            service_id: JSON.stringify(service.map((s) => parseInt(s.value))),
            month: parseInt(uservalues.duration),
            person_type: role,
            ...response,
            payment_id: verificationData.payment_id,
            method: JSON.stringify(verificationData.method),
            user_id,
          })

          sessionStorage.removeItem('isPaymentSuccessful')
          sessionStorage.removeItem('responseFromOrder')
          sessionStorage.removeItem('paymentResponse')

          navigate('/loginmain')
        } catch (error) {
          console.error(error)
        }
      },
      theme: { color: '#3399cc' },
    }
    const razorpayInstance = new window.Razorpay(paymentOptions)
    razorpayInstance.open()
  }

  const verifyPayment = async (paymentDetails) => {
    try {
      const res = await fetch(Apiurl.Fetchurl + 'verify?' + new URLSearchParams(paymentDetails).toString())
      if (!res.ok) throw new Error('Something went wrong')
      // const data = await res.json()
      // return data
    } catch (error) {
      console.error(error)
      throw new Error('Error in verifyPayment')
    }
  }

  const processSignup = async () => {
    if (err || err1) return

    if (service.map((s) => parseInt(s.value)).includes(7)) {
      try {
        await signup()
        return navigate('/loginmain')
      } catch (error) {
        console.log(error)
        return seterr(error.message)
      }
    }

    const isPaymentSuccessful = sessionStorage.getItem('isPaymentSuccessful') === 'true'
    const paymentResponse = JSON.parse(sessionStorage.getItem('paymentResponse'))
    const responseFromOrder = JSON.parse(sessionStorage.getItem('responseFromOrder'))

    if (isPaymentSuccessful) {
      const { user_id } = await signup()
      if (!user_id) throw new Error('User Creation failed')

      await verifyPayment({
        ...responseFromOrder,
        service_id: JSON.stringify(service.map((s) => parseInt(s.value))),
        month: parseInt(uservalues.duration),
        person_type: role,
        payment_id: paymentResponse.payment_id,
        method: JSON.stringify(paymentResponse.method),
        user_id,
      })
      sessionStorage.removeItem('isPaymentSuccessful')
      sessionStorage.removeItem('responseFromOrder')
      sessionStorage.removeItem('paymentResponse')

      navigate('/loginmain')
    } else {
      try {
        await initiatePayment()
      } catch (error) {
        console.error(error)
        throw new Error('Error in processSignup')
      }
    }
  }

  const checkAvailability = useCallback(async () => {
    if (!uservalues.username) return
    try {
      const response = await fetch(Apiurl.Fetchurl + 'verify_user', {
        method: 'POST',
        body: JSON.stringify({ username: uservalues.username }),
      })

      if (!response.ok) throw new Error('username unqiueness check failed')

      const data = await response.json()
      if (data.Status === 400) return seterr(data.message)
      else return seterr()
    } catch (error) {
      console.error('Error:', error)
    }
  }, [uservalues.username])

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(checkAvailability, 1000)

    return () => {
      clearTimeout(typingTimeoutRef.current)
    }
  }, [uservalues.username, checkAvailability])

  useEffect(() => {
    const getServices = async () => {
      if (!role) return
      try {
        const response = await fetch(`${Apiurl.Fetchurl}service_id_plan?person_type=${role}`)
        const data = await response.json()
        const modifiedData = Object.entries(data.output).map(([id, name]) => ({
          label: name,
          value: id,
          isFixed: false,
        }))
        setServices(modifiedData)
        if (modifiedData.length > 0) {
          setService(modifiedData.filter((d) => d.isFixed))
        }
      } catch (error) {
        console.error(error)
      }
    }

    getServices()
  }, [role])

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: 'transparent',
      border: 0,
      borderRadius: 0,
      borderBottom: err1 ? '2px solid #dc3545' : state.isFocused ? '2px solid var(--primary-color)' : '2px solid #ebebeb',
      boxShadow: 'none',
      '&:hover': {
        borderBottom: err1 ? '2px solid #dc3545' : '2px solid var(--primary-color)',
      },
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isSelected || isFocused ? 'var(--primary-color)' : null,
      color: isSelected || isFocused ? 'white' : 'black',
    }),
    valueContainer: (base) => ({ ...base, padding: 0 }),
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base
    },
  }

  const handleServiceChange = (value, actionMeta) => {
    seterr1('')
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) {
          return
        }
        break
      case 'clear':
        value = service.filter((v) => v.isFixed)
        break
    }
    setService(value)
  }

  return (
    <>
      <Row>
        {err && <p className='text-danger'>{err}</p>}
        <Col md={8}>
          <Form className={Formstyle.formContainer} noValidate validated={validated} ref={formElement}>
            <Row>
              <Col md={6}>
                <div className={Formstyle.profileContainer}>
                  <figure>
                    <img src={userImg} alt='userimage' />
                  </figure>
                  <FloatingLabel controlId='floatingInput' label='Username' className={Formstyle.labelinput}>
                    <Form.Control
                      type='text'
                      placeholder='xcvvx'
                      maxLength='40'
                      required
                      name='username'
                      value={uservalues.username}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                  </FloatingLabel>
                </div>
              </Col>
              <Col md={6}>
                <FloatingLabel controlId='floatingInput' label='Password' className='mb-3'>
                  <Form.Control
                    type='password'
                    value={uservalues.password}
                    onChange={handleChange}
                    name='password'
                    placeholder='asdasd'
                    maxLength='40'
                    required
                  />
                  <PasswordStrengthMeter password={uservalues.password} setIsStrongPassword={setIsStrongPassword} />
                  <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col md={6}>
                <label>Services</label>
                <Select
                  placeholder='Add Services'
                  onChange={handleServiceChange}
                  options={services}
                  value={service}
                  isMulti
                  isClearable={service.some((v) => !v.isFixed)}
                  isSearchable={false}
                  styles={customStyles}
                />
                {err1 && <p style={{ marginTop: '0.25rem', fontSize: '.875em', color: '#dc3545' }}>{err1}</p>}
              </Col>
              <Col md={6}>
                <label className='mb-1'>Subscription</label>
                <div className='d-flex align-items-end justify-content-between'>
                  {['1', '3', '6', '12'].map((month) => (
                    <>
                      <label
                        htmlFor={month + 'M'}
                        className='px-3 py-2 rounded-3'
                        style={{
                          border: `2px solid ${uservalues.duration === month ? 'var(--primary-color)' : '#dee2e6'}`,
                          color: uservalues.duration === month && 'var(--primary-color)',
                        }}
                      >
                        {month} {month === '1' ? 'Month' : 'Months'}
                      </label>
                      <input
                        type='radio'
                        value={month}
                        name='duration'
                        id={month + 'M'}
                        onChange={(e) => setInputValue({ ...uservalues, duration: e.target.value })}
                        className='d-none'
                      />
                    </>
                  ))}
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FloatingLabel controlId='floatingInput' label='Email' className='mb-3'>
                  <Form.Control
                    type='email'
                    value={uservalues.email_id}
                    onChange={handleChange}
                    name='email_id'
                    placeholder='name@example.com'
                    maxLength='40'
                    required
                  />
                  <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <div className={Formstyle.mobContainer}>
                  <FloatingLabel controlId='floatingInput' label='Phone Number' required className={Formstyle.labelinput}>
                    <Form.Control
                      type='number'
                      maxLength='40'
                      placeholder=''
                      value={uservalues.phonenumber}
                      onChange={handleChange}
                      name='phonenumber'
                      required
                    />
                    <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                  </FloatingLabel>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className='mb-3'>
                  <Form.Label htmlFor='gender'>Gender</Form.Label>
                  <br />
                  <Form.Check
                    required
                    type='radio'
                    id='Male'
                    label='Male'
                    name='gender'
                    value='male'
                    checked={childData.gender === 'male'}
                    onChange={(e) => setChildData({ ...childData, gender: e.target.value })}
                    inline
                  />
                  <Form.Check
                    required
                    type='radio'
                    id='Female'
                    label='Female'
                    name='gender'
                    value='female'
                    checked={childData.gender === 'female'}
                    onChange={(e) => setChildData({ ...childData, gender: e.target.value })}
                    inline
                  />
                  <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <FloatingLabel controlId='dob' label='Date of Birth' className='mb-3'>
                  <Form.Control
                    required
                    type='date'
                    name='dob'
                    value={childData.dob
                      ?.split('-')
                      .reverse()
                      .join('-')}
                    onChange={(e) =>
                      setChildData({
                        ...childData,
                        dob: e.target.value
                          ?.split('-')
                          .reverse()
                          .join('-'),
                      })
                    }
                  />
                  <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>

            <Row>
              <Col>
                <FloatingLabel controlId='floatingInput' label='College' className='mb-3'>
                  <Form.Control
                    type='text'
                    placeholder='asdsa'
                    name='school_name'
                    value={uservalues.school_name}
                    onChange={handleChange}
                    maxLength='40'
                    required
                  />
                  <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId='floatingSelect' label='Department'>
                  <Form.Control
                    type='text'
                    placeholder='xcvvx'
                    maxLength='40'
                    required
                    name='speciality'
                    value={uservalues.speciality}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>

            {/* <Row>
              <Col>
                <FloatingLabel controlId='floatingInput' label='Access Key' className={Formstyle.labelinput}>
                  <Form.Control
                    required
                    type='text'
                    placeholder='******'
                    name='secret_key'
                    value={uservalues.secret_key}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row> */}

            <Row>
              <Col md={6}>
                <FloatingLabel controlId='max_students' label='No of Students' className={Formstyle.labelinput}>
                  <Form.Control
                    type='number'
                    max={100}
                    min={1}
                    placeholder='1'
                    value={uservalues.max_students}
                    onChange={handleChange}
                    name='max_students'
                    required
                  />
                  <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>

            <Button onClick={teacherValidate} variant='primary' className='common-btn full-btn' disabled={loading} type='button'>
              {loading ? (
                <div className='d-flex align-items-center justify-content-center gap-2'>
                  <Spinner animation='border' />
                  Submitting
                </div>
              ) : (
                'Submit'
              )}
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default CollegeForm

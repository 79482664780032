import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Activity from '../dashboard/Activity'
import { NavLink } from 'react-router-dom'
import addicon from '../assets/images/layout_add_icon.svg'
import editicon from '../assets/images/edit-admin.svg'
import parenticon from '../assets/images/parent.svg'
import teachericon from '../assets/images/teacher.svg'

const Admin = () => {
  const [active, setActive] = useState(0)
  let data = [
    {
      id: 1,
      title: 'Add',
      buttontxt: 'Add',
      text: 'Add New Things To Platform',
      link: 'add',
      images: addicon,
    },
    {
      id: 2,
      title: 'Edit',
      buttontxt: 'Edit',
      text: 'Edit Existing Things On Platform',
      link: 'edit',
      images: editicon,
    },
    {
      id: 3,
      title: 'Teacher',
      buttontxt: 'View',
      text: 'View and Edit Teacher Access to Platform',
      link: 'teacher',
      images: teachericon,
    },
    {
      id: 4,
      title: 'Parent',
      buttontxt: 'View',
      text: 'View and Edit Parent Access to Platform',
      link: 'parent',
      images: parenticon,
    },
    {
      id: 5,
      title: 'Nema e-Shop',
      buttontxt: 'View',
      text: 'View and Edit Nema Shop Products',
      link: 'shop',
      images: parenticon,
    },
  ]

  return (
    <div className='content'>
      <Container fluid className='option-main-container report-tab'>
        <div className='dashboard-heading'>
          <div class='content-container'>
            <h2>Admin</h2>
            <p>Add, Update and Manage your content here</p>
          </div>
          <Activity />
        </div>

        <Row className='option-container justify-content-center'>
          {data.map((d, i) => {
            return (
              <Col key={d.id} md={4} style={{ width: '400px' }}>
                <li onClick={() => setActive(i)} className={`text-center ${active == i ? 'active' : ''}`}>
                  <img src={d.images} alt='icon' />

                  <h6 className='mt-4'>{d.title}</h6>
                  <p>{d.text}</p>
                  <div class='btn-container'>
                    <NavLink to={d.link}>
                      <button class='common-btn'>{d.buttontxt} </button>
                    </NavLink>
                  </div>
                </li>
              </Col>
            )
          })}
        </Row>
      </Container>
    </div>
  )
}

export default Admin

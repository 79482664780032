import React, { useState } from 'react'
import { DragDropContainer, DropTarget } from 'react-drag-drop-container'
import bus from '../../../assets/images/modesoftransport/bus.svg'
import car from '../../../assets/images/modesoftransport/car.svg'
import ballon from '../../../assets/images/modesoftransport/ballon.svg'
import helicopter from '../../../assets/images/modesoftransport/helicopter.svg'
import boat from '../../../assets/images/modesoftransport/boat.svg'
import motorboat from '../../../assets/images/modesoftransport/motorboat.svg'

const ModesOfTransport = (props) => {
  const [result, setResult] = useState([])

  const handleHit = (e) => {
    e.containerElem.style.visibility = 'hidden'
    let data = e.dragData
    let img = document.createElement('img')
    img.src = data.element
    let parentContainer = e.target
    parentContainer.appendChild(img)
  }

  const handleDrop = (e) => {
    let ques = props.level === 1 ? 'air' : props.level === 2 ? 'water' : 'land'
    let ans = e.dragData.type
    setResult([...result, { ques, ans, via: ques === ans ? 'correct' : 'incorrect' }])
  }

  const reset = () => {
    let el = document.getElementsByClassName('drag-element')
    let dropTargets = document.getElementsByClassName('drop-target')
    Object.values(dropTargets).forEach((dropTarget) => {
      dropTarget.innerHTML = ''
    })
    Object.values(el).forEach((e) => {
      e.style.visibility = 'visible'
    })
    setResult([])
    props.setScore(0)
  }

  const check = () => {
    let score = 0
    result.forEach(({ via }) => {
      if (via === 'correct') {
        score++
      }
    })

    localStorage.setItem('useranswer', JSON.stringify(result))
    props.submit()
  }

  return (
    <div>
      <div className='d-flex align-items-center '>
        <h2 className='m-0'>Find Mode of {props.level === 1 ? 'air' : props.level === 2 ? 'water' : 'land'} Transport</h2>
      </div>
      <div className='d-flex align-items-center mx-5 my-3'>
        <div className='d-flex flex-column gap-4'>
          <DragDropContainer targetKey='items' dragData={{ type: 'land', element: bus }} onDrop={handleDrop}>
            <div className='drag-element'>
              <img src={bus} alt='' />
            </div>
          </DragDropContainer>
          <DragDropContainer targetKey='items' dragData={{ type: 'land', element: car }} onDrop={handleDrop}>
            <div className='drag-element'>
              <img src={car} alt='' />
            </div>
          </DragDropContainer>
          <DragDropContainer targetKey='items' dragData={{ type: 'air', element: ballon }} onDrop={handleDrop}>
            <div className='drag-element'>
              <img src={ballon} alt='' />
            </div>
          </DragDropContainer>
          <DragDropContainer targetKey='items' dragData={{ type: 'air', element: helicopter }} onDrop={handleDrop}>
            <div className='drag-element'>
              <img src={helicopter} alt='' />
            </div>
          </DragDropContainer>
          <DragDropContainer targetKey='items' dragData={{ type: 'water', element: boat }} onDrop={handleDrop}>
            <div className='drag-element'>
              <img src={boat} alt='' />
            </div>
          </DragDropContainer>
          <DragDropContainer targetKey='items' dragData={{ type: 'water', element: motorboat }} onDrop={handleDrop}>
            <div className='drag-element'>
              <img src={motorboat} alt='' />
            </div>
          </DragDropContainer>
        </div>
        <div className='d-flex justify-content-center flex-grow-1'>
          <DropTarget targetKey='items' onHit={handleHit}>
            <div
              className='drop-target'
              style={{
                minWidth: '350px',
                minHeight: '350px',
                border: '4px dashed var(--primary-color)',
              }}
            ></div>
          </DropTarget>
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-end'>
        <button type='button' onClick={reset} className='btn common-btn mx-2'>
          Reset
        </button>
        <button type='button' onClick={check} className='btn common-btn'>
          Submit
        </button>
      </div>
    </div>
  )
}

export default ModesOfTransport

import React, { useState } from 'react'
import Tree from './Tree'
import './PartsOfTree.css'
import Speech from '../../Speech'

const PartsOfTree = () => {
  const [clickXY, setClickXY] = useState()

  const [partName, setPartName] = useState()

  const handleClick = (e, part) => {
    Speech.Speech(part)
    setPartName(part)
    setClickXY({
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
    })
  }
  return (
    <>
      <h4>Prompt child to click on parts of the tree</h4>
      <div
        className='d-flex justify-content-center position-relative mx-auto'
        style={{
          width: '1000px',
        }}
      >
        <div>
          {partName === 'fruits' && <h1 className='tree-part fruits'>Fruits</h1>}
          {partName === 'leaves' && <h1 className='tree-part leaves'>Leaves</h1>}
        </div>
        <Tree handleClick={handleClick} />
        <div>
          {partName === 'branch' && <h1 className='tree-part branch'>Branch</h1>}
          {partName === 'trunk' && <h1 className='tree-part trunk'>Trunk</h1>}
          {partName === 'roots' && <h1 className='tree-part roots'>Roots</h1>}
        </div>
      </div>
    </>
  )
}

export default PartsOfTree

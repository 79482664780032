import { CategoryScale } from 'chart.js'
import Chart from 'chart.js/auto'
import 'chartjs-plugin-datalabels'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { useContext } from 'react'
import { Bar } from 'react-chartjs-2'
import Context from '../Context'
import Bottomarr from '../assets/images/bottomarr.svg'

const Bloom = () => {
  const selectData = useContext(Context)
  Chart.register(CategoryScale)

  let seriesColor = ['#c8d1fc', '#b3ddf6', '#95d8a5', '#c5e49e', '#fedf6b', '#eea08c']
  let getResut

  if (selectData?.bloomData) {
    getResut = selectData?.bloomData?.reduce((total, arg) => total + arg, 0)
  }

  if (selectData.bloomData) {
    return (
      <div className='chartcolor'>
        <div className='percent-container'>
          <h5 style={{ fontSize: '14px' }}>
            Average :
            <img style={{ height: '14px', width: '14px' }} src={Bottomarr} alt='bottomarr' />
            {parseInt(getResut / 6)}
          </h5>
        </div>

        <Bar
          data={{
            labels: ['Remember', 'Understand', 'Apply', 'Analyze', 'Evaluate', 'Create'],
            datasets: [
              {
                barPercentage: 0.5,
                barThickness: 40,
                maxBarThickness: 50,
                borderRadius: 8,
                minBarLength: 2,
                data: selectData.bloomData,
                backgroundColor: seriesColor,
              },
            ],
          }}
          plugins={[ChartDataLabels]}
          options={{
            tooltips: {
              enabled: false,
            },

            plugins: {
              datalabels: {
                labels: {
                  value: {
                    formatter: (value, ctx) => {
                      return value
                    },
                    color: '#3c726e',
                    display: true,
                    font: {
                      weight: 'bold',
                      size: '20px',
                    },
                    anchor: 'center',
                    offset: 0,
                  },
                },
              },
              legend: {
                display: false,
                labels: {
                  color: 'rgb(255, 99, 132)',
                },
                title: {
                  display: false,
                  text: 'Custom Chart Title',
                },
              },
            },

            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                type: 'linear',
                display: false,
                position: 'left',
                grid: {
                  display: false,
                },
              },
            },
          }}
          height='80px'
        />
      </div>
    )
  }
}
export default Bloom

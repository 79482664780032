import React from 'react'
import { useState, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useSpeechSynthesis } from 'react-speech-kit'

import MainData from './Maindata'
import prevArrow from '../assets/images/prevarrow.svg'
import nextArrow from '../assets/images/nextarrow.svg'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Speech from './Speech'
import { useNavigate } from 'react-router-dom'
import Placeholder from './Placeholder'

const PhaseSelect = () => {
  const [active, setActive] = useState(0)

  const navigate = useNavigate()
  let data = JSON.parse(localStorage.getItem('modulejson'))

  let txt = useRef()

  function redirectPage(e, item, i) {
    Speech.Speech(item)

    let redirectLink = item
      .toLowerCase()
      .split(' ')
      .join('')

    localStorage.setItem('modulename', item)
    navigate(redirectLink, {
      state: {
        modulename1: item.charAt(0).toUpperCase() + item.slice(1),
        modulename3: redirectLink,
      },
    })
  }

  const getactiveid = (id) => {
    setActive(id)
  }

  var settings = {
    dots: false,
    infinite: false,
    initialslide: 0,
    speed: 500,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerPadding: '50px',
    nextArrow: (
      <figure className='arrow-item prevArrow'>
        <img src={nextArrow} alt='nextArrow' />
      </figure>
    ),
    prevArrow: (
      <figure className='arrow-item prevArrow'>
        <img src={prevArrow} alt='prevarrow' />
      </figure>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  let module1 = data ? Object.keys(data) : []
  module1.sort((a, b) => {
    const numA = parseInt(a.split(' ')[1])
    const numB = parseInt(b.split(' ')[1])
    return numA - numB
  })

  return (
    <>
      <h3>Please select a phase to proceed</h3>
      <Container>
        <Row className='option-container'>
          {module1 != undefined && module1 != '' ? (
            <Slider {...settings} className='gameCatContainer'>
              {module1.map((item, i) => {
                let moduleData = data[item] != undefined ? data[item] : ''

                if (item != undefined) {
                  let percentage =
                    Math.round(
                      (moduleData[1]?.attempted_percentage +
                        moduleData[2]?.attempted_percentage +
                        moduleData[3]?.attempted_percentage) /
                        3
                    ) || 0
                  return (
                    <Col md={4} key={i} className='px-5 pb-5'>
                      <li onClick={() => getactiveid(i)} className='my-2'>
                        <div
                          className='position-absolute d-flex justify-content-center align-items-center rounded-circle'
                          style={{
                            width: '50px',
                            height: '50px',
                            right: '15px',
                            top: '15px',
                            background: `conic-gradient(var(--primary-color) ${(percentage / 100) *
                              360}deg, #e9e9e9 0deg)`,
                          }}
                        >
                          <div
                            className='d-flex justify-content-center align-items-center bg-white text-black rounded-circle shadow'
                            style={{ width: '35px', height: '35px', fontSize: '12px', fontWeight: 800 }}
                          >
                            {percentage}%
                          </div>
                        </div>
                        <figure>
                          <img
                            src={Array.isArray(moduleData) ? moduleData[0].image : moduleData.image}
                            alt='education'
                          />
                        </figure>
                        <figcaption>
                          <h6>{item}</h6>
                          <p className='text-center' ref={txt}>
                            {Array.isArray(moduleData) ? moduleData[0].text : moduleData.text}
                          </p>
                          <div class='btn-container' style={{ display: 'block' }}>
                            <div>
                              <button class='common-btn m-0' onClick={(e) => redirectPage(e, item, i)}>
                                Start
                              </button>
                            </div>
                          </div>
                        </figcaption>
                      </li>
                    </Col>
                  )
                }
              })}
            </Slider>
          ) : (
            <Row>
              <Col md={4}>
                <Placeholder btnVia={1} />
              </Col>
              <Col md={4}>
                <Placeholder btnVia={1} />
              </Col>
              <Col md={4}>
                <Placeholder btnVia={1} />
              </Col>
            </Row>
          )}
        </Row>
      </Container>
    </>
  )
}

export default PhaseSelect

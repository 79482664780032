import React, { useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions'
import * as API from '../../Apisurl'
import Context from '../../Context'
import { Container } from 'react-bootstrap'
import Activity from '../../dashboard/Activity'
import Header from '../../profile/Header'

const QuestionarieLogical = () => {
  const { selectValue } = useContext(Context)
  const [reports, setReports] = useState([])

  useEffect(() => {
    const getReports = async () => {
      try {
        const response = await fetch(`${API.Fetchurl}questionaire_logical?user_id=${selectValue.user_id}`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${sessionStorage.getItem('session')}` },
        })
        const { All } = await response.json()
        setReports(All)
      } catch (error) {
        console.log(error)
        setReports([])
      }
    }

    getReports()
  }, [])

  const tableData = {
    columns: [
      {
        name: 'User ID',
        selector: (row) => row.user_id,
      },
      {
        name: 'User Name',
        selector: (row) => row.username,
      },
      {
        name: 'Analogies',
        selector: (row) => {
          let data = JSON.parse(row.result.Analogies)
          return (
            <p className='score-badge'>
              <span>{data.score}</span>/{data.total}
            </p>
          )
        },
      },
      {
        name: 'Matching Definitions',
        selector: (row) => {
          let data = JSON.parse(row.result.Matching_Definitions)
          return (
            <p className='score-badge'>
              <span>{data.score}</span>/{data.total}
            </p>
          )
        },
      },
      {
        name: 'Number Series',
        selector: (row) => {
          let data = JSON.parse(row.result.Number_Series)
          return (
            <p className='score-badge'>
              <span>{data.score}</span>/{data.total}
            </p>
          )
        },
      },
      {
        name: 'Verbal Classification',
        selector: (row) => {
          let data = JSON.parse(row.result.Verbal_Classification)
          return (
            <p className='score-badge'>
              <span>{data.score}</span>/{data.total}
            </p>
          )
        },
      },
      {
        name: 'Time Taken',
        selector: (row) => `${row.time} Mins`,
      },
      {
        name: 'Combined Score',
        selector: (row) => (
          <p className='score-badge'>
            <span>{row.score}</span>/{row.total}
          </p>
        ),
      },
    ],
    data: reports,
  }
  return (
    <div className='content flex-grow-1'>
      <Container fluid className='option-main-container report-tab'>
        <div className='dashboard-heading'>
          <div class='content-container'>
            <h2>Reports</h2>
            <p>Check out you cumulative and test-wise reports here</p>
          </div>
          <Activity />
        </div>
        <Header />
      </Container>
      <DataTableExtensions {...tableData}>
        <DataTable pagination customStyles={customStyles} highlightOnHover />
      </DataTableExtensions>
    </div>
  )
}

export default QuestionarieLogical

const customStyles = {
  rows: {
    style: {
      minHeight: '80px',
    },
  },
  cells: {
    style: {
      fontSize: '14px',
      color: '#414141',
    },
  },
}

import { test3 } from './data'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { useStopwatch } from 'react-timer-hook'

const Test3 = ({ submit, options }) => {
  const [formData, setFormData] = useState({})
  const [validated, setValidated] = useState(false)
  const [step, setStep] = useState(1)
  const [result, setResult] = useState({})
  const formRef = useRef()

  const { minutes, seconds, start, pause, reset } = useStopwatch({
    autoStart: false,
  })

  const titles = Object.keys(test3)
  const data = Object.values(test3)

  const last_page = titles.length

  useEffect(() => {
    if (Object.keys(result).length === last_page) {
      pause()
      let s = 0
      let t = 0
      Object.values(result).forEach((r) => {
        s += r.score
        t += r.total
      })
      submit({
        result,
        time: `${minutes}:${seconds}`,
        total: t,
        score: s,
      })
    }
  }, [result, last_page])

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === '1' && formData[name] === undefined) {
      start()
    }

    setFormData((old) => ({ ...old, [name]: value }))
  }

  const nextStep = (e) => {
    e.preventDefault()
    if (formRef.current.checkValidity() === false) {
      e.stopPropagation()
    } else {
      let s = 0
      data[step - 1].forEach((d) => {
        if (d.answer.includes(formData[d.q_no])) {
          s++
        }
      })

      setResult((old) => {
        let key = titles[step - 1]
        let val = {
          score: s,
          total: data[step - 1].length,
        }
        return { ...old, [key]: val }
      })

      if (step === last_page) return
      setStep((s) => s + 1)
    }
    setValidated(true)
  }

  return (
    <Container>
      <Row>
        <Col sm={4} lg={2} className='dashboard-cards px-4 ms-auto'>
          Time : {minutes < 9 ? `0${minutes}` : minutes} : {seconds < 9 ? `0${seconds}` : seconds}
        </Col>
      </Row>
      <Row className='my-3 dashboard-cards p-4'>
        <Col>
          <p className='text-black'>
            <strong>{titles[step - 1]}</strong>
          </p>
          <Form noValidate validated={validated} ref={formRef}>
            <ul className='list-unstyled'>
              {data[step - 1].map((d) => {
                return (
                  <li key={d.q_no} className='my-3 mx-2 text-secondary'>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex gap-4 w-75'>
                        {d.q_no}.
                        <div>
                          <p className='m-0 text-black'>{d.ques}</p>
                          {d.choices.map((q, i) => {
                            return (
                              <p key={i} className='m-0'>
                                {q}
                              </p>
                            )
                          })}
                        </div>
                      </div>
                      <div>
                        <Form.Group>
                          {d.choices.map((_, idx) => {
                            return (
                              <Form.Check
                                required
                                key={idx}
                                id={`${options[idx]}_${d.q_no}`}
                                type='radio'
                                label={options[idx]}
                                name={d.q_no}
                                value={options[idx]}
                                checked={formData[d.q_no] === options[idx]}
                                onChange={handleChange}
                                inline
                              />
                            )
                          })}
                          <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
            <div className='d-flex justify-content-between'>
              <button
                onClick={() => setStep((s) => s - 1)}
                type='button'
                className='d-flex justify-content-center align-items-center py-1 px-3 rounded-pill border border-2 text-secondary'
                style={{ backgroundColor: 'transparent' }}
                disabled={step === 1}
              >
                Prev
              </button>
              <button
                onClick={nextStep}
                type='submit'
                className={`d-flex justify-content-center align-items-center py-1 px-3 rounded-pill text-white`}
                style={{ backgroundColor: 'var(--green-color)', border: 'none' }}
              >
                {step !== last_page ? 'Next' : 'Finish'}
              </button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default Test3

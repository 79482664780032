import react, { useEffect, useState } from 'react'
import { Row, Col, Table } from 'react-bootstrap'
import { htmlToJson } from './Htmltojson'
import * as Currdate from '../../../CurrDate'
import { NavLink, useLocation, useParams, useNavigate } from 'react-router-dom'
import { updateLocale } from 'moment'

const Excelgame = (props) => {
  const [fields, setFields] = useState([''])
  const [err, seterr] = useState([''])
  const [inputArr, setinputArr] = useState([])

  let currIndexData = props.vocationalData
  let datalengthi = props.datalength.length
  let date = Currdate.GetCurrentDate()
  let navigate = useNavigate()
  const location = useLocation()
  let answerArray = []

  const setQueTable1 = (Via) => {
    let mapDATA = []
    let data = document.querySelector('.tbody-container')
    let dataheader = document.querySelector('.tbody-container1')
    let tableInputData = Array.from(data.children).map((html, index) => {
      mapDATA = Array.from(html.children).map((html1, index1) => {
        console.log(html1.children[0])
        html1.children[0].value = ''
      })
    })
    return tableInputData
  }

  let update
  function handleSubmit(e, via) {
    let mapDATA = []

    let data = document.querySelector('.tbody-container')
    let dataheader = document.querySelector('.tbody-container1')
    let tableInputData = Array.from(data.children).map((html, index) => {
      mapDATA = Array.from(html.children).map((html1, index1) => {
        return {
          [dataheader.children[0].children[0].children[index1].textContent]: html1.children[0].value,
        }
      })
      answerArray.push(mapDATA)
      return mapDATA
    })
    if (tableInputData.length == 0) {
      seterr('please fill atleast one answer')
    } else {
      props.setPage((prev) => {
        props.setTheArray((oldArray) => {
          update = [
            ...oldArray,
            {
              via: 'correct',
              useranswers: answerArray,
              answers: currIndexData,
            },
          ]

          localStorage.setItem('useranswer', JSON.stringify(update))
          return update
        })

        if (prev == datalengthi - 1) {
          props.submitResult(date)

          props.setTheArray([])

          // navigate('vocationresult', {
          //   state: { ...location.state, date: date },
          // })
        } else {
          setQueTable1()

          return prev + 1
        }
      })
    }
  }

  if (currIndexData != undefined) {
    return (
      <Row className='excelgame-container'>
        <h6>World Problems</h6>
        <Col md={6}>
          <div className='content-container'>
            <p>{currIndexData.ques}</p>
          </div>
        </Col>
        <Col md={6} className='inputsTable'>
          <Table striped bordered hover className='tbody-container1'>
            <thead>
              <tr>
                {Object.keys(currIndexData.ans).map((item, index) => {
                  return <th>{item}</th>
                })}
              </tr>
            </thead>
            <tbody className='tbody-container'>
              {currIndexData.ans['item'].map((o, i) => {
                return (
                  <tr>
                    {Object.keys(currIndexData.ans).map((item, index) => {
                      return (
                        <td>
                          <input
                            type='text'
                            placeHolder={'Type' + ' ' + item}
                            className={item + i}
                            maxlength={item == 'quantity' ? '10' : '50'}
                          />
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </Table>
          {err}
          <div style={{ textAlign: 'right' }}>
            <button className='common-btn px-3 py-1 ' onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </Col>
      </Row>
    )
  }
}

export default Excelgame

import { BsCheckLg } from 'react-icons/bs'
const Step = ({ step, currentStep, title }) => {
  let status = currentStep === step ? 'active' : currentStep < step ? 'inactive' : 'complete'

  return (
    <div className='d-flex flex-column align-items-center'>
      <div
        className={` d-flex align-items-center justify-content-center rounded-circle`}
        style={{
          height: '40px',
          width: '40px',
          fontWeight: 'bold',
          zIndex: '11',
          backgroundColor: `${status === 'complete' ? 'var(--green-color)' : 'white'}`,
          border: `${status !== 'inactive' ? '3px solid var(--green-color)' : '3px solid var(--light-gray)'}`,
          color: `${
            status === 'active' ? 'var(--green-color)' : status === 'inactive' ? 'var(--light-gray)' : 'white'
          }`,
        }}
      >
        <div className='d-flex align-align-items-center justify-content-center'>
          {status === 'complete' ? <BsCheckLg /> : <span>{step}</span>}
        </div>
      </div>
      <div
        style={{
          color: `${status === 'inactive' ? 'var(--light-gray)' : 'var(--green-color)'}`,
          marginTop: '5px',
        }}
      >
        <h4>{title}</h4>
      </div>
    </div>
  )
}

export default Step

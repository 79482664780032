import React, { Component } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import * as Currdate from '../../../CurrDate'
import { NavLink, useLocation, useParams, useNavigate } from 'react-router-dom'

const EditorFn = (props) => {
  let date = Currdate.GetCurrentDate()
  const location = useLocation()

  let navigate = useNavigate()

  const submitTextResult = () => {
    // navigate('vocationresult', {
    //   state: { ...location.state, date: date },
    // })
    props.submitResult(date)
  }

  return (
    <div className='App' style={{ textAlign: 'right' }}>
      <h2></h2>
      <CKEditor
        editor={ClassicEditor}
        data='Type here'
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          console.log('Editor is ready to use!', editor)
        }}
        onChange={(event, editor) => {
          const data = editor.getData()
          console.log(data.replace(/(\s*<.*?>\s*)+/g, ' ').trim())
          localStorage.setItem(
            'useranswer',
            JSON.stringify({
              text: data,
            })
          )

          // console.log({ event, editor, data });
        }}
        onBlur={(event, editor) => {
          console.log('Blur.', editor)
        }}
        onFocus={(event, editor) => {
          console.log('Focus.', editor)
        }}
      />
      <button className='common-btn mt-2 p-2   ml-auto' onClick={submitTextResult}>
        Submit
      </button>
    </div>
  )
}
export default EditorFn

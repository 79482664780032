import React, { useContext, useState } from 'react'
import { Button, Container, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import * as Apiurl from '../Apisurl'
import OTPInput from './OtpInput'
import Context from '../Context'
import FormHeader from '../includes/FormHeader'
import bcrypt from 'bcryptjs'
import PasswordStrengthMeter from './PasswordStrengthMeter'

const ForgotPassword = () => {
  const navigate = useNavigate()
  const { setUserServices } = useContext(Context)
  const [otpValue, setOTPValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState(null)
  const [step, setStep] = useState(1)
  const [isOtp, setisOtpCheck] = useState()
  const [passwordError, setPasswordError] = useState('')
  const [newpasswordError, setnewPasswordError] = useState('')

  const [passwordVisible, setPasswordVisible] = useState(false)
  const [isStrongPassword, setIsStrongPassword] = useState(false)
  const [emailCheck, setemailCheck] = useState(false)

  const [userValues, setUserValues] = useState({
    email: '',
    newPassword: '',
    confirmPassword: '',
  })

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.currentTarget

    switch (step) {
      case 1:
        sendOTP()
        break
      case 2:
        verifyOTP()
        break
      case 3:
        resetPassword()
        break
      default:
        break
    }
  }

  const handleChange = (e) => {
    setisOtpCheck('')
    setPasswordError('')
    setnewPasswordError('')
    setErr('')
    setemailCheck(' ')
    const { name, value } = e.target
    setUserValues({ ...userValues, [name]: value })
  }

  const sendOTP = async () => {
    if (!userValues.email) {
      setemailCheck('Please enter required  field')
      return
    }
    setLoading(true)

    try {
      const response = await fetch(Apiurl.Fetchurl + 'forgot_password_test', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email_id: userValues.email }),
      })
      const data = await response.json()

      if (data.status === 200) {
        setStep(2)
      } else {
        setErr(data.error)
      }
      console.log(data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const verifyOTP = async () => {
    if (!otpValue) {
      setisOtpCheck('Please enter O.T.P')
      return
    }
    setLoading(true)

    try {
      const response = await fetch(
        Apiurl.Fetchurl + `forgot_password_test?email_id=${userValues.email}&otp_user=${otpValue}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )
      const data = await response.json()
      if (data.Status == 200) {
        setStep(3)
      } else {
        setisOtpCheck('Please enter valid otp')
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const resetPassword = async () => {
    if (userValues.newPassword == '') {
      setnewPasswordError('Please enter required field')
      return
    }
    if (userValues.confirmPassword == '') {
      setPasswordError('Please enter required field')
      return
    }
    if (userValues.newPassword !== userValues.confirmPassword) {
      setPasswordError('Passwords do not match')
      return
    }

    if (!isStrongPassword) {
      alert('Please use a stronger password')
      return
    }

    setLoading(true)
    try {
      const response = await fetch(Apiurl.Fetchurl + 'reset_password', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email_id: userValues.email, password: userValues.newPassword }),
      })
      const data = await response.json()
      if (data.status === 200) {
        alert('Password set Successfully')
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleOTPChange = (value) => {
    setisOtpCheck('')
    setOTPValue(value)
  }

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Form.Group controlId='username' className='form-inputs'>
              <Form.Label>Enter your Email or Username</Form.Label>
              <Form.Control
                type='text'
                placeholder='Email'
                name='email'
                value={userValues.email}
                maxLength='30'
                onChange={handleChange}
                required
              />
              {emailCheck && <p className='text-danger'>{emailCheck}</p>}
              {err && <p className='text-danger'>{err}</p>}
            </Form.Group>
          </>
        )
      case 2:
        return (
          <>
            <Form.Group controlId='username' className='form-inputs mt-4 text-center'>
              <p>Please enter the OTP sent to your email:</p>
              <OTPInput length={6} onOTPChange={handleOTPChange} resendOtp={sendOTP} />
              <p type='invalid' className='text-danger'>
                {isOtp}
              </p>
              <a href='#' className='small' onClick={() => setStep(1)}>
                Change Email?
              </a>
            </Form.Group>
          </>
        )
      case 3:
        return (
          <>
            <Form.Group controlId='New Password' className='forcm-inputs'>
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='New Password'
                name='newPassword'
                value={userValues.newPassword}
                maxLength='30'
                onChange={handleChange}
                required
              />
              {newpasswordError && <p className='text-danger'>{newpasswordError}</p>}
              <PasswordStrengthMeter password={userValues.newPassword} setIsStrongPassword={setIsStrongPassword} />
            </Form.Group>
            <Form.Group controlId='Confirm Password' className='form-inputs position-relative'>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type={passwordVisible ? 'text' : 'password'}
                placeholder='Confirm Password'
                name='confirmPassword'
                value={userValues.confirmPassword}
                maxLength='30'
                onChange={handleChange}
                required
              />
              <button type='button' className='password-toggle-btn small' onClick={togglePasswordVisibility}>
                {passwordVisible ? 'Hide' : 'Show'}
              </button>

              {passwordError && <p className='text-danger'>{passwordError}</p>}
              <a href='#' className='small' onClick={() => setStep(1)}>
                Change Email?
              </a>
              <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
            </Form.Group>
          </>
        )
      default:
        return null
    }
  }

  return (
    <>
      <FormHeader />
      <main>
        <section className='login-form-section mt-5'>
          <Container>
            <Row>
              <Col md={6} className='o-1 m-auto login-form-container parent-container'>
                <Form noValidate onSubmit={handleSubmit}>
                  <h3>Forgot Password</h3>
                  {renderForm()}
                  <Button variant='primary' className='common-btn full-btn mt-2' type='submit' disabled={loading}>
                    {loading ? (
                      <div className='d-flex align-items-center justify-content-center gap-2'>
                        <Spinner animation='border' />
                        Loading...
                      </div>
                    ) : (
                      'Submit'
                    )}
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}

export default ForgotPassword

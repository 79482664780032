import React, { useContext, useEffect, useState } from 'react'
import FormHeader from '../includes/FormHeader'
import { Col, Container, Form, Row } from 'react-bootstrap'
import * as Apiurl from '../Apisurl'
import './renew.css'
import { useNavigate } from 'react-router-dom'
import headersReq from '../HeadersJson'
import Context from '../Context'

const Renew = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    max_students: '',
    duration: '',
    services: [],
  })
  const [error, setError] = useState('')
  const [services, setServices] = useState([])

  const reqJson = headersReq()
  const role = localStorage.getItem('role')
  const user_id = sessionStorage.getItem('user_id')
  const username = sessionStorage.getItem('sessionname')
  const max_students = sessionStorage.getItem('max_students')

  console.log(user_id)

  useEffect(() => {
    const getServices = async () => {
      if (!role) return
      try {
        const response = await fetch(`${Apiurl.Fetchurl}service_id_plan?person_type=${role}`)
        const data = await response.json()
        const { output, price, text } = data

        const formattedData = Object.keys(output).map((key) => {
          const id = key
          const title = output[key]
          const listItems = text[key].split(' | ')
          const priceValue = price[key]

          return {
            id,
            title,
            listItems,
            price: priceValue,
          }
        })

        formattedData.sort((a, b) => a.price - b.price)
        setServices(formattedData)
      } catch (error) {
        console.error(error)
      }
    }
    getServices()
  }, [role])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((data) => ({ ...data, [name]: value }))
  }

  const handleServiceChange = (serviceId) => {
    let updatedServices = []

    if (serviceId === '7') {
      updatedServices = formData.services.includes('7') ? [] : ['7']
    } else {
      updatedServices = formData.services.includes(serviceId)
        ? [...formData.services.filter((service) => service !== serviceId)]
        : [...formData.services, serviceId].filter((service) => service !== '7')
    }

    setFormData((data) => ({ ...data, services: updatedServices }))
    setError('')
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!formData.services.length) {
      setError('Select Atleast one service')
    } else {
      initiatePayment()
    }
  }

  const initiatePayment = async () => {
    try {
      const response = await fetch(Apiurl.Fetchurl + 'order', {
        method: 'POST',
        body: JSON.stringify({
          service_id: formData.services.map((s) => parseInt(s)),
          month: parseInt(formData.duration),
          person_type: role,
        }),
      })
      const data = await response.json()
      openPaymentGateway(data.Success)
    } catch (error) {
      console.error(error)
      throw new Error('Error in initiatePayment')
    }
  }

  const openPaymentGateway = (paymentData) => {
    const paymentOptions = {
      key: process.env.REACT_APP_RAZORPAY,
      order_id: paymentData.id,
      amount: paymentData.amount,
      currency: paymentData.currency,
      name: 'NEMA AI',
      description: 'Test Transaction',

      handler: async (response) => {
        try {
          const verificationResponse = await fetch(Apiurl.Fetchurl + 'verify', {
            method: 'POST',
            body: JSON.stringify({
              ...response,
              service_id: formData.services.map((s) => parseInt(s)),
              month: parseInt(formData.duration),
              person_type: role,
            }),
          })

          if (!verificationResponse.ok) throw new Error('Something went wrong')

          const verificationData = await verificationResponse.json()
          sessionStorage.setItem('isPaymentSuccessful', 'true')
          sessionStorage.setItem('responseFromOrder', JSON.stringify(response))
          sessionStorage.setItem('paymentResponse', JSON.stringify(verificationData))

          const { uid_trans } = await verifyPayment({
            service_id: JSON.stringify(formData.services.map((s) => parseInt(s))),
            month: parseInt(formData.duration),
            person_type: role,
            ...response,
            payment_id: verificationData.payment_id,
            method: JSON.stringify(verificationData.method),
            user_id,
          })

          if (!uid_trans) throw new Error('no trans uid found')

          await renew(uid_trans)

          sessionStorage.removeItem('isPaymentSuccessful')
          sessionStorage.removeItem('responseFromOrder')
          sessionStorage.removeItem('paymentResponse')

          navigate('/loginmain')
        } catch (error) {
          console.error(error)
        }
      },
      theme: { color: '#3399cc' },
    }
    const razorpayInstance = new window.Razorpay(paymentOptions)
    razorpayInstance.open()
  }

  const verifyPayment = async (paymentDetails) => {
    try {
      const res = await fetch(Apiurl.Fetchurl + 'verify?' + new URLSearchParams(paymentDetails).toString())
      if (!res.ok) throw new Error('Something went wrong')
      const data = await res.json()
      return data
    } catch (error) {
      console.error(error)
      throw new Error('Error in verifyPayment')
    }
  }

  const renew = async (id) => {
    try {
      const res = await fetch(Apiurl.Fetchurl + 'renew_subcription', {
        method: 'POST',
        headers: reqJson,
        body: JSON.stringify({
          duration: formData.duration,
          max_students: formData.max_students,
          username: username,
          serial_id_tran: id,
        }),
      })
      if (!res.ok) throw new Error('Something went wrong')
      const data = await res.json()
      return data
    } catch (error) {
      console.error(error)
      throw new Error('Error in renew')
    }
  }

  return (
    <div>
      <FormHeader />
      <main>
        <section className='login-form-section'>
          <Container className='option-main-container '>
            <h1 className='text-center'>Your Subscription has expired, Please Renew to Login.</h1>

            <div className='row role-card-container'>
              <Form onSubmit={handleSubmit}>
                <Form.Group
                  className={`${role === 'student' || role === 'employee' || role === 'hr' ? 'd-none' : ''} mb-3`}
                >
                  <Form.Label for='max_students'>No of users</Form.Label>
                  <Form.Control
                    required
                    type='number'
                    className='form-control'
                    id='max_students'
                    name='max_students'
                    min={parseInt(max_students)}
                    value={formData.max_students}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Duration</Form.Label>
                  <br />
                  {['1', '3', '6', '12'].map((duration) => (
                    <Form.Check
                      required
                      inline
                      type='radio'
                      name='duration'
                      label={`${duration} month`}
                      id={`${duration}month`}
                      value={duration}
                      checked={formData.duration === duration}
                      onChange={handleChange}
                      style={{ accentColor: 'pink' }}
                    />
                  ))}
                </Form.Group>

                <Form.Group>
                  <Form.Label for='services'>Services</Form.Label>
                  {error && <p className='text-danger'>{error}</p>}
                  <Row>
                    {services.map((service) => {
                      return (
                        service.id !== '7' && (
                          <Col md={3} className='p-1'>
                            <div
                              key={service.id}
                              className={`services__card ${formData.services.includes(service.id) ? 'active' : ''}`}
                              onClick={() => handleServiceChange(service.id)}
                            >
                              <div className='px-4 py-3'>
                                <h6 style={{ fontWeight: '500' }}>{service.title}</h6>
                                <ol className='px-0 ps-3'>
                                  {service.listItems.map((item, idx) => (
                                    <li style={{ fontSize: '14px', lineHeight: 'normal' }}>{item}</li>
                                  ))}
                                </ol>
                              </div>
                              <h6 className='bg-secondary bg-opacity-10 py-2 px-4 m-0' style={{ fontWeight: '500' }}>
                                ₹{service.price}
                              </h6>
                            </div>
                          </Col>
                        )
                      )
                    })}
                  </Row>
                </Form.Group>

                <button className='common-btn px-4 py-2'>Pay</button>
              </Form>
            </div>
          </Container>
        </section>
      </main>
    </div>
  )
}

export default Renew

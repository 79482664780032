import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Apiurl from '../../Apisurl'
import headersReq from '../../HeadersJson'
import { SocketConfig } from '../../SocketConfig'
import CommResult from './CommResult'

const SliderPreview = (props) => {
  const reqjson = headersReq()
  const [totalMrksInput, setTotalMarksInput] = useState('')
  const [array, setArray] = useState([])
  const [ansValue, setansValue] = useState()
  const [successmsg, setSuccessmsg] = useState('')
  const [errors, seterror] = useState('')
  const [errors1, seterror1] = useState('')
  const navigate = useNavigate()
  const locationdata = useLocation()

  let sliderIndex = props.currSlide?.getAttribute('data-index')

  let totalMarks = 0
  if (sliderIndex >= 0) {
    totalMarks = props.flashcardScoreData[sliderIndex]
  }

  const submitResult = (res) => {
    if (totalMrksInput > totalMarks) {
        seterror1('Please enter marks below the total marks.');
        return;
    }

    const score = res !== 0 ? totalMrksInput || res : res;
    const newValue = {
        image: props.image,
        score: score,
        level: 'level1',
        ans_text: props.subtext.current.value,
    };

    setArray((oldArray) => {
        const index = oldArray.findIndex(item => item.image === newValue.image); 
        if (index !== -1) {
            const newArray = [...oldArray];
            newArray[index] = { ...newArray[index], ...newValue };
            setansValue(newArray);
            return newArray;
        } else {
            const newArray = [...oldArray, newValue];
            setansValue(newArray);
            return newArray;
        }
    });

    setSuccessmsg('Submitted Successfully');
    setTimeout(() => {
        seterror1('');
        setSuccessmsg('');
    }, 1000);

    props.subtext.current.value = '';
    props.sliderRef.current.slickNext();
};

  useEffect(() => {
     console.log(array,"55555")

    if (array.length == props.questionArrLength) {
      subMit();
    }
  }, [array])

  const subMit = () => {
    if (!ansValue) return seterror('Complete at least one flashcard to end session')

    let score1 = 0

    ansValue.forEach((element) => {
      score1 += parseInt(element.score)
    })

    setSuccessmsg()
    seterror1()
    SocketConfig.emit('educate', { ...props.jsonData, stop: true })

    const etable = async () => {
      //localStorage.setItem('score', res === 1 ? 'correct' : 'incorrect')
      localStorage.setItem('ans_text', props.subtext.current.value)

      const scoreData = {
        total: props.datalength,
        score: score1,
        ans: ansValue,
      }

      const alldata = {
        ...props.jsonData,
        result: JSON.stringify(scoreData),
      }

      // const esc = encodeURIComponent

      // const query = Object.keys(alldata)
      //   .map((k) => `${esc(k)}=${esc(alldata[k])}`)
      //   .join('&')

      // const response = await fetch(`${Apiurl.Fetchurl + 'manual_report_fc?' + query}`, {
      //   method: 'GET',
      //   headers: reqjson,
      // })

      const response = await fetch(`${Apiurl.Fetchurl + 'manual_report_fc_mcq'}`, {
        method: 'POST',
        headers: reqjson,
        body: JSON.stringify({ ...alldata }),
      })

      const responseData = await response.json()
      return responseData
    }

    etable()
      .then((data) => {
        localStorage.setItem('fresult', data)
        navigate(`report?id=${data.serial_num}`, {
          state: { ...props.jsonData, ...locationdata.state, image: props.prevImg },
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <div className='result-header-container'>
        <div className='category-txt'>
          <label>Result</label>
        </div>
      </div>
      <div className='start-container' style={props.hidebtn ? { display: 'none' } : { display: 'block' }}>
        <p>Tap on Start and then Click on image to Preview.</p>
        <div className='d-flex justify-content-center'>
          <button className='common-btn p-10 strtbtn' type='button' onClick={props.getStart}>
            Start
          </button>
        </div>
      </div>
      <div
        className='dashboard-cards stat-container resultCard'
        style={{ display: props.visibility ? 'block' : 'none' }}
      >
        <div className='form-container'>
          <div className='score_type_container'>
            <label>Gained Marks </label>
            <div className='input-container'>
              <input
                type='number'
                placeholder='Marks'
                min={0}
                value={totalMrksInput}
                onChange={(e) => setTotalMarksInput(e.target.value)}
              />
              <span>/{totalMarks}</span>
            </div>
          </div>
          <CommResult
            alldata={props.alldata}
            inputref={props.inputref}
            subtext={props.subtext}
            totalMarks={totalMarks}
            error={errors}
            error1={errors1}
            image={props.image}
            sliderLength={props.sliderLength}
            subMit={submitResult}
            setvia={props.setstop}
            saveReport={subMit}
          />
        </div>
        <p classNane='text-warning'>{errors1}</p>
        <p className='text-success'>{successmsg}</p>
      </div>
    </>
  )
}

export default SliderPreview

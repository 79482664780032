import { Row } from 'react-bootstrap'
import { useState, useRef, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Currdate from '../../../CurrDate'
import * as Apiurl from '../../../Apisurl'
import { useSpeechSynthesis } from 'react-speech-kit'
import { SocketConfig } from '../../../SocketConfig'

const A_an = (props) => {
  const { speak } = useSpeechSynthesis()
  const [score, setScore] = useState(0)
  const location = useLocation()
  const [isActive, setIsActive] = useState(false)
  let initialArray = []
  const [theArray, setTheArray] = useState(initialArray)
  const [mistakes, setMistakes] = useState(0)
  const [userAnswer, setUserAnswer] = useState('')
  const resetButton = useRef(null)
  const [smShow, setsmShow] = useState(false)
  const [parsedata, setparsedata] = useState(false)
  const [page, setPage] = useState(0)
  const [activeleftAnswer, setleftactiveAnswer] = useState('')
  const [activerightAnswer, setrightactiveAnswer] = useState(false)
  let englishdatavalue = props.englishData
  let datalength = props.datalength

  let date = Currdate.GetCurrentDate()
  props.time(date)
  let datalengthi = datalength
  const [callday, setcallday] = useState(1)
  let navigate = useNavigate()
  useEffect(() => {
    SocketConfig.on('action_data', (data) => {
      console.log('Datta ', data)
      let parseddata = data.result
      if (parseddata.length != 0) {
        setparsedata(true)
        handleSubmit(parseddata.action, true)
      }
      console.log(parseddata)
    })
    //
  }, [])
  // populate level text
  // alert(datalengthi.length);
  let update
  // checkanswers
  function handleSubmit(e, via) {
    console.log(parsedata)

    let userAnswer = e
    console.log(userAnswer)
    speak({ text: userAnswer })
    console.log(englishdatavalue.ans, userAnswer)
    console.log(englishdatavalue.ans == userAnswer)
    let correctAnswer
    if (
      englishdatavalue.ans === userAnswer ||
      englishdatavalue.direction[0] === userAnswer ||
      englishdatavalue.direction[1] === userAnswer
    ) {
      console.log(datalengthi, userAnswer)
      props.setPage((prev) => {
        props.setTheArray((oldArray) => {
          update = [
            ...oldArray,
            {
              via: 'correct',
              answer: userAnswer,
            },
          ]
          localStorage.setItem('useranswer', JSON.stringify(update))
          return update
        })

        if (update.length === datalengthi.length) {
          props.setTheArray([])
          // socketemitdata();
          props.submitResult(date)

          // navigate('enresult', { state: { ...location.state, date: date } })
        } else {
          props.setScore((prev) => prev + 1)
          return prev + 1
        }
      })
    } else {
      props.setPage((prev) => {
        props.setTheArray((oldArray) => {
          update = [
            ...oldArray,
            {
              via: 'incorrect',
              answer: userAnswer,
            },
          ]
          localStorage.setItem('useranswer', JSON.stringify(update))
          return update
        })

        if (update.length === datalengthi.length) {
          props.setTheArray([])
          props.submitResult(date)
          // navigate('enresult', { state: { ...location.state, date: date } })
        } else {
          props.setScore((prev) => prev + 1)
          return prev + 1
        }
      })
    }
  }

  if (englishdatavalue != undefined) {
    return (
      <>
        <h2>Choose A or An</h2>
        <Row className=' english-main-container question-container'>
          <div className='english-container'>
            <input type='text' readOnly />
            <figure className='questionImage'>
              <img src={englishdatavalue.images} alt='apple' />
              <figcaption>{englishdatavalue.ques}</figcaption>
            </figure>
          </div>

          <div className='option-container'>
            {englishdatavalue.mcq.map((obj, i) => {
              return (
                <h5
                  data-attr={obj}
                  Style={
                    activeleftAnswer == true && i == 0
                      ? {
                          background: 'radial-gradient(98.07% 73.14% at 3.51% 19.05%, #97D5CD 8.51%, #54BAAF 100%)',
                        }
                      : activerightAnswer == true && i == 1
                      ? {
                          background: 'radial-gradient(98.07% 73.14% at 3.51% 19.05%, #97D5CD 8.51%, #54BAAF 100%)',
                        }
                      : ''
                  }
                  onClick={() => handleSubmit(obj, false)}
                >
                  {obj}
                </h5>
              )
            })}
          </div>
        </Row>
      </>
    )
  }
}
export default A_an

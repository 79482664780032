import react from 'react'
import { Row, Container, Col } from 'react-bootstrap'
import backbtn from '../../../assets/images/backbtn.svg'
import Focus from '../../../includes/Focus'
import Progress from '../Testprogrsscom'
import refreshbtn from '../../../assets/images/refresh.svg'
import Draggable, { DraggableCore } from 'react-draggable' // Both at the same time
import { useState, useRef, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { NavLink, useLocation, useParams, useNavigate } from 'react-router-dom'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import * as Apiurl from '../../../Apisurl'
import * as Currdate from '../../../CurrDate'
import StartModal from '../../SelectgameStart'
import { useSpeechSynthesis } from 'react-speech-kit'
import { SocketConfig } from '../../../SocketConfig'
import Speech from '../../Speech'
import ExpressPlugin from '../../../ExpressionPlugin'

const Calculation = (props) => {
  let headingname = useParams()
  const { speak } = useSpeechSynthesis()
  // let date = (UserJson().date = GetCurrentDate());
  let DraggableCore = Draggable.DraggableCore
  const [score, setScore] = useState(0)
  const location = useLocation()
  const [isActive, setIsActive] = useState(false)
  let initialArray = []
  const [parsedata, setparsedata] = useState(false)

  const [mistakes, setMistakes] = useState(0)
  const [userAnswer, setUserAnswer] = useState('')
  const resetButton = useRef(null)
  const [smShow, setsmShow] = useState(false)
  const [img1, setimg1] = useState()
  const [img2, setimg2] = useState()
  let mathsdatavalue = props.mathsdata

  let datalength = props.datalength
  let date = Currdate.GetCurrentDate()

  props.time(date)
  let datalengthi = datalength
  const [callday, setcallday] = useState(1)
  let navigate = useNavigate()
  let update = ''
  let count = 0

  function handleSubmit(e, via) {
    let userAnswer = via == true ? e : e.target.innerText
    Speech.Speech(userAnswer)
    let correctAnswer
    if (datalength[props.page].ans == userAnswer || datalength[props.page].direction == userAnswer) {
      props.setPage((prev) => {
        props.setTheArray((oldArray) => {
          update = [
            ...oldArray,
            {
              via: 'correct',
              answer: userAnswer,
            },
          ]
          localStorage.setItem('useranswer', JSON.stringify(update))
          return update
        })

        if (update.length === datalengthi.length) {
          props.setTheArray([])
          // socketemitdata();
          props.submitResult(date)

          // navigate('mathresult', { state: { ...location.state, date: date } })
        } else {
          props.setScore((prev) => prev + 1)
          count++
          return prev + 1
        }
      })
    } else {
      props.setPage((prev) => {
        props.setTheArray((oldArray) => {
          update = [
            ...oldArray,
            {
              via: 'incorrect',
              answer: userAnswer,
            },
          ]
          localStorage.setItem('useranswer', JSON.stringify(update))
          return update
        })

        if (update.length === datalengthi.length) {
          props.setTheArray([])
          props.submitResult(date)
          // navigate('mathresult', { state: { ...location.state, date: date } })
        } else {
          count++
          return prev + 1
        }
      })
    }
  }

  function resetGame() {
    setScore(0)
    setMistakes(0)
    props.setTheArray([])
    setUserAnswer('')
    setsmShow(false)
  }

  useEffect(() => {
    SocketConfig.on('gesture_data', (data) => {
      console.log('Datta ', data)
      let parseddata = data.result.gesture
      if (parseddata.length != 0) {
        setparsedata(true)

        switch (parseddata) {
          case 'raise eyebrow':
            handleSubmit(parseddata, true)
            break
          case 'smile':
            handleSubmit(parseddata, true)
            break
        }
      }
    })
    //
  }, [])

  if (mathsdatavalue && mathsdatavalue.length != 0) {
    return (
      <>
        <Container fluid>
          <Row className='colors-main-container objgame '>
            <Col md={12}>
              <p>Your Problem</p>
              <Focus />

              <div className='addition-container  option_img d-flex justify-content-center flex-wrap  b-none'>
                <div
                  style={{
                    flex: '0 0 100%',
                    textAlign: 'center',
                    marginBottom: ' 14px',
                  }}
                >
                  {' '}
                  {[...Array(parseInt(mathsdatavalue.occ_img1))].map((e, i) => {
                    console.log(i)
                    return <img src={mathsdatavalue.image_1} alt='' />
                  })}
                </div>

                <div className='operator'> {props.operator}</div>
                <div>
                  {[...Array(parseInt(mathsdatavalue.occ_img2))].map((e, i) => {
                    return <img src={mathsdatavalue.image_1} alt='' />
                  })}
                </div>
              </div>
            </Col>

            <Col md={12}>
              {/* <h6 className="score-txt">Score : {score}</h6> */}
              <p style={{ textAlign: 'center' }} class='my-2'>
                Select answer by-
              </p>
              <div
                className='measure-container'
                style={{
                  display: 'flex',
                  alignItem: 'center',
                  justifyContent: 'center',
                  gap: '30px',
                }}
              >
                {mathsdatavalue.mcq.map((o, i) => {
                  return (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          alignItem: 'center',
                          justifyContent: 'space-between',
                          gap: '40px',
                          flexFlow: 'row-reverse',
                        }}
                      >
                        {ExpressPlugin(o, location.state.typeValue, i, handleSubmit)}
                        {/* <div
                          className="measure-item subobj"
                          onClick={handleSubmit}
                        >
                          <h5>{o}</h5>
                        </div>*/}
                      </div>
                    </>
                  )
                })}
              </div>
            </Col>
          </Row>
        </Container>
        <StartModal
          calldelay={setcallday}
          socket={SocketConfig}
          type={['gesture', 'focus']}
          letquesLength={datalength.length}
          date={date}
          calldaynum={callday}
        />
      </>
    )
  }
}
export default Calculation

import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Quiz from './Quiz'
import ISSA from './ISSA'
import Wender from './Wender'
import {
  ISSA as issa,
  careGroup,
  prePrimary,
  preVocation1,
  preVocation2,
  primary1,
  primary2,
  secondary,
  ADHD as adhd,
  ADDH as addh,
  PSC as psc,
  Wender as wender,
} from './data'
import { GetCurrentDate } from '../../CurrDate'
import Userjson from '../../Userjson'
import headersReq from '../../HeadersJson'
import * as API from '../../Apisurl'
import ADHD from './ADHD'
import ADDH from './ADDH'
import PSC from './PSC'

const QuizGame = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { category } = useParams()

  const reqJson = headersReq()
  const userData = Userjson()

  const getQuizData = () => {
    let data = []

    switch (category) {
      case 'preprimary':
        data = prePrimary
        break
      case 'prevocation 1':
        data = preVocation1
        break
      case 'prevocation 2':
        data = preVocation2
        break
      case 'primary 1':
        data = primary1
        break
      case 'primary 2':
        data = primary2
        break
      case 'secondary':
        data = secondary
        break
      case 'issa':
        data = issa
        break
      case 'adhd':
        data = adhd
        break
      case 'addh':
        data = addh
        break
      case 'psc':
        data = psc
        break
      case 'wenderadhd':
        data = wender
        break
      default:
        break
    }

    return data
  }
  const data = getQuizData()

  const saveReport = async (res) => {
    let date = GetCurrentDate()

    let alldata = {
      ...userData,
      result: JSON.stringify(res),
      level: 'level1',
      date: date,
    }

    try {
      const response = await fetch(`${API.Fetchurl + 'manual_report_fc_mcq'}`, {
        method: 'POST',
        headers: reqJson,
        body: JSON.stringify(alldata),
      })

      if (!response) throw new Error('Unable to call API')
      const data = await response.json()
      navigate(`report?id=${data.serial_num}`, { state: { ...location.state, date: date } })
    } catch (error) {
      console.log(error.message)
    }
  }

  const renderQuizComponent = () => {
    let comp = ''

    switch (category) {
      case 'issa':
        comp = <ISSA data={data} submit={saveReport} />
        break
      case 'adhd':
        comp = <ADHD data={data} options={['0', '1', '3', '4']} limit={10} submit={saveReport} />
        break
      case 'addh':
        comp = <ADDH data={data} options={['0', '1', '2', '3']} limit={11} submit={saveReport} />
        break
      case 'psc':
        comp = <PSC data={data} options={['0', '1', '2']} limit={10} submit={saveReport} />
        break
      case 'wenderadhd':
        comp = <Wender data={data} options={['0', '1', '2', '3', '4']} limit={11} submit={saveReport} />
        break
      default:
        comp = <Quiz data={data} options={['Yes', 'No']} submit={saveReport} />
        break
    }

    return comp
  }

  return <div>{renderQuizComponent()}</div>
}

export default QuizGame

import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Categorycard from './Categorycard'
import { useLocation } from 'react-router-dom'
import UserJson from '../Userjson'

const Category = () => {
  let userDatat = UserJson()
  let category = JSON.parse(localStorage.getItem('modulejson'))
  let use_loc = useLocation()
  let use_locmod = use_loc.state.modulename
  let use_module = use_loc.state.modulename1.charAt(0).toUpperCase() + use_loc.state.modulename1.slice(1)

  if (use_loc.state != undefined && use_loc.state != '') {
    return (
      <>
        <h3>Please select a category to proceed</h3>
        <Container>
          <div class='students-container category-container'>
            <Row>
              {category != undefined && category != ''
                ? category[use_module].map(function(key, index) {
                    if (index != 0) {
                      if (key.module.toUpperCase() == use_locmod.toUpperCase()) {
                        return key.categories.map((obj, index) => {
                          return (
                            <Categorycard
                              title={obj.name}
                              link={obj.name_title}
                              images={obj.image}
                              attempted={obj.attempted}
                              percentage={obj.attempted_percentage || 0}
                            />
                          )
                        })
                      }
                    }
                  })
                : ''}
            </Row>
          </div>
        </Container>
      </>
    )
  }
}
export default Category

/* eslint-disable no-undef */
import { useEffect, useRef, useState } from 'react'
import { Col, Collapse, Modal, Row } from 'react-bootstrap'
import { FiChevronDown } from 'react-icons/fi'
import { IoChevronBackOutline } from 'react-icons/io5'
import brainJPG from '../assets/images/brain.png'
import * as API from '../Apisurl'

const BrainAnim = (props) => {
  const role = localStorage.getItem('role')

  const [isPlaying, setIsPlaying] = useState(false)
  const [idx, setIdx] = useState(0)
  const id = useRef()

  const [show, setShow] = useState(false)
  const [headsetData, setheadsetData] = useState([])

  const coordinatesDict = {
    Nz: { x: 275, y: 25, textX: 275, textY: 25, color: '#555555' },

    Fp1: { x: 227, y: 80, textX: 227, textY: 80, color: '#b2daec' },
    Fpz: { x: 275, y: 75, textX: 275, textY: 75, color: '#b2daec' },
    Fp2: { x: 323, y: 80, textX: 323, textY: 80, color: '#b2daec' },

    AF7: { x: 165, y: 110, textX: 165, textY: 110, color: '#b2daec' },
    AF3: { x: 215, y: 120, textX: 215, textY: 120, color: '#b2daec' },
    AFz: { x: 275, y: 125, textX: 275, textY: 125, color: '#b2daec' },
    AF4: { x: 335, y: 120, textX: 335, textY: 120, color: '#b2daec' },
    AF8: { x: 385, y: 110, textX: 385, textY: 110, color: '#b2daec' },

    F9: { x: 60, y: 145, textX: 60, textY: 145, color: '#b2daec' },
    F7: { x: 110, y: 165, textX: 110, textY: 165, color: '#b2daec' },
    F5: { x: 152.5, y: 168, textX: 152.5, textY: 168, color: '#b2daec' },
    F3: { x: 195, y: 170, textX: 195, textY: 170, color: '#b2daec' },
    F1: { x: 235, y: 173.5, textX: 235, textY: 173.5, color: '#b2daec' },
    Fz: { x: 275, y: 175, textX: 275, textY: 175, color: '#b2daec' },
    F2: { x: 315, y: 173.5, textX: 315, textY: 173.5, color: '#b2daec' },
    F4: { x: 355, y: 170, textX: 355, textY: 170, color: '#b2daec' },
    F6: { x: 397.5, y: 168, textX: 397.5, textY: 168, color: '#b2daec' },
    F8: { x: 440, y: 165, textX: 440, textY: 165, color: '#b2daec' },
    F10: { x: 485, y: 145, textX: 485, textY: 145, color: '#b2daec' },

    FT9: { x: 35, y: 205, textX: 35, textY: 205, color: '#b5d4c5' },
    FT7: { x: 85, y: 217, textX: 85, textY: 217, color: '#b5d4c5' },
    FC5: { x: 130, y: 223, textX: 130, textY: 223, color: '#cddfe9' },
    FC3: { x: 180, y: 224, textX: 180, textY: 224, color: '#cddfe9' },
    FC1: { x: 225, y: 225, textX: 225, textY: 225, color: '#cddfe9' },
    FCz: { x: 275, y: 225, textX: 275, textY: 225, color: '#cddfe9' },
    FC2: { x: 325, y: 225, textX: 325, textY: 225, color: '#cddfe9' },
    FC4: { x: 370, y: 224, textX: 370, textY: 224, color: '#cddfe9' },
    FC6: { x: 420, y: 223, textX: 420, textY: 223, color: '#cddfe9' },
    FT8: { x: 465, y: 217, textX: 465, textY: 217, color: '#b5d4c5' },
    FT10: { x: 515, y: 205, textX: 515, textY: 205, color: '#b5d4c5' },

    T9: { x: 25, y: 275, textX: 25, textY: 275, color: '#b6cf9b' },
    T3: { x: 50, y: 300, textX: 50, textY: 300, color: '#b6cf9b' },
    T7: { x: 75, y: 275, textX: 75, textY: 275, color: '#b6cf9b' },
    C5: { x: 125, y: 275, textX: 125, textY: 275, color: '#e5e5e5' },
    C3: { x: 175, y: 275, textX: 175, textY: 275, color: '#e5e5e5' },
    C1: { x: 225, y: 275, textX: 225, textY: 275, color: '#e5e5e5' },
    Cz: { x: 275, y: 275, textX: 275, textY: 275, color: '#e5e5e5' },
    C2: { x: 325, y: 275, textX: 325, textY: 275, color: '#e5e5e5' },
    C4: { x: 375, y: 275, textX: 375, textY: 275, color: '#e5e5e5' },
    C6: { x: 425, y: 275, textX: 425, textY: 275, color: '#e5e5e5' },
    T8: { x: 475, y: 275, textX: 475, textY: 275, color: '#b6cf9b' },
    T4: { x: 500, y: 300, textX: 500, textY: 300, color: '#b6cf9b' },
    T10: { x: 525, y: 275, textX: 525, textY: 275, color: '#b6cf9b' },

    TP9: { x: 35, y: 345, textX: 35, textY: 345, color: '#d9e49e' },
    TP7: { x: 85, y: 333, textX: 85, textY: 333, color: '#d9e49e' },
    CP5: { x: 130, y: 327, textX: 130, textY: 327, color: '#f2f2c0' },
    CP3: { x: 180, y: 326, textX: 180, textY: 326, color: '#f2f2c0' },
    CP1: { x: 225, y: 325, textX: 225, textY: 325, color: '#f2f2c0' },
    CPz: { x: 275, y: 325, textX: 275, textY: 325, color: '#f2f2c0' },
    CP2: { x: 325, y: 325, textX: 325, textY: 325, color: '#f2f2c0' },
    CP4: { x: 370, y: 326, textX: 370, textY: 326, color: '#f2f2c0' },
    CP6: { x: 420, y: 327, textX: 420, textY: 327, color: '#f2f2c0' },
    TP8: { x: 465, y: 333, textX: 465, textY: 333, color: '#d9e49e' },
    TP10: { x: 515, y: 345, textX: 515, textY: 345, color: '#d9e49e' },

    P9: { x: 60, y: 405, textX: 60, textY: 405, color: '#fefc98' },
    T5: { x: 97, y: 417, textX: 97, textY: 417, color: '#fefc98' },
    P7: { x: 110, y: 385, textX: 110, textY: 385, color: '#fefc98' },
    P5: { x: 152.5, y: 382, textX: 152.5, textY: 382, color: '#fefc98' },
    P3: { x: 195, y: 380, textX: 195, textY: 380, color: '#fefc98' },
    P1: { x: 235, y: 377.5, textX: 235, textY: 377.5, color: '#fefc98' },
    Pz: { x: 275, y: 375, textX: 275, textY: 375, color: '#fefc98' },
    P2: { x: 315, y: 377.5, textX: 315, textY: 377.5, color: '#fefc98' },
    P4: { x: 355, y: 380, textX: 355, textY: 380, color: '#fefc98' },
    P6: { x: 397.5, y: 382, textX: 397.5, textY: 382, color: '#fefc98' },
    P8: { x: 440, y: 385, textX: 440, textY: 385, color: '#fefc98' },
    T6: { x: 450, y: 417, textX: 450, textY: 417, color: '#fefc98' },
    P10: { x: 485, y: 405, textX: 485, textY: 405, color: '#fefc98' },

    PO7: { x: 165, y: 440, textX: 165, textY: 440, color: '#f8d0a9' },
    PO3: { x: 215, y: 430, textX: 215, textY: 430, color: '#f8d0a9' },
    POz: { x: 275, y: 425, textX: 275, textY: 425, color: '#f8d0a9' },
    PO4: { x: 335, y: 430, textX: 335, textY: 430, color: '#f8d0a9' },
    PO8: { x: 385, y: 440, textX: 385, textY: 440, color: '#f8d0a9' },

    O1: { x: 227, y: 470, textX: 227, textY: 470, color: '#f8a6b5' },
    Oz: { x: 275, y: 475, textX: 275, textY: 475, color: '#f8a6b5' },
    O2: { x: 323, y: 470, textX: 323, textY: 470, color: '#f8a6b5' },

    Iz: { x: 275, y: 525, textX: 275, textY: 525, color: '#555555' },
  }

  const draw = (eeg_position) => {
    let canvas = document.getElementById('canvas')
    let context = canvas.getContext('2d')
    context.clearRect(0, 0, 550, 550)

    class Circle {
      constructor(x, y, radius, bgColor, borderColor, isFill) {
        this.x = x
        this.y = y
        this.radius = radius
        this.borderColor = borderColor
        this.bgColor = bgColor

        this.isFill = isFill
      }

      draw(ctx) {
        ctx.beginPath()
        ctx.arc(this.x, this.y, this.radius, 0, 2 * Math.PI)
        ctx.lineWidth = 2
        ctx.strokeStyle = this.isFill ? '#54baaf' : this.borderColor
        ctx.fillStyle = this.isFill ? '#54baaf' : this.bgColor
        ctx.fill()

        ctx.stroke()
        ctx.closePath()
      }
    }

    class Text {
      constructor(x, y, text) {
        this.x = x
        this.y = y
        this.text = text
      }
      write(ctx) {
        ctx.font = '14px Arial'
        ctx.fontWeight = '800'
        ctx.fillStyle = this.text === 'Nz' || this.text === 'Iz' ? 'white' : 'black'
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'
        ctx.fillText(this.text, this.x, this.y)
      }
    }

    class Line {
      constructor(x1, y1, x2, y2) {
        this.x1 = x1
        this.y1 = y1
        this.x2 = x2
        this.y2 = y2
      }

      drawLine(ctx) {
        ctx.beginPath()
        ctx.lineWidth = 2
        ctx.strokeStyle = '#54baaf'
        ctx.moveTo(this.x1, this.y1)
        ctx.lineTo(this.x2, this.y2)
        ctx.stroke()
        ctx.closePath()
      }
    }

    let radius = 250

    for (let i = 1; i <= 2; i++) {
      let c = new Circle(275, 275, radius, 'transparent', '#c4c4c4', false)
      c.draw(context)
      radius -= 50
    }

    eeg_position.forEach((eeg) => {
      let startPoint = eeg[0],
        endPoint = eeg[1]
      let startX = coordinatesDict[startPoint].x,
        startY = coordinatesDict[startPoint].y
      let endX = coordinatesDict[endPoint].x,
        endY = coordinatesDict[endPoint].y

      let l = new Line(startX, startY, endX, endY)
      l.drawLine(context)
    })

    headsetData?.forEach((position) => {
      let point = coordinatesDict[position],
        id = position
      let isFill = false

      for (let i = 0; i < eeg_position.length; i++) {
        if (eeg_position[i].includes(id)) {
          isFill = true
          break
        }
      }

      let c = new Circle(point.x, point.y, 15, point.color, point.color, isFill)
      c.draw(context)

      let text = new Text(point.textX, point.textY, id)
      text.write(context)
    })
  }

  const startVideo = () => {
    setIsPlaying(true)
    id.current = setInterval(() => {
      setIdx((prev) => {
        return prev < props.coordinates.length - 1 ? prev + 1 : 0
      })
    }, props.speed * 1000)
  }

  const stopVideo = () => {
    setIsPlaying(false)
    clearInterval(id.current)
  }

  useEffect(() => {
    props?.coordinates?.length && draw(props.coordinates[idx])

    props?.coordinates?.length && startVideo()

    return () => stopVideo()
  }, [idx, props.coordinates, props.speed, headsetData.length])

  useEffect(() => {
    const getHeadsetChannels = async () => {
      try {
        const response = await fetch(`${API.Fetchurl}headset_config`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ headset_name: props.headset }),
        })

        const data = await response.json()
        setheadsetData(data.channel_name)
      } catch (error) {
        console.log(error)
      }
    }
    getHeadsetChannels()
  }, [])

  return (
    <div className='d-flex flex-column'>
      {props.via !== false && (
        // ---------------------------------- buttons --------------------------------------------
        <div className='d-flex justify-content-between my-2'>
          <button
            className='border-0 px-3 py-2 text-white d-flex align-items-center rounded-pill'
            style={{ backgroundColor: 'var(--primary-color)' }}
            onClick={() => {
              props.setCoordinates()
            }}
          >
            <IoChevronBackOutline />
            Back
          </button>
          <button
            className='border-0 px-3 py-2 text-white rounded-pill'
            style={{ backgroundColor: 'var(--primary-color)' }}
            onClick={() => window.print()}
          >
            Download
          </button>
        </div>
      )}

      {props.insights && (
        <>
          <Row className='py-3'>
            {/* -------------------------- Brain Animation ------------------------------- */}
            <Col xl={6}>
              <div className='brain-circle position-relative d-flex justify-content-center'>
                <div className='position-relative'>
                  <canvas
                    id='canvas'
                    height='550'
                    width='550'
                    // onClick={(e) => console.log(e.nativeEvent.offsetX, e.nativeEvent.offsetY)}
                  ></canvas>

                  <svg
                    width='224'
                    height='160'
                    viewBox='0 0 224 160'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='position-absolute'
                    style={{
                      top: '40px',
                      left: '35px',
                      zIndex: '-11',
                    }}
                  >
                    <path
                      d='M220.5 59.0001C220.5 166 252.758 164.627 95 155.5C36.5 148.5 -22.5 119 9.50002 88C45.3976 53.2242 101 17.5 158.5 3.5C210.705 -4.83131 220.5 -0.787247 220.5 59.0001Z'
                      fill='rgba(180,217,236,0.5)'
                    />
                  </svg>

                  <svg
                    width='223'
                    height='160'
                    viewBox='0 0 223 160'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='position-absolute'
                    style={{
                      top: '40px',
                      right: '35px',
                      zIndex: '-11',
                    }}
                  >
                    <g>
                      <path
                        d='M2.71767 58.761C2.71767 165.761 -29.5404 164.388 128.218 155.261C186.718 148.261 245.718 118.761 213.718 87.7609C177.82 52.9852 122.218 17.2609 64.7177 3.26094C12.5122 -5.07037 2.71767 -1.02631 2.71767 58.761Z'
                        fill='rgba(180,217,236,0.5)'
                      />
                    </g>
                  </svg>

                  <svg
                    width='103'
                    height='130'
                    viewBox='0 0 103 130'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='position-absolute'
                    style={{
                      top: '191px',
                      left: '4px',
                      zIndex: '-11',
                    }}
                  >
                    <g>
                      <path
                        d='M100.104 62.4461C98.1703 105.589 91.9901 107.233 47.5001 128.5C12.2464 136.291 -14.0195 83.3674 9.00005 62.4461C55 42 54 13 78.5 0C108.245 0 102.842 1.34631 100.104 62.4461Z'
                        fill='rgba(183,207,157,0.5)'
                      />
                      <path
                        d='M100.104 62.4461C98.1703 105.589 91.9901 107.233 47.5001 128.5C12.2464 136.291 -14.0195 83.3674 9.00005 62.4461C55 42 54 13 78.5 0C108.245 0 102.842 1.34631 100.104 62.4461Z'
                        fill='rgba(183,207,157,0.5)'
                      />
                    </g>
                  </svg>

                  <svg
                    width='103'
                    height='130'
                    viewBox='0 0 103 130'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='position-absolute'
                    style={{
                      top: '191px',
                      right: '4px',
                      zIndex: '-11',
                    }}
                  >
                    <g>
                      <path
                        d='M3.17163 62.4461C5.10501 105.589 11.2852 107.233 55.7752 128.5C91.0289 136.291 117.295 83.3674 94.2752 62.4461C48.2753 42 49.2753 13 24.7753 0C-4.96977 0 0.433555 1.34631 3.17163 62.4461Z'
                        fill='rgba(183,207,157,0.5)'
                      />
                      <path
                        d='M3.17163 62.4461C5.10501 105.589 11.2852 107.233 55.7752 128.5C91.0289 136.291 117.295 83.3674 94.2752 62.4461C48.2753 42 49.2753 13 24.7753 0C-4.96977 0 0.433555 1.34631 3.17163 62.4461Z'
                        fill='rgba(183,207,157,0.5)'
                      />
                    </g>
                  </svg>

                  <svg
                    width='93'
                    height='55'
                    viewBox='0 0 93 55'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='position-absolute'
                    style={{
                      bottom: '135px',
                      left: '42px',
                      zIndex: '-11',
                    }}
                  >
                    <path
                      d='M91.668 9.42384C95.7662 20.4061 78.8113 37.3306 53.7982 47.2257C28.785 57.1207 5.1856 56.2394 1.08739 45.2571C-3.01082 34.2748 13.9441 17.3503 38.9572 7.45525C63.9704 -2.43982 87.5698 -1.55846 91.668 9.42384Z'
                      fill='rgba(253,250,153,0.5)'
                    />
                  </svg>

                  <svg
                    width='92'
                    height='56'
                    viewBox='0 0 92 56'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='position-absolute'
                    style={{
                      bottom: '135px',
                      right: '42px',
                      zIndex: '-11',
                    }}
                  >
                    <path
                      d='M0.788043 8.98251C-3.5291 19.8806 13.0838 37.1409 37.8939 47.5345C62.704 57.9282 86.3164 57.5192 90.6335 46.6211C94.9506 35.723 78.3378 18.4627 53.5277 8.06907C28.7175 -2.32454 5.10519 -1.91558 0.788043 8.98251Z'
                      fill='rgba(253,250,153,0.5)'
                    />
                  </svg>

                  <svg
                    width='124'
                    height='44'
                    viewBox='0 0 124 44'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='position-absolute'
                    style={{
                      bottom: '159px',
                      right: '39%',
                      zIndex: '-11',
                    }}
                  >
                    <path
                      d='M124 21.8291C124 4 96.8045 0.527068 62.5218 0.527068C22 0.527068 0.499999 3.5 0.499999 21.8291C0.499999 40.1583 28.2987 43.1312 62.5814 43.1312C96.8641 43.1312 123.5 37 124 21.8291Z'
                      fill='rgba(253,250,153,0.5)'
                    />
                  </svg>

                  <svg
                    width='170'
                    height='52'
                    viewBox='0 0 170 52'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='position-absolute'
                    style={{
                      bottom: '58px',
                      right: '35%',
                      zIndex: '-11',
                    }}
                  >
                    <path
                      d='M169.205 25.7259C172.653 38.961 134.577 52.9066 87.035 51.3916C39.4935 49.8765 -1.66037 39.4746 0.396797 24.8679C5.75217 -13.1574 39.3281 6.7237 86.8696 8.23875C134.411 9.7538 157.298 -19.9795 169.205 25.7259Z'
                      fill='rgba(247,166,181,0.5)'
                    />
                  </svg>
                </div>

                <button
                  onClick={() => setShow(true)}
                  className='position-absolute top-0 end-0 border-0 px-3 py-2 text-white rounded-pill'
                  style={{ backgroundColor: 'var(--primary-color)' }}
                >
                  more info
                </button>
                <Modal size='lg' centered show={show} onHide={setShow}>
                  <Modal.Header closeButton>Brain info</Modal.Header>
                  <img src={brainJPG} alt='parts-of-brain' />
                </Modal>

                {/* <ul className='brain-coordinates blob-container'>
                  <li className='blob'>Frontal Left</li>
                  <li className='blob'>Frontal Right</li>
                  <li className='blob'>Temporal Left</li>
                  <li className='blob'>Temporal Right</li>
                  <li className='blob'>Occipital </li>
                  <li className='blob'>Parietal</li>
                  <li className='blob'></li>
                  <li className='blob'></li>
                  <li className='ear right'></li>
                  <li className='ear left'></li>
                </ul> */}
              </div>
              {props.coordinates?.length > 1 && (
                // ---------------------------------- buttons --------------------------------------------
                <div className='d-flex justify-content-center'>
                  {isPlaying ? (
                    <button
                      onClick={stopVideo}
                      className='border-0 px-3 py-2 text-white rounded-pill'
                      style={{ backgroundColor: 'var(--primary-color)' }}
                    >
                      Pause
                    </button>
                  ) : (
                    <button
                      onClick={startVideo}
                      className='border-0 px-3 py-2 text-white rounded-pill'
                      style={{ backgroundColor: 'var(--primary-color)' }}
                    >
                      Play
                    </button>
                  )}
                </div>
              )}
            </Col>
            <Col>
              {/* -------------------------- Most Active ------------------------------- */}
              <InfoTable title={'Most Active Area'} data={Object.entries(props.insights.overall.active)} />
            </Col>
          </Row>
          <Row>
            <div className='d-flex gap-2 mt-2'>
              <Col>
                {/* -------------------------- Left Insights ------------------------------- */}
                <InfoTable
                  title={'Left Brain'}
                  overall={props.insights.left_insight.percentage}
                  data={Object.entries(props.insights.left_insight.active)}
                />
              </Col>
              <Col>
                {/* -------------------------- Right Insights ------------------------------- */}
                <InfoTable
                  title={'Right Brain'}
                  overall={props.insights.right_insight.percentage}
                  data={Object.entries(props.insights.right_insight.active)}
                />
              </Col>
            </div>
          </Row>
        </>
      )}
    </div>
  )
}

export default BrainAnim

export const InfoTable = ({ title, overall, data }) => {
  const [open, setOpen] = useState(false)
  return (
    <div className='p-4 border rounded-3'>
      <div className='d-flex align-items-center justify-content-between px-2 mb-2'>
        <h5 className='m-0'>{title}</h5>
        {data.length > 3 ? (
          <span
            onClick={() => setOpen(!open)}
            className={`icon-down ${open ? 'open' : ''}`}
            style={{ cursor: 'pointer' }}
          >
            <FiChevronDown />
          </span>
        ) : null}
      </div>

      <table className='w-100 d-flex flex-column gap-2 rounded'>
        {overall ? (
          <tr
            className='border d-flex justify-content-between align-items-center rounded-pill p-1'
            style={{
              backgroundColor: 'var(--green-highlight-color)',
            }}
          >
            <td className='mx-2'>Overall</td>
            <td className='text-white rounded-pill py-2 px-3' style={{ backgroundColor: 'var(--green-color)' }}>
              <strong>{Math.round(overall)}</strong>%
            </td>
          </tr>
        ) : null}
        {data.length ? (
          data
            .sort(([, a], [, b]) => b - a)
            .slice(0, 3)
            .map(([point, value], i) => (
              <tr
                key={i}
                className='border d-flex justify-content-between align-items-center rounded-pill p-1 bg-white'
              >
                <td className='mx-2'>{point}</td>
                <td className='text-white rounded-pill py-2 px-3' style={{ backgroundColor: 'var(--green-color)' }}>
                  {value}%
                </td>
              </tr>
            ))
        ) : (
          <p className='m-0 mx-2'>No data points</p>
        )}
      </table>
      {data.length > 3 ? (
        <Collapse in={open}>
          <div className='mt-2'>
            <table className='w-100 d-flex flex-column gap-2 rounded'>
              {data
                .sort(([, a], [, b]) => b - a)
                .slice(3)
                .map(([point, value], i) => (
                  <tr
                    key={i}
                    className='border d-flex justify-content-between align-items-center rounded-pill p-1 bg-white'
                  >
                    <td className='mx-2'>{point}</td>
                    <td className='text-white rounded-pill py-2 px-3' style={{ backgroundColor: 'var(--green-color)' }}>
                      {value}%
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </Collapse>
      ) : null}
    </div>
  )
}

// const draw = (eeg_position) => {
//   const p = new Raphael('canvas');
//   p.clear();

//   eeg_position.forEach((eeg) => {
//     let startPoint = eeg[0],
//       endPoint = eeg[1];
//     let startX = coordinatesDict[startPoint].x,
//       startY = coordinatesDict[startPoint].y;
//     let endX = coordinatesDict[endPoint].x,
//       endY = coordinatesDict[endPoint].y;

//     p.path(`M${startX} ${startY} L${endX} ${endY}`).attr({
//       stroke: '#54baaf',
//       'stroke-width': '2',
//     });
//   });

//   for (const [id, point] of Object.entries(coordinatesDict)) {
//     let isFill = false;

//     for (let i = 0; i < eeg_position.length; i++) {
//       if (eeg_position[i].includes(id)) {
//         isFill = true;
//         break;
//       }
//     }

//     var c = p.circle(point.x, point.y, 10).attr({
//       stroke: '#54baaf',
//       'stroke-width': '3',
//       fill: isFill && '#54baaf',
//     });

//     isFill &&
//       c.click((e) => {
//         console.log('click event \n', e);
//       });

//     p.text(point.textX, point.textY, id).attr({
//       fill: '#54baaf',
//       font: '14px Arial',
//     });
//   }
// };

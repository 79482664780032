import { Navigate, Outlet } from 'react-router-dom'

function PrivateRoute() {
  const isLoggedIn = Boolean(sessionStorage.getItem('session'))

  if (!isLoggedIn) {
    return <Navigate to='/loginmain' replace />
  }

  return <Outlet />
}
export { PrivateRoute }

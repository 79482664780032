import { differenceInSeconds } from 'date-fns'
import React, { useState, useEffect, useCallback, useContext } from 'react'
import * as API from '../../Apisurl'
import Context from '../../Context'
import { Col, Row } from 'react-bootstrap'
import log from '../../assets/images/log.svg'
import memoryGameIcon from '../../assets/images/memory.png'
import corsi from '../../assets/images/chain.png'
import memoryspan from '../../assets/images/creative.png'
import schutletable from '../../assets/images/The-Schulte-Table.jpg'
import mentalmaths from '../../assets/images/mathematics.png'

const Corsi = () => {
  const { selectValue } = useContext(Context)
  const gridSize = 7
  const [cards, setCards] = useState(
    Array.from({ length: gridSize * gridSize }, (_) => ({ value: -1, visible: false }))
  )
  const [result, setResult] = useState([])
  const [clickCount, setClickCount] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [settings, setSettings] = useState({
    type: 'Normal',
    level: '1',
    no_of_rounds: 4,
    curr_round: 0,
  })
  const [reports, setReports] = useState([])

  const handleChange = (e) => {
    const { name, value } = e.target
    setSettings((old) => ({ ...old, [name]: value }))
  }

  useEffect(() => {
    const getReports = async () => {
      try {
        const response = await fetch(`${API.Fetchurl}games_data?user_id=${selectValue.user_id}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('session')}` },
        })
        const data = await response.json()

        const modifiedData = data.ques.filter((d) => d[2] === 'Corsi').map((d) => JSON.parse(d[6][0]))

        setReports(modifiedData)
      } catch (error) {
        console.log(error)
      }
    }

    getReports()
  }, [selectValue.user_id])

  const saveReport = async (res) => {
    try {
      await fetch(`${API.Fetchurl}games_data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('session')}`,
        },
        body: JSON.stringify(res),
      })
    } catch (error) {
      console.log(error)
    }
  }

  const generateSequence = useCallback(() => {
    if (settings.curr_round === settings.no_of_rounds) {
      let duration = 0,
        avg = 0,
        rate = 0

      setResult((oldReports) => {
        for (let i = 0; i < oldReports.length; i++) {
          duration += oldReports[i].time
          rate += (settings.level - oldReports[i].errorCount) / settings.level
        }

        rate = Math.round((rate / oldReports.length) * 100)
        avg = (duration / oldReports.length).toFixed(1)

        saveReport({
          user_id: selectValue.user_id,
          username: selectValue.username,
          bet: 0,
          time: avg,
          category: 'Corsi',
          score: rate,
          bet_array: [
            JSON.stringify({
              type: settings.type,
              level: settings.level,
              rate: rate,
              duration: duration,
              avg: avg,
            }),
          ],
        })

        stopGame()
        return oldReports
      })
      return
    }

    setIsPlaying(true)
    setIsGenerating(true)
    setSettings((old) => ({ ...old, curr_round: old.curr_round + 1 }))

    const uniqueIndexes = new Set()
    let intervalId,
      i = 0

    const updateCardVisibility = () => {
      if (uniqueIndexes.size === parseInt(settings.level)) {
        setResult((old) => [...old, { errorCount: 0, time: new Date() }])
        setIsGenerating(false)
        clearInterval(intervalId)
        return
      }

      const getRandomIndex = () => Math.floor(Math.random() * cards.length)

      let randomIndex
      do {
        randomIndex = getRandomIndex()
      } while (uniqueIndexes.has(randomIndex))

      uniqueIndexes.add(randomIndex)

      setCards((prevCards) => {
        const updatedCards = [...prevCards]
        updatedCards[randomIndex].visible = true
        updatedCards[randomIndex].value = i++
        return updatedCards
      })
    }

    intervalId = setInterval(updateCardVisibility, 1000)
  }, [cards.length, settings.level, settings.curr_round, settings.no_of_rounds])

  useEffect(() => {
    if (clickCount === parseInt(settings.level)) {
      // replace the initial time (date format) with difference in seconds
      const updatedData = [...result]
      let diff = differenceInSeconds(new Date(), updatedData[settings.curr_round - 1].time)
      updatedData[settings.curr_round - 1].time = diff
      setResult(updatedData)

      setClickCount(0)
      generateSequence()
    }
  }, [clickCount, settings.level, generateSequence])

  const stopGame = () => {
    setIsPlaying(false)
    setCards(Array.from({ length: gridSize * gridSize }, (_) => ({ value: -1, visible: false })))
    setResult([])
    setSettings((old) => ({ ...old, curr_round: 0 }))
    setClickCount(0)
  }

  const handleCardClick = (cardIdx) => {
    const isNormalType = settings.type === 'Normal'
    const isCorrectValue = isNormalType
      ? cards[cardIdx].value === clickCount
      : cards[cardIdx].value === parseInt(settings.level) - clickCount - 1

    if (isCorrectValue) {
      setClickCount((prevCount) => prevCount + 1)

      const updatedCards = [...cards]
      updatedCards[cardIdx].visible = false
      setCards(updatedCards)
    } else {
      const updatedData = [...result]
      updatedData[settings.curr_round - 1].errorCount += 1
      setResult(updatedData)
    }
  }

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <Row className='h-100'>
        <Col lg={8} xl={9} xxl={9} className='p-0 h-100'>
          <div
            className='d-flex align-items-center justify-content-between bg-white shadow-sm p-4 mb-3'
            style={{ height: '75px' }}
          >
            <div className='d-flex align-items-center gap-3'>
              <img src={corsi} alt='mental-maths' height={48} width={48} />
              <h1 className='m-0 fs-2'>Corsi</h1>
            </div>

            <div className='d-flex align-items-center gap-4'>
              <div>
                <label htmlFor='type'>Type</label>
                <select
                  id='type'
                  name='type'
                  value={settings.type}
                  onChange={handleChange}
                  required
                  disabled={isPlaying}
                  style={{ width: 'fit-content', padding: 0, height: '25px' }}
                  className='ms-2 border border-2 rounded-pill'
                >
                  <option value='Normal'>Normal</option>
                  <option value='Reverse'>Reverse</option>
                </select>
              </div>
              <div>
                <label htmlFor='level'>Level</label>
                <select
                  id='level'
                  name='level'
                  value={settings.level}
                  onChange={handleChange}
                  required
                  disabled={isPlaying}
                  style={{ width: 'fit-content', padding: 0, height: '25px' }}
                  className='ms-2 border border-2 rounded-pill'
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((lvl) => (
                    <option key={lvl} value={lvl}>
                      {lvl}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className='mx-auto' style={{ maxWidth: 'fit-content' }}>
            <div className='d-flex align-items-center justify-content-between'>
              <p className='m-0'>
                Round {settings.curr_round} of {settings.no_of_rounds}
              </p>
              {!isPlaying ? (
                <button className='p-2 px-4 border-0 rounded-3 text-black bg-warning' onClick={generateSequence}>
                  Start
                </button>
              ) : (
                <button className='p-2 px-4 border-0 rounded-3 bg-black text-white' onClick={stopGame}>
                  Cancel
                </button>
              )}
            </div>
            <p className='my-2 text-secondary'>Remember the order of the blocks as they appear</p>
            <div
              className='dashboard-cards'
              style={{ display: 'grid', gap: '6px', gridTemplateColumns: `repeat(${gridSize}, auto)` }}
            >
              {cards.map(({ visible }, i) => {
                return (
                  <button
                    key={i}
                    disabled={isGenerating}
                    onClick={() => handleCardClick(i)}
                    style={{
                      visibility: visible ? 'visible' : 'hidden',
                      background: '#f4c531',
                      width: '50px',
                      height: '50px',
                      border: 0,
                      borderRadius: '8px',
                      color: 'white',
                    }}
                  ></button>
                )
              })}
            </div>
          </div>
        </Col>
        <Col
          lg={4}
          xl={3}
          xxl={3}
          className='h-100 bg-white p-4'
          style={{ opacity: isPlaying ? 0 : 1, transition: 'opacity 0.2s ease' }}
        >
          <h6>Game Info</h6>
          <table className='table table-borderless table-sm mb-4'>
            <tbody>
              <tr>
                <td>Mode</td>
                <td className='fw-bold'>{settings.type}</td>
              </tr>
              <tr>
                <td>Level</td>
                <td className='fw-bold'>{settings.level}</td>
              </tr>
              <tr>
                <td>Number of Rounds</td>
                <td className='fw-bold'>{settings.no_of_rounds}</td>
              </tr>
            </tbody>
          </table>

          <h6>How to play</h6>
          <ul>
            <li>
              To start the game press start button. When you blocks finish appearing click the blocks in the correct
              order.
            </li>
          </ul>

          <h6>Reports</h6>
          {reports.length === 0 && 'No reports yet'}
          <table className='table table-sm table-hover' style={{ fontSize: '10px' }}>
            <tbody className='table-group-divider align-middle '>
              {reports.map((report, index) => (
                <tr key={index}>
                  <td>{index + 1}.</td>
                  <td>{report.type}</td>
                  <td>{report.level}</td>
                  <td>{report.rate}%</td>
                  <td>{report.avg}</td>
                  <td>{report.duration}s</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  )
}

export default Corsi

{
  /* <div className='dashboard-cards p-4 flex-grow-1' style={{ width: '450px' }}>
            <h4>About</h4>
            <p style={{ textAlign: 'justify' }}>
              Corsi Block-Tapping is a variant of a memory-span task. Originally it was based on a Digit Span task, but
              unlike Digit Span it requires the use of visuo-spatial memory.
            </p>
            <h4>How To Play</h4>
            <p style={{ textAlign: 'justify' }}>
              To start the game press start button. When you blocks finish appearing click the blocks in the correct
              order.
            </p>
          </div> */
}

import React, { useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import Selectedlang from '../Selectedlang'
import SelectModuleCard from './SelectModuleCard'

const ModuleComp = () => {
  let location = useLocation()

  const [active, setActive] = useState(0)

  let modulecard = JSON.parse(localStorage.getItem('modulejson'))

  let phasedata = location.state != null ? location.state.modulename1 : ''
  let phasedata1 = location.state != null ? location.state.modulename3 : ''

  Selectedlang()

  const getactiveid = (data, id) => {
    localStorage.setItem('moduleCard', data)
    setActive(id)
  }
  let modName =
    localStorage
      .getItem('modulename')
      .toUpperCase()
      .charAt(0)
      .toUpperCase() +
    localStorage
      .getItem('modulename')
      .toUpperCase()
      .slice(1)
      .toLowerCase()

  return (
    <>
      <h3>Please select a module to proceed</h3>
      <Container>
        <Row className='option-container'>
          {modulecard != undefined && modulecard != '' ? (
            modulecard[modName].map(function(key, index) {
              let data
              if (index != 0) {
                return (data = (
                  <SelectModuleCard
                    images={key.image}
                    title={key.module}
                    percentage={key.attempted_percentage || 0}
                    buttontxt='Start'
                    link={key.module_title.toLowerCase()}
                    //  key={i}
                    phase={phasedata}
                    phase1={phasedata1}
                    text={key.text}
                    btnvia={true}
                    onClick={() => getactiveid(key.module, index)}
                    isActive={active === index}
                    loaderVia={1}
                  />
                ))
              }
              return data
            })
          ) : (
            <>
              <SelectModuleCard loaderVia={0} />
              <SelectModuleCard loaderVia={0} />
              <SelectModuleCard loaderVia={0} />
            </>
          )}
        </Row>
      </Container>
    </>
  )
}
export default ModuleComp

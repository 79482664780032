import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom'
import * as Apiurl from '../../Apisurl'
import Context from '../../Context'
import headersReq from '../../HeadersJson'
import { default as UserJson, default as userJson } from '../../Userjson'
import Backbtn from '../BackComp'
import Pagination from '../Pagination'
import EmotReport from './EmotReport'
import PmReport from './PmReport'

const ResultLayout = (props) => {
  const reqJson = headersReq()
  const [lgShow, setLgShow] = useState(false)
  const [emotDatad, setemotData] = useState([])
  const [score, setScore] = useState([])
  const [Overview, setOverview] = useState([])
  const [total, setTotal] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()

  let headingname = useParams()
  const id = searchParams.get('id')

  let userData
  if (userJson() != {}) {
    userData = JSON.stringify(userJson())
  }

  useEffect(() => {
    async function emotdata() {
      const response = await fetch(`${Apiurl.Fetchurl + 'emot_report'}`, {
        method: 'POST',
        headers: reqJson,
        body: userData,
      })
      const data = await response.json()
      return data
    }
    emotdata()
      .then((data) => {
        let datad = JSON.parse(data.replace(/'/g, '"'))

        let getData = datad.result.EmotionalAnalysis

        setemotData([getData.Contempt, getData.Happy, getData.Relaxed, getData.Tired])
      })
      .catch((error) => {
        console.log('error')
      })
  }, [])

  const elementRef = useRef(null)
  const [arrowDisable, setArrowDisable] = useState(true)

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0
    const slideTimer = setInterval(() => {
      element.current.scrollLeft += step
      scrollAmount += Math.abs(step)
      if (scrollAmount >= distance) {
        clearInterval(slideTimer)
      }
      if (element.current.scrollLeft === 0) {
        setArrowDisable(true)
      } else {
        setArrowDisable(false)
      }
    }, speed)
  }

  let userData1 = userJson()

  useEffect(() => {
    async function levelgame() {
      const response = await fetch(`${Apiurl.Fetchurl + 'manual_report_fc'}`, {
        method: 'POST',
        headers: reqJson,
        // body: userData,
        body: JSON.stringify({ serial_num: id }),
      })
      const childdata = await response.json()
      return childdata
    }
    // setTimeout(() => {
    levelgame()
      .then((data) => {
        let resultData = data.result

        setOverview(resultData)
        setScore(resultData.score)

        if (userData1.modulename == 'flashcard') {
          setTotal(data.total_score)
        } else {
          setTotal(resultData.ques == undefined ? resultData.total : resultData.ques.length)
        }
      })
      .catch((error) => {
        console.log(error)
      })
    // }, [500])
  }, [])

  let catarr = ['adjectives', '2_phase_mcq', 'Sentences_mcq']

  return (
    <>
      <Backbtn title={headingname.category} />
      <ul className='userInfo-container'>
        <li>Userame:-{userData1.username}</li>
        <li>Module:-{userData1.modulename1}</li>
        <li>Category:-{userData1.modulename}</li>
        <li>Level:-{userData1.level}</li>
      </ul>
      <Container>
        <Row>
          <Col md={sessionStorage.getItem('headsetVia') == 2 ? 7 : 12} style={{ position: 'relative' }}>
            <div className='dashboard-cards  stat-container resultCard'>
              <div className='report-container'>
                <h3>Reports</h3>
                {userData1.category == 'paragraph_type' ? (
                  <p>
                    Score:
                    <b>{Overview.ans && Overview.ans[0]['percentage']}%</b>
                  </p>
                ) : (
                  <p>
                    Score:
                    <b> {score}</b>/<span>{catarr.includes(userData1.category) ? total / 2 : total}</span>
                  </p>
                )}
              </div>
              <div class='table-container result-t' ref={elementRef}>
                <Context.Provider value={[{ setScore }, { setTotal }, { Overview }]}>
                  <Outlet />
                </Context.Provider>
              </div>
            </div>
            {userData1.category != 'content_writing' ? (
              <Pagination
                prevClick={() => {
                  handleHorizantalScroll(elementRef, 10, 100, -10)
                }}
                nextClick={() => {
                  handleHorizantalScroll(elementRef, 10, 100, 10)
                }}
              />
            ) : (
              ''
            )}
          </Col>
          {sessionStorage.getItem('headsetVia') == 2 ? (
            <Col md={5}>
              <div className='dashboard-cards  stat-container'>
                <div class='performance-heading'>
                  <div class='performance-content'>
                    <h4>Performance Metrics</h4>
                    <p>{UserJson().date}</p>
                  </div>
                </div>
                <div className='Headset-msg'>
                  <p>{localStorage.getItem('category')}</p>
                </div>
                <PmReport />
                <button className='common-btn full-btn' onClick={() => setLgShow(true)} type='button'>
                  Emotional Analysis
                </button>

                <Modal
                  size='md'
                  show={lgShow}
                  onClick={() => setLgShow(false)}
                  aria-labelledby='example-modal-sizes-title-lg'
                >
                  <Modal.Header closeButton>
                    <h2 className='m-0'>Emotional Analysis</h2>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col md={12}>
                        <EmotReport />
                      </Col>
                    </Row>
                  </Modal.Body>
                </Modal>
              </div>
            </Col>
          ) : (
            ''
          )}
        </Row>
      </Container>
    </>
  )
}

export default ResultLayout

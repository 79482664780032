import { Col } from 'react-bootstrap'
import { NavLink, useLocation } from 'react-router-dom'
import Speech from '../Speech'

const CardComponent = (props) => {
  let location = useLocation()
  let sendModulleData = JSON.parse(localStorage.getItem('sendModulleData'))

  return (
    <Col md={4} className='mt-3'>
      <figure className={props.classes} style={{ position: 'relative' }}>
        <div
          className='position-absolute d-flex justify-content-center align-items-center rounded-circle'
          style={{
            width: '50px',
            height: '50px',
            right: '15px',
            top: '15px',
            background: `conic-gradient(var(--primary-color) ${(props.percentage / 100) * 360}deg, #e9e9e9 0deg)`,
          }}
        >
          <div
            className='d-flex justify-content-center align-items-center bg-white text-black rounded-circle shadow'
            style={{ width: '35px', height: '35px', fontSize: '12px', fontWeight: 800 }}
          >
            {Math.round(props.percentage)}%
          </div>
        </div>
        <img src={props.img} alt={props.img} />
        <figcaption>
          <h3>{props.title}</h3>
          <p>{props.content}</p>
          <div className='start-btnContainer'>
            <NavLink
              to={props.link.toLowerCase()}
              onClick={() => Speech.Speech(`${sendModulleData.username}  choose ${props.title}`)}
              state={{
                attempted: props.attempted,
                modulename: location.state.modulename,
                modulename2: location.state.modulename2,
                modulename3: location.state.modulename3,
                modulename1: props.modulename1.toLowerCase(),
                title: location.state.title,
                category:
                  location.state.category == undefined
                    ? props.link.toLowerCase()
                    : (location.state.category = props.link.toLowerCase()),
                username: sendModulleData.username,
                userid: sendModulleData.userid,
              }}
              className='start-btn w-100'
            >
              <button style={{ textAlign: 'center' }}>Start</button>
            </NavLink>
          </div>
        </figcaption>
      </figure>
    </Col>
  )
}
export default CardComponent

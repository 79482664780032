import { useContext, useEffect, useState } from 'react'
import { Button, Collapse, Spinner } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { useNavigate, NavLink } from 'react-router-dom'
import * as Apiurl from '../Apisurl'
import jwtDecode from 'jwt-decode'
import Context from '../Context'
import ReCAPTCHA from 'react-google-recaptcha'

const LoginForm = () => {
  const navigate = useNavigate()
  const { setUserServices } = useContext(Context)

  const [loading, setLoading] = useState(false)
  const [err, seterr] = useState(null)
  const [headsetVia, setheadsetVia] = useState(false)
  const [headsetList, setHeadsetList] = useState()
  const [recaptchaToken, setRecaptchaToken] = useState(null)
  const [captchaErr, setcaptchaErr] = useState()
  const [validated, setValidated] = useState(false)
  const [uservalues, setInputValue] = useState({
    username: '',
    password: '',
    remember_me: false,
    headset_name: 'na',
  })

  useEffect(() => {
    const getHeadsetLists = async () => {
      const response = await fetch(Apiurl.Fetchurl + 'headset_list', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()
      setHeadsetList(data.headset)
    }
    getHeadsetLists()
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (e.currentTarget.checkValidity() === false) {
      e.stopPropagation()
    } else {
      gotologin()
    }
    setValidated(true)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputValue({ ...uservalues, [name]: value })
  }

  const handleCheck = () => {
    setInputValue({ ...uservalues, remember_me: !uservalues.remember_me })
  }

  const gotologin = () => {
    if (!recaptchaToken) {
      setcaptchaErr('Please complete the reCAPTCHA challenge.')
      return
    }
    setLoading(true)
    async function login() {
      const response = await fetch(Apiurl.Fetchurl + 'login', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(uservalues),
      })
      const Admin = await response.json()
      return Admin
    }

    login()
      .then((data) => {
        if (data.Status == 200) {
          let user = jwtDecode(data.token)
          console.log(user)
          sessionStorage.setItem('session', data.session)
          sessionStorage.setItem('headsetVia', headsetVia ? 2 : 1)
          sessionStorage.setItem('headset_name', uservalues.headset_name)
          sessionStorage.setItem('sessionname', data.username)
          sessionStorage.setItem('max_students', data.max_students)
          sessionStorage.setItem('services', JSON.stringify(data.service_subs))
          localStorage.setItem('ischildActive', 0)
          localStorage.setItem('isGroup', false)

          setUserServices(data.service_subs)

          let role = user.person_type.split('_').at(-1)
          let isDemo = user.person_type.split('_')[0] === 'demo'
          localStorage.setItem('role', role)
          localStorage.setItem('isDemo', isDemo)

          if (role === 'hr' || role === 'college') {
            return navigate('/overview', { state: { pageid: 3 } })
          } else if (role === 'employee' || role === 'student') {
            return navigate('/overview', { state: { pageid: 2 } })
          } else if (role === 'admin') {
            return navigate('/children/add', { state: { pageid: 3 } })
          }

          navigate('/logintype', { state: { pageid: 1 } })
        } else if (data.Status == 400) {
          seterr("username or password doesn't exist")
        } else if (data.Status == 403) {
          let user = jwtDecode(data.token)
          sessionStorage.setItem('session', data.session)
          sessionStorage.setItem('headsetVia', headsetVia ? 2 : 1)
          sessionStorage.setItem('headset_name', uservalues.headset_name)
          sessionStorage.setItem('sessionname', data.username)
          sessionStorage.setItem('user_id', user.user_id)
          sessionStorage.setItem('max_students', data.max_students)
          sessionStorage.setItem('services', JSON.stringify(data.service_subs))
          localStorage.setItem('ischildActive', 0)
          localStorage.setItem('isGroup', false)

          setUserServices(data.service_subs)

          let role = user.person_type.split('_').at(-1)
          let isDemo = user.person_type.split('_')[0] === 'demo'
          localStorage.setItem('role', role)
          localStorage.setItem('isDemo', isDemo)

          seterr('Please Renew Subscription')
          navigate('/renew')
        } else {
          seterr(data.error)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }
  const handleRecaptchaChange = (value) => {
    // You can handle the recaptcha value change here
    setRecaptchaToken(value)
    setcaptchaErr()
  }

  return (
    <>
      <Form className='login-form-container parent-container' noValidate validated={validated} onSubmit={handleSubmit}>
        <h3>Login</h3>
        <p>Please log in to continue</p>
        <span className='text-danger my-3'>{err}</span>
        <Form.Group controlId='username' className='form-inputs'>
          <Form.Label>Username</Form.Label>
          <Form.Control
            type='text'
            placeholder='Username'
            name='username'
            value={uservalues.username}
            maxLength='30'
            onChange={handleChange}
            required
          />
          <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='password' className='form-inputs'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='password'
            name='password'
            placeholder='****************'
            required
            value={uservalues.password}
            onChange={handleChange}
            maxLength='30'
          />
          <p className='m-0'>
            <NavLink to='/forgot-password' class='small'>
              Forgot password?
            </NavLink>
          </p>
          <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
        </Form.Group>

        <div className='d-flex justify-content-between' style={{ marginBottom: '15px' }}>
          <div className='radio-btn-container m-0'>
            <Form.Check
              type='switch'
              id='headset-on-off'
              name='headset'
              value={headsetVia}
              checked={headsetVia}
              onChange={() => setheadsetVia((p) => !p)}
              label={`Headset ${headsetVia ? 'On' : 'Off'}`}
            />
          </div>
          <div className='radio-btn-container m-0'>
            <Form.Check
              inline
              id='remember_me'
              style={{ cursor: 'pointer' }}
              name='remember_me'
              label='Remember Me'
              type='checkbox'
              value={uservalues.remember_me}
              selected={uservalues.remember_me}
              onClick={handleCheck}
            />
          </div>
        </div>

        <Collapse in={headsetVia}>
          <div>
            <Form.Group controlId='headset' className='form-inputs'>
              <Form.Label>Headset</Form.Label>
              <Form.Select
                name='headset_name'
                value={uservalues.headset_name}
                onChange={handleChange}
                required={headsetVia}
              >
                <option value=''>Select Your Headset</option>
                {headsetList?.map((name) => {
                  return (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  )
                })}
              </Form.Select>
              <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
            </Form.Group>
          </div>
        </Collapse>
        <div className='my-4'>
          <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPCHA} onChange={handleRecaptchaChange} />
          {captchaErr && <p className='text-danger small mt-1'>{captchaErr}</p>}
        </div>
        <Button variant='primary' className='common-btn full-btn mt-2' type='submit' disabled={loading}>
          {loading ? (
            <div className='d-flex align-items-center justify-content-center gap-2'>
              <Spinner animation='border' />
              Logging you in
            </div>
          ) : (
            'Continue'
          )}
        </Button>
      </Form>
    </>
  )
}
export default LoginForm

import React from 'react'
import HomeHeader from '../includes/HomepageHeader'
import BreadCrumb from '../includes/BreadCrumb'
import { Outlet, useLocation } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Header from '../profile/Header'

const RenderSelectModule = (props) => {
  const location = useLocation()

  let URL = location.pathname.split('/').filter((path) => path !== '')

  console.log(URL)

  let isHidden
  if (URL.length > 5 && URL[2] === 'games') {
    isHidden = true
  } else if (URL.length > 3 && (URL[2] === 'flashcard' || URL[2] === 'education')) {
    isHidden = true
  } else {
    isHidden = false
  }

  return (
    <div className='content flex-grow-1'>
      <Container fluid>
        <HomeHeader title={props.title} subititle={props.subititle} />
        {!isHidden && <Header />}
        <BreadCrumb />
        <div class='option-main-container '>
          <Outlet />
        </div>
      </Container>
    </div>
  )
}
export default RenderSelectModule

import React, { useContext, useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import FormHeader from '../includes/FormHeader'
import * as Apiurl from '../Apisurl'
import { useNavigate } from 'react-router-dom'
import headersReq from '../HeadersJson'
import PayButton from '../pay/PayButton'
import Context from '../Context'

const LoginTypeCard = () => {
  let reqJson = headersReq()
  let navigate = useNavigate()
  const { userServices } = useContext(Context)
  const [type, settype] = useState(0)

  const role = localStorage.getItem('role')

  useEffect(() => {
    async function phaseselect() {
      try {
        const response = await fetch(`${Apiurl.Fetchurl + 'product_type'}`, {
          method: 'POST',
          headers: reqJson,
          body: JSON.stringify({ module: 1, language: localStorage.getItem('getlang') || 'en' }),
        })
        const data = await response.json()
        settype(data)
      } catch (error) {
        console.log(error.message)
      }
    }

    phaseselect()
  }, [])

  async function populateChild() {
    const response = await fetch(`${Apiurl.Fetchurl + 'child'}`, {
      method: 'POST',
      headers: reqJson,
      body: JSON.stringify({
        person_uid: window.sessionStorage.getItem('session'),
      }),
    })
    const childdata = await response.json()
    return childdata
  }

  function goto(api_name) {
    localStorage.setItem('api_name', api_name)
    localStorage.removeItem('modulejson')
    localStorage.setItem('isGroup', false)
    localStorage.setItem('getlang', 'en')

    populateChild()
      .then((data) => {
        if (data.Status.children.registred_child.length === 0) {
          navigate('/add', { state: { pageid: 1 } })
        } else {
          if (role === 'admin') {
            navigate('/school', { state: { pageid: 1 } })
          } else {
            navigate('/', { state: { pageid: 1 } })
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  if (type !== 0) {
    return (
      <>
        <FormHeader />
        <main>
          <section className='login-form-section'>
            <Container className='option-main-container '>
              <h3>Please select a type</h3>

              <Row className='option-container'>
                {type.map((itm, i) => {
                  const isAccessable = itm.id_payment === 1 || userServices.includes(itm.id_payment)
                  return (
                    <Col md={4} key={i}>
                      <div className='position-relative'>
                        {!isAccessable && (
                          <div
                            className='d-flex align-items-center justify-content-center'
                            style={{
                              position: 'absolute',
                              zIndex: 1,
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              background: 'rgba(255,255,255,0.8)',
                            }}
                          >
                            <PayButton service={itm.id_payment} month={1} />
                          </div>
                        )}
                        <li className='active user-select-none' style={{ filter: !isAccessable && 'grayscale(100%)' }}>
                          <figure>
                            <img src={itm.image} alt='education' />
                          </figure>
                          <figcaption>
                            <h6>{itm.name}</h6>
                            <p className='text-center'>{itm.text}</p>
                            <div className='btn-container'>
                              <button className='common-btn' onClick={() => goto(itm.api)}>
                                Start
                              </button>
                            </div>
                          </figcaption>
                        </li>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </Container>
          </section>
        </main>
      </>
    )
  }
}
export default LoginTypeCard

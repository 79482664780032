import React from "react";
import FormHeader from "../includes/FormHeader";
import { NavLink } from "react-router-dom";
const SuccessScreen = () => {
  return (
    <>
      <FormHeader />
      <section class="select-role-section parent-container ">
        <div class="animate-success-container">
          <div class="check-wrap"></div>
          <h2> Account created successfully!</h2>
          <p>Please log in to continue</p>
          <NavLink to="/loginmain">
            <button class="btn common-btn">Login</button>
          </NavLink>
        </div>
      </section>
    </>
  );
};
export default SuccessScreen;

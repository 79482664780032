import { useContext } from 'react'
import { Container, Tab, Tabs } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import * as Apiurl from '../Apisurl'
import Context from '../Context'
import headersReq from '../HeadersJson'
import Activity from '../dashboard/Activity'
import Header from '../profile/Header'
import Test0 from './Test0'
import { test0 } from './data'

const QRC = () => {
  const reqJson = headersReq()
  const { selectValue } = useContext(Context)
  const navigate = useNavigate()

  const submit = async (res) => {
    try {
      const response = await fetch(Apiurl.Fetchurl + 'questionaire', {
        method: 'POST',
        headers: reqJson,
        body: JSON.stringify({ user_id: selectValue.user_id, ...res }),
      })
      if (response.ok) {
        const data = await response.json()
        navigate('result', { state: { data: data } })
      } else {
        const error = await response.json()
        throw new Error(error?.error || 'Something Went Wrong....')
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  const options = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']

  return (
    <div className='content flex-grow-1'>
      <Container fluid className='report-tab'>
        <div className='dashboard-heading'>
          <div class='content-container'>
            <h2>Keirsey Test</h2>
            <p>Answer few questions and find the surprise</p>
          </div>
          <Activity />
        </div>
        <Header />
        <Tabs defaultActiveKey='test-0' id='test-0' className='mt-3'>
          <Tab eventKey='test-0' title='Test 1'>
            <Test0 data={test0} submit={submit} options={options} limit={7} />
          </Tab>
        </Tabs>
      </Container>
    </div>
  )
}

export default QRC

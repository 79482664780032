import React, { useRef, useState } from 'react'
import Step from '../components/Step'
import StepOne from '../components/add/StepOne'
import StepTwo from '../components/add/StepTwo'
import StepThree from '../components/add/StepThree'
import StepFourA from '../components/add/StepFourA'
import StepFourB from '../components/add/StepFourB'
import * as API from '../../Apisurl'
import { Spinner } from 'react-bootstrap'

const Add = () => {
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [validated, setValidated] = useState(false)
  const formRef = useRef()

  const [module, setModule] = useState({
    module: '',
    text: '',
    image: 'https://ui-nema-database.s3.ap-south-1.amazonaws.com/thumbnails/module1.png',
  })

  const [modCategory, setModCategory] = useState({
    module: 'Education',
    module_title: 'Education',
    text: '',
    image: 'https://ui-nema-database.s3.ap-south-1.amazonaws.com/thumbnails/education.svg',
  })

  const [category, setCategory] = useState({
    name: '',
    name_title: '',
    image: 'https://ui-nema-database.s3.ap-south-1.amazonaws.com/education/level1/fruitslevel1.jpeg',
  })

  const [flashcard, setFlashcard] = useState([])
  const [education, setEducation] = useState([
    {
      name: 'level1',
      name_title: 'level1',
      image: '',
      video: '',
    },
    {
      name: 'level2',
      name_title: 'level2',
      image: '',
      video: '',
    },
    {
      name: 'level3',
      name_title: 'level3',
      image: '',
      video: '',
    },
  ])

  const StepComponents = [
    <StepOne formRef={formRef} validated={validated} data={module} setData={setModule} />,
    <StepTwo formRef={formRef} validated={validated} data={modCategory} setData={setModCategory} />,
    <StepThree formRef={formRef} validated={validated} data={category} setData={setCategory} />,
    modCategory.module === 'Flashcard' ? (
      <StepFourA formRef={formRef} validated={validated} data={flashcard} setData={setFlashcard} />
    ) : (
      <StepFourB formRef={formRef} validated={validated} data={education} setData={setEducation} />
    ),
  ]

  const nextStep = (e) => {
    e.preventDefault()
    if (formRef?.current?.checkValidity() === false) {
      e.stopPropagation()
    } else {
      if (step === 4) return finish()
      setStep((s) => s + 1)
    }
    setValidated(true)
  }

  //merge all 4 steps data in reverse to create JSON
  const finish = async () => {
    setLoading(true)

    let isFlashcard = modCategory.module === 'Flashcard'

    let categories = []
    let extraKey = isFlashcard ? { sub_cat: flashcard } : { levels: education }

    //push -> (edu || flashcard) to categories
    categories.push({
      ...category,
      ...extraKey,
    })

    //push -> categories to module-category
    let modcat = {
      ...modCategory,
      categories,
    }

    //make module as key  and remove from object
    let mod = { ...module }
    delete mod.module

    //final JSON [0: [module], 1: [module-category]]
    let json = {}
    json[module.module] = [mod, modcat]

    try {
      const response = await fetch(`${API.Fetchurl}add_main_module`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('session')}`,
        },
        body: JSON.stringify({
          user_id: sessionStorage.getItem('session'),
          new_data: json,
        }),
      })

      await response.json()
      setLoading(false)
      reset()
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const reset = () => {
    setModule({
      module: '',
      text: '',
      image: 'https://ui-nema-database.s3.ap-south-1.amazonaws.com/thumbnails/module1.png',
    })
    setModCategory({
      module: 'Education',
      module_title: 'Education',
      text: '',
      image: 'https://ui-nema-database.s3.ap-south-1.amazonaws.com/thumbnails/education.svg',
    })
    setCategory({
      name: '',
      name_title: '',
      image: 'https://ui-nema-database.s3.ap-south-1.amazonaws.com/education/level1/fruitslevel1.jpeg',
    })
    setFlashcard([])
    setEducation([
      {
        name: 'level1',
        name_title: 'level1',
        image: '',
        video: '',
      },
      {
        name: 'level2',
        name_title: 'level2',
        image: '',
        video: '',
      },
      {
        name: 'level3',
        name_title: 'level3',
        image: '',
        video: '',
      },
    ])

    setStep(1)
  }

  return (
    <div className='admin-container content'>
      <h5>Add Details</h5>
      <p>Add and Manage your content here</p>
      <div>
        <div className='mt-4 mx-auto dashboard-cards' style={{ width: '1200px' }}>
          {/* --------------- step indicator ------------------------ */}
          <div className='d-flex justify-content-between position-relative'>
            <Step step={1} currentStep={step} title={'Module'} />
            <Step step={2} currentStep={step} title={'Module Category'} />
            <Step step={3} currentStep={step} title={'Category'} />
            <Step step={4} currentStep={step} title={'Upload'} />

            <div
              style={{
                backgroundColor: 'var(--light-gray)',
                position: 'absolute',
                top: '30%',
                height: '2px',
                width: '95%',
                marginInline: '32px',
              }}
            >
              <span
                style={{
                  backgroundColor: 'var(--green-color)',
                  position: 'absolute',
                  height: '100%',
                  width: `${((step - 1) / 3) * 100}%`,
                  transition: 'width 0.5s',
                }}
              ></span>
            </div>
          </div>

          {/* --------------- component render ------------------------ */}
          <div className='my-5'>{StepComponents[step - 1]}</div>

          {/* --------------- back continue btn ------------------------ */}
          <div className='d-flex justify-content-between'>
            <button
              onClick={() => setStep((s) => s - 1)}
              type='button'
              className='d-flex justify-content-center align-items-center py-1 px-3 rounded-pill border border-2 text-secondary'
              style={{ backgroundColor: 'transparent' }}
              disabled={step === 1}
            >
              Back
            </button>

            <button
              onClick={(e) => nextStep(e)}
              type='submit'
              className={`d-flex justify-content-center align-items-center py-1 px-3 rounded-pill text-white`}
              style={{ backgroundColor: 'var(--green-color)', border: 'none' }}
              disabled={loading}
            >
              {loading ? <Spinner animation='border' size='sm' /> : step < 4 ? 'Continue' : 'Finish'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Add

const prePrimary = {
  Personal: [
    { q_no: 1, ques: 'Chews and swallows solid food when placed in his mouth.', choices: ['yes', 'no'] },
    { q_no: 2, ques: 'Holds and drinks water or milk or juice from a glass or cup.', choices: ['yes', 'no'] },
    { q_no: 3, ques: 'Eats by self with fingers when food is mixed and given.', choices: ['yes', 'no'] },
    {
      q_no: 4,
      ques: 'Indicates verbally or through gestures the need to go to the toilet.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 5,
      ques: 'Takes off under clothes to use toilet (when unbuttoned/ pulling elastic pants).',
      choices: ['yes', 'no'],
    },
    {
      q_no: 6,
      ques: 'Brushes teeth either with tooth brush or with a finger using tooth paste or tooth powder.',
      choices: ['yes', 'no'],
    },
    { q_no: 7, ques: 'Cooperates while being bathed - extending hands/legs when told.', choices: ['yes', 'no'] },
    { q_no: 8, ques: 'Takes off clothes (including undergarments) when unbuttoned.', choices: ['yes', 'no'] },
    { q_no: 9, ques: 'Wears undergarments.', choices: ['yes', 'no'] },
    { q_no: 10, ques: 'Cleans nose with a handkerchief.', choices: ['yes', 'no'] },
    {
      q_no: 11,
      ques: 'Washes hands before eating snacks or food or after using toilet or when hands are dirty.',
      choices: ['yes', 'no'],
    },
    { q_no: 12, ques: 'Wipes with a towel after bath.', choices: ['yes', 'no'] },
    { q_no: 13, ques: 'Peels off fruits such as orange and banana before eating.', choices: ['yes', 'no'] },
    {
      q_no: 14,
      ques: 'Eats with an appropriate side dish such as bread & jam, chapati & curry, Idli & chutney.',
      choices: ['yes', 'no'],
    },
    { q_no: 15, ques: 'Mixes and eats by self without spilling.', choices: ['yes', 'no'] },
    { q_no: 16, ques: 'Wipes hand and mouth with a towel after washing.', choices: ['yes', 'no'] },
    { q_no: 17, ques: 'Wears slippers.', choices: ['yes', 'no'] },
    { q_no: 18, ques: 'Wears shoes without shoe lace or buckle.', choices: ['yes', 'no'] },
  ],

  Social: [
    { q_no: 19, ques: 'Moves head or eyes to see persons moving near him or in the room.', choices: ['yes', 'no'] },
    {
      q_no: 20,
      ques: 'Responds to his name by stopping an activity or looking at the person when called.',
      choices: ['yes', 'no'],
    },
    { q_no: 21, ques: 'Smiles when other person smiles at him.', choices: ['yes', 'no'] },
    { q_no: 22, ques: 'Plays with two other children cooperatively.', choices: ['yes', 'no'] },
    { q_no: 23, ques: 'Waits for his turn in the classroom, play ground, dining room.', choices: ['yes', 'no'] },
    {
      q_no: 24,
      ques: 'Shares his things (pencil, books, eraser, toys and eatables) when requested by his classmates or others.',
      choices: ['yes', 'no'],
    },
    { q_no: 25, ques: 'Greets teachers or elders in school or at home.', choices: ['yes', 'no'] },
    { q_no: 26, ques: 'Differentiates strangers from familiar people.', choices: ['yes', 'no'] },
    {
      q_no: 27,
      ques: 'Expresses anger or displeasure by vocalizations (shouting / turning away/ screaming) other than crying.',
      choices: ['yes', 'no'],
    },
    { q_no: 28, ques: 'Stops momentarily an activity when said ‘no’.', choices: ['yes', 'no'] },
    {
      q_no: 29,
      ques: 'Uses vocal sounds/gestures/actions to call a person/to get attention of others.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 30,
      ques: 'Responds appropriately to words along with gestures such as come, up, go, bye-bye, etc.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 31,
      ques:
        'Uses gestures such as shaking head for ‘no’, head nodding for ‘yes’ hand gesture for ‘come’, ‘give’, ‘sleep’, ‘tata’.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 32,
      ques:
        'Follows simple verbal requests with gestures such as ‘give’, ‘I will take’ (response could be gestural or verbal).',
      choices: ['yes', 'no'],
    },
    {
      q_no: 33,
      ques:
        'Answers quess such as ‘how does a car go’, ‘how does an aeroplane go’, ‘how do you apply face powder’, (responses in miming fashion).',
      choices: ['yes', 'no'],
    },
    {
      q_no: 34,
      ques: 'Identifies persons by pointing or naming upon request (eg. uncle, aunty, sister, brother, etc.).',
      choices: ['yes', 'no'],
    },
    { q_no: 35, ques: 'Asks for desired objects using gestures/along with vocalizations.', choices: ['yes', 'no'] },
    { q_no: 36, ques: 'Tells him name when asked.', choices: ['yes', 'no'] },
    { q_no: 37, ques: 'Gestures/says suitably for Namaste/salam/good morning.', choices: ['yes', 'no'] },
  ],

  Academic: [
    {
      q_no: 38,
      ques: 'Points/shows body parts (head, nose, eyes, ears, hands, legs) when requested.',
      choices: ['yes', 'no'],
    },
    { q_no: 39, ques: 'Names body parts when pointed to.', choices: ['yes', 'no'] },
    {
      q_no: 40,
      ques:
        'Points to 10 common objects with which he has to interact in his/her home environment (eg. rice, dall, chapati, light, fan, mat, table, chair, shirt/frock, shorts/pants,etc.), when asked or when he wants.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 41,
      ques: 'Says orally the names of the 10 common objects when shown or when he wants.',
      choices: ['yes', 'no'],
    },
    { q_no: 42, ques: 'Holds pencil and scribbles.', choices: ['yes', 'no'] },
    { q_no: 43, ques: 'Colours with a crayon within a given diagram.', choices: ['yes', 'no'] },
    { q_no: 44, ques: 'Traces on the given diagram.', choices: ['yes', 'no'] },
    { q_no: 45, ques: 'Joins dots to form pictures.', choices: ['yes', 'no'] },
    { q_no: 46, ques: 'Copies a given figure/diagram.', choices: ['yes', 'no'] },
    {
      q_no: 47,
      ques: 'Groups common colours (red, green, blue, yellow) when given a group of coloured objects.',
      choices: ['yes', 'no'],
    },
    { q_no: 48, ques: 'Groups objects according to size (big and small, long and short).', choices: ['yes', 'no'] },
    { q_no: 49, ques: 'Gives objects pictures of common colours when asked.', choices: ['yes', 'no'] },
    { q_no: 50, ques: 'Names common colours (red, green, blue, yellow).', choices: ['yes', 'no'] },
    {
      q_no: 51,
      ques: 'Points to the objects which are big and small/long and short upon request.',
      choices: ['yes', 'no'],
    },
    { q_no: 52, ques: 'Tells the size of the objects (big & small, long & short).', choices: ['yes', 'no'] },
    { q_no: 53, ques: 'Tells which set has more/less quantity.', choices: ['yes', 'no'] },
    {
      q_no: 54,
      ques: 'Points to containers with either solids/liquids to show full and empty.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 55,
      ques: 'Tells when a container is shown either full or empty with or without liquid or solid.',
      choices: ['yes', 'no'],
    },
    { q_no: 56, ques: 'Rote counts up to 5.', choices: ['yes', 'no'] },
    { q_no: 57, ques: 'Counts and gives objects up to 5 when not asked sequentially.', choices: ['yes', 'no'] },
    { q_no: 58, ques: 'Counts and gives objects up to 10 when not asked sequentially.', choices: ['yes', 'no'] },
    { q_no: 59, ques: 'Shows a numeral and places value when not asked sequentially.', choices: ['yes', 'no'] },
    { q_no: 60, ques: 'Names up to 5 numbers when not asked sequentially.', choices: ['yes', 'no'] },
    { q_no: 61, ques: 'Writes numerals up to 5 in a sequence.', choices: ['yes', 'no'] },
    { q_no: 62, ques: 'Writes numerals up to 5 when given dictation not sequentially.', choices: ['yes', 'no'] },
    {
      q_no: 63,
      ques:
        'Points to alphabets (English/regional language) on a chart not asked sequentially (3 letter words in case of sight word teaching).',
      choices: ['yes', 'no'],
    },
    {
      q_no: 64,
      ques: 'Names alphabets when asked not sequentially (3 letter words in case of sight word teaching).',
      choices: ['yes', 'no'],
    },
    {
      q_no: 65,
      ques: 'Writes alphabets when given dictation (3 letter words in case of sight word teaching).',
      choices: ['yes', 'no'],
    },
    { q_no: 66, ques: 'Identifies a clock or wristwatch.', choices: ['yes', 'no'] },
    { q_no: 67, ques: 'Tells/points to when asked the use of a clock or wristwatch.', choices: ['yes', 'no'] },
    { q_no: 68, ques: 'Differentiates money from other objects.', choices: ['yes', 'no'] },
    { q_no: 69, ques: 'Tells/points to when asked the use of money.', choices: ['yes', 'no'] },
    { q_no: 70, ques: 'Groups Rs 1, 2, 5 notes when given.', choices: ['yes', 'no'] },
    { q_no: 71, ques: 'Gives 1, 2, 5 rupee notes when asked.', choices: ['yes', 'no'] },
    { q_no: 72, ques: 'Points to at least 5 pictures of animals when asked.', choices: ['yes', 'no'] },
    { q_no: 73, ques: 'Names at least 5 animals when shown pictures.', choices: ['yes', 'no'] },
    { q_no: 74, ques: 'Counts and gives 1 rupee note up to Rs. 5 when asked.', choices: ['yes', 'no'] },
    { q_no: 75, ques: 'Point/tells when asked by showing Rs. 1, 2, 5 notes.', choices: ['yes', 'no'] },
    { q_no: 76, ques: 'Points to at least 5 pictures of fruits when asked.', choices: ['yes', 'no'] },
    { q_no: 77, ques: 'Names at least 5 fruits when shown pictures.', choices: ['yes', 'no'] },
    {
      q_no: 78,
      ques: 'Tells/points to the clothes (frock, shorts, shirt, t-shirt) he/she wears when asked.',
      choices: ['yes', 'no'],
    },
    { q_no: 79, ques: "Uses words or gestures for 'now and later' when necessary.", choices: ['yes', 'no'] },
  ],

  'Recreational Indoor': [
    { q_no: 80, ques: 'Watches TV Advertisements.', choices: ['yes', 'no'] },
    { q_no: 81, ques: 'Dances/claps/taps with rhythm of music in Radio or TV.', choices: ['yes', 'no'] },
    { q_no: 82, ques: 'Plays with blocks - assembling and dismantling.', choices: ['yes', 'no'] },
    { q_no: 83, ques: 'Colors with pencils or crayons.', choices: ['yes', 'no'] },
    {
      q_no: 84,
      ques:
        'Plays make-believe games - pretending to be teacher, mummy, daddy or elder sibling going to school/household keeping.',
      choices: ['yes', 'no'],
    },
    { q_no: 85, ques: 'Arranges own things in their appropriate places, in a given room.', choices: ['yes', 'no'] },
    { q_no: 86, ques: 'Sees pictures in magazines without tearing the book.', choices: ['yes', 'no'] },
  ],

  Outdoor: [
    { q_no: 87, ques: 'Plays with ball.', choices: ['yes', 'no'] },
    {
      q_no: 88,
      ques: 'Plays running and catching game/runs a race/duck walking/frog jumping, etc.',
      choices: ['yes', 'no'],
    },
    { q_no: 89, ques: 'Climbs jungle gym etc.', choices: ['yes', 'no'] },
    { q_no: 90, ques: 'Sand play.', choices: ['yes', 'no'] },
    { q_no: 91, ques: 'Water play.', choices: ['yes', 'no'] },
    { q_no: 92, ques: 'Plays hide and seek game/passing the parcel/musical chairs, etc.', choices: ['yes', 'no'] },
    { q_no: 93, ques: 'Plays on a slide.', choices: ['yes', 'no'] },
    { q_no: 94, ques: 'Swings on a swing.', choices: ['yes', 'no'] },
  ],

  Occupational: [
    { q_no: 95, ques: 'Dusts with a duster the furniture in the classroom and home.', choices: ['yes', 'no'] },
    { q_no: 96, ques: 'Washes glasses and plates before and after meals.', choices: ['yes', 'no'] },
    { q_no: 97, ques: 'Wipes glasses and plates with a cloth after washing.', choices: ['yes', 'no'] },
    { q_no: 98, ques: 'Folds small clothes (such as hand towel/panties).', choices: ['yes', 'no'] },
  ],
}

const preVocation1 = {
  Personal: [
    { q_no: 1, ques: 'Stitches buttons.', choices: ['yes', 'no'] },
    { q_no: 2, ques: 'Mends his clothes with a running stitch.', choices: ['yes', 'no'] },
    { q_no: 3, ques: 'Washes hair with soap or shampoo or soap nut powder.', choices: ['yes', 'no'] },
    { q_no: 4, ques: 'When needed carries a medium size bucket with water.', choices: ['yes', 'no'] },
    {
      q_no: 5,
      ques: 'Shaves beard to present himself neat (male). Manages herself during menstruation (female).',
      choices: ['yes', 'no'],
    },
    { q_no: 6, ques: 'Washes his clothes with soap cake or powder.', choices: ['yes', 'no'] },
    { q_no: 7, ques: 'Irons his own clothes.', choices: ['yes', 'no'] },
    { q_no: 8, ques: 'Applies ointment on a cut and bandages if necessary.', choices: ['yes', 'no'] },
    { q_no: 9, ques: 'Tells/gestures illness and pain promptly to get assistance.', choices: ['yes', 'no'] },
    { q_no: 10, ques: 'Follows the medical advice without reminder during illness.', choices: ['yes', 'no'] },
    { q_no: 11, ques: 'Presents self neatly and suitably for varied occasions.', choices: ['yes', 'no'] },
  ],

  Social: [
    {
      q_no: 12,
      ques:
        'Uses bicycle or walks independently for simple errands at request within 1 km. periphery around the house.',
      choices: ['yes', 'no'],
    },
    { q_no: 13, ques: 'Assists in moving heavy furniture in the house or workplace.', choices: ['yes', 'no'] },
    { q_no: 14, ques: 'Orders items of his choice in a restaurant or a hotel.', choices: ['yes', 'no'] },
    {
      q_no: 15,
      ques:
        'Finds a toilet on his own or by asking others in new places (such as relatives or friend’s house, restaurant, cinema hall, bus station, railway station).',
      choices: ['yes', 'no'],
    },
    {
      q_no: 16,
      ques: 'Stands in a queue (for getting into a bus, buying tickets, or buying ration from a ration shop).',
      choices: ['yes', 'no'],
    },
    { q_no: 17, ques: 'Asks for directions when needed.', choices: ['yes', 'no'] },
    {
      q_no: 18,
      ques: 'Participates age appropriately in social/religious activities without attracting undue attention.',
      choices: ['yes', 'no'],
    },
    { q_no: 19, ques: 'Expresses distress when in trouble and seeks help.', choices: ['yes', 'no'] },
    { q_no: 20, ques: 'Defends self when teased/exploited by others.', choices: ['yes', 'no'] },
    { q_no: 21, ques: 'Reads essential signboards for getting the work done.', choices: ['yes', 'no'] },
    {
      q_no: 22,
      ques: 'Selects, buys, signs and sends cards to significant persons for New Year and other occasions.',
      choices: ['yes', 'no'],
    },
    { q_no: 23, ques: 'Keeps appointments.', choices: ['yes', 'no'] },
    { q_no: 24, ques: 'Tells time upon request.', choices: ['yes', 'no'] },
    {
      q_no: 25,
      ques: 'Goes to a cinema hall, stands in a queue, buys ticket to see a film.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 26,
      ques: 'Receives telephone calls and responds or passes on information when told to.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 27,
      ques: 'Tells the name of the city/district/town/village where he is living.',
      choices: ['yes', 'no'],
    },
  ],

  Academic: [
    { q_no: 28, ques: 'Reads two words phrases.', choices: ['yes', 'no'] },
    { q_no: 29, ques: 'Reads simple sentences.', choices: ['yes', 'no'] },
    { q_no: 30, ques: 'Does two digit subtraction with borrowing.', choices: ['yes', 'no'] },
    { q_no: 31, ques: 'Does two digit addition with carry over.', choices: ['yes', 'no'] },
    { q_no: 32, ques: 'Aware of table 5.', choices: ['yes', 'no'] },
    { q_no: 33, ques: 'Uses table 5 for time/money purposes.', choices: ['yes', 'no'] },
    { q_no: 34, ques: 'Writes simple sentences when required.', choices: ['yes', 'no'] },
    { q_no: 35, ques: 'Writes a list of items to buy from a general store.', choices: ['yes', 'no'] },
    { q_no: 36, ques: 'Copies a paragraph (5-6 sentences) with punctuation.', choices: ['yes', 'no'] },
    { q_no: 37, ques: 'Writes a letter.', choices: ['yes', 'no'] },
    { q_no: 38, ques: 'Sets time on watch.', choices: ['yes', 'no'] },
    { q_no: 39, ques: 'Buys things upto the value of 10 rupees.', choices: ['yes', 'no'] },
    { q_no: 40, ques: 'Gives change upto Rs.1.', choices: ['yes', 'no'] },
    { q_no: 41, ques: 'Gives change upto Rs.5.', choices: ['yes', 'no'] },
    { q_no: 42, ques: 'Gives change upto Rs.10.', choices: ['yes', 'no'] },
    { q_no: 43, ques: 'Identifies/Names 2 kg, 5 kg, 10 kg, weighing stones.', choices: ['yes', 'no'] },
    { q_no: 44, ques: 'Identifies 1/2 kg, 1/4 kg, weighing stones.', choices: ['yes', 'no'] },
    { q_no: 45, ques: 'Measures 1/2 kg, 1/4 kg rice, pulses and vegetables.', choices: ['yes', 'no'] },
    {
      q_no: 46,
      ques: 'Measures 2 kg, 5 kg, 10 kg of items such as rice, pulses, vegetables.',
      choices: ['yes', 'no'],
    },
    { q_no: 47, ques: 'Measures cloth in metres.', choices: ['yes', 'no'] },
    { q_no: 48, ques: 'Adds the prices on a receipt upto Rs.5.', choices: ['yes', 'no'] },
    { q_no: 49, ques: 'Adds the prices on a receipt upto Rs.10.', choices: ['yes', 'no'] },
    {
      q_no: 50,
      ques: 'Pays bills upto Rs.2 in any form of combination of Rs.1, 2, 5 and 10.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 51,
      ques: 'Pays bills upto Rs.5 in any form of combination of Rs.1, 2, 5, 10 and 20.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 52,
      ques: 'Indicates the body parts such as lungs, heart, kidneys, on a map or approximate place on self.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 53,
      ques: 'Describes in very simple terms the functions of lungs, heart, kidneys and blood vessels.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 54,
      ques: 'Tells the name of the Prime Minister/Chief Minister/Governor/President of India.',
      choices: ['yes', 'no'],
    },
    { q_no: 55, ques: 'Names ones own country, state.', choices: ['yes', 'no'] },
    { q_no: 56, ques: 'Rolls/Indicates different sources of water.', choices: ['yes', 'no'] },
    { q_no: 57, ques: 'Tells/Indicates the sources of milk products/oil.', choices: ['yes', 'no'] },
    { q_no: 58, ques: 'Tells/Indicates the use of disinfectants.', choices: ['yes', 'no'] },
  ],

  Occupational: [
    { q_no: 59, ques: 'Clean utensils with washing powder or liquid soap.', choices: ['yes', 'no'] },
    { q_no: 60, ques: 'Wipe floor with a wet cloth.', choices: ['yes', 'no'] },
    { q_no: 61, ques: 'Prepare tea/coffee/juice.', choices: ['yes', 'no'] },
    { q_no: 62, ques: 'Store rice and pulses in respective tins after shopping.', choices: ['yes', 'no'] },
    { q_no: 63, ques: 'Operate kerosene stove/gas stove.', choices: ['yes', 'no'] },
    {
      q_no: 64,
      ques: 'Operate kitchen appliances (such as scraper, peeler, coconut grater, mixi, grinder).',
      choices: ['yes', 'no'],
    },
    { q_no: 65, ques: 'Prepare upma, chapati, puri, idli, dosa.', choices: ['yes', 'no'] },
    { q_no: 66, ques: 'Prepare rice and dal/curries.', choices: ['yes', 'no'] },
    { q_no: 67, ques: 'Arrange dishes, plates, etc. on the dining table.', choices: ['yes', 'no'] },
    { q_no: 68, ques: 'Wash clothes with soap/surf.', choices: ['yes', 'no'] },
    { q_no: 69, ques: 'Fold clothes and stack them in almirah.', choices: ['yes', 'no'] },
    { q_no: 70, ques: 'Make bed for sleeping.', choices: ['yes', 'no'] },
    { q_no: 71, ques: 'Fetch vegetables and other household items from a nearby shop.', choices: ['yes', 'no'] },
    { q_no: 72, ques: 'Get ready to reach workplace in time.', choices: ['yes', 'no'] },
    { q_no: 73, ques: 'Describe the various transports.', choices: ['yes', 'no'] },
    { q_no: 74, ques: 'Buy a ticket from the conductor/ticket counter.', choices: ['yes', 'no'] },
    { q_no: 75, ques: 'Make/renew bus pass.', choices: ['yes', 'no'] },
    {
      q_no: 76,
      ques:
        'Fill in personal information in an application form (i.e. name, age, date of birth, address, father’s name, sex, language spoken).',
      choices: ['yes', 'no'],
    },
    { q_no: 77, ques: 'Use bank forms under guardian’s supervision when required.', choices: ['yes', 'no'] },
    { q_no: 78, ques: 'Fill railway booking form.', choices: ['yes', 'no'] },
    { q_no: 79, ques: 'Upon request, nurse family members during their illness.', choices: ['yes', 'no'] },
    { q_no: 80, ques: 'Do simple first aid (fixing band aid, applying pain balm).', choices: ['yes', 'no'] },
    { q_no: 81, ques: 'Cut vegetables appropriately to prepare different dishes.', choices: ['yes', 'no'] },
  ],

  'Recreational Indoor': [
    { q_no: 82, ques: 'Shows interest in drawing and colouring work.', choices: ['yes', 'no'] },
    {
      q_no: 83,
      ques: 'Plays carrom, card games (clearance, donkey), scrabble, Chinese checkers.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 84,
      ques: 'Participates in decorating the house for festivals or parties or special social occasions.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 85,
      ques:
        'Listens to selected music in cassette players or selects a station at given time on radio or selects appropriate channel on TV independently.',
      choices: ['yes', 'no'],
    },
    { q_no: 86, ques: 'Gets dressed and puts on make-up or any activity for beauty care.', choices: ['yes', 'no'] },
    { q_no: 87, ques: 'Arranges bouquets with natural/artificial flowers.', choices: ['yes', 'no'] },
    { q_no: 88, ques: 'Spends time with peers chatting about various social incidents.', choices: ['yes', 'no'] },
    {
      q_no: 89,
      ques: 'Involves or makes dolls, greeting cards, or any other craft activity.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 90,
      ques: 'Participates in doing needlework, embroidery, knitting/ crochet work etc.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 91,
      ques:
        'Collects photos of favorite sports/film stars/botany collection/stamp, coin collection/collecting pebbles/arranges photos in an album.',
      choices: ['yes', 'no'],
    },
  ],

  Outdoor: [
    { q_no: 92, ques: 'Goes for a hike or camping trip.', choices: ['yes', 'no'] },
    {
      q_no: 93,
      ques: 'Rides bicycle to visit friends or relatives or travels by bus to visit within 3 km distance.',
      choices: ['yes', 'no'],
    },
    { q_no: 94, ques: 'Attends a music concert/goes out for a play or a film.', choices: ['yes', 'no'] },
    { q_no: 95, ques: 'Flies kites/plays kho-kho/kabadi/hopscotch.', choices: ['yes', 'no'] },
    { q_no: 96, ques: 'Tends plants - gardening.', choices: ['yes', 'no'] },
    {
      q_no: 97,
      ques:
        'Develops/shows interest in martial skills like judo, karate, taekwondo, etc./or and practices dance/music.',
      choices: ['yes', 'no'],
    },
    { q_no: 98, ques: 'Goes out to a restaurant with peers.', choices: ['yes', 'no'] },
    { q_no: 99, ques: 'Plays racquet games (badminton, table tennis, lawn tennis, etc.).', choices: ['yes', 'no'] },
    { q_no: 100, ques: 'Plays rule governed ball games (basketball, cricket, etc.).', choices: ['yes', 'no'] },
  ],
}

const preVocation2 = {
  Personal: [
    { q_no: 1, ques: "Uses toilet when necessary without anybody's assistance.", choices: ['yes', 'no'] },
    {
      q_no: 2,
      ques: 'Takes bath (including soaping, washing and wiping with a towel) closing the door for privacy by himself.',
      choices: ['yes', 'no'],
    },
    { q_no: 3, ques: 'Washes hair independently.', choices: ['yes', 'no'] },
    { q_no: 4, ques: 'Combs hair.', choices: ['yes', 'no'] },
    { q_no: 5, ques: 'Plaits hair and puts a rubber band.', choices: ['yes', 'no'] },
    { q_no: 6, ques: 'Wears clean clothes.', choices: ['yes', 'no'] },
    { q_no: 7, ques: 'Choose clothes appropriate to the weather conditions.', choices: ['yes', 'no'] },
    { q_no: 8, ques: 'Presents self neatly dressed and groomed to go out.', choices: ['yes', 'no'] },
    { q_no: 9, ques: 'Places dirty clothes for washing.', choices: ['yes', 'no'] },
    { q_no: 10, ques: 'Cleans his own tiffin box, plate, glass and spoon after eating.', choices: ['yes', 'no'] },
    { q_no: 11, ques: 'Folds paper to fit into an envelope and seals it.', choices: ['yes', 'no'] },
    { q_no: 12, ques: 'Stitches buttons.', choices: ['yes', 'no'] },
    { q_no: 13, ques: 'Mends his clothes with a running stitch.', choices: ['yes', 'no'] },
    { q_no: 14, ques: 'When needed carries a medium size bucket with water.', choices: ['yes', 'no'] },
    { q_no: 15, ques: 'Washes his clothes with soap cake/powder.', choices: ['yes', 'no'] },
    { q_no: 16, ques: 'Irons his own clothes.', choices: ['yes', 'no'] },
    { q_no: 17, ques: 'Tells/gestures illness and pain promptly to get assistance.', choices: ['yes', 'no'] },
    { q_no: 18, ques: 'Applies ointment on a cut and bandages if necessary.', choices: ['yes', 'no'] },
  ],

  Social: [
    { q_no: 19, ques: 'Asks politely to pass on the dishes he wants while having a meal.', choices: ['yes', 'no'] },
    { q_no: 20, ques: 'Identifies himself with boys. Identifies herself with girls.', choices: ['yes', 'no'] },
    {
      q_no: 21,
      ques:
        "Goes by walk to a shop or play ground or friend's house in 1km. periphery of the neighbourhood and returns.",
      choices: ['yes', 'no'],
    },
    {
      q_no: 22,
      ques: 'Buys two items written on a chit from the shop nearby (may not know account).',
      choices: ['yes', 'no'],
    },
    { q_no: 23, ques: 'Informs the family members the arrival of a visitor.', choices: ['yes', 'no'] },
    {
      q_no: 24,
      ques: 'When required introduces himself to new people who come to his house.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 25,
      ques: 'Greets and asks relatives or family friends to be seated when they come to their house.',
      choices: ['yes', 'no'],
    },
    { q_no: 26, ques: 'Buys correct postage from the post office when told.', choices: ['yes', 'no'] },
    { q_no: 27, ques: 'Tells/gestures that he requires money to travel.', choices: ['yes', 'no'] },
    { q_no: 28, ques: 'During conversation asks relevant quess.', choices: ['yes', 'no'] },
    {
      q_no: 29,
      ques: 'Remembers information/messages and passes on appropriately to concerned person (verbal/gestural).',
      choices: ['yes', 'no'],
    },
    {
      q_no: 30,
      ques:
        'Narrates in 2-3 sentences about a past event without prompts (if nonverbal makes self understood by gestures and actions).',
      choices: ['yes', 'no'],
    },
    {
      q_no: 31,
      ques: 'Follows verbal directions to move from place to place within a building.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 32,
      ques: 'Spontaneously tries to express ideas to other people or narrates incidents.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 33,
      ques: 'Appropriately uses past, present and future tenses of verbs in sentences.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 34,
      ques: 'Speaks/expresses gesturally clearly enough to be understood by someone who is not familiar.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 35,
      ques:
        "Finds a toilet on his own or by asking others in new places (such as relative or friend's house, restaurant, cinema, hall, bus station, railway station).",
      choices: ['yes', 'no'],
    },
    {
      q_no: 36,
      ques: 'Participates age appropriately in social/religious activities without attracting undue attention.',
      choices: ['yes', 'no'],
    },
    { q_no: 37, ques: 'Expresses distress when in trouble and seeks help.', choices: ['yes', 'no'] },
    { q_no: 38, ques: 'Defends self when teased/exploited by others.', choices: ['yes', 'no'] },
    { q_no: 39, ques: 'Reads essential sign boards for getting the work done.', choices: ['yes', 'no'] },
    { q_no: 40, ques: 'Keeps appointments.', choices: ['yes', 'no'] },
    { q_no: 41, ques: 'Tells time upon request.', choices: ['yes', 'no'] },
    {
      q_no: 42,
      ques: 'Goes to a cinema hall, stands in a queue, buys ticket to see a film.',
      choices: ['yes', 'no'],
    },
  ],

  Academic: [
    {
      q_no: 43,
      ques:
        'Reads names of 10 common objects which he comes across at home and at school (words should be with simple matras).',
      choices: ['yes', 'no'],
    },
    { q_no: 44, ques: 'Reads names of week days.', choices: ['yes', 'no'] },
    { q_no: 45, ques: 'Reads names of months.', choices: ['yes', 'no'] },
    {
      q_no: 46,
      ques: 'Does addition when given either horizontally or vertically (2 + 3) within 10.',
      choices: ['yes', 'no'],
    },
    { q_no: 47, ques: 'Does subtraction within 10.', choices: ['yes', 'no'] },
    { q_no: 48, ques: 'Tells/gestures what day is today.', choices: ['yes', 'no'] },
    { q_no: 49, ques: 'Tells time when long hand is at 3, 6, 9, 12.', choices: ['yes', 'no'] },
    { q_no: 50, ques: 'Tells time with minutes in multiples of 5.', choices: ['yes', 'no'] },
    { q_no: 51, ques: 'Counts one rupee coins and notes upto Rs.10.', choices: ['yes', 'no'] },
    { q_no: 52, ques: 'Gives change in one rupee for Rs.2, Rs.5, and Rs.10.', choices: ['yes', 'no'] },
    { q_no: 53, ques: 'Reads price tags (Rs.0.50, Rs.1.25, Rs.10.00, Rs.5.35, etc.).', choices: ['yes', 'no'] },
    { q_no: 54, ques: 'Pays bills upto Rs.10 in any form of combination of Rs.1, 2and 5.', choices: ['yes', 'no'] },
    { q_no: 55, ques: 'Groups the coins (50p, 25p, 10p) to make one rupee.', choices: ['yes', 'no'] },
    { q_no: 56, ques: 'Gives upto Rs.5 by grouping into one rupee from mixed coins.', choices: ['yes', 'no'] },
    { q_no: 57, ques: 'Gives change upto Rs.1.', choices: ['yes', 'no'] },
    { q_no: 58, ques: 'Gives change upto Rs.5.', choices: ['yes', 'no'] },
    { q_no: 59, ques: 'Gives change upto Rs.10.', choices: ['yes', 'no'] },
    { q_no: 60, ques: 'Reads price tags on items (RS. 10.25, Rs. 11.15).', choices: ['yes', 'no'] },
  ],

  Occupational: [
    { q_no: 61, ques: 'Climbs a ladder to clean or dust the house or paint wall.', choices: ['yes', 'no'] },
    { q_no: 62, ques: 'Dries/Folds clothes.', choices: ['yes', 'no'] },
    { q_no: 63, ques: 'Wipes kitchen wear with a cloth after cleaning.', choices: ['yes', 'no'] },
    { q_no: 64, ques: 'Sweeps floor with a broom stick.', choices: ['yes', 'no'] },
    {
      q_no: 65,
      ques: 'Picks up items in a super market as per the written or pictorial list and carries to the cash counter.',
      choices: ['yes', 'no'],
    },
    { q_no: 66, ques: 'Identifies/names 1 litre/1 kg. measuring jars/weighing stone.', choices: ['yes', 'no'] },
    { q_no: 67, ques: 'Tells the use of balance.', choices: ['yes', 'no'] },
    { q_no: 68, ques: 'Measures 1 kg. of vegetables/ pulses.', choices: ['yes', 'no'] },
    { q_no: 69, ques: 'Identifies/Names a measuring rod/tape.', choices: ['yes', 'no'] },
    { q_no: 70, ques: 'Goes to the market/general store to buy provisions.', choices: ['yes', 'no'] },
    { q_no: 71, ques: 'Makes flower garlands using thread and needle.', choices: ['yes', 'no'] },
    { q_no: 72, ques: 'Cleans rice and other pulses.', choices: ['yes', 'no'] },
    { q_no: 73, ques: 'Washes rice, dal and vegetables when requested.', choices: ['yes', 'no'] },
    { q_no: 74, ques: 'Peels vegetables.', choices: ['yes', 'no'] },
    {
      q_no: 75,
      ques: 'Cuts vegetables that do not require peeling (bindi, brinjal, etc.).',
      choices: ['yes', 'no'],
    },
    {
      q_no: 76,
      ques: 'Collects dried leaves and twigs and dumps them at a prescribed place.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 77,
      ques: 'Decorates entrance/room with leaves, flowers and paper during festivals and social functions.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 78,
      ques: 'Draws or uses white powder to make rangoli designs (in case of girls).',
      choices: ['yes', 'no'],
    },
    { q_no: 79, ques: 'Cleans utensils with washing powder or liquid soap.', choices: ['yes', 'no'] },
    { q_no: 80, ques: 'Puts off stove when told.', choices: ['yes', 'no'] },
    { q_no: 81, ques: 'Wipes floor with a wet cloth.', choices: ['yes', 'no'] },
    { q_no: 82, ques: 'Stores rice and pulses in respective tins after shopping.', choices: ['yes', 'no'] },
    { q_no: 83, ques: 'Cuts vegetables appropriately upon request.', choices: ['yes', 'no'] },
    { q_no: 84, ques: 'Prepares tea or coffee or juice.', choices: ['yes', 'no'] },
    { q_no: 85, ques: 'Gets ready to reach the workplace in time.', choices: ['yes', 'no'] },
    { q_no: 86, ques: 'Reads directions on the street, bus route details and follows.', choices: ['yes', 'no'] },
    {
      q_no: 87,
      ques: 'Goes to the correct bus stop to travel to a given destination in familiar route.',
      choices: ['yes', 'no'],
    },
    { q_no: 88, ques: 'Identifies the bus to reach his destination.', choices: ['yes', 'no'] },
    { q_no: 89, ques: 'Buys ticket or shows bus pass to the conductor.', choices: ['yes', 'no'] },
    {
      q_no: 90,
      ques: 'Finds a seat/request people for sitting place while traveling or offers his seat to elders.',
      choices: ['yes', 'no'],
    },
    { q_no: 91, ques: 'Gets down at the correct destination.', choices: ['yes', 'no'] },
    { q_no: 92, ques: 'Lights and regulates stove (gas/kerosene - specify).', choices: ['yes', 'no'] },
  ],

  Recreational_Indoor: [
    { q_no: 93, ques: 'Shows interest in drawing and coloring work.', choices: ['yes', 'no'] },
    {
      q_no: 94,
      ques: 'Plays carrom, card games (clearance, donkey), scrabble, chinese checkers.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 95,
      ques:
        'Listens to selected music in cassette players or selecting a station at a given time on the radio or selecting an appropriate channel on T.V. as per choice, independently.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 96,
      ques: 'Participates in decorating the house for festivals or parties or special social occasions.',
      choices: ['yes', 'no'],
    },
    { q_no: 97, ques: 'Gets dressed and puts on make-up or any activity for beauty care.', choices: ['yes', 'no'] },
    { q_no: 98, ques: 'Arranges bouquets with natural/artificial flowers.', choices: ['yes', 'no'] },
    { q_no: 99, ques: 'Spends time with peers chatting about various social incidents.', choices: ['yes', 'no'] },
    {
      q_no: 100,
      ques: 'Involves or makes dolls, greeting cards, or any other craft activity.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 101,
      ques: 'Participates in doing needlework, embroidery, knitting/crochet work, etc.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 102,
      ques:
        'Collects photos of film stars/botany collection/stamp, coin collection/collecting pebbles/arranges photos in an album.',
      choices: ['yes', 'no'],
    },
  ],

  Outdoor: [
    { q_no: 103, ques: 'Goes for a hike or camping trip.', choices: ['yes', 'no'] },
    {
      q_no: 104,
      ques: 'Rides a bicycle to visit friends or relatives or travel by bus to visit within 3 km. distance.',
      choices: ['yes', 'no'],
    },
    { q_no: 105, ques: 'Attends a music concert/goes out for a play or film.', choices: ['yes', 'no'] },
    { q_no: 106, ques: 'Tends plants - gardening.', choices: ['yes', 'no'] },
    { q_no: 107, ques: 'Flies kites/plays kho-kho/kabaddi/hopscotch.', choices: ['yes', 'no'] },
    {
      q_no: 108,
      ques: 'Develops martial skills like judo, karate, taekwondo, etc. and practice of dance/music.',
      choices: ['yes', 'no'],
    },
    { q_no: 109, ques: 'Goes out to a restaurant with peers.', choices: ['yes', 'no'] },
    {
      q_no: 110,
      ques: 'Plays racquet games (badminton, table tennis, lawn tennis, etc.).',
      choices: ['yes', 'no'],
    },
    { q_no: 111, ques: 'Plays rule governed ball games (basketball, cricket, etc.).', choices: ['yes', 'no'] },
  ],
}

const primary1 = {
  Personal: [
    { q_no: 1, ques: 'Walks by himself a distance of 10 feet to fetch an object.', choices: ['yes', 'no'] },
    {
      q_no: 2,
      ques: 'Climbs up and down the stairs placing both feet on each step without support.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 3,
      ques: 'Turns/removes a door knob/latch/bolt and opens the door when he wants to go in/out of the room.',
      choices: ['yes', 'no'],
    },
    { q_no: 4, ques: 'Cleans self after defecation.', choices: ['yes', 'no'] },
    { q_no: 5, ques: 'Flushes or pours water after toileting.', choices: ['yes', 'no'] },
    { q_no: 6, ques: 'Wears clothes (including under garments)(may not fix fastener).', choices: ['yes', 'no'] },
    {
      q_no: 7,
      ques:
        'Unfastens clothes: a) zip, b) press buttons, c) shirt buttons, d) hooks, e) untying / tape / lace / ribbon.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 8,
      ques: 'Fastens clothes: a) zip, b) press buttons, c) shirt buttons, d) hooks, e) tying/tape/lace/ribbon.',
      choices: ['yes', 'no'],
    },
    { q_no: 9, ques: 'Eats by self a complete meal without spilling.', choices: ['yes', 'no'] },
    {
      q_no: 10,
      ques: 'Asks/points to curries (vegetable, dal, sambar, etc.) when he wants more.',
      choices: ['yes', 'no'],
    },
    { q_no: 11, ques: 'Makes arrangements for lunch and dinner either on table/floor.', choices: ['yes', 'no'] },
    {
      q_no: 12,
      ques: 'Washes hands before eating snacks or food or after using toilet or when hands are dirty.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 13,
      ques: 'Climbs up and down the stairs placing alternate feet without support.',
      choices: ['yes', 'no'],
    },
    { q_no: 14, ques: 'Takes water from the pot/tap to drink when he is thirsty.', choices: ['yes', 'no'] },
    { q_no: 15, ques: 'Serves water to others.', choices: ['yes', 'no'] },
    { q_no: 16, ques: 'Washes face with soap and water.', choices: ['yes', 'no'] },
    { q_no: 17, ques: 'Applies powder (and fixes bindi - in case of girls).', choices: ['yes', 'no'] },
    { q_no: 18, ques: 'Applies soap on the body while bathing.', choices: ['yes', 'no'] },
    { q_no: 19, ques: 'Wipes hands and face with a towel after washing.', choices: ['yes', 'no'] },
    { q_no: 20, ques: 'Cleans nose with a handkerchief when needed.', choices: ['yes', 'no'] },
    {
      q_no: 21,
      ques: 'Combs/brushes hair and puts rubber band after removing tangled hair (in case of girls).',
      choices: ['yes', 'no'],
    },
  ],

  Social: [
    { q_no: 22, ques: 'Performs the activity requested by teachers and parents.', choices: ['yes', 'no'] },
    {
      q_no: 23,
      ques: 'When given a chance makes a choice between two activities or between two items.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 24,
      ques: 'Asks for permission to use a toy or other material which does not belong to him.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 25,
      ques: 'Participates in a group game in which 4 to 5 children are involved.',
      choices: ['yes', 'no'],
    },
    { q_no: 26, ques: 'Waits for his turn while playing games.', choices: ['yes', 'no'] },
    { q_no: 27, ques: 'Shares play material during group games.', choices: ['yes', 'no'] },
    { q_no: 28, ques: 'Maintains appropriate manners when taken to social functions.', choices: ['yes', 'no'] },
    {
      q_no: 29,
      ques: 'Takes care of his own belongings in school (Eg.School bag, lunch box, pencil box).',
      choices: ['yes', 'no'],
    },
    { q_no: 30, ques: 'Defends/seeks assistance when injured or teased by others.', choices: ['yes', 'no'] },
    { q_no: 31, ques: 'Recognizes that the speaker is angry, tired, sad, happy, etc.', choices: ['yes', 'no'] },
    { q_no: 32, ques: 'Names body parts when pointed to.', choices: ['yes', 'no'] },
    {
      q_no: 33,
      ques: 'Uses combination of words or gestures to express his need or wish.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 34,
      ques: 'Answers (verbally/ gesturally) to ques ‘Whose is’ by pointing to self or others correctly.',
      choices: ['yes', 'no'],
    },
    { q_no: 35, ques: 'Carries out two consecutive instructions/commands.', choices: ['yes', 'no'] },
    { q_no: 36, ques: 'Names members of the family like brother, sister, aunt, uncle.', choices: ['yes', 'no'] },
    {
      q_no: 37,
      ques: 'Uses 2-3 word sentence to communicate information (if nonverbal makes self understood by gestures).',
      choices: ['yes', 'no'],
    },
    { q_no: 38, ques: 'Asks for help (verbally or gesturally) for personal needs.', choices: ['yes', 'no'] },
    {
      q_no: 39,
      ques:
        'Follows messages involving prepositions such as under, behind, in front. (Put the bag in front of the boy).',
      choices: ['yes', 'no'],
    },
    {
      q_no: 40,
      ques:
        "Uses correct gender term when asked. Are you (or pointed person) a boy or girl'? Can say plural forms of names. Eg.Chairs, pencils, books.",
      choices: ['yes', 'no'],
    },
    {
      q_no: 41,
      ques: 'Can follow instructions of a task without visual clues or gestures.',
      choices: ['yes', 'no'],
    },
  ],

  Academic: [
    {
      q_no: 42,
      ques:
        'Reads alphabets (English or any other regional language) when shown a chart/book. (Words incase of sight word teaching).',
      choices: ['yes', 'no'],
    },
    {
      q_no: 43,
      ques: 'Reads words (vegetables, fruits, furniture, animals) seeing a picture.',
      choices: ['yes', 'no'],
    },
    { q_no: 44, ques: 'Reads 2 - 3 letter words without a clue (picture) (about 10).', choices: ['yes', 'no'] },
    { q_no: 45, ques: 'Reads his name.', choices: ['yes', 'no'] },
    { q_no: 46, ques: "Reads his friend's names in his class.", choices: ['yes', 'no'] },
    {
      q_no: 47,
      ques: 'Reads four sign boards which are essential for independent mobility.',
      choices: ['yes', 'no'],
    },
    { q_no: 48, ques: 'Writes his name.', choices: ['yes', 'no'] },
    { q_no: 49, ques: "Reads his friend's names in his class.", choices: ['yes', 'no'] },
    {
      q_no: 50,
      ques: 'Writes names of words (vegetables, fruits, animals, furniture, clothes).',
      choices: ['yes', 'no'],
    },
    { q_no: 51, ques: 'Tells functions of sense organs.', choices: ['yes', 'no'] },
    { q_no: 52, ques: 'Counts and gives objects up to 10 when not asked sequentially.', choices: ['yes', 'no'] },
    { q_no: 53, ques: 'Shows a numeral when not asked sequentially up to 10.', choices: ['yes', 'no'] },
    { q_no: 54, ques: 'Names numbers up to 10 when not asked sequentially.', choices: ['yes', 'no'] },
    { q_no: 55, ques: 'Writes numerals up to 10 when given dictation randomly.', choices: ['yes', 'no'] },
    { q_no: 56, ques: 'Writes numerals up to 10 in a logical sequence.', choices: ['yes', 'no'] },
    { q_no: 57, ques: 'Writes missing numbers before and after up to 10.', choices: ['yes', 'no'] },
    { q_no: 58, ques: 'Does simple addition within 10 with objects (without paper).', choices: ['yes', 'no'] },
    { q_no: 59, ques: 'Does simple addition within 10 on paper using slash marks.', choices: ['yes', 'no'] },
    { q_no: 60, ques: 'Gives exact number of objects when asked up to 20.', choices: ['yes', 'no'] },
    { q_no: 61, ques: 'Expands the numbers from 11-20 (Eg. 11 = 10 & 1).', choices: ['yes', 'no'] },
    { q_no: 62, ques: 'Points to numerals up to 20 when not asked sequentially.', choices: ['yes', 'no'] },
    { q_no: 63, ques: 'Names numbers up to 20 when not asked sequentially.', choices: ['yes', 'no'] },
    { q_no: 64, ques: 'Writes numerals up to 20 when given dictation.', choices: ['yes', 'no'] },
    { q_no: 65, ques: 'Writes numerals up to 20 in a sequence.', choices: ['yes', 'no'] },
    { q_no: 66, ques: 'Writes missing numbers after and before up to 20.', choices: ['yes', 'no'] },
    { q_no: 67, ques: 'Counts in tens up to 100 (10, 20, 30,. 100).', choices: ['yes', 'no'] },
    { q_no: 68, ques: 'Groups in tens to make 20, 30,. 100.', choices: ['yes', 'no'] },
    { q_no: 69, ques: 'Associates time with daily activities.', choices: ['yes', 'no'] },
    {
      q_no: 70,
      ques:
        'Tells/shows on a calendar/chart the name of the day when asked what day is today, what day will be tomorrow and what day was yesterday.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 71,
      ques: 'Tells/gestures in order what he does from morning till he goes to bed.',
      choices: ['yes', 'no'],
    },
    { q_no: 72, ques: 'Tells his age/points to self when asked who is ....', choices: ['yes', 'no'] },
    { q_no: 73, ques: 'Tells/gestures the number of days in a week.', choices: ['yes', 'no'] },
    { q_no: 74, ques: 'Tells the number of months in a year.', choices: ['yes', 'no'] },
    { q_no: 75, ques: 'Indicates the date on the calendar.', choices: ['yes', 'no'] },
    { q_no: 76, ques: 'Changes the date and month on a calendar stand correctly.', choices: ['yes', 'no'] },
    { q_no: 77, ques: 'Tells the position of long and short hand on a clock when asked.', choices: ['yes', 'no'] },
    { q_no: 78, ques: 'Shows on a clock time in hours when asked.', choices: ['yes', 'no'] },
    { q_no: 79, ques: 'Tell time in hours seeing a clock/wrist watch.', choices: ['yes', 'no'] },
    { q_no: 80, ques: 'Shows on a clock the time in half hours (7.30, 8.30).', choices: ['yes', 'no'] },
    { q_no: 81, ques: 'Tells time in half hour (4.30, 5.30) seeing a clock wrist watch.', choices: ['yes', 'no'] },
    { q_no: 82, ques: 'Gets ready to come to school in time.', choices: ['yes', 'no'] },
    { q_no: 83, ques: 'Identifies coins (1 rupee, 50p, 25p, 10p, 5p).', choices: ['yes', 'no'] },
    { q_no: 84, ques: 'Tells names of coins.', choices: ['yes', 'no'] },
    { q_no: 85, ques: 'Identifies rupee notes (Rs.1, 2 up to 10).', choices: ['yes', 'no'] },
    { q_no: 86, ques: 'Counts 1 rupee notes to give a sum of Rs.8, 10, 15, 20, etc.', choices: ['yes', 'no'] },
    { q_no: 87, ques: 'Groups Rs.10 notes to give 20, 30, 40, 100.', choices: ['yes', 'no'] },
    { q_no: 88, ques: 'Gives change to Rs.10 using 5 rupee notes (5 + 5).', choices: ['yes', 'no'] },
    {
      q_no: 89,
      ques: 'Gives change to Rs. 10, 20, 50 using a combination of Rs. 5 & Rs. 10 notes.',
      choices: ['yes', 'no'],
    },
    { q_no: 90, ques: 'Tells names of rupee notes.', choices: ['yes', 'no'] },
    {
      q_no: 91,
      ques: 'Tells how many cups/glasses of water, rice/dall are required to fill a given utensil.',
      choices: ['yes', 'no'],
    },
    { q_no: 92, ques: 'Measures the required cups of water for making tea.', choices: ['yes', 'no'] },
    { q_no: 93, ques: 'Posts letter in the post box.', choices: ['yes', 'no'] },
    { q_no: 94, ques: 'Tells his parents/family members when he feels sick.', choices: ['yes', 'no'] },
    {
      q_no: 95,
      ques:
        'Identifies the various means of transport (cycle, auto, cycle rickshaw, bus, car, lorry, aeroplane, train).',
      choices: ['yes', 'no'],
    },
    { q_no: 96, ques: 'Names various means of transport.', choices: ['yes', 'no'] },
    {
      q_no: 97,
      ques: 'Gets in and gets down from the desired bus when bus stops in the bus stop.',
      choices: ['yes', 'no'],
    },
  ],

  Occupational: [
    {
      q_no: 98,
      ques: 'Dusts with a duster cloth the furniture and the other items in the house.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 99,
      ques: 'Sorts out vegetables and places them in respective container/baskets/in a fridge.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 100,
      ques: 'Places plates and glasses suitably on the dining table/floor for breakfast/lunch/dinner, when told.',
      choices: ['yes', 'no'],
    },
    { q_no: 101, ques: 'Washes plates and glasses after eating food/snacks.', choices: ['yes', 'no'] },
    {
      q_no: 102,
      ques: 'Peels vegetables such as beans, onions, boiled potatoes, peas, etc. where knife/scrapper are not used.',
      choices: ['yes', 'no'],
    },
  ],

  Recreational_Indoor: [
    {
      q_no: 103,
      ques: 'Plays common games like snakes & ladder, five stones, with 2-3 children (with only 2 specific rules).',
      choices: ['yes', 'no'],
    },
    { q_no: 104, ques: 'Watches T.V. programmes for 15 to 30 minutes.', choices: ['yes', 'no'] },
    { q_no: 105, ques: 'Builds blocks to copy a given model (3 to 8 pieces).', choices: ['yes', 'no'] },
    { q_no: 106, ques: 'Draws simple figures and colours them.', choices: ['yes', 'no'] },
    {
      q_no: 107,
      ques:
        'Cuts and pastes pictures from old magazines to make a scrap book/collage work (when assisted while cutting).',
      choices: ['yes', 'no'],
    },
    { q_no: 108, ques: 'Collects stamps/stickers.', choices: ['yes', 'no'] },
    { q_no: 109, ques: 'Assembles (Logo) games/puzzle.', choices: ['yes', 'no'] },
    { q_no: 110, ques: 'Looks through picture books or comics.', choices: ['yes', 'no'] },
    { q_no: 111, ques: 'Arranges room, by placing objects in their respective place.', choices: ['yes', 'no'] },
    { q_no: 112, ques: 'Feeds and cares for pets (under supervision).', choices: ['yes', 'no'] },
  ],

  Outdoor: [
    { q_no: 113, ques: 'Plays by passing/throwing and catching the ball.', choices: ['yes', 'no'] },
    { q_no: 114, ques: 'Flies kites, plays marble games.', choices: ['yes', 'no'] },
    {
      q_no: 115,
      ques: 'Plays games like, police and thief, land and sea, four corners or seven stones.',
      choices: ['yes', 'no'],
    },
    { q_no: 116, ques: 'Waters plants.', choices: ['yes', 'no'] },
    {
      q_no: 117,
      ques: "Goes for a walk outside or visit friend's house alone in same area/colony of living.",
      choices: ['yes', 'no'],
    },
    { q_no: 118, ques: "Plays aiming of darts at the bull's eye/bucketing the ball, etc.", choices: ['yes', 'no'] },
    { q_no: 119, ques: 'Climbs jungle gym, trees or other similar structures.', choices: ['yes', 'no'] },
    { q_no: 120, ques: 'Plays hopping game governed by rules.', choices: ['yes', 'no'] },
    { q_no: 121, ques: 'Goes with adults for shopping.', choices: ['yes', 'no'] },
    { q_no: 122, ques: 'Goes out to restaurant/theatres with adults.', choices: ['yes', 'no'] },
  ],
}

const primary2 = {
  Personal: [
    { q_no: 1, ques: 'Walks by himself a distance of 10 feet to fetch an object.', choices: ['yes', 'no'] },
    {
      q_no: 2,
      ques: 'Climbs up and down the stairs placing alternate feet without support.',
      choices: ['yes', 'no'],
    },
    { q_no: 3, ques: 'Unlatches a door to open when he wants to go in/out of the room.', choices: ['yes', 'no'] },
    { q_no: 4, ques: 'Holds and drinks liquids from a glass or cup.', choices: ['yes', 'no'] },
    { q_no: 5, ques: 'Takes water from the pot/tap to drink when he is thirsty.', choices: ['yes', 'no'] },
    { q_no: 6, ques: 'Serves water to others.', choices: ['yes', 'no'] },
    {
      q_no: 7,
      ques: 'Eats appropriately with the side dish such as bread & jam, chapati and curry, Idli & chutney.',
      choices: ['yes', 'no'],
    },
    { q_no: 8, ques: 'Mixes and eats by self without spilling.', choices: ['yes', 'no'] },
    {
      q_no: 9,
      ques: 'Makes arrangements (placing plates, cup/glass) for lunch and dinner on table/floor.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 10,
      ques: 'Asks for curries (vegetable, dal, sambar, etc.) when he wants more.',
      choices: ['yes', 'no'],
    },
    { q_no: 11, ques: 'Sits on potty or squats to pass urine or stools.', choices: ['yes', 'no'] },
    {
      q_no: 12,
      ques: 'Indicates verbally or through gestures the need to go to the toilet.',
      choices: ['yes', 'no'],
    },
    { q_no: 13, ques: 'Takes off under clothes to use toilet.', choices: ['yes', 'no'] },
    { q_no: 14, ques: 'Cleans self after defecation.', choices: ['yes', 'no'] },
    { q_no: 15, ques: 'Flushes or pours water after toileting.', choices: ['yes', 'no'] },
    {
      q_no: 16,
      ques: 'Brushes teeth with tooth brush/with a finger using tooth paste/tooth powder.',
      choices: ['yes', 'no'],
    },
    { q_no: 17, ques: 'Takes off shirt/pant/frock, etc when unbuttoned.', choices: ['yes', 'no'] },
    { q_no: 18, ques: 'Wears clothes (including under garments) except buttoning.', choices: ['yes', 'no'] },
    {
      q_no: 19,
      ques: 'Unfastens clothes: a) zip, b) press buttons, c) shirt buttons, d) hooks, e) untying/tape/lace/ribbon.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 20,
      ques: 'Fastens clothes: a) zip, b) press buttons, c) shirt buttons, d) hooks, e) tying/tape/lace/ribbon.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 21,
      ques: 'Washes hands before eating snacks or food or after using toilet or when hands are dirty.',
      choices: ['yes', 'no'],
    },
    { q_no: 22, ques: 'Washes face with soap and water.', choices: ['yes', 'no'] },
    { q_no: 23, ques: 'Wipes hands and face after washing.', choices: ['yes', 'no'] },
    { q_no: 24, ques: 'Cleans nose with a handkerchief when needed.', choices: ['yes', 'no'] },
    { q_no: 25, ques: 'Applies soap on the body while bathing.', choices: ['yes', 'no'] },
    { q_no: 26, ques: 'Wipes self with a towel after bath.', choices: ['yes', 'no'] },
    { q_no: 27, ques: 'Applies powder (and fixes bindi - in case of girls).', choices: ['yes', 'no'] },
    {
      q_no: 28,
      ques: 'Combs/brushes hair (puts rubber band after removing tangled hair in case of girls).',
      choices: ['yes', 'no'],
    },
  ],
  Social: [
    { q_no: 29, ques: 'Smiles in response to another person’s smile at him.', choices: ['yes', 'no'] },
    {
      q_no: 30,
      ques: "Stops momentarily an activity when said 'no’ (at least half a number of times).",
      choices: ['yes', 'no'],
    },
    { q_no: 31, ques: 'Waits for his turn in the classroom/play ground/dining room.', choices: ['yes', 'no'] },
    {
      q_no: 32,
      ques: 'Shares his things (pencil, books, eraser, toys and eatables) when requested by his classmates or others.',
      choices: ['yes', 'no'],
    },
    { q_no: 33, ques: 'Greets teachers or elders in school or at home.', choices: ['yes', 'no'] },
    {
      q_no: 34,
      ques: 'When given a chance makes a choice between two activities or between two items.',
      choices: ['yes', 'no'],
    },
    { q_no: 35, ques: 'Performs the activity requested by teachers and parents.', choices: ['yes', 'no'] },
    {
      q_no: 36,
      ques: 'Participates in a group game in which 4 to 5 children are involved.',
      choices: ['yes', 'no'],
    },
    { q_no: 37, ques: 'Waits for his turn while playing games.', choices: ['yes', 'no'] },
    { q_no: 38, ques: 'Shares play material during group games.', choices: ['yes', 'no'] },
    { q_no: 39, ques: 'Maintains appropriate manners when taken to functions.', choices: ['yes', 'no'] },
    {
      q_no: 40,
      ques: 'Takes care of his own belongings in school (Eg. school bag, lunch box, pencil box).',
      choices: ['yes', 'no'],
    },
    { q_no: 41, ques: 'Asks for assistance when injured by others.', choices: ['yes', 'no'] },
    { q_no: 42, ques: 'Expresses anger or displeasure by vocalization.', choices: ['yes', 'no'] },
    {
      q_no: 43,
      ques: 'Uses vocal sounds/gestures/actions/words to call a person/to get attention of others.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 44,
      ques:
        'Says/Uses gestures such as shaking head appropriately for ‘no’, and ‘yes’, hand gesture for ‘come’, ‘give’, ‘sleep’, ‘tata’.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 45,
      ques:
        'Follows simple verbal requests with gestures such as ‘give’, ‘I will take’ (response could be gestural or verbal).',
      choices: ['yes', 'no'],
    },
    { q_no: 46, ques: 'Uses words such as ‘mama’, ‘nana’, ‘deedi’, ‘bow bow’, etc.', choices: ['yes', 'no'] },
    {
      q_no: 47,
      ques:
        'Follows simple verbal commands without gestures like ‘Where is the ball’, ‘Put that down’, ‘Bring the ball’.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 48,
      ques: 'Uses combination of words or gestures to express his need or wish.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 49,
      ques: 'Answers (verbally/gesturally) to ques ‘Whose is’ by pointing to self or others correctly.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 50,
      ques: 'Identifies persons by pointing to them or naming upon request (eg. Uncle, aunty, sister, brother, etc.).',
      choices: ['yes', 'no'],
    },
    { q_no: 51, ques: 'Asks for desired objects using gesture vocalization.', choices: ['yes', 'no'] },
    { q_no: 52, ques: 'Names members of the family like brother, sister, aunt, uncle.', choices: ['yes', 'no'] },
    {
      q_no: 53,
      ques: 'Uses 2-3 word sentences to state information (if nonverbal makes self understood by gestures).',
      choices: ['yes', 'no'],
    },
    { q_no: 54, ques: 'Asks for help (verbally or gesturally) for personal needs.', choices: ['yes', 'no'] },
    { q_no: 55, ques: 'Follows instructions of a task without visual clues or gestures.', choices: ['yes', 'no'] },
  ],
  Academic: [
    {
      q_no: 56,
      ques:
        'Points to 10 common objects with which he has to interact in his/her home environment (eg. rice, dall, chapati, light, fan, mat, table, chair).',
      choices: ['yes', 'no'],
    },
    { q_no: 57, ques: 'Says orally the names of the 10 common objects when shown.', choices: ['yes', 'no'] },
    { q_no: 58, ques: 'Holds pencil and scribbles.', choices: ['yes', 'no'] },
    { q_no: 59, ques: 'Colours with a crayon within a given diagram.', choices: ['yes', 'no'] },
    { q_no: 60, ques: 'Traces on the given diagram.', choices: ['yes', 'no'] },
    { q_no: 61, ques: 'Joins dots to form pictures.', choices: ['yes', 'no'] },
    { q_no: 62, ques: 'Copies a given figure/diagram.', choices: ['yes', 'no'] },
    {
      q_no: 63,
      ques: 'Groups objects according to the size (big and small, long and short).',
      choices: ['yes', 'no'],
    },
    { q_no: 64, ques: 'Points out the objects which are big and small.', choices: ['yes', 'no'] },
    { q_no: 65, ques: 'Counts to give objects up to 10.', choices: ['yes', 'no'] },
    {
      q_no: 66,
      ques: 'Reads numbers up to 10 including pointing the numbers in between when asked.',
      choices: ['yes', 'no'],
    },
    { q_no: 67, ques: 'Tells/gestures day or night when asked.', choices: ['yes', 'no'] },
    { q_no: 68, ques: 'Tells/points the names of week days in a logical order.', choices: ['yes', 'no'] },
    { q_no: 69, ques: 'Identifies a clock or wrist watch.', choices: ['yes', 'no'] },
    { q_no: 70, ques: 'Tells/points to when asked the use of clock or wrist watch.', choices: ['yes', 'no'] },
    { q_no: 71, ques: 'Differentiates money from other objects.', choices: ['yes', 'no'] },
    { q_no: 72, ques: 'Tells/gestures when asked for the use of money.', choices: ['yes', 'no'] },
    { q_no: 73, ques: 'Shows long and short objects when asked.', choices: ['yes', 'no'] },
    {
      q_no: 74,
      ques: 'Tells/gestures which one has more/less quantity of things/liquids.',
      choices: ['yes', 'no'],
    },
    { q_no: 75, ques: 'Points/Names to at least 5 pictures of animals when asked.', choices: ['yes', 'no'] },
    {
      q_no: 76,
      ques: 'Tells/points to the clothes (frock, nikkar, shirt, T-shirt) he/she wears when asked.',
      choices: ['yes', 'no'],
    },
    { q_no: 77, ques: "Uses words or gestures for 'now and later' when necessary.", choices: ['yes', 'no'] },
    { q_no: 78, ques: 'Points/Names to at least 5 pictures of fruits when asked.', choices: ['yes', 'no'] },
    {
      q_no: 79,
      ques:
        'Names/gestures the various means of transport (cycle, auto, cycle rickshaw, bus, car, lorry, aeroplane, train).',
      choices: ['yes', 'no'],
    },
    { q_no: 80, ques: 'Reads his name.', choices: ['yes', 'no'] },
    { q_no: 81, ques: 'Writes his name.', choices: ['yes', 'no'] },
    { q_no: 82, ques: 'Associates time with daily activities.', choices: ['yes', 'no'] },
    {
      q_no: 83,
      ques: 'Tells/gestures sequentially what he does from morning till he goes to bed.',
      choices: ['yes', 'no'],
    },
  ],
  Occupational: [
    {
      q_no: 84,
      ques: 'Dusts with a duster cloth the furniture and the other items in the house.',
      choices: ['yes', 'no'],
    },
    { q_no: 85, ques: 'Washes glasses and plates before and after meals.', choices: ['yes', 'no'] },
    {
      q_no: 86,
      ques: 'Sorts out vegetables and places them in respective container/fridge.',
      choices: ['yes', 'no'],
    },
    { q_no: 87, ques: 'Gets in/out of bus independently when told.', choices: ['yes', 'no'] },
    { q_no: 88, ques: 'Waters plants using a water can/hose pipe.', choices: ['yes', 'no'] },
    { q_no: 89, ques: 'Sweeps floor with a broom stick.', choices: ['yes', 'no'] },
    { q_no: 90, ques: 'Eats without calling the attention of others.', choices: ['yes', 'no'] },
    { q_no: 91, ques: 'Cleans rices and other pulses.', choices: ['yes', 'no'] },
    { q_no: 92, ques: 'Prepares Coffee/tea/juice.', choices: ['yes', 'no'] },
    { q_no: 93, ques: 'Prepares simple snacks such as, sandwich, upma, chapatis.', choices: ['yes', 'no'] },
    { q_no: 94, ques: 'Cuts Vegetables into small pieces.', choices: ['yes', 'no'] },
    { q_no: 95, ques: 'Peals potatoes, cucumber and other vegetables when required.', choices: ['yes', 'no'] },
    { q_no: 96, ques: 'Prepares salad.', choices: ['yes', 'no'] },
  ],
  Recreational_Indoor: [
    {
      q_no: 97,
      ques: 'Plays common games like snakes & ladder, five stones, with 2-3 children (with only 2 specific rules).',
      choices: ['yes', 'no'],
    },
    { q_no: 98, ques: 'Watches T.V. programmes for 15 to 30 minutes.', choices: ['yes', 'no'] },
    { q_no: 99, ques: 'Builds blocks to copy a given model (3 to 8 pieces).', choices: ['yes', 'no'] },
    { q_no: 100, ques: 'Draws simple figures and colours them.', choices: ['yes', 'no'] },
    {
      q_no: 101,
      ques:
        'Cuts and pastes pictures from old magazines to make a scrap book/collage work (when assisted while cutting).',
      choices: ['yes', 'no'],
    },
    { q_no: 102, ques: 'Collects stamps/stickers.', choices: ['yes', 'no'] },
    { q_no: 103, ques: 'Assembles (Lego) games/puzzle.', choices: ['yes', 'no'] },
    { q_no: 104, ques: 'Looks through picture books or comics.', choices: ['yes', 'no'] },
    { q_no: 105, ques: 'Arranges room, by placing objects in their respective place.', choices: ['yes', 'no'] },
    { q_no: 106, ques: 'Feeds and cares for pets (under supervision).', choices: ['yes', 'no'] },
  ],
  Recreational_Outdoor: [
    { q_no: 107, ques: 'Plays by passing/throwing and catching the ball.', choices: ['yes', 'no'] },
    { q_no: 108, ques: 'Flies kites, plays marble games.', choices: ['yes', 'no'] },
    {
      q_no: 109,
      ques: 'Plays games like, police and thief, land and sea, four corners or seven stones.',
      choices: ['yes', 'no'],
    },
    { q_no: 110, ques: 'Waters plants.', choices: ['yes', 'no'] },
    {
      q_no: 111,
      ques: 'Goes for a walk outside or visit friends house alone in same colony/area.',
      choices: ['yes', 'no'],
    },
    { q_no: 112, ques: "Plays aiming of darts at the bull's eye/bucketing the ball, etc.", choices: ['yes', 'no'] },
    { q_no: 113, ques: 'Climbs jungle gym, trees or other similar structures.', choices: ['yes', 'no'] },
    { q_no: 114, ques: 'Plays hopping game governed by rules.', choices: ['yes', 'no'] },
    { q_no: 115, ques: 'Goes with adults for shopping.', choices: ['yes', 'no'] },
    { q_no: 116, ques: 'Goes out to restaurant/theatres with adults.', choices: ['yes', 'no'] },
  ],
}

const secondary = {
  Personal: [
    {
      q_no: 1,
      ques: 'Folds paper (note book size) into four parts (like folding an inland).',
      choices: ['yes', 'no'],
    },
    { q_no: 2, ques: 'Folds paper to fit into an envelope and seals it.', choices: ['yes', 'no'] },
    {
      q_no: 3,
      ques: "Uses toilet on his/her own when necessary without anybody's assistance.",
      choices: ['yes', 'no'],
    },
    {
      q_no: 4,
      ques:
        'Takes a bath (including soaping, washing, and wiping with a towel) closing the door for privacy by himself.',
      choices: ['yes', 'no'],
    },
    { q_no: 5, ques: 'Combs hair.', choices: ['yes', 'no'] },
    { q_no: 6, ques: 'Combs hair and puts a rubber band.', choices: ['yes', 'no'] },
    { q_no: 7, ques: 'Plaits hair and puts a rubber band.', choices: ['yes', 'no'] },
    { q_no: 8, ques: 'Applies powder (and bindi, kajal also in case of girls).', choices: ['yes', 'no'] },
    { q_no: 9, ques: 'Wears clean clothes.', choices: ['yes', 'no'] },
    { q_no: 10, ques: 'Chooses clothes appropriate to the weather conditions.', choices: ['yes', 'no'] },
    { q_no: 11, ques: 'Dresses and grooms suitably to go out.', choices: ['yes', 'no'] },
    { q_no: 12, ques: 'Places dirty clothes for washing.', choices: ['yes', 'no'] },
    { q_no: 13, ques: 'Cleans his own tiffin box, plate, glass, and spoon after eating.', choices: ['yes', 'no'] },
    { q_no: 14, ques: 'Informs the family members of the arrival of a visitor.', choices: ['yes', 'no'] },
    { q_no: 15, ques: 'Identifies himself with boys/identifies herself with girls.', choices: ['yes', 'no'] },
    { q_no: 16, ques: 'Tells to which group he/she belongs.', choices: ['yes', 'no'] },
  ],
  Social: [
    { q_no: 17, ques: 'Asks politely to pass on the dishes he wants while having a meal.', choices: ['yes', 'no'] },
    {
      q_no: 18,
      ques:
        "Goes by walk to a shop or playground or friend's house within a 1km perimeter of the neighborhood and returns.",
      choices: ['yes', 'no'],
    },
    {
      q_no: 19,
      ques: 'Buys two items written on a chit from the shop near his house (may not know account).',
      choices: ['yes', 'no'],
    },
    {
      q_no: 20,
      ques: 'When required, introduces himself to new people who come to his house.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 21,
      ques: 'Greets and asks relatives or family friends to be seated when they come to their house.',
      choices: ['yes', 'no'],
    },
    { q_no: 22, ques: 'Reads directions on the street/bus route details and follows.', choices: ['yes', 'no'] },
    { q_no: 23, ques: 'Reads signboards.', choices: ['yes', 'no'] },
    { q_no: 24, ques: 'Reads the price labels on items (Rs.10.25, Rs.11.15).', choices: ['yes', 'no'] },
    { q_no: 25, ques: 'Buys correct postage from the post office when told.', choices: ['yes', 'no'] },
    {
      q_no: 26,
      ques: 'Tells/gestures that he requires money to travel by bus, auto, taxi, train, and airplane.',
      choices: ['yes', 'no'],
    },
    { q_no: 27, ques: 'Points out/tells items of his choice in the restaurant.', choices: ['yes', 'no'] },
    { q_no: 28, ques: 'During conversation, asks relevant quess.', choices: ['yes', 'no'] },
    {
      q_no: 29,
      ques: 'Remembers information/messages and passes on appropriately to the concerned person (verbal/gestural).',
      choices: ['yes', 'no'],
    },
    {
      q_no: 30,
      ques:
        'Narrates in 2-3 sentences about a past event without prompts (if nonverbal makes self understood by gestures and actions).',
      choices: ['yes', 'no'],
    },
    {
      q_no: 31,
      ques: 'Follows verbal directions to move from place to place within a building.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 32,
      ques: 'Spontaneously tries to express ideas to other people or narrates incidents.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 33,
      ques: 'Appropriately uses past, present, and future tenses of verbs in sentences.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 34,
      ques: 'Speaks/expresses gesturally clearly enough to be understood by someone who is not familiar.',
      choices: ['yes', 'no'],
    },
  ],
  Academic: [
    {
      q_no: 35,
      ques: 'Reads names of vegetables, fruits, ingredients, pulses, clothes, etc.',
      choices: ['yes', 'no'],
    },
    { q_no: 36, ques: 'Reads names of weekdays.', choices: ['yes', 'no'] },
    { q_no: 37, ques: 'Reads names of months.', choices: ['yes', 'no'] },
    {
      q_no: 38,
      ques: 'Does simple addition (one digit) when given either vertically or horizontally (2 + 3).',
      choices: ['yes', 'no'],
    },
    { q_no: 39, ques: 'Does simple subtraction (one digit).', choices: ['yes', 'no'] },
    { q_no: 40, ques: 'Does two-digit addition without carry over.', choices: ['yes', 'no'] },
    { q_no: 41, ques: 'Does two-digit subtraction without borrowing.', choices: ['yes', 'no'] },
    { q_no: 42, ques: 'Able to relate the computations to daily living situations.', choices: ['yes', 'no'] },
    {
      q_no: 43,
      ques: 'Writes names of at least 5 vegetables, fruits, clothes, ingredients, pulses. (Specify)',
      choices: ['yes', 'no'],
    },
    { q_no: 44, ques: 'Writes names of weekdays when dictated.', choices: ['yes', 'no'] },
    { q_no: 45, ques: 'Writes names of month names when dictated.', choices: ['yes', 'no'] },
    { q_no: 46, ques: 'Writes two-word phrases when dictated.', choices: ['yes', 'no'] },
    { q_no: 47, ques: 'Writes date, month, and year in his notebook/board.', choices: ['yes', 'no'] },
    {
      q_no: 48,
      ques: 'Writes date, month, and year while writing a leave letter application form/cheque.',
      choices: ['yes', 'no'],
    },
    { q_no: 49, ques: 'Tells/gestures what day is today.', choices: ['yes', 'no'] },
    { q_no: 50, ques: 'Tells time when the long hand is at 3, 6, 9, 12.', choices: ['yes', 'no'] },
    { q_no: 51, ques: 'Tells time with minutes in multiples of 5.', choices: ['yes', 'no'] },
    { q_no: 52, ques: 'Counts one rupee coins and notes up to Rs.10.', choices: ['yes', 'no'] },
    { q_no: 53, ques: 'Gives change in one rupee for Rs.2, Rs.5, and Rs.10.', choices: ['yes', 'no'] },
    {
      q_no: 54,
      ques: 'Pays bills up to Rs.10 in any form of combination of Rs.1, 2, and 5.',
      choices: ['yes', 'no'],
    },
    { q_no: 55, ques: 'Groups the coins (50p, 25p, 10p) to make one rupee.', choices: ['yes', 'no'] },
    { q_no: 56, ques: 'Gives up to Rs.5 by grouping into one rupee from mixed coins.', choices: ['yes', 'no'] },
    { q_no: 57, ques: 'Groups coins (20p, 10p, 5p) to make change such as 35p, 45p, 65p.', choices: ['yes', 'no'] },
    { q_no: 58, ques: 'Gives change up to Rs.1.', choices: ['yes', 'no'] },
    { q_no: 59, ques: 'Gives change up to Rs.5.', choices: ['yes', 'no'] },
    { q_no: 60, ques: 'Gives change up to Rs.10.', choices: ['yes', 'no'] },
    { q_no: 61, ques: 'Writes amounts larger than one rupee with decimals.', choices: ['yes', 'no'] },
    { q_no: 62, ques: 'Does addition with carry-over (3 and more lines 2 digits).', choices: ['yes', 'no'] },
    { q_no: 63, ques: 'Does subtraction involving borrowing (2 digits/3 digits).', choices: ['yes', 'no'] },
    { q_no: 64, ques: 'Identifies/names 1 litre/1 kg. measuring jars/weighing stone.', choices: ['yes', 'no'] },
    { q_no: 65, ques: 'Tells the use of balance.', choices: ['yes', 'no'] },
    { q_no: 66, ques: 'Measures kg. of vegetables and pulses.', choices: ['yes', 'no'] },
    { q_no: 67, ques: 'Identifies/names a measuring rod/tape.', choices: ['yes', 'no'] },
  ],
  Occupational: [
    { q_no: 68, ques: 'Climbs a ladder to clean or dust the house or paint a wall.', choices: ['yes', 'no'] },
    { q_no: 69, ques: 'Wipes kitchenware with a cloth after cleaning.', choices: ['yes', 'no'] },
    { q_no: 70, ques: 'Sweeps floor with a broomstick.', choices: ['yes', 'no'] },
    { q_no: 71, ques: 'Eats without calling the attention of others.', choices: ['yes', 'no'] },
    {
      q_no: 72,
      ques:
        'Picks up items in a supermarket as per the written or pictorial list and carries them to the cash counter.',
      choices: ['yes', 'no'],
    },
    { q_no: 73, ques: 'Reads bills, receipts.', choices: ['yes', 'no'] },
    { q_no: 74, ques: 'Writes small notes, shopping list when dictated.', choices: ['yes', 'no'] },
    { q_no: 75, ques: 'Takes phone messages.', choices: ['yes', 'no'] },
    { q_no: 76, ques: 'Goes to the market/general store to buy provisions.', choices: ['yes', 'no'] },
    { q_no: 77, ques: 'Makes flower garlands using thread and needle.', choices: ['yes', 'no'] },
    { q_no: 78, ques: 'Cleans rice and other pulses.', choices: ['yes', 'no'] },
    { q_no: 79, ques: 'Prepares coffee/tea/juice.', choices: ['yes', 'no'] },
    { q_no: 80, ques: 'Prepares simple snacks such as, sandwich, upma, chapati.', choices: ['yes', 'no'] },
    { q_no: 81, ques: 'Cuts vegetables into small pieces.', choices: ['yes', 'no'] },
    { q_no: 82, ques: 'Peels potatoes, cucumber and other vegetables when required.', choices: ['yes', 'no'] },
    { q_no: 83, ques: 'Prepares a salad.', choices: ['yes', 'no'] },
    { q_no: 84, ques: 'Lights gas stove/kerosene on her own.', choices: ['yes', 'no'] },
    { q_no: 85, ques: 'Serves breakfast to family members.', choices: ['yes', 'no'] },
    { q_no: 86, ques: 'Arranges dishes on the dining table before lunch and dinner.', choices: ['yes', 'no'] },
    { q_no: 87, ques: 'Cleans the table after eating food.', choices: ['yes', 'no'] },
    {
      q_no: 88,
      ques: 'Dusts furniture, cupboards, and other items in the house with a duster.',
      choices: ['yes', 'no'],
    },
    { q_no: 89, ques: 'Wipes floor with a wet cloth.', choices: ['yes', 'no'] },
    { q_no: 90, ques: 'Washes utensils and puts them in respective places.', choices: ['yes', 'no'] },
    { q_no: 91, ques: 'Spreads clothes on a clothesline after washing.', choices: ['yes', 'no'] },
    { q_no: 92, ques: 'Folds clothes after drying and places them in almirah.', choices: ['yes', 'no'] },
    { q_no: 93, ques: 'Folds bed sheets and places them in proper place.', choices: ['yes', 'no'] },
    { q_no: 94, ques: 'Makes the bed on his own.', choices: ['yes', 'no'] },
    { q_no: 95, ques: 'Washes rice, dal, and vegetables when requested.', choices: ['yes', 'no'] },
    {
      q_no: 96,
      ques: 'Decorates entrance/room with leaves, flowers, and paper during festivals and social functions.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 97,
      ques: 'Draws or uses white powder to make rangoli designs (in case of girls).',
      choices: ['yes', 'no'],
    },
    { q_no: 98, ques: 'Makes flower arrangements in flower vases.', choices: ['yes', 'no'] },
    {
      q_no: 99,
      ques: 'Goes to the correct bus stop to travel to a given destination on a familiar route.',
      choices: ['yes', 'no'],
    },
    { q_no: 100, ques: 'Identifies the bus to reach his destination.', choices: ['yes', 'no'] },
    { q_no: 101, ques: 'Buys a ticket or shows a bus pass to the conductor.', choices: ['yes', 'no'] },
    {
      q_no: 102,
      ques:
        'Finds a seat/requests people for a sitting place while traveling in bus/train or offers his seat to elders.',
      choices: ['yes', 'no'],
    },
    { q_no: 103, ques: 'Gets down at the correct destination.', choices: ['yes', 'no'] },
  ],
  'Recreational Indoor': [
    { q_no: 104, ques: 'Watches TV serials and follows the story sequence.', choices: ['yes', 'no'] },
    { q_no: 105, ques: 'Plays games like ludo, Chinese checkers, snakes and ladders.', choices: ['yes', 'no'] },
    {
      q_no: 106,
      ques: 'Selects a specific cassette or radio station and operates a record player/radio independently.',
      choices: ['yes', 'no'],
    },
    { q_no: 107, ques: 'Plays a musical instrument or sings.', choices: ['yes', 'no'] },
    {
      q_no: 108,
      ques: 'Dances/claps/taps with a tune when music is played (or shows signs of enjoying music).',
      choices: ['yes', 'no'],
    },
    { q_no: 109, ques: 'Arranges flowers in vases.', choices: ['yes', 'no'] },
    { q_no: 110, ques: 'Cares for pets.', choices: ['yes', 'no'] },
    {
      q_no: 111,
      ques: 'Practises crafts (doll making, greeting card making, origami, cut work on paper, needlework).',
      choices: ['yes', 'no'],
    },
  ],
  'Recreational Outdoor': [
    { q_no: 112, ques: 'Goes out for a picnic with family or friends (3-4 persons).', choices: ['yes', 'no'] },
    { q_no: 113, ques: 'Involves in plant care and gardening.', choices: ['yes', 'no'] },
    {
      q_no: 114,
      ques: 'Goes out to see a film, accompanied by one or more family members.',
      choices: ['yes', 'no'],
    },
    {
      q_no: 115,
      ques: 'Plays racquet games/cricket/other rule-governed games with a ball (specify).',
      choices: ['yes', 'no'],
    },
    { q_no: 116, ques: 'Goes out for a walk (accompanied).', choices: ['yes', 'no'] },
    {
      q_no: 117,
      ques: 'Participates in social gatherings organized by friends and relatives (accompanied).',
      choices: ['yes', 'no'],
    },
  ],
}

const careGroup = {
  Personal: [
    {
      q_no: 1,
      ques: 'Cries or makes body movement or gestures when he/she is hungry/thirsty.',
      choices: ['yes', 'no'],
    },
    { q_no: 2, ques: 'Holds given objects in the hand momentarily.', choices: ['yes', 'no'] },
    { q_no: 3, ques: 'Chews and swallows food if fed.', choices: ['yes', 'no'] },
    { q_no: 4, ques: 'Sucks water if fed.', choices: ['yes', 'no'] },
    {
      q_no: 5,
      ques: 'Eats dry foods like biscuits, chapati piece by self (may use modified aids).',
      choices: ['yes', 'no'],
    },
    { q_no: 6, ques: 'Cries or makes body movement or gesture when gets wet.', choices: ['yes', 'no'] },
    { q_no: 7, ques: 'Cries or makes body movement or gestures for toilet need.', choices: ['yes', 'no'] },
    { q_no: 8, ques: 'Holds object when given in hand and manipulates with awareness.', choices: ['yes', 'no'] },
    { q_no: 9, ques: 'Indicates need for changing dress/bed.', choices: ['yes', 'no'] },
    {
      q_no: 10,
      ques: 'Makes gestures/sound when required to be cleaned (mouth cleaning, drooling, etc.).',
      choices: ['yes', 'no'],
    },
    { q_no: 11, ques: 'Stretches hands and legs while dressing/bathing/powdering.', choices: ['yes', 'no'] },
    {
      q_no: 12,
      ques: 'Cries/makes gestures/body movements/facial expression when gets pain/fever/any distress.',
      choices: ['yes', 'no'],
    },
    { q_no: 13, ques: 'Reaches for and holds objects and manipulates meaningfully.', choices: ['yes', 'no'] },
    { q_no: 14, ques: 'Looks at/points to objects he wants.', choices: ['yes', 'no'] },
    { q_no: 15, ques: 'Smiles in response to others greetings/talking to him.', choices: ['yes', 'no'] },
    { q_no: 16, ques: 'Makes gestures to say namaste.', choices: ['yes', 'no'] },
    {
      q_no: 17,
      ques: 'Moves from place to place (rolls, creeps, crawls, any other specify).',
      choices: ['yes', 'no'],
    },
    { q_no: 18, ques: 'Turns head/looks/smiles at people when called by name.', choices: ['yes', 'no'] },
    { q_no: 19, ques: 'Says ‘yes’ or ‘no’ by nodding head/waving hands.', choices: ['yes', 'no'] },
    { q_no: 20, ques: 'Shows special preference mother/caretaker/familiar persons.', choices: ['yes', 'no'] },
    { q_no: 21, ques: 'Watches other children playing.', choices: ['yes', 'no'] },
    {
      q_no: 22,
      ques: 'Keeps quiet/shows signs of enjoyment when taken out (park, shops, temple, etc.).',
      choices: ['yes', 'no'],
    },
    { q_no: 23, ques: 'Listens to music if music is on.', choices: ['yes', 'no'] },
    { q_no: 24, ques: 'Sits for at least 10-15 minutes if made to sit with support.', choices: ['yes', 'no'] },
    { q_no: 25, ques: 'Watches TV if made to sit in the TV room.', choices: ['yes', 'no'] },
    { q_no: 26, ques: 'Waves hands to say bye (may do it with assistance/prompts).', choices: ['yes', 'no'] },
  ],
  'Recreational Indoor': [
    { q_no: 27, ques: 'Watches TV advertisement.', choices: ['yes', 'no'] },
    { q_no: 28, ques: 'Listens to music.', choices: ['yes', 'no'] },
    { q_no: 29, ques: 'Swings and sways body with the rhythm of music in Radio or T.V.', choices: ['yes', 'no'] },
    { q_no: 30, ques: 'Plays with blocks/squeaky rubber toys.', choices: ['yes', 'no'] },
    { q_no: 31, ques: 'Makes strokes with pencils or crayons when assisted.', choices: ['yes', 'no'] },
    { q_no: 32, ques: 'Looks at pictures in magazines when shown.', choices: ['yes', 'no'] },
    { q_no: 33, ques: 'Plays with dolls.', choices: ['yes', 'no'] },
  ],
  Outdoor: [
    { q_no: 34, ques: 'Plays with a ball.', choices: ['yes', 'no'] },
    {
      q_no: 35,
      ques: 'Enjoys rounds (when carried/driven taken fir for walk) in the garden or open yard around.',
      choices: ['yes', 'no'],
    },
    { q_no: 36, ques: 'Sand play.', choices: ['yes', 'no'] },
    { q_no: 37, ques: 'Watches and enjoys children playing in the outdoor.', choices: ['yes', 'no'] },
    { q_no: 38, ques: 'Enjoys going for a ride on cycle, scooter, bus or car.', choices: ['yes', 'no'] },
  ],
}

const ISSA = {
  'SOCIAL RELATIONSHIP AND RECIPROCITY': [
    {
      q_no: 1,
      ques: 'Has poor eye contact',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 2,
      ques: 'Lacks social smile',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 3,
      ques: 'Remains aloof',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 4,
      ques: 'Does not reach out to others',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 5,
      ques: 'Unable to relate to people',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 6,
      ques: 'Unable to respond to social/ environmental cues',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 7,
      ques: 'Engages in solitary and repetitive play activities',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 8,
      ques: 'Unable to take turns in social interaction',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 9,
      ques: 'Does not maintain peer relationships',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
  ],
  'EMOTIONAL RESPONSIVENESS': [
    {
      q_no: 10,
      ques: 'Shows inappropriate emotional response',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 11,
      ques: 'Shows exaggerated emotions',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 12,
      ques: 'Engages in self-stimulating emotions',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 13,
      ques: 'Lacks fear of danger',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 14,
      ques: 'Excited or agitated for no apparent reason',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
  ],
  'SPEECH-LANGUAGE AND COMMUNICATION': [
    {
      q_no: 15,
      ques: 'Acquired speech and lost it',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 16,
      ques: 'Has difficulty in using non-verbal language or gestures to communicate',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 17,
      ques: 'Engages in stereotyped and repetitive use of language',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 18,
      ques: 'Engages in echolalic speech',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 19,
      ques: 'Produces infantile squeals/ unusual noises',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 20,
      ques: 'Unable to initiate or sustain conversation with other',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 21,
      ques: 'Uses jargon or meaningless words',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 22,
      ques: 'Uses pronoun reversals',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 23,
      ques: 'Unable to grasp pragmatics of communication real meaning',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
  ],
  'BEHAVIOUR PATTERNS': [
    {
      q_no: 24,
      ques: 'Engages in stereotyped and repetitive motor mannerisms',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 25,
      ques: 'Shows attachment to inanimate objects',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 26,
      ques: 'Shows hyperactivity/ restlessness',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 27,
      ques: 'Exhibits aggressive behavior',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 28,
      ques: 'Throws temper tantrums',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 29,
      ques: 'Engages in self-injurious behavior',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 30,
      ques: 'Insists on sameness',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
  ],
  'SENSORY ASPECTS': [
    {
      q_no: 31,
      ques: 'Unusually sensitive to sensory stimuli',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 32,
      ques: 'Stares into space for long periods of time',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 33,
      ques: 'Has difficulty in tracking objects',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 34,
      ques: 'Has unusual vision',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 35,
      ques: 'Insensitive to pain',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 36,
      ques: 'Responds to objects/people unusually by smelling, touching or tasting',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
  ],
  'COGNITIVE COMPONENT': [
    {
      q_no: 37,
      ques: 'Inconsistent attention and concentration',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 38,
      ques: 'Shows delay in responding ',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 39,
      ques: 'Has unusual memory of some kind ',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
    {
      q_no: 40,
      ques: 'Has ‘savant’ ability',
      choices: ['Rarely', 'Sometimes', 'Frequently', 'Mostly', 'Always'],
    },
  ],
}

const ADHD = [
  {
    q_no: 1,
    ques: 'Is the child restless and overactive?',
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
  {
    q_no: 2,
    ques: 'Is the child excitable and impulsive?',
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
  {
    q_no: 3,
    ques: 'Does the child disturb other children?',
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
  {
    q_no: 4,
    ques: 'Does the child often fail to finish things? (short attention span)',
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
  {
    q_no: 5,
    ques: 'Does the child fidget?',
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
  {
    q_no: 6,
    ques: 'Is the child easily distractible?',
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
  {
    q_no: 7,
    ques: "Does the child demand that needs must be met immediately and get frustrated if they're not?",
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
  {
    q_no: 8,
    ques: 'Does the child cry frequently?',
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
  {
    q_no: 9,
    ques: "Does the child's mood change quickly?",
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
  {
    q_no: 10,
    ques: 'Does the child exhibit temper outbursts (explosive and unpredictable behavior)?',
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
]

const Wender = [
  {
    q_no: 1,
    ques: 'active restless always on the go',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 2,
    ques: 'afraid of things',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 3,
    ques: 'concentration problems easily distracted',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 4,
    ques: 'anxious worrying',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 5,
    ques: 'nervous fidgety',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 6,
    ques: 'inattentive daydreaming',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 7,
    ques: 'hot- or short-tempered low boiling point',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 8,
    ques: 'shy sensitive',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 9,
    ques: 'temper outbursts tantrums',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 10,
    ques: 'trouble with stick-to-it-liveness not following through. Failing to finish things started',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 11,
    ques: 'stubborn strong-willed',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 12,
    ques: 'sad or blue depressed unhappy',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 13,
    ques: 'incautious dare-devilish involved in pranks',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 14,
    ques: 'not getting a kick out of things dissatisfied with life',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 15,
    ques: 'disobedient with parents rebellious sassy',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 16,
    ques: 'low opinion of myself',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 17,
    ques: 'irritable',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 18,
    ques: 'outgoing friendly enjoys company of people',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 19,
    ques: 'sloppy disorganized',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 20,
    ques: 'moody ups and downs',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 21,
    ques: 'angry',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 22,
    ques: 'friends popular',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 23,
    ques: 'well-organized tidy neat',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 24,
    ques: 'acting without thinking impulsive',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 25,
    ques: 'tendency to be immature',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 26,
    ques: 'guilty feelings regretful',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 27,
    ques: 'losing control of myself',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 28,
    ques: 'tendency to be or act irrational',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 29,
    ques: "unpopular with other children didn't keep friends for long didn't get along with other children",
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 30,
    ques: 'poorly coordinated did not participate in sports',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 31,
    ques: 'afraid of losing control of self',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 32,
    ques: 'well-coordinated picked first in games',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 33,
    ques: 'tomboyish (for women only)',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 34,
    ques: 'running away from home',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 35,
    ques: 'getting into fights',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 36,
    ques: 'teasing other children',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 37,
    ques: 'leader bossy',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 38,
    ques: 'difficulty getting awake',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 39,
    ques: 'follower led around too much',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 40,
    ques: "trouble seeing things from someone else's point of view",
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 41,
    ques: "trouble with authorities trouble with school visits to principal's office",
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 42,
    ques: 'trouble with police booked convicted',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 43,
    ques: 'headaches',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 44,
    ques: 'stomachaches',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 45,
    ques: 'constipation',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 46,
    ques: 'diarrhea',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 47,
    ques: 'food allergies',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 48,
    ques: 'other allergies',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 49,
    ques: 'bedwetting',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 50,
    ques: 'overall a good student last',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 51,
    ques: 'overall a poor student slow learner',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 52,
    ques: 'slow in learning to read',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 53,
    ques: 'slow reader',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 54,
    ques: 'trouble reversing letters',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 55,
    ques: 'problems with spelling',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 56,
    ques: 'trouble with mathematics or numbers',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 57,
    ques: 'bad handwriting',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 58,
    ques: 'able to read pretty well but never really enjoyed reading',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 59,
    ques: 'not achieving up to potential',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: true,
  },
  {
    q_no: 60,
    ques: 'repeating grades',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
  {
    q_no: 61,
    ques: 'suspended or expelled',
    choices: ['not at all', 'mildly', 'moderately', 'quite a bit', 'very much'],
    isADHD: false,
  },
]

const ADDH = [
  {
    q_no: 1,
    ques: 'I have trouble concentrating on one thing at a time.',
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
  {
    q_no: 2,
    ques: 'My mind wanders.',
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
  {
    q_no: 3,
    ques: 'I have trouble keeping my thoughts organized.',
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
  {
    q_no: 4,
    ques: "I can't stick with things for more than a few minutes.",
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
  {
    q_no: 5,
    ques: 'I lose track of what I am supposed to be doing.',
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
  {
    q_no: 6,
    ques: 'I get distracted easily.',
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
  {
    q_no: 7,
    ques: 'It takes a lot of effort to get my schoolwork done.',
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
  {
    q_no: 8,
    ques: 'I tend to learn more slowly than I would like.',
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
  {
    q_no: 9,
    ques: 'I have trouble organizing my schoolwork.',
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
  {
    q_no: 10,
    ques: "I don't make much effort at my schoolwork.",
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
  {
    q_no: 11,
    ques: 'I am behind on my studies.',
    choices: ['not at all', 'just a little', 'pretty much', 'very much'],
  },
]

const PSC = [
  {
    q_no: 1,
    ques: 'complains of aches or pains',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 2,
    ques: 'spends more time alone',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 3,
    ques: 'tires easily little energy',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 4,
    ques: 'fidgety unable to sit still',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 5,
    ques: 'has trouble with a teacher',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 6,
    ques: 'less interested in school',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 7,
    ques: 'acts as if driven by a motor',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 8,
    ques: 'daydreams too much',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 9,
    ques: 'distracted easily',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 10,
    ques: 'is afraid of new situations',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 11,
    ques: 'feels sad or unhappy',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 12,
    ques: 'is irritable or angry',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 13,
    ques: 'feels hopeless',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 14,
    ques: 'has trouble concentrating',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 15,
    ques: 'less interested in friends',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 16,
    ques: 'fights with other children',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 17,
    ques: 'absent from school',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 18,
    ques: 'school grades dropping',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 19,
    ques: 'is down on himself or herself',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 20,
    ques: 'visits physician but physician finds nothing wrong',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 21,
    ques: 'has trouble with sleeping',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 22,
    ques: 'worries a lot',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 23,
    ques: 'wants to be with you more than before',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 24,
    ques: 'feels he or she is bad',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 25,
    ques: 'takes unnecessary risks',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 26,
    ques: 'gets hurt frequently',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 27,
    ques: 'seems to be having less fun',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 28,
    ques: 'acts younger than children his or her age',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 29,
    ques: 'does not listen to rules',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 30,
    ques: 'does not show feelings',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 31,
    ques: "does not understand other people's feelings",
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 32,
    ques: 'teases others',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 33,
    ques: 'blames others for his or her troubles',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 34,
    ques: 'takes things that do not belong to him or her',
    choices: ['never', 'sometimes', 'often'],
  },
  {
    q_no: 35,
    ques: 'refuses to share',
    choices: ['never', 'sometimes', 'often'],
  },
]

export {
  prePrimary,
  preVocation1,
  preVocation2,
  primary1,
  primary2,
  secondary,
  careGroup,
  ISSA,
  ADHD,
  Wender,
  ADDH,
  PSC,
}

import { AiOutlineTeam } from 'react-icons/ai'
import { FaGamepad } from 'react-icons/fa'
import { GiBrain } from 'react-icons/gi'
import { RxDashboard } from 'react-icons/rx'

let navObj = [
  {
    link: '/',
    image: (
      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M16.3753 6.29337L8.72812 0.999163C8.62973 0.931032 8.51291 0.894531 8.39323 0.894531C8.27356 0.894531 8.15673 0.931032 8.05834 0.999163L0.411157 6.29337C0.282868 6.38219 0.195116 6.51833 0.167206 6.67185C0.139295 6.82536 0.173513 6.98368 0.262331 7.11197C0.351149 7.24026 0.487291 7.32801 0.640809 7.35592C0.794326 7.38383 0.952644 7.34961 1.08093 7.2608L1.92254 6.67814V13.2478C1.92305 13.7157 2.10914 14.1642 2.43998 14.4951C2.77082 14.8259 3.21939 15.012 3.68727 15.0125H13.0992C13.5671 15.012 14.0156 14.8259 14.3465 14.4951C14.6773 14.1642 14.8634 13.7157 14.8639 13.2478V6.67814L15.7055 7.2608C15.8338 7.34961 15.9921 7.38383 16.1457 7.35592C16.2992 7.32801 16.4353 7.24026 16.5241 7.11197C16.613 6.98368 16.6472 6.82536 16.6193 6.67185C16.5913 6.51833 16.5036 6.38219 16.3753 6.29337Z'
          fill='#CFCFCF'
        />
      </svg>
    ),
    title: 'Overview',
    isGroup: false,
  },
  {
    link: '/school',
    image: (
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15.2 6.4H12V4.8L8.4 3.36V1.6H10.4V0H7.6V3.36L4 4.8V6.4H0.8C0.36 6.4 0 6.76 0 7.2V16H6.4V12H9.6V16H16V7.2C16 6.76 15.64 6.4 15.2 6.4ZM4.8 14.4H1.6V12H4.8V14.4ZM4.8 10.4H1.6V8H4.8V10.4ZM8 4.8C8.44 4.8 8.8 5.16 8.8 5.6C8.8 6.04 8.44 6.4 8 6.4C7.56 6.4 7.2 6.04 7.2 5.6C7.2 5.16 7.56 4.8 8 4.8ZM9.6 10.4H6.4V8H9.6V10.4ZM14.4 14.4H11.2V12H14.4V14.4ZM14.4 10.4H11.2V8H14.4V10.4Z'
          fill='white'
        />
      </svg>
    ),
    title: 'School',
    isGroup: false,
  },
  {
    link: '/report',
    image: (
      <svg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.94039 4.78889C6.94039 3.14896 7.79601 1.69917 9.07943 0.867188H1.87814C1.21266 0.867188 0.666016 1.41383 0.666016 2.07931V14.5094C0.666016 15.1748 1.21266 15.7215 1.87814 15.7215H11.9554C12.6209 15.7215 13.1675 15.1748 13.1675 14.5094V9.20944C12.6684 9.37581 12.1455 9.47088 11.6226 9.47088C9.03203 9.47088 6.94039 7.37937 6.94039 4.78862V4.78889ZM3.94574 13.8679H2.75738V7.14197H3.94574V13.8679ZM6.32245 13.8679H5.13409V8.568H6.32245V13.8679ZM8.69916 13.8679H7.5108V10.0177H8.69916V13.8679ZM11.0759 13.8679H9.88751V11.4912H11.0759V13.8679Z'
          fill='white'
        />
      </svg>
    ),
    title: 'Report',
    isGroup: false,
  },
  {
    link: '/personalised',
    image: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='currentColor'
        class='bi bi-person-badge'
        viewBox='0 0 16 16'
      >
        <path d='M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z' />
        <path d='M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z' />
      </svg>
    ),
    title: 'Personalize',
    isGroup: false,
  },

  {
    link: '/add',
    image: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='currentColor'
        class='bi bi-file-earmark-plus-fill'
        viewBox='0 0 16 16'
      >
        <path d='M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0z' />
      </svg>
    ),
    title: 'Add Child',
    isGroup: false,
  },
  {
    link: '/logintype',
    image: (
      <svg data-name='Layer 1' id='Layer_1' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'>
        <path d='M412.23,309.71,478,375.47l-65.77,65.77L391,420l31.42-31.42c-38.94-4.53-73-17.09-103.54-38.05-34.33-23.59-60.12-54.78-85.07-84.94-48.48-58.64-94.28-114-199.79-114V121.5c53.64,0,98.75,13.08,137.89,40C206.22,185.07,232,216.26,257,246.42c42,50.83,82.06,99.21,160.69,111.19L391,331ZM351.08,176.79c20.1-11.27,42.1-18.67,66.55-22.39L391,181l21.25,21.25L478,136.53,412.23,70.76,391,92l31.4,31.4c-31.74,3.67-60.11,12.65-86,27.17-17.86,10-42.89,26.69-78.23,67l22.61,19.8C298.79,216.82,321.25,193.52,351.08,176.79ZM139.67,335.21c-30.28,17-64.85,25.24-105.67,25.24V390.5c46.11,0,85.48-9.51,120.37-29.08,33.8-19,58.51-44.52,78.23-67L210,274.59C192,295.18,169.5,318.48,139.67,335.21Z' />
      </svg>
    ),
    title: 'Switch',
    isGroup: false,
  },

  {
    title: 'Personality Insights',
    isGroup: false,
    link: '/profile',
    image: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        enable-background='new 0 0 24 24'
        height='24'
        viewBox='0 0 24 24'
        width='24'
      >
        <g>
          <rect fill='none' height='24' width='24' />
        </g>
        <g>
          <g>
            <path d='M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z' />
            <polygon points='15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93' />
            <polygon points='3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9' />
          </g>
        </g>
      </svg>
    ),
  },
  {
    title: 'Subject Wise',
    isGroup: false,
    link: '/subject',
    image: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        enable-background='new 0 0 24 24'
        height='24'
        viewBox='0 0 24 24'
        width='24'
      >
        <g>
          <rect fill='none' height='24' width='24' />
        </g>
        <g>
          <g>
            <path d='M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z' />
            <polygon points='15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93' />
            <polygon points='3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9' />
          </g>
        </g>
      </svg>
    ),
  },

  {
    title: 'Brain Reports',
    sublinks: [
      {
        link: '/overview',
        image: <RxDashboard />,
        title: 'Dashboard',
        isGroup: false,
      },
      {
        link: '/brain',
        image: <GiBrain />,
        title: 'Brain',
        isGroup: false,
      },
      {
        link: '/aggregate',
        image: '',
        title: 'Aggregate',
        isGroup: false,
      },
      {
        link: '/brain_report',
        image: '',
        title: 'Brain Report',
        isGroup: false,
      },
    ],
    isGroup: true,
  },
]

let demo = [
  {
    link: '/',
    image: (
      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M16.3753 6.29337L8.72812 0.999163C8.62973 0.931032 8.51291 0.894531 8.39323 0.894531C8.27356 0.894531 8.15673 0.931032 8.05834 0.999163L0.411157 6.29337C0.282868 6.38219 0.195116 6.51833 0.167206 6.67185C0.139295 6.82536 0.173513 6.98368 0.262331 7.11197C0.351149 7.24026 0.487291 7.32801 0.640809 7.35592C0.794326 7.38383 0.952644 7.34961 1.08093 7.2608L1.92254 6.67814V13.2478C1.92305 13.7157 2.10914 14.1642 2.43998 14.4951C2.77082 14.8259 3.21939 15.012 3.68727 15.0125H13.0992C13.5671 15.012 14.0156 14.8259 14.3465 14.4951C14.6773 14.1642 14.8634 13.7157 14.8639 13.2478V6.67814L15.7055 7.2608C15.8338 7.34961 15.9921 7.38383 16.1457 7.35592C16.2992 7.32801 16.4353 7.24026 16.5241 7.11197C16.613 6.98368 16.6472 6.82536 16.6193 6.67185C16.5913 6.51833 16.5036 6.38219 16.3753 6.29337Z'
          fill='#CFCFCF'
        />
      </svg>
    ),
    title: 'Overview',
    isGroup: false,
  },
  {
    link: '/school',
    image: (
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15.2 6.4H12V4.8L8.4 3.36V1.6H10.4V0H7.6V3.36L4 4.8V6.4H0.8C0.36 6.4 0 6.76 0 7.2V16H6.4V12H9.6V16H16V7.2C16 6.76 15.64 6.4 15.2 6.4ZM4.8 14.4H1.6V12H4.8V14.4ZM4.8 10.4H1.6V8H4.8V10.4ZM8 4.8C8.44 4.8 8.8 5.16 8.8 5.6C8.8 6.04 8.44 6.4 8 6.4C7.56 6.4 7.2 6.04 7.2 5.6C7.2 5.16 7.56 4.8 8 4.8ZM9.6 10.4H6.4V8H9.6V10.4ZM14.4 14.4H11.2V12H14.4V14.4ZM14.4 10.4H11.2V8H14.4V10.4Z'
          fill='white'
        />
      </svg>
    ),
    title: 'School',
    isGroup: false,
  },
  {
    link: '/report',
    image: (
      <svg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.94039 4.78889C6.94039 3.14896 7.79601 1.69917 9.07943 0.867188H1.87814C1.21266 0.867188 0.666016 1.41383 0.666016 2.07931V14.5094C0.666016 15.1748 1.21266 15.7215 1.87814 15.7215H11.9554C12.6209 15.7215 13.1675 15.1748 13.1675 14.5094V9.20944C12.6684 9.37581 12.1455 9.47088 11.6226 9.47088C9.03203 9.47088 6.94039 7.37937 6.94039 4.78862V4.78889ZM3.94574 13.8679H2.75738V7.14197H3.94574V13.8679ZM6.32245 13.8679H5.13409V8.568H6.32245V13.8679ZM8.69916 13.8679H7.5108V10.0177H8.69916V13.8679ZM11.0759 13.8679H9.88751V11.4912H11.0759V13.8679Z'
          fill='white'
        />
      </svg>
    ),
    title: 'Report',
    isGroup: false,
  },
]

let hr = [
  {
    link: '/overview',
    image: (
      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M16.3753 6.29337L8.72812 0.999163C8.62973 0.931032 8.51291 0.894531 8.39323 0.894531C8.27356 0.894531 8.15673 0.931032 8.05834 0.999163L0.411157 6.29337C0.282868 6.38219 0.195116 6.51833 0.167206 6.67185C0.139295 6.82536 0.173513 6.98368 0.262331 7.11197C0.351149 7.24026 0.487291 7.32801 0.640809 7.35592C0.794326 7.38383 0.952644 7.34961 1.08093 7.2608L1.92254 6.67814V13.2478C1.92305 13.7157 2.10914 14.1642 2.43998 14.4951C2.77082 14.8259 3.21939 15.012 3.68727 15.0125H13.0992C13.5671 15.012 14.0156 14.8259 14.3465 14.4951C14.6773 14.1642 14.8634 13.7157 14.8639 13.2478V6.67814L15.7055 7.2608C15.8338 7.34961 15.9921 7.38383 16.1457 7.35592C16.2992 7.32801 16.4353 7.24026 16.5241 7.11197C16.613 6.98368 16.6472 6.82536 16.6193 6.67185C16.5913 6.51833 16.5036 6.38219 16.3753 6.29337Z'
          fill='#CFCFCF'
        />
      </svg>
    ),
    title: 'Overview',
    isGroup: false,
  },

  {
    title: 'Personality Insights',
    isGroup: false,
    link: '/profile',
    image: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        enable-background='new 0 0 24 24'
        height='24'
        viewBox='0 0 24 24'
        width='24'
      >
        <g>
          <rect fill='none' height='24' width='24' />
        </g>
        <g>
          <g>
            <path d='M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z' />
            <polygon points='15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93' />
            <polygon points='3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9' />
          </g>
        </g>
      </svg>
    ),
  },
  {
    title: 'Subject Wise',
    isGroup: false,
    link: '/subject',
    image: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        enable-background='new 0 0 24 24'
        height='24'
        viewBox='0 0 24 24'
        width='24'
      >
        <g>
          <rect fill='none' height='24' width='24' />
        </g>
        <g>
          <g>
            <path d='M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z' />
            <polygon points='15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93' />
            <polygon points='3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9' />
          </g>
        </g>
      </svg>
    ),
  },

  {
    link: '/activities',
    image: <FaGamepad />,
    title: 'Activities',
    isGroup: false,
  },
  {
    link: '/team',
    image: <AiOutlineTeam />,
    title: 'Team',
    isGroup: false,
  },
]

let emp = [
  {
    link: '/overview',
    image: (
      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M16.3753 6.29337L8.72812 0.999163C8.62973 0.931032 8.51291 0.894531 8.39323 0.894531C8.27356 0.894531 8.15673 0.931032 8.05834 0.999163L0.411157 6.29337C0.282868 6.38219 0.195116 6.51833 0.167206 6.67185C0.139295 6.82536 0.173513 6.98368 0.262331 7.11197C0.351149 7.24026 0.487291 7.32801 0.640809 7.35592C0.794326 7.38383 0.952644 7.34961 1.08093 7.2608L1.92254 6.67814V13.2478C1.92305 13.7157 2.10914 14.1642 2.43998 14.4951C2.77082 14.8259 3.21939 15.012 3.68727 15.0125H13.0992C13.5671 15.012 14.0156 14.8259 14.3465 14.4951C14.6773 14.1642 14.8634 13.7157 14.8639 13.2478V6.67814L15.7055 7.2608C15.8338 7.34961 15.9921 7.38383 16.1457 7.35592C16.2992 7.32801 16.4353 7.24026 16.5241 7.11197C16.613 6.98368 16.6472 6.82536 16.6193 6.67185C16.5913 6.51833 16.5036 6.38219 16.3753 6.29337Z'
          fill='#CFCFCF'
        />
      </svg>
    ),
    title: 'Overview',
    isGroup: false,
  },

  {
    title: 'Personality Insights',
    isGroup: false,
    link: '/profile',
    image: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        enable-background='new 0 0 24 24'
        height='24'
        viewBox='0 0 24 24'
        width='24'
      >
        <g>
          <rect fill='none' height='24' width='24' />
        </g>
        <g>
          <g>
            <path d='M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z' />
            <polygon points='15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93' />
            <polygon points='3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9' />
          </g>
        </g>
      </svg>
    ),
  },
  {
    title: 'Subject Wise',
    isGroup: false,
    link: '/subject',
    image: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        enable-background='new 0 0 24 24'
        height='24'
        viewBox='0 0 24 24'
        width='24'
      >
        <g>
          <rect fill='none' height='24' width='24' />
        </g>
        <g>
          <g>
            <path d='M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z' />
            <polygon points='15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93' />
            <polygon points='3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9' />
          </g>
        </g>
      </svg>
    ),
  },

  {
    link: '/activities',
    image: <FaGamepad />,
    title: 'Activities',
    isGroup: false,
  },
  {
    link: '/brainreport',
    image: <GiBrain />,
    title: 'Brain Report',
    isGroup: false,
  },
]

let student = [
  {
    link: '/overview',
    image: (
      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M16.3753 6.29337L8.72812 0.999163C8.62973 0.931032 8.51291 0.894531 8.39323 0.894531C8.27356 0.894531 8.15673 0.931032 8.05834 0.999163L0.411157 6.29337C0.282868 6.38219 0.195116 6.51833 0.167206 6.67185C0.139295 6.82536 0.173513 6.98368 0.262331 7.11197C0.351149 7.24026 0.487291 7.32801 0.640809 7.35592C0.794326 7.38383 0.952644 7.34961 1.08093 7.2608L1.92254 6.67814V13.2478C1.92305 13.7157 2.10914 14.1642 2.43998 14.4951C2.77082 14.8259 3.21939 15.012 3.68727 15.0125H13.0992C13.5671 15.012 14.0156 14.8259 14.3465 14.4951C14.6773 14.1642 14.8634 13.7157 14.8639 13.2478V6.67814L15.7055 7.2608C15.8338 7.34961 15.9921 7.38383 16.1457 7.35592C16.2992 7.32801 16.4353 7.24026 16.5241 7.11197C16.613 6.98368 16.6472 6.82536 16.6193 6.67185C16.5913 6.51833 16.5036 6.38219 16.3753 6.29337Z'
          fill='#CFCFCF'
        />
      </svg>
    ),
    title: 'Overview',
    isGroup: false,
  },

  {
    link: '/activities',
    image: <FaGamepad />,
    title: 'Activities',
    isGroup: false,
  },
  {
    title: '4 Week Plan',
    isGroup: false,
    link: '/weekplan',
    image: '',
  },
  {
    link: '/brainreport',
    image: <GiBrain />,
    title: 'Brain Report',
    isGroup: false,
  },

  {
    title: 'Personality',
    isGroup: false,
    link: '/profile',
    image: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        enable-background='new 0 0 24 24'
        height='24'
        viewBox='0 0 24 24'
        width='24'
      >
        <g>
          <rect fill='none' height='24' width='24' />
        </g>
        <g>
          <g>
            <path d='M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z' />
            <polygon points='15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93' />
            <polygon points='3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9' />
          </g>
        </g>
      </svg>
    ),
  },
  {
    title: 'Subject Wise',
    isGroup: false,
    link: '/subject',
    image: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        enable-background='new 0 0 24 24'
        height='24'
        viewBox='0 0 24 24'
        width='24'
      >
        <g>
          <rect fill='none' height='24' width='24' />
        </g>
        <g>
          <g>
            <path d='M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z' />
            <polygon points='15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93' />
            <polygon points='3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9' />
          </g>
        </g>
      </svg>
    ),
  },
]

let college = [
  {
    link: '/overview',
    image: (
      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M16.3753 6.29337L8.72812 0.999163C8.62973 0.931032 8.51291 0.894531 8.39323 0.894531C8.27356 0.894531 8.15673 0.931032 8.05834 0.999163L0.411157 6.29337C0.282868 6.38219 0.195116 6.51833 0.167206 6.67185C0.139295 6.82536 0.173513 6.98368 0.262331 7.11197C0.351149 7.24026 0.487291 7.32801 0.640809 7.35592C0.794326 7.38383 0.952644 7.34961 1.08093 7.2608L1.92254 6.67814V13.2478C1.92305 13.7157 2.10914 14.1642 2.43998 14.4951C2.77082 14.8259 3.21939 15.012 3.68727 15.0125H13.0992C13.5671 15.012 14.0156 14.8259 14.3465 14.4951C14.6773 14.1642 14.8634 13.7157 14.8639 13.2478V6.67814L15.7055 7.2608C15.8338 7.34961 15.9921 7.38383 16.1457 7.35592C16.2992 7.32801 16.4353 7.24026 16.5241 7.11197C16.613 6.98368 16.6472 6.82536 16.6193 6.67185C16.5913 6.51833 16.5036 6.38219 16.3753 6.29337Z'
          fill='#CFCFCF'
        />
      </svg>
    ),
    title: 'Overview',
    isGroup: false,
  },

  {
    link: '/activities',
    image: <FaGamepad />,
    title: 'Activities',
    isGroup: false,
  },
  {
    title: '4 Week Plan',
    isGroup: false,
    link: '/weekplan',
    image: '',
  },
  {
    link: '/brainreport',
    image: <GiBrain />,
    title: 'Brain Report',
    isGroup: false,
  },

  {
    title: 'Personality Insights',
    isGroup: false,
    link: '/profile',
    image: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        enable-background='new 0 0 24 24'
        height='24'
        viewBox='0 0 24 24'
        width='24'
      >
        <g>
          <rect fill='none' height='24' width='24' />
        </g>
        <g>
          <g>
            <path d='M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z' />
            <polygon points='15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93' />
            <polygon points='3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9' />
          </g>
        </g>
      </svg>
    ),
  },

  {
    title: 'Subject Wise',
    isGroup: false,
    link: '/subject',
    image: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        enable-background='new 0 0 24 24'
        height='24'
        viewBox='0 0 24 24'
        width='24'
      >
        <g>
          <rect fill='none' height='24' width='24' />
        </g>
        <g>
          <g>
            <path d='M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z' />
            <polygon points='15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93' />
            <polygon points='3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9' />
          </g>
        </g>
      </svg>
    ),
  },

  {
    link: '/aggregate',
    image: '',
    title: 'Aggregate',
    isGroup: false,
  },
  {
    link: '/add',
    image: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='currentColor'
        class='bi bi-file-earmark-plus-fill'
        viewBox='0 0 16 16'
      >
        <path d='M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0z' />
      </svg>
    ),
    title: 'Add Child',
    isGroup: false,
  },
  {
    link: '/team',
    image: <AiOutlineTeam />,
    title: 'Team',
    isGroup: false,
  },
]

let corp = [
  {
    link: '/overview',
    image: (
      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M16.3753 6.29337L8.72812 0.999163C8.62973 0.931032 8.51291 0.894531 8.39323 0.894531C8.27356 0.894531 8.15673 0.931032 8.05834 0.999163L0.411157 6.29337C0.282868 6.38219 0.195116 6.51833 0.167206 6.67185C0.139295 6.82536 0.173513 6.98368 0.262331 7.11197C0.351149 7.24026 0.487291 7.32801 0.640809 7.35592C0.794326 7.38383 0.952644 7.34961 1.08093 7.2608L1.92254 6.67814V13.2478C1.92305 13.7157 2.10914 14.1642 2.43998 14.4951C2.77082 14.8259 3.21939 15.012 3.68727 15.0125H13.0992C13.5671 15.012 14.0156 14.8259 14.3465 14.4951C14.6773 14.1642 14.8634 13.7157 14.8639 13.2478V6.67814L15.7055 7.2608C15.8338 7.34961 15.9921 7.38383 16.1457 7.35592C16.2992 7.32801 16.4353 7.24026 16.5241 7.11197C16.613 6.98368 16.6472 6.82536 16.6193 6.67185C16.5913 6.51833 16.5036 6.38219 16.3753 6.29337Z'
          fill='#CFCFCF'
        />
      </svg>
    ),
    title: 'Overview',
    isGroup: false,
  },

  {
    link: '/activities',
    image: <FaGamepad />,
    title: 'Activities',
    isGroup: false,
  },
  {
    title: '4 Week Plan',
    isGroup: false,
    link: '/weekplan',
    image: '',
  },
  {
    link: '/school',
    image: (
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15.2 6.4H12V4.8L8.4 3.36V1.6H10.4V0H7.6V3.36L4 4.8V6.4H0.8C0.36 6.4 0 6.76 0 7.2V16H6.4V12H9.6V16H16V7.2C16 6.76 15.64 6.4 15.2 6.4ZM4.8 14.4H1.6V12H4.8V14.4ZM4.8 10.4H1.6V8H4.8V10.4ZM8 4.8C8.44 4.8 8.8 5.16 8.8 5.6C8.8 6.04 8.44 6.4 8 6.4C7.56 6.4 7.2 6.04 7.2 5.6C7.2 5.16 7.56 4.8 8 4.8ZM9.6 10.4H6.4V8H9.6V10.4ZM14.4 14.4H11.2V12H14.4V14.4ZM14.4 10.4H11.2V8H14.4V10.4Z'
          fill='white'
        />
      </svg>
    ),
    title: 'School',
    isGroup: false,
  },
  {
    link: '/report',
    image: (
      <svg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.94039 4.78889C6.94039 3.14896 7.79601 1.69917 9.07943 0.867188H1.87814C1.21266 0.867188 0.666016 1.41383 0.666016 2.07931V14.5094C0.666016 15.1748 1.21266 15.7215 1.87814 15.7215H11.9554C12.6209 15.7215 13.1675 15.1748 13.1675 14.5094V9.20944C12.6684 9.37581 12.1455 9.47088 11.6226 9.47088C9.03203 9.47088 6.94039 7.37937 6.94039 4.78862V4.78889ZM3.94574 13.8679H2.75738V7.14197H3.94574V13.8679ZM6.32245 13.8679H5.13409V8.568H6.32245V13.8679ZM8.69916 13.8679H7.5108V10.0177H8.69916V13.8679ZM11.0759 13.8679H9.88751V11.4912H11.0759V13.8679Z'
          fill='white'
        />
      </svg>
    ),
    title: 'Report',
    isGroup: false,
  },
  {
    link: '/logintype',
    image: (
      <svg data-name='Layer 1' id='Layer_1' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'>
        <path d='M412.23,309.71,478,375.47l-65.77,65.77L391,420l31.42-31.42c-38.94-4.53-73-17.09-103.54-38.05-34.33-23.59-60.12-54.78-85.07-84.94-48.48-58.64-94.28-114-199.79-114V121.5c53.64,0,98.75,13.08,137.89,40C206.22,185.07,232,216.26,257,246.42c42,50.83,82.06,99.21,160.69,111.19L391,331ZM351.08,176.79c20.1-11.27,42.1-18.67,66.55-22.39L391,181l21.25,21.25L478,136.53,412.23,70.76,391,92l31.4,31.4c-31.74,3.67-60.11,12.65-86,27.17-17.86,10-42.89,26.69-78.23,67l22.61,19.8C298.79,216.82,321.25,193.52,351.08,176.79ZM139.67,335.21c-30.28,17-64.85,25.24-105.67,25.24V390.5c46.11,0,85.48-9.51,120.37-29.08,33.8-19,58.51-44.52,78.23-67L210,274.59C192,295.18,169.5,318.48,139.67,335.21Z' />
      </svg>
    ),
    title: 'Switch',
    isGroup: false,
  },

  {
    link: '/personalised',
    image: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='currentColor'
        class='bi bi-person-badge'
        viewBox='0 0 16 16'
      >
        <path d='M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z' />
        <path d='M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z' />
      </svg>
    ),
    title: 'Personalize',
    isGroup: false,
  },

  {
    link: '/brain',
    image: <GiBrain />,
    title: 'Brain',
    isGroup: false,
  },
  {
    link: '/brain_report',
    image: '',
    title: 'Brain Report',
    isGroup: false,
  },
  {
    title: 'Personality Insights',
    isGroup: false,
    link: '/profile',
    image: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        enable-background='new 0 0 24 24'
        height='24'
        viewBox='0 0 24 24'
        width='24'
      >
        <g>
          <rect fill='none' height='24' width='24' />
        </g>
        <g>
          <g>
            <path d='M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z' />
            <polygon points='15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93' />
            <polygon points='3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9' />
          </g>
        </g>
      </svg>
    ),
  },
  {
    title: 'Subject Wise',
    isGroup: false,
    link: '/subject',
    image: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        enable-background='new 0 0 24 24'
        height='24'
        viewBox='0 0 24 24'
        width='24'
      >
        <g>
          <rect fill='none' height='24' width='24' />
        </g>
        <g>
          <g>
            <path d='M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z' />
            <polygon points='15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93' />
            <polygon points='3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9' />
          </g>
        </g>
      </svg>
    ),
  },

  {
    link: '/add',
    image: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='currentColor'
        class='bi bi-file-earmark-plus-fill'
        viewBox='0 0 16 16'
      >
        <path d='M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0z' />
      </svg>
    ),
    title: 'Add Child',
    isGroup: false,
  },

  {
    link: '/aggregate',
    image: '',
    title: 'Aggregate',
    isGroup: false,
  },

  {
    link: '/team',
    image: <AiOutlineTeam />,
    title: 'Team',
    isGroup: false,
  },
]

let specialAccessArr = [
  {
    link: '/',
    image: <GiBrain />,
    title: 'Overview',
    isGroup: false,
  },

  {
    link: '/add',
    image: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='currentColor'
        class='bi bi-file-earmark-plus-fill'
        viewBox='0 0 16 16'
      >
        <path d='M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0z' />
      </svg>
    ),
    title: 'Add Child',
    isGroup: false,
  },
]

let parentAccessArr = [
  {
    link: '/',
    image: (
      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M16.3753 6.29337L8.72812 0.999163C8.62973 0.931032 8.51291 0.894531 8.39323 0.894531C8.27356 0.894531 8.15673 0.931032 8.05834 0.999163L0.411157 6.29337C0.282868 6.38219 0.195116 6.51833 0.167206 6.67185C0.139295 6.82536 0.173513 6.98368 0.262331 7.11197C0.351149 7.24026 0.487291 7.32801 0.640809 7.35592C0.794326 7.38383 0.952644 7.34961 1.08093 7.2608L1.92254 6.67814V13.2478C1.92305 13.7157 2.10914 14.1642 2.43998 14.4951C2.77082 14.8259 3.21939 15.012 3.68727 15.0125H13.0992C13.5671 15.012 14.0156 14.8259 14.3465 14.4951C14.6773 14.1642 14.8634 13.7157 14.8639 13.2478V6.67814L15.7055 7.2608C15.8338 7.34961 15.9921 7.38383 16.1457 7.35592C16.2992 7.32801 16.4353 7.24026 16.5241 7.11197C16.613 6.98368 16.6472 6.82536 16.6193 6.67185C16.5913 6.51833 16.5036 6.38219 16.3753 6.29337Z'
          fill='#CFCFCF'
        />
      </svg>
    ),
    title: 'Overview',
    isGroup: false,
  },
  {
    link: '/school',
    image: (
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15.2 6.4H12V4.8L8.4 3.36V1.6H10.4V0H7.6V3.36L4 4.8V6.4H0.8C0.36 6.4 0 6.76 0 7.2V16H6.4V12H9.6V16H16V7.2C16 6.76 15.64 6.4 15.2 6.4ZM4.8 14.4H1.6V12H4.8V14.4ZM4.8 10.4H1.6V8H4.8V10.4ZM8 4.8C8.44 4.8 8.8 5.16 8.8 5.6C8.8 6.04 8.44 6.4 8 6.4C7.56 6.4 7.2 6.04 7.2 5.6C7.2 5.16 7.56 4.8 8 4.8ZM9.6 10.4H6.4V8H9.6V10.4ZM14.4 14.4H11.2V12H14.4V14.4ZM14.4 10.4H11.2V8H14.4V10.4Z'
          fill='white'
        />
      </svg>
    ),
    title: 'School',
    isGroup: false,
  },
  {
    link: '/report',
    image: (
      <svg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.94039 4.78889C6.94039 3.14896 7.79601 1.69917 9.07943 0.867188H1.87814C1.21266 0.867188 0.666016 1.41383 0.666016 2.07931V14.5094C0.666016 15.1748 1.21266 15.7215 1.87814 15.7215H11.9554C12.6209 15.7215 13.1675 15.1748 13.1675 14.5094V9.20944C12.6684 9.37581 12.1455 9.47088 11.6226 9.47088C9.03203 9.47088 6.94039 7.37937 6.94039 4.78862V4.78889ZM3.94574 13.8679H2.75738V7.14197H3.94574V13.8679ZM6.32245 13.8679H5.13409V8.568H6.32245V13.8679ZM8.69916 13.8679H7.5108V10.0177H8.69916V13.8679ZM11.0759 13.8679H9.88751V11.4912H11.0759V13.8679Z'
          fill='white'
        />
      </svg>
    ),
    title: 'Report',
    isGroup: false,
  },

  {
    link: '/language',
    image: (
      <svg width='800px' height='800px' version='1.1' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'>
        <path d='m426.67 85.333v256h-64.04l0.040365 64-106.67-64h-85.333v-256h256zm-170.67-85.333v64h-42.667l3.33e-4 -21.333h-170.67v170.67l106.67-3.33e-4v55.467l-85.333 51.2 0.040365-64h-64.04v-256h256zm128 128h-170.67v170.67h170.67v-170.67zm-72.801 21.333 48.418 128h-23.848l-13.188-36.675h-48.056l-13.098 36.675h-22.493l47.334-128h24.932zm-12.646 21.409c-1.7344 6.0703-4.0237 13.084-6.8681 21.041l-0.35844 0.99958-10.749 30.351h35.862l-10.749-30.351-0.94143-2.7156c-2.1802-6.3496-4.2451-12.791-6.1948-19.325zm-160.19-114.87-5.92e-4 10.666h10.969l0.001341 78.679c-1.4874 2.8648-3.0806 5.6779-4.7796 8.439 1.5184 1.3404 3.1109 2.6742 4.7777 4.0012l6.88e-4 26.249c-6.5501-4.2833-12.453-8.6886-17.709-13.222-14.56 15.378-34.394 26.278-59.244 32.734l-6.368 1.5327-4.6278-20.825c24.259-5.3909 42.587-14.983 55.328-28.731-11.6-14.21-19.795-30.275-24.545-48.104l-1.565-6.5126 20.858-4.4799c3.2441 15.104 9.2471 28.664 18.048 40.768 7.9328-14.661 12.542-31.026 13.822-49.194l-79.631-9.69e-4v-21.333l53.333-6.666e-4 2.58e-4 -10.666h21.333z'></path>
      </svg>
    ),
    title: 'Language',
    isGroup: false,
  },
  {
    link: '/brain',
    image: (
      <svg fill='#ffffff' version='1.1' id='Capa_1' stroke='#ffffff'>
        <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
        <g id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'></g>
        <g id='SVGRepo_iconCarrier'>
          <g>
            <path d='M29.483,5.019c-2.685-3.222-10.254-5.497-16.646-3.674C5.87,3.332,2.49,7.787,2.25,15.752l-2.153,4.396 c-0.12,0.244-0.128,0.528-0.022,0.779c0.105,0.251,0.313,0.444,0.572,0.53l1.5,0.497c-0.188,7.378-0.118,9.225,7.229,9.225 c7.527,0,6.559-5.532,15.648-6.611C31.742,23.768,34.091,10.547,29.483,5.019z M25.643,18.481c-1.583,1.112-2.734,1.151-3.66,1.182 c-0.992,0.035-1.775,0.061-3.124,1.478c-0.134,0.142-0.316,0.213-0.497,0.213c-0.17,0-0.341-0.062-0.473-0.188 c-0.275-0.264-0.286-0.697-0.025-0.973c1.735-1.821,2.924-1.863,4.071-1.902c0.833-0.029,1.623-0.058,2.811-0.86 c-0.955,0.129-2.013-0.132-3.054-0.896c-3.271-2.398-8.024-0.123-8.024-4.179c0-4.053,3.284-7.338,7.337-7.338 c4.056,0,7.343,3.285,7.343,7.338c0,0.006-0.001,0.011-0.001,0.015C28.445,13.015,28.78,16.277,25.643,18.481z'></path>
          </g>
        </g>
      </svg>
    ),
    title: 'Brain',
    isGroup: false,
  },
  {
    link: '/personalised',
    image: (
      <svg fill='#ffffff' version='1.1' id='Capa_1' stroke='#ffffff'>
        <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
        <g id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'></g>
        <g id='SVGRepo_iconCarrier'>
          <g>
            <path d='M29.483,5.019c-2.685-3.222-10.254-5.497-16.646-3.674C5.87,3.332,2.49,7.787,2.25,15.752l-2.153,4.396 c-0.12,0.244-0.128,0.528-0.022,0.779c0.105,0.251,0.313,0.444,0.572,0.53l1.5,0.497c-0.188,7.378-0.118,9.225,7.229,9.225 c7.527,0,6.559-5.532,15.648-6.611C31.742,23.768,34.091,10.547,29.483,5.019z M25.643,18.481c-1.583,1.112-2.734,1.151-3.66,1.182 c-0.992,0.035-1.775,0.061-3.124,1.478c-0.134,0.142-0.316,0.213-0.497,0.213c-0.17,0-0.341-0.062-0.473-0.188 c-0.275-0.264-0.286-0.697-0.025-0.973c1.735-1.821,2.924-1.863,4.071-1.902c0.833-0.029,1.623-0.058,2.811-0.86 c-0.955,0.129-2.013-0.132-3.054-0.896c-3.271-2.398-8.024-0.123-8.024-4.179c0-4.053,3.284-7.338,7.337-7.338 c4.056,0,7.343,3.285,7.343,7.338c0,0.006-0.001,0.011-0.001,0.015C28.445,13.015,28.78,16.277,25.643,18.481z'></path>
          </g>
        </g>
      </svg>
    ),
    title: 'Personalised',
    isGroup: false,
  },
  {
    link: '/logintype',
    image: (
      <svg data-name='Layer 1' id='Layer_1' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'>
        <path d='M412.23,309.71,478,375.47l-65.77,65.77L391,420l31.42-31.42c-38.94-4.53-73-17.09-103.54-38.05-34.33-23.59-60.12-54.78-85.07-84.94-48.48-58.64-94.28-114-199.79-114V121.5c53.64,0,98.75,13.08,137.89,40C206.22,185.07,232,216.26,257,246.42c42,50.83,82.06,99.21,160.69,111.19L391,331ZM351.08,176.79c20.1-11.27,42.1-18.67,66.55-22.39L391,181l21.25,21.25L478,136.53,412.23,70.76,391,92l31.4,31.4c-31.74,3.67-60.11,12.65-86,27.17-17.86,10-42.89,26.69-78.23,67l22.61,19.8C298.79,216.82,321.25,193.52,351.08,176.79ZM139.67,335.21c-30.28,17-64.85,25.24-105.67,25.24V390.5c46.11,0,85.48-9.51,120.37-29.08,33.8-19,58.51-44.52,78.23-67L210,274.59C192,295.18,169.5,318.48,139.67,335.21Z' />
      </svg>
    ),
    title: 'Switch',
    isGroup: false,
  },
]

let defaultParentAccess = [
  {
    link: '/',
    image: (
      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M16.3753 6.29337L8.72812 0.999163C8.62973 0.931032 8.51291 0.894531 8.39323 0.894531C8.27356 0.894531 8.15673 0.931032 8.05834 0.999163L0.411157 6.29337C0.282868 6.38219 0.195116 6.51833 0.167206 6.67185C0.139295 6.82536 0.173513 6.98368 0.262331 7.11197C0.351149 7.24026 0.487291 7.32801 0.640809 7.35592C0.794326 7.38383 0.952644 7.34961 1.08093 7.2608L1.92254 6.67814V13.2478C1.92305 13.7157 2.10914 14.1642 2.43998 14.4951C2.77082 14.8259 3.21939 15.012 3.68727 15.0125H13.0992C13.5671 15.012 14.0156 14.8259 14.3465 14.4951C14.6773 14.1642 14.8634 13.7157 14.8639 13.2478V6.67814L15.7055 7.2608C15.8338 7.34961 15.9921 7.38383 16.1457 7.35592C16.2992 7.32801 16.4353 7.24026 16.5241 7.11197C16.613 6.98368 16.6472 6.82536 16.6193 6.67185C16.5913 6.51833 16.5036 6.38219 16.3753 6.29337Z'
          fill='#CFCFCF'
        />
      </svg>
    ),
    title: 'Overview',
    isGroup: false,
  },

  {
    link: '/report',
    image: (
      <svg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.94039 4.78889C6.94039 3.14896 7.79601 1.69917 9.07943 0.867188H1.87814C1.21266 0.867188 0.666016 1.41383 0.666016 2.07931V14.5094C0.666016 15.1748 1.21266 15.7215 1.87814 15.7215H11.9554C12.6209 15.7215 13.1675 15.1748 13.1675 14.5094V9.20944C12.6684 9.37581 12.1455 9.47088 11.6226 9.47088C9.03203 9.47088 6.94039 7.37937 6.94039 4.78862V4.78889ZM3.94574 13.8679H2.75738V7.14197H3.94574V13.8679ZM6.32245 13.8679H5.13409V8.568H6.32245V13.8679ZM8.69916 13.8679H7.5108V10.0177H8.69916V13.8679ZM11.0759 13.8679H9.88751V11.4912H11.0759V13.8679Z'
          fill='white'
        />
      </svg>
    ),
    title: 'Report',
    isGroup: false,
  },

  {
    link: '/language',
    image: (
      <svg width='800px' height='800px' version='1.1' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'>
        <path d='m426.67 85.333v256h-64.04l0.040365 64-106.67-64h-85.333v-256h256zm-170.67-85.333v64h-42.667l3.33e-4 -21.333h-170.67v170.67l106.67-3.33e-4v55.467l-85.333 51.2 0.040365-64h-64.04v-256h256zm128 128h-170.67v170.67h170.67v-170.67zm-72.801 21.333 48.418 128h-23.848l-13.188-36.675h-48.056l-13.098 36.675h-22.493l47.334-128h24.932zm-12.646 21.409c-1.7344 6.0703-4.0237 13.084-6.8681 21.041l-0.35844 0.99958-10.749 30.351h35.862l-10.749-30.351-0.94143-2.7156c-2.1802-6.3496-4.2451-12.791-6.1948-19.325zm-160.19-114.87-5.92e-4 10.666h10.969l0.001341 78.679c-1.4874 2.8648-3.0806 5.6779-4.7796 8.439 1.5184 1.3404 3.1109 2.6742 4.7777 4.0012l6.88e-4 26.249c-6.5501-4.2833-12.453-8.6886-17.709-13.222-14.56 15.378-34.394 26.278-59.244 32.734l-6.368 1.5327-4.6278-20.825c24.259-5.3909 42.587-14.983 55.328-28.731-11.6-14.21-19.795-30.275-24.545-48.104l-1.565-6.5126 20.858-4.4799c3.2441 15.104 9.2471 28.664 18.048 40.768 7.9328-14.661 12.542-31.026 13.822-49.194l-79.631-9.69e-4v-21.333l53.333-6.666e-4 2.58e-4 -10.666h21.333z'></path>
      </svg>
    ),
    title: 'Language',
    isGroup: false,
  },
]

let demoAccess = [
  {
    link: '/',
    image: (
      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M16.3753 6.29337L8.72812 0.999163C8.62973 0.931032 8.51291 0.894531 8.39323 0.894531C8.27356 0.894531 8.15673 0.931032 8.05834 0.999163L0.411157 6.29337C0.282868 6.38219 0.195116 6.51833 0.167206 6.67185C0.139295 6.82536 0.173513 6.98368 0.262331 7.11197C0.351149 7.24026 0.487291 7.32801 0.640809 7.35592C0.794326 7.38383 0.952644 7.34961 1.08093 7.2608L1.92254 6.67814V13.2478C1.92305 13.7157 2.10914 14.1642 2.43998 14.4951C2.77082 14.8259 3.21939 15.012 3.68727 15.0125H13.0992C13.5671 15.012 14.0156 14.8259 14.3465 14.4951C14.6773 14.1642 14.8634 13.7157 14.8639 13.2478V6.67814L15.7055 7.2608C15.8338 7.34961 15.9921 7.38383 16.1457 7.35592C16.2992 7.32801 16.4353 7.24026 16.5241 7.11197C16.613 6.98368 16.6472 6.82536 16.6193 6.67185C16.5913 6.51833 16.5036 6.38219 16.3753 6.29337Z'
          fill='#CFCFCF'
        />
      </svg>
    ),
    title: 'Overview',
    isGroup: false,
  },

  {
    link: '/report',
    image: (
      <svg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.94039 4.78889C6.94039 3.14896 7.79601 1.69917 9.07943 0.867188H1.87814C1.21266 0.867188 0.666016 1.41383 0.666016 2.07931V14.5094C0.666016 15.1748 1.21266 15.7215 1.87814 15.7215H11.9554C12.6209 15.7215 13.1675 15.1748 13.1675 14.5094V9.20944C12.6684 9.37581 12.1455 9.47088 11.6226 9.47088C9.03203 9.47088 6.94039 7.37937 6.94039 4.78862V4.78889ZM3.94574 13.8679H2.75738V7.14197H3.94574V13.8679ZM6.32245 13.8679H5.13409V8.568H6.32245V13.8679ZM8.69916 13.8679H7.5108V10.0177H8.69916V13.8679ZM11.0759 13.8679H9.88751V11.4912H11.0759V13.8679Z'
          fill='white'
        />
      </svg>
    ),
    title: 'Report',
    isGroup: false,
  },
  {
    link: '/school',
    image: (
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15.2 6.4H12V4.8L8.4 3.36V1.6H10.4V0H7.6V3.36L4 4.8V6.4H0.8C0.36 6.4 0 6.76 0 7.2V16H6.4V12H9.6V16H16V7.2C16 6.76 15.64 6.4 15.2 6.4ZM4.8 14.4H1.6V12H4.8V14.4ZM4.8 10.4H1.6V8H4.8V10.4ZM8 4.8C8.44 4.8 8.8 5.16 8.8 5.6C8.8 6.04 8.44 6.4 8 6.4C7.56 6.4 7.2 6.04 7.2 5.6C7.2 5.16 7.56 4.8 8 4.8ZM9.6 10.4H6.4V8H9.6V10.4ZM14.4 14.4H11.2V12H14.4V14.4ZM14.4 10.4H11.2V8H14.4V10.4Z'
          fill='white'
        />
      </svg>
    ),
    title: 'School',
    isGroup: false,
  },
  {
    title: 'Personality Insights',
    isGroup: false,
    link: '/profile',
    image: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        enable-background='new 0 0 24 24'
        height='24'
        viewBox='0 0 24 24'
        width='24'
      >
        <g>
          <rect fill='none' height='24' width='24' />
        </g>
        <g>
          <g>
            <path d='M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z' />
            <polygon points='15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93' />
            <polygon points='3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9' />
          </g>
        </g>
      </svg>
    ),
  },
  {
    link: '/logintype',
    image: (
      <svg data-name='Layer 1' id='Layer_1' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'>
        <path d='M412.23,309.71,478,375.47l-65.77,65.77L391,420l31.42-31.42c-38.94-4.53-73-17.09-103.54-38.05-34.33-23.59-60.12-54.78-85.07-84.94-48.48-58.64-94.28-114-199.79-114V121.5c53.64,0,98.75,13.08,137.89,40C206.22,185.07,232,216.26,257,246.42c42,50.83,82.06,99.21,160.69,111.19L391,331ZM351.08,176.79c20.1-11.27,42.1-18.67,66.55-22.39L391,181l21.25,21.25L478,136.53,412.23,70.76,391,92l31.4,31.4c-31.74,3.67-60.11,12.65-86,27.17-17.86,10-42.89,26.69-78.23,67l22.61,19.8C298.79,216.82,321.25,193.52,351.08,176.79ZM139.67,335.21c-30.28,17-64.85,25.24-105.67,25.24V390.5c46.11,0,85.48-9.51,120.37-29.08,33.8-19,58.51-44.52,78.23-67L210,274.59C192,295.18,169.5,318.48,139.67,335.21Z' />
      </svg>
    ),
    title: 'Switch',
    isGroup: false,
  },
]

let accessArray = [
  '383aee1b-2527-4437-bbf8-f557406005d3',
  '87f6e1c0-9c62-4adb-892a-13ffebbd0551',
  '398d7be5-8a73-40d9-9c74-b97e9f9a9ee0',
  '6c72be0d-74bf-4803-b290-943b15521e62',
  '515b2d7b-ce57-4b51-bd5d-4b7fc898ee34',
  '1df4d642-5771-4a17-96c6-1705f85c88a5',
  'd95e590e-32fb-4b28-bf56-b4cbbe36dd97',
  '37a4c907-74e9-4982-90b5-c6yd264c2a189',
  '37a4c907-74e9-4982-90b5-c6d264c2a189',
  '898089',
  'bfb8c121-f83c-40ba-ab3d-ebc00738ac9e',
  'cf414583-262d-4987-90e6-5ac1cb34df2e',
  '59d32bb7-1387-4401-b780-6e016beb1c73',
  '9197a489-2b19-496a-af52-b2451ee4f915',
  '19dc2db3-a457-4742-ba77-52acf7d49895',
  'd15223ac-16c7-49e8-a04c-2a88cb776bd9',
]

let accessArray1 = ['be9d991a-e5d5-4142-81ba-c79d1fabdf07']

export {
  navObj,
  parentAccessArr,
  defaultParentAccess,
  accessArray,
  hr,
  emp,
  corp,
  demo,
  specialAccessArr,
  accessArray1,
  student,
  college,
  demoAccess,
}

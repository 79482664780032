import React from 'react'
import { useState, useEffect } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import Backbtn from '../BackComp'
import Pagination from '../Pagination'
import PM from '../../dashboard/Pm'
import ReportAnalysis from '../../dashboard/ReporModal'
import * as Apiurl from '../../Apisurl'
import { Bar, getDatasetAtEvent } from 'react-chartjs-2'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import * as Currdate from '../../CurrDate'

import userJson from '../../Userjson'
import headersReq from '../../HeadersJson'
const ReportLayout = (props) => {
  const reqJson = headersReq()
  const [gradient, setgradient] = useState(1)

  const [performanceMatrix, setperformManceMatricsarr] = useState()
  let headingname = useParams()
  let modulename = useLocation().pathname.split('/')[2]
  let currdate = localStorage.getItem('edudate')

  let userData
  if (userJson() != {}) {
    userData = JSON.stringify(userJson())
  }
  console.log(userData)
  let userData1 = userJson()

  useEffect(() => {
    async function pmData() {
      const response = await fetch(`${Apiurl.Fetchurl + 'pm_report'}`, {
        method: 'POST',
        headers: reqJson,
        body: userData,
      })
      const data = await response.json()
      return data
    }
    pmData()
      .then((data) => {
        let datad = JSON.parse(data.replace(/'/g, '"'))
        let getData = datad.result.PerformanceMetric
        if (getData.Status == 400) {
          localStorage.setItem('category', getData.text)
        } else {
          localStorage.removeItem('category')
          setperformManceMatricsarr([getData.Engagement, getData.Focus, getData.Interest, getData.Stress])
        }
      })
      .catch((error) => {
        console.log('error')
      })
  }, [])

  return (
    <>
      <Bar
        style={{ marginTop: '30px' }}
        data={{
          labels: ['Attention', 'Focus', 'Interest', 'Stress'],

          datasets: [
            {
              barPercentage: 1,
              barThickness: 80,
              maxBarThickness: 60,
              borderRadius: 8,

              maintainAspectRatio: false,
              data: performanceMatrix,
              backgroundColor: (context) => {
                const ctx = context.chart.ctx
                const gradient = ctx.createLinearGradient(0, 0, 0, 200)
                gradient.addColorStop(0, '#eaf6f6')
                gradient.addColorStop(1, '#e0f3f1')
                return gradient
              },
            },
          ],
        }}
        height={'180px'}
        options={{
          plugins: {
            legend: {
              display: false,
              labels: {
                color: 'rgb(255, 99, 132)',
              },
              backgroundColor: gradient,

              title: {
                display: false,
                text: 'Custom Chart Title',
              },
            },
          },

          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              type: 'linear',
              display: false,
              position: 'left',
              grid: {
                display: false,
              },
            },
          },
        }}
      />
    </>
  )
}

export default ReportLayout

import React from "react";
import refreshbtn from "../assets/images/refresh.svg";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { NavLink, useLocation } from "react-router-dom";
import UserJson from "../Userjson";
const routes = [
  { path: "/school", breadcrumb: "School" },
  { path: "/school/sliderChild", breadcrumb: "Communication" },
  { path: "/school/sliderChild/category", breadcrumb: "Category" },
  {
    path: "/school/sliderChild/category/communication",
    breadcrumb: "Live Report",
  },
];

const BreadCrumb = () => {
  let loc = useLocation();
  const breadcrumbs = useBreadcrumbs(routes, {
    excludePaths: ["/", "/no-breadcrumb/for-this-route"],
  });
  console.log(loc.state);
  return (
    <>
      <div className="bread-crumb-container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {breadcrumbs.map(({ match, breadcrumb }) => (
              <li className="breadcrumb-item">
                <NavLink to={match.pathname} state={loc.state}>
                  {breadcrumb}
                </NavLink>
              </li>
            ))}
          </ol>
        </nav>
        <div className="refresh-btn">
          <button>
            <img src={refreshbtn} alt="refresh" /> Start Over
          </button>
        </div>
      </div>
    </>
  );
};
export default BreadCrumb;

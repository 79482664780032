import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Container } from 'react-bootstrap'
import QAcharts from '../dashboard/QAcharts'
import ReportTable from './ReportTable'
import Style from '../cssmodules/button.module.css'
import Showresult from '../dashboard/Showresult'
import Activity from '../dashboard/Activity'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
// import Pm from "./dashboard/Pm";
import * as Apiurl from '../Apisurl'
import headersReq from '../HeadersJson'

const AssignmentReport = () => {
  const reqJson = headersReq()
  const [reportdata, setreportdata] = useState()
  const [lgShow, setLgShow] = useState(false)
  let pmref = useRef(null)

  const changeUserTable = (res) => {
    async function levelgame() {
      const response = await fetch(`${Apiurl.Fetchurl + 'reports_table'}`, {
        method: 'POST',
        headers: reqJson,
        body: JSON.stringify({
          username: res.item.username.toLowerCase(),
        }),
      })
      const childdata = await response.json()
      return childdata
    }
    levelgame()
      .then((data) => {
        console.log(res)
        setreportdata(data.reports_table)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  var options = {}
  return (
    <>
      <div className='content'>
        <Container fluid className='option-main-container report-tab'>
          <div className='dashboard-heading'>
            <div class='content-container'>
              <h2>Reports</h2>
              <p>Check out you cumulative and test-wise reports here</p>
            </div>
            <Activity />
          </div>
          <Showresult via={false} sendFunction={changeUserTable} />
          <Tabs defaultActiveKey='profile' id='uncontrolled-tab-example' className='mb-3'>
            <Tab eventKey='home' title='Cumulative Reports'>
              <div className='week-report'>
                <button type='button' class='btn btn-week '>
                  Weekly
                </button>
                <button type='button' class='btn btn-month '>
                  Monthly
                </button>
              </div>
              <Row>
                <Col md={6}>
                  <div className='dashboard-cards  stat-container'>
                    <QAcharts />
                  </div>
                </Col>
                <Col md={6}>
                  <div className='dashboard-cards  stat-container'>
                    <div class='performance-heading'>
                      <div class='performance-content'>
                        <h4>Performance Metrics</h4>
                        <p>20 June 2021 to 26 June 2021</p>
                      </div>
                      <button className={Style.commonbtn} onClick={() => setLgShow(true)} type='button'>
                        Emotional Analysis
                      </button>
                    </div>
                    {/* <Pm
            options={options}
            selectFunction={selectFunction}
            selectvalue={props.selectvalue}
            url={"pm_overview"}
            ref={pmref}
          /> */}
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey='profile' title='Test-Wise Reports'>
              <div className='week-report'>
                <button type='button' class='btn btn-all '>
                  All
                </button>
                <button type='button' class='btn btn-allweek '>
                  This Week
                </button>
                <button type='button' class='btn btn-allmonth '>
                  This Month
                </button>
              </div>

              <ReportTable reportdata={reportdata} />
            </Tab>
          </Tabs>

        </Container>
      </div>
    </>
  )
}

export default AssignmentReport

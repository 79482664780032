import React, { useEffect, useState } from 'react'
import Tree from './Tree'
import './PartsOfTree.css'
import Speech from '../../Speech'
import { DragDropContainer, DropTarget } from 'react-drag-drop-container'

const PartsOfTree2 = ({ submit }) => {
  const [options, setOptions] = useState({
    fruit: '',
    leaf: '',
    branch: '',
    trunk: '',
    root: '',
  })

  const [result, setResult] = useState([])

  useEffect(() => {
    if (result.length === Object.keys(options).length) {
      localStorage.setItem('useranswer', JSON.stringify(result))
      submit()
    }
  }, [result])

  const handleHit = (e) => {
    let parentContainer = e.target
    parentContainer.innerText = e.dragData
    parentContainer.style.setProperty('color', 'gray', 'important')
    parentContainer.style.setProperty('border-color', 'gray', 'important')
  }

  const handleDrop = (e) => {
    let ques = e.dropData
    let ans = e.dragData

    setOptions({ ...options, [ques]: ans })
  }

  const reset = () => {
    let el = document.getElementsByClassName('tree-part')
    Object.values(el).forEach((e) => {
      e.innerText = ''
      e.style.setProperty('border-color', 'gray', 'important')
    })
    setResult([])
    setOptions({
      fruit: '',
      leaf: '',
      branch: '',
      trunk: '',
      root: '',
    })
  }

  const handleSubmit = () => {
    Object.entries(options).forEach(([ques, ans], i) => {
      setResult((old) => [
        ...old,
        {
          ques: ques,
          ans: ans,
          via: ques === ans ? 'correct' : 'incorrect',
        },
      ])
    })

    // localStorage.setItem('useranswer', JSON.stringify(result))
    // submit()
  }

  return (
    <div className='d-flex flex-column align-items-center gap-4'>
      <div className='d-flex align-items-center gap-4'>
        {Object.keys(options).map((op) => {
          return (
            <DragDropContainer targetKey='parts' dragData={op} onDrop={handleDrop}>
              <div className='px-4 py-2 rounded-pill d-flex align-items-center justify-content-center shadow'>
                <h2 className='m-0 p-0 text-capitalize text-black'>{op}</h2>
              </div>
            </DragDropContainer>
          )
        })}
        <button
          className='px-4 py-2 rounded-pill d-flex align-items-center justify-content-center border border-2 bg-transparent'
          onClick={reset}
        >
          <h2 className='m-0 p-0 text-capitalize text-secondary'>Reset</h2>
        </button>
        <button
          className='px-4 py-2 rounded-pill d-flex align-items-center justify-content-center border border-2 bg-transparent'
          onClick={handleSubmit}
        >
          <h2 className='m-0 p-0 text-capitalize text-secondary'>Submit</h2>
        </button>
      </div>
      <div className='position-relative d-flex justify-content-center' style={{ width: '1000px' }}>
        <div>
          <DropTarget targetKey='parts' dropData={'fruit'} onHit={handleHit}>
            <div
              className='tree-part fruits px-4 py-2 border border-4 rounded-pill d-flex align-items-center justify-content-center text-capitalize'
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                width: '115px',
                height: '50px',
              }}
            ></div>
          </DropTarget>
          <DropTarget targetKey='parts' dropData={'leaf'} onHit={handleHit}>
            <div
              className='tree-part leaves px-4 py-2 border border-4 rounded-pill d-flex align-items-center justify-content-center text-capitalize'
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                width: '140px',
                height: '50px',
              }}
            ></div>
          </DropTarget>
        </div>
        <Tree handleClick={() => {}} />
        <div>
          <DropTarget targetKey='parts' dropData={'branch'} onHit={handleHit}>
            <div
              className='tree-part branch px-4 py-2 border border-4 rounded-pill d-flex align-items-center justify-content-center text-capitalize'
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                width: '145px',
                height: '50px',
              }}
            ></div>
          </DropTarget>
          <DropTarget targetKey='parts' dropData={'trunk'} onHit={handleHit}>
            <div
              className='tree-part trunk px-4 py-2 border border-4 rounded-pill d-flex align-items-center justify-content-center text-capitalize'
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                width: '115px',
                height: '50px',
              }}
            ></div>
          </DropTarget>
          <DropTarget targetKey='parts' dropData={'root'} onHit={handleHit}>
            <div
              className='tree-part roots px-4 py-2 border border-4 rounded-pill d-flex align-items-center justify-content-center text-capitalize'
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                width: '115px',
                height: '50px',
              }}
            ></div>
          </DropTarget>
        </div>
      </div>
    </div>
  )
}

export default PartsOfTree2

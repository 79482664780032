import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import * as API from '../Apisurl'
import Context from '../Context'
import Rating from '../profile/Rating'

const UserView = () => {
  const { selectValue } = useContext(Context)

  const [productivity, setProductivity] = useState()
  const [productivityG, setProductivityG] = useState()
  const [ranks, setRanks] = useState({
    prod_prec: [],
    attention: [],
    focus: [],
    stress: [],
  })
  const [ranksG, setRanksG] = useState({
    prod_prec: [],
    attention: [],
    focus: [],
    stress: [],
  })

  const [categories, setCategories] = useState()

  const initialValues = {
    category: 'all',
    speciality: 'all',
    gender: 'all',
    minAge: 2,
    maxAge: 99,
  }
  const [filter, setFilter] = useState(initialValues)

  const isGroup = localStorage.getItem('isGroup')

  const getinfo = async () => {
    try {
      const response = await fetch(`${API.Fetchurl}user_productivity`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('session')}`,
        },
        body: JSON.stringify({
          user_id: selectValue.user_id,
          isGroup: false,
          ...filter,
        }),
      })
      if (response.ok) {
        const data = await response.json()

        let p = [],
          a = [],
          f = [],
          s = []

        let pG = [],
          aG = [],
          fG = [],
          sG = []

        Object.entries(data.user_insight).forEach(([day, stats]) => {
          p.push([day, stats.prod_prec])
          a.push([day, stats.attention])
          f.push([day, stats.focus])
          s.push([day, stats.stress])
        })

        Object.entries(data.global_insight).forEach(([day, stats]) => {
          pG.push([day, stats.prod_prec])
          aG.push([day, stats.attention])
          fG.push([day, stats.focus])
          sG.push([day, stats.stress])
        })

        p.sort((a, b) => b[1] - a[1])
        a.sort((a, b) => b[1] - a[1])
        f.sort((a, b) => b[1] - a[1])
        s.sort((a, b) => a[1] - b[1])

        pG.sort((a, b) => b[1] - a[1])
        aG.sort((a, b) => b[1] - a[1])
        fG.sort((a, b) => b[1] - a[1])
        sG.sort((a, b) => a[1] - b[1])

        setRanks((old) => ({ ...old, prod_prec: p, attention: a, focus: f, stress: s }))
        setRanksG((old) => ({ ...old, prod_prec: pG, attention: aG, focus: fG, stress: sG }))

        setProductivity(data.user_insight)
        setProductivityG(data.global_insight)
      } else {
        throw new Error('Something Went Wrong...')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getinfo()
  }, [selectValue.user_id, filter])

  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await fetch(`${API.Fetchurl}get_all_category`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('session')}`,
          },
        })
        const data = await response.json()
        setCategories(data)
      } catch (error) {
        console.log(error)
      }
    }

    getCategories()
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFilter((old) => ({
      ...old,
      [name]: value,
    }))
  }

  const reset = () => {
    setFilter(initialValues)
  }

  return (
    <div className='text-black'>
      <Row>
        {productivity &&
          Object.entries(productivity).map(([day, info], i) => {
            return (
              <InfoCard key={i} day={day} star={info.prod_star} i={i}>
                {Object.entries(info).map(([d, v], idx) => {
                  return d !== 'prod_star' && <InfoBar key={idx} ranks={ranks} d={d} v={v} />
                })}
              </InfoCard>
            )
          })}
      </Row>
      <Form className='dashboard-cards p-4'>
        <Row>
          <Col md={4} lg={2}>
            <Form.Group controlId='Category'>
              <Form.Label>Category</Form.Label>
              <Form.Select required value={filter.category} onChange={handleChange} name='category'>
                <option value='all'>Any</option>
                {categories?.map((c, i) => {
                  return (
                    <option key={i} value={c}>
                      {c}
                    </option>
                  )
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4} lg={2}>
            <Form.Group controlId='Speciality'>
              <Form.Label>Speciality</Form.Label>
              <Form.Select required value={filter.speciality} onChange={handleChange} name='speciality'>
                <option value='all'>Any</option>
                <option value='educator'>Special educator</option>
                <option value='therapis'>Speech therapist</option>
                <option value='assessor'>Technical assessor</option>
                <option value='other'>Other</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4} lg={2}>
            <Form.Group controlId='Gender'>
              <Form.Label>Gender</Form.Label>
              <Form.Select required value={filter.gender} onChange={handleChange} name='gender'>
                <option value='all'>Any</option>
                <option value='male'>Male</option>
                <option value='female'>Female</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4} lg={2}>
            <Form.Group controlId='Min Age'>
              <Form.Label>Min Age</Form.Label>
              <Form.Control
                type='number'
                min={2}
                max={99}
                onChange={handleChange}
                name='minAge'
                value={filter.minAge}
              />
            </Form.Group>
          </Col>
          <Col md={4} lg={2}>
            <Form.Group controlId='Max Age'>
              <Form.Label>Max Age</Form.Label>
              <Form.Control
                type='number'
                min={2}
                max={99}
                onChange={handleChange}
                name='maxAge'
                value={filter.maxAge}
              />
            </Form.Group>
          </Col>
          <Col md={4} lg={2}>
            <Button className='common-btn w-100 h-75 my-2' onClick={reset}>
              Reset
            </Button>
          </Col>
        </Row>
      </Form>
      <Row>
        {productivityG &&
          Object.entries(productivityG).map(([day, info], i) => {
            return (
              <InfoCard key={i} day={day} star={info.prod_star} i={i}>
                {Object.entries(info).map(([d, v], idx) => {
                  return d !== 'prod_star' && <InfoBar key={idx} ranks={ranksG} d={d} v={v} />
                })}
              </InfoCard>
            )
          })}
      </Row>
    </div>
  )
}

export default UserView

export const InfoBar = ({ ranks, d, v }) => {
  const [bg, setBg] = useState('silver')

  useEffect(() => {
    const getBgColor = () => {
      if (v === 0) {
        return setBg('gray')
      }

      let data = ranks[d],
        idx = null

      for (let i = 0; i < data.length; i++) {
        if (data[i][1] === v) {
          idx = i
          break
        }
      }

      setBg(colors[idx])
    }

    getBgColor()
  }, [v])

  return (
    <div className={`d-flex justify-content-between align-items-center ${d === 'prod_prec' ? 'order-1' : 'order-2'}`}>
      <span className='text-capitalize'>{d === 'prod_prec' ? 'productivity' : d}</span>
      <div
        style={{
          marginInline: '10px',
          borderRadius: '2px',
          height: '2px',
          width: '100%',
          backgroundColor: 'silver',
        }}
      ></div>
      <span className={`rounded-pill px-3 py-1`} style={{ backgroundColor: bg, color: 'white' }}>
        {v === 0 ? 'NA' : v}
      </span>
    </div>
  )
}

export const InfoCard = ({ day, star, i, children }) => {
  return (
    <Col key={i} style={{ order: i === 0 ? 2 : i === 1 ? 3 : i === 2 ? 1 : 4 }}>
      <div className='dashboard-cards p-4'>
        <div className='d-flex justify-content-between align-items-center mb-4'>
          <h5 className='text-capitalize m-0'>{day}</h5>

          <Rating value={star} />
        </div>
        <div className='d-flex flex-column gap-2'>{children}</div>
      </div>
    </Col>
  )
}

export const colors = ['green', 'orange', 'orange', 'red']

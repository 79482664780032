import React from 'react'
import { useState, useEffect } from 'react'

import { Container, Row, Col, Card } from 'react-bootstrap'
import HomeHeader from '../includes/HomepageHeader'
import SelectModuleCard from './SelectModuleCard'
import Sidebar from '../includes/Sidebar'
import Backbtn from './BackComp'
import BreadCrumb from '../includes/BreadCrumb'
import ChartistGraph from 'react-chartist'
import Pagination from './Pagination'

const ResultDashBoard = () => {
  const onDrawHandler = (e) => {
    if (e.element._node.classList[0] == 'ct-bar') {
      e.element.attr({
        style: 'stroke-width: 40px   ;stroke:#E4F4F2;margin-bottom:30px;display:inline-block',
      })
    }
    if (e.type == 'bar') {
      e.element.animate({
        y2: {
          dur: '0.2s',
          from: e.y1,

          to: e.y2,
        },
      })
    }
  }
  var data = {
    labels: ['Attention', 'Focus', 'Interest', 'Relaxed'],
    series: [[10, 20, 30, 50, 4]],
  }

  var options = {
    reverseData: true,
    horizontalBars: true,
    axisY: {
      offset: 70,
    },
    height: 300,
  }

  var type = 'Bar'
  return (
    <>
      <Sidebar />
      <div className='content'>
        <HomeHeader />
        <div class='option-main-container '>
          <BreadCrumb />

          <Backbtn title='Fruits' />
          <Container>
            <Row>
              <Col md={7}>
                <div className='dashboard-cards  stat-container resultCard'>
                  <h3>Visual Reports</h3>
                  <Row>
                    <Col md={6}>
                      <h4>Result</h4>
                      <figure>
                        <img src={require('../assets/images/apple1.png')} />
                      </figure>
                    </Col>
                    <Col md={6}>
                      <h4>Result</h4>
                      <figure>
                        <img src={require('../assets/images/apple1.png')} />
                      </figure>
                    </Col>
                  </Row>
                  <Pagination />
                </div>
              </Col>
              <Col md={5}>
                <div className='dashboard-cards  stat-container'>
                  <div class='performance-heading'>
                    <div class='performance-content'>
                      <h4>Performance Metrics</h4>
                      <p>20 June 2021 to 26 June 2021</p>
                    </div>
                  </div>
                  <ChartistGraph
                    data={data}
                    options={options}
                    type={type}
                    listener={{ draw: (e) => onDrawHandler(e) }}
                  />
                  <button className='common-btn full-btn' type='button'>
                    Emotional Analysis
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}

export default ResultDashBoard

import react from "react";
import { Modal, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Formstyle from "../teacher/cssModule/Formstyle.module.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import userImg from "../assets/images/user5.svg";
import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import * as Apiurl from "../Apisurl";

const MeetModal = (props) => {
  console.log(props.showvia);
  const [validated, setValidated] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  const [apiVia, setapivia] = useState(false);
  const [err, seterr] = useState(false);
  const [uservalues, setInputValue] = useState({});
  let formElement = useRef();
  const childValidate = (event) => {
    if (formElement.current.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
    }
    setValidated(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...uservalues,
      [name]: value,
    });

    console.log(uservalues);
    //userSaveHandler();
  };
  const hidemodal = () => {
    props.setshowvia(false);
  };

  return (
    <>
      <Modal show={props.showvia} onHide={hidemodal} className="FormModal">
        <Modal.Body>
          <Form
            className={Formstyle.formContainer}
            noValidate
            validated={validated}
            ref={formElement}
          >
            <FloatingLabel controlId="floatingSelect" label="Speciality">
              <Form.Select
                required
                aria-label="Floating label select example"
                value={uservalues.parent}
                onChange={handleChange}
                name="special_need"
              >
                <option value="">Please Select</option>
                <option value="Autism">Teacher</option>
                <option value="Autism">Parent</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please enter required field
              </Form.Control.Feedback>
            </FloatingLabel>

            <FloatingLabel controlId="floatingSelect" label="Speciality">
              <Form.Select
                required
                aria-label="Floating label select example"
                value={uservalues.child}
                onChange={handleChange}
                name="special_need"
              >
                <option value="">Please Select</option>
                <option value="Autism">Child</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please enter required field
              </Form.Control.Feedback>
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingInput"
              label="Time"
              className="mb-3"
            >
              <Form.Control
                required
                type="time"
                placeholder="Class"
                maxLength="30"
                onChange={handleChange}
                name="age"
                value={uservalues.time}
              />
              <Form.Control.Feedback type="invalid">
                Please enter required field.
              </Form.Control.Feedback>
            </FloatingLabel>

            <FloatingLabel controlId="Date" label="Date">
              <Form.Control
                required
                type="date"
                placeholder="Date"
                name="parent_name"
                onChange={handleChange}
                maxLength="50"
                value={uservalues.date}
              />
            </FloatingLabel>

            <FloatingLabel controlId="Date" label="Additional Date">
              <Form.Control
                required
                type="date"
                placeholder="Additional Date"
                name="parent_name"
                onChange={handleChange}
                maxLength="50"
                value={uservalues.date}
              />
            </FloatingLabel>
            <NavLink to="/Meetmodal2">
              {" "}
              <Button
                variant="primary"
                className="common-btn full-btn  "
                type="button"
                //   onClick={childValidate1}
              >
                Schedule
              </Button>
            </NavLink>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default MeetModal;

import react from 'react'
import illustration1 from '../assets/images/g10.svg'

const LoginContent = () => {
  return (
    <>
      <img src={illustration1} alt='g10' />
      <h2>Neuro Electrical Mind for All</h2>
      <p>
        NEMA AI is a neuroscience and AI based consumer insight platform which makes learning , branding & marketing
        efficient.
      </p>
    </>
  )
}
export default LoginContent

import React from "react";
import { Row } from "react-bootstrap";
const SelectAssign = () => {
  return (
    <>
      <Row className="multi-select-card">
        <div class="col-sm-4">
          <div class="quiz_card_area">
            <input class="quiz_checkbox" type="checkbox" id="2" value="2" />
            <div class="single_quiz_card">
              <div class="quiz_card_content">
                <div class="child-img">
                  <img src="../assets/images/child2.png" alt="child" />
                </div>
                <div class="child-data">
                  <span>Naveen Kumar</span>
                  <p>Group 1</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="quiz_card_area">
            <input class="quiz_checkbox" type="checkbox" id="2" value="2" />
            <div class="single_quiz_card">
              <div class="quiz_card_content">
                <div class="child-img">
                  <img src="../assets/images/child2.png" alt="child" />
                </div>
                <div class="child-data">
                  <span>Prem chand sharma</span>
                  <p>Group 1</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="quiz_card_area">
            <input class="quiz_checkbox" type="checkbox" id="2" value="2" />
            <div class="single_quiz_card">
              <div class="quiz_card_content">
                <div class="child-img">
                  <img src="../assets/images/child2.png" alt="child" />
                </div>
                <div class="child-data">
                  <span>Naveen Kumar</span>
                  <p>Group 1</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="quiz_card_area">
            <input class="quiz_checkbox" type="checkbox" id="2" value="2" />
            <div class="single_quiz_card">
              <div class="quiz_card_content">
                <div class="child-img">
                  <img src="../assets/images/child2.png" alt="child" />
                </div>
                <div class="child-data">
                  <span>Prem chand sharma</span>
                  <p>Group 1</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="quiz_card_area">
            <input class="quiz_checkbox" type="checkbox" id="2" value="2" />
            <div class="single_quiz_card">
              <div class="quiz_card_content">
                <div class="child-img">
                  <img src="../assets/images/child2.png" alt="child" />
                </div>
                <div class="child-data">
                  <span>Naveen Kumar</span>
                  <p>Group 1</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="quiz_card_area">
            <input class="quiz_checkbox" type="checkbox" id="2" value="2" />
            <div class="single_quiz_card">
              <div class="quiz_card_content">
                <div class="child-img">
                  <img src="../assets/images/child2.png" alt="child" />
                </div>
                <div class="child-data">
                  <span>Prem chand sharma</span>
                  <p>Group 1</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
};

export default SelectAssign;

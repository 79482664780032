import react from 'react'
import { Row, Container, Col } from 'react-bootstrap'
import backbtn from '../../../assets/images/backbtn.svg'
import Focus from '../../../includes/Focus'
import Progress from '../Testprogrsscom'
import refreshbtn from '../../../assets/images/refresh.svg'
import Draggable, { DraggableCore } from 'react-draggable' // Both at the same time
import { useState, useRef, useEffect, useContext } from 'react'
import StartModal from '../../SelectgameStart'
import { NavLink, useLocation, useParams, useNavigate } from 'react-router-dom'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import * as Currdate from '../../../CurrDate'
import * as Apiurl from '../../../Apisurl'
import { useSpeechSynthesis } from 'react-speech-kit'
import { SocketConfig } from '../../../SocketConfig'
import Speech from '../../Speech'

const Calculation = (props) => {
  let DraggableCore = Draggable.DraggableCore
  const { speak } = useSpeechSynthesis()
  const [score, setScore] = useState(0)
  const location = useLocation()
  const [isActive, setIsActive] = useState(false)
  let initialArray = []
  const [theArray, setTheArray] = useState(initialArray)
  const [mistakes, setMistakes] = useState(0)
  const [userAnswer, setUserAnswer] = useState('')
  const resetButton = useRef(null)
  const [smShow, setsmShow] = useState(false)
  const [parsedata, setparsedata] = useState(false)
  const [page, setPage] = useState(0)

  // const props1 = useContext(UserContext);
  let moneyDatavalue = props.moneyData

  let datalength = props.datalength
  let date = Currdate.GetCurrentDate()
  props.time(date)
  let datalengthi = datalength
  const [callday, setcallday] = useState(1)
  let navigate = useNavigate()

  useEffect(() => {
    SocketConfig.on('action_data', (data) => {
      // console.log("Datta ", data);
      let parseddata = data.result
      if (parseddata.length != 0) {
        setparsedata(true)
        handleSubmit(parseddata.action, true)
      }
      // console.log(parseddata);
    })
    //
  }, [])
  // populate level text

  let update
  // checkanswers
  let count = 0
  function handleSubmit(e, via) {
    console.log(e)
    let userAnswer = e
    // console.log(userAnswer);
    // speak({ text: userAnswer });
    Speech.Speech(userAnswer)

    let correctAnswer
    // alert(datalength[props.page].ans.toUpperCase());

    if (
      datalength[props.page].ans.toUpperCase() == userAnswer.toUpperCase() ||
      datalength[props.page].direction[0].toUpperCase() == userAnswer.toUpperCase() ||
      datalength[props.page].direction[1].toUpperCase() == userAnswer.toUpperCase()
    ) {
      props.setPage((prev) => {
        props.setTheArray((oldArray) => {
          update = [
            ...oldArray,
            {
              via: 'correct',
              answer: userAnswer,
            },
          ]
          localStorage.setItem('useranswer', JSON.stringify(update))
          return update
        })
        if (update.length === datalengthi.length) {
          props.setTheArray([])
          // socketemitdata();
          props.submitResult(date)

          // navigate(
          //   'moneyresult',
          //   {
          //     state: { ...location.state, date: date },
          //   },
          //   1000
          // )
        } else {
          props.setScore((prev) => prev + 1)
          count++
          return prev + 1
        }
      })
      // console.log(props.page);
    } else {
      props.setPage((prev) => {
        props.setTheArray((oldArray) => {
          update = [
            ...oldArray,
            {
              via: 'incorrect',
              answer: userAnswer,
            },
          ]
          localStorage.setItem('useranswer', JSON.stringify(update))
          return update
        })

        if (update.length === datalengthi.length) {
          props.setTheArray([])
          props.submitResult(date)
          // navigate(
          //   'moneyresult',
          //   {
          //     state: { ...location.state, date: date },
          //   },
          //   100
          // )
        } else {
          count++
          return prev + 1
        }
      })
    }
  }

  if (moneyDatavalue != undefined) {
    return (
      <>
        <Container fluid className='currencyGame'>
          <Row className='colors-main-container comparing-game mt-0'>
            <h1
              style={{
                textAlign: 'center',
                fontWeight: '600',
                textTransform: 'uppercase',
              }}
            ></h1>
            <Col md={12}>
              <p>Your Problem</p>
              <Focus />
              <div className='addition-container  '>
                {/* <h5>{moneyDatavalue.no1}</h5>
                <div className="color question-container">?</div>
                <h5>{moneyDatavalue.no2}</h5> */}
              </div>
              <div className='content-container'></div>
            </Col>
            <figure className='measure-item view-option m-auto'>
              <h3 className='text-center' style={{ fontSize: '40px' }}>
                IDENTIFY
              </h3>
              <img src={moneyDatavalue.image} style={{ objectFit: 'contain', height: '180px' }} />
            </figure>
          </Row>
          <Row className=''>
            {moneyDatavalue.mcq.map((o, i) => {
              console.log(o)
              let currText = o
                .split('/')
                .pop()
                .split('.')
              console.log(currText[0])
              return (
                <Col md={6} className='measure-item mcq-item' onClick={() => handleSubmit(currText[0], false)}>
                  <h4>{i == 0 ? 'A.)' : 'B.)'}</h4>
                  <img src={o} style={{ objectFit: 'contain', height: '180px' }} />
                </Col>
              )
            })}
          </Row>
        </Container>
        <StartModal
          calldelay={setcallday}
          socket={SocketConfig}
          type={['action', 'focus']}
          date={date}
          letquesLength={datalength.length}
          calldaynum={callday}
        />
      </>
    )
  }
}
export default Calculation

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { Line } from 'react-chartjs-2'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions'
import * as API from '../../Apisurl'
import Context from '../../Context'
import Activity from '../../dashboard/Activity'
import Header from '../../profile/Header'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const Games = () => {
  const { selectValue } = useContext(Context)
  const [reports, setReports] = useState()
  const [data, setData] = useState({
    score: '',
    totalAttention: [],
    category: '',
  })
  const [show, setShow] = useState(false)

  useEffect(() => {
    const getReports = async () => {
      try {
        const response = await fetch(`${API.Fetchurl}games_data?user_id=${selectValue.user_id}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('session')}`,
          },
        })
        const data = await response.json()
        let res = []
        data.ques.forEach((d) => {
          res.push({ id: d[0], time: d[1], category: d[2], bet: d[3], username: d[4], score: d[5], bet_array: d[6] })
        })
        setReports(res)
      } catch (error) {
        console.log(error)
      }
    }

    getReports()
  }, [selectValue.user_id])

  const isJson = (res) => {
    try {
      let r = JSON.parse(res)
      return (
        <>
          <p className='score-badge w-100 px-2'>
            game :<span>{r.score_game}</span>
          </p>
          <p className='score-badge w-100 px-2'>
            brain :<span>{r.score_brain}</span>
          </p>
        </>
      )
    } catch (e) {
      return res
    }
  }

  const tableData = {
    columns: [
      // { name: 'id', selector: (row) => row.id },
      { name: 'Username', selector: (row) => row.username },
      { name: 'Category', selector: (row) => row.category, sortable: true },
      { name: 'Duration', selector: (row) => `${row.time} Mins`, sortable: true },
      // { name: 'Bet', selector: (row) => row.bet, sortable: true },
      // { name: 'Score', selector: (row) => isJson(row.score), sortable: true },
      // {
      //   name: 'Report',
      //   selector: (row) => {
      //     const res = {
      //       score: row.score,
      //       totalAttention: row.bet_array.length > 0 ? row.bet_array : [0],
      //       category: row.category,
      //     }
      //     return (
      //       <button
      //         type='button'
      //         className='btn common-btn w-100 report-button'
      //         onClick={() => {
      //           setData(res)
      //           setShow(true)
      //         }}
      //       >
      //         View Report
      //       </button>
      //     )
      //   },
      // },
    ],
    data: reports,
  }

  return (
    <div className='content flex-grow-1'>
      <Container fluid className='report-tab'>
        <div className='dashboard-heading'>
          <div class='content-container'>
            <h2>Game Reports</h2>
            <p>Report of all games played</p>
          </div>
          <Activity />
        </div>
        <Header />

        <DataTableExtensions {...tableData}>
          <DataTable pagination customStyles={customStyles} highlightOnHover />
        </DataTableExtensions>
        <Modal size='lg' centered show={show} onHide={setShow} className='FormModal'>
          <Modal.Header closeButton className='px-4'>
            <h2 className='m-0'>Report</h2>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={4}>
                <div className='d-flex justify-content-between align-items-center border border-2 border-dark rounded-pill p-1'>
                  <h4 className='m-0 ms-2'>Score</h4>
                  <h2 className='m-0 rounded-pill bg-info px-3 py-1 text-white'>{isJson(data.score)}</h2>
                </div>
              </Col>
              <Col md={8}>
                <div className='d-flex gap-4 justify-content-between align-items-center border border-2 border-dark rounded-pill p-1'>
                  <h4 className='m-0 ms-2 text-capitalize'>{data.category}</h4>

                  <div className='d-flex gap-2'>
                    <h2 className='m-0 bg-success rounded-pill px-3 py-1 text-white'>
                      H : {Math.max(...data.totalAttention)}
                    </h2>
                    <h2 className='m-0 bg-danger rounded-pill px-3 py-1 text-white'>
                      L : {Math.min(...data.totalAttention)}
                    </h2>
                    <h2 className='m-0 bg-warning rounded-pill px-3 py-1 text-white'>
                      A :{' '}
                      {(
                        data.totalAttention.reduce((total, curr) => total + curr, 0) / data.totalAttention.length
                      ).toFixed(0)}
                    </h2>
                  </div>
                </div>
              </Col>
              <Col md={12} className='mt-4'>
                <Line data={getChartData(data.totalAttention, data.category)} options={options} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  )
}

export default Games

const customStyles = {
  rows: {
    style: {
      minHeight: '80px',
    },
  },
  cells: {
    style: {
      fontSize: '14px',
      color: '#414141',
    },
  },
}

export const colorsUser = ['#6AB04C', '#F0932B', '#BC1CA4', '#22A6B3', '#6AB04C', '#BC1CA4']
export const colorsOthers = ['#558d3d', '#c07622', '#961683', '#1b858f', '#558d3d', '#961683']

export const getChartData = (data, label) => {
  let labels = data.map((_, i) => i + 1)
  return {
    labels: labels,
    datasets: [{ label: label, data: data, borderColor: colorsUser[0], backgroundColor: colorsUser[0] }],
  }
}

export const options = {
  responsive: true,
  scales: {
    x: {
      display: false,
      title: {
        display: true,
        text: 'Attention',
      },
      grid: {
        display: false,
      },
    },
    y: {
      min: 0,
      max: 100,
      ticks: {
        stepSize: 20,
      },
    },
  },

  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      labels: {
        value: {
          anchor: 'end',
          align: 'top',
          display: true,
          color: 'black',
          font: {
            weight: 'bold',
            size: 12,
          },
          offset: 0,
        },
      },
    },
  },
}

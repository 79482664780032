import react from 'react'
const GetCurrentDate = () => {
  let newDate = new Date()
  let date = newDate.getDate()
  let month = newDate.getMonth() + 1
  let year = newDate.getFullYear()

  const formatTime = () => {
    return `${newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes()}`
  }

  return `${date}${'-'}${month < 10 ? `0${month}` : `${month}`}${'-'}${year}:${newDate.getHours() + ':' + formatTime()}`
  // return n
}
export { GetCurrentDate }

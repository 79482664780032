import React from 'react'
import { Outlet } from 'react-router-dom'
import image from './assets/images/401.jpg'

const AdminAccess = () => {
  let id = sessionStorage.getItem('session')
  // return id !== '1234' ? (
  return <Outlet />
  // ) : (
  //   <div className='content bg-white d-flex justify-content-center align-items-center'>
  //     <div className='text-center'>
  //       <img src={image} alt='401-unauthorized' height='600px' />
  //       <h4>Admin Access Required</h4>
  //     </div>
  //   </div>
  // )
}

export default AdminAccess

import React, { useContext, useEffect, useRef, useState } from 'react'
import anaconda from './images/anaconda.png'
import bee from './images/bee.png'
import chameleon from './images/chameleon.png'
import cockatoo from './images/cockatoo.png'
import crocodile from './images/crocodile.png'
import gorilla from './images/gorilla.png'
import macaw from './images/macaw.png'
import monkey from './images/monkey.png'
import piranha from './images/piranha.png'
import sloth from './images/sloth.png'
import tiger from './images/tiger.png'
import toucan from './images/toucan.png'
import * as API from '../../Apisurl'
import Context from '../../Context'
import './memorygame.css'
import { Col, Row } from 'react-bootstrap'
import psyduck from '../../assets/images/hand_help.svg'
import memorygame from '../../assets/images/memory.png'
import memoryspan from '../../assets/images/creative.png'
import schutletable from '../../assets/images/The-Schulte-Table.jpg'
import mentalmaths from '../../assets/images/mathematics.png'

const MemoryGame = () => {
  const { selectValue } = useContext(Context)
  const [cards, setCards] = useState([])
  const [moves, setMoves] = useState(0)
  const [winCount, setWinCount] = useState(0)

  const [isPlaying, setIsPlaying] = useState(false)
  const [isWin, setIsWin] = useState(false)
  const [reports, setReports] = useState([])

  const [settings, setSettings] = useState({
    type: 'Animal',
    level: '1',
    no_of_questions: 8,
    curr_question: 0,
  })

  const minRef = useRef()

  useEffect(() => {
    const getReports = async () => {
      try {
        const response = await fetch(`${API.Fetchurl}games_data?user_id=${selectValue.user_id}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('session')}` },
        })
        const data = await response.json()

        const modifiedData = data.ques
          .filter((d) => d[2].toLowerCase() === 'Memory game'.toLowerCase())
          .map((d) => JSON.parse(d[6][0]))

        setReports(modifiedData)
      } catch (error) {
        console.log(error)
      }
    }

    getReports()
  }, [selectValue.user_id])

  useEffect(() => {
    let items = [
      { name: 'bee', image: bee },
      { name: 'crocodile', image: crocodile },
      { name: 'macaw', image: macaw },
      { name: 'gorilla', image: gorilla },
      { name: 'tiger', image: tiger },
      { name: 'monkey', image: monkey },
      { name: 'chameleon', image: chameleon },
      { name: 'piranha', image: piranha },
      { name: 'anaconda', image: anaconda },
      { name: 'sloth', image: sloth },
      { name: 'cockatoo', image: cockatoo },
      { name: 'toucan', image: toucan },
    ]

    const pickRandomCardsAndShuffle = (size = 4) => {
      //temporary array
      let tempArray = [...items]

      //initializes cardValues array
      let cardValues = []

      //size should be double (4*4 matrix)/2 since pairs of objects would exist
      size = (size * size) / 2

      //Random object selection
      for (let i = 0; i < size; i++) {
        const randomIndex = Math.floor(Math.random() * tempArray.length)
        cardValues.push(tempArray[randomIndex])

        //once selected remove the object from temp array
        tempArray.splice(randomIndex, 1)
      }

      //Need double to make pairs
      cardValues = [...cardValues, ...cardValues]
      //simple shuffle
      cardValues.sort(() => Math.random() - 0.5)

      return cardValues
    }

    const shuffledCards = pickRandomCardsAndShuffle()
    setCards(shuffledCards)
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setSettings((old) => ({ ...old, [name]: value }))
  }

  const handleStart = () => {
    setMoves(0)
    setWinCount(0)
    setIsWin(false)

    setIsPlaying(true)
  }

  const handleStop = () => {
    setIsPlaying(false)

    let time = minRef.current.split(':')
    saveReport({
      user_id: selectValue.user_id,
      username: selectValue.username,
      bet: winCount,
      time: time[1],
      category: 'Memory Game',
      score: moves,
      bet_array: [
        JSON.stringify({
          type: settings.type,
          level: settings.level,
          rate: 100 - ((moves - 8) / 8) * 100,
          duration: parseInt(time[0]) * 60 + parseInt(time[1]),
          avg: moves,
        }),
      ],
    })
  }

  //check if winCount === half of total cards
  useEffect(() => {
    if (cards.length > 0 && !isWin && winCount === Math.floor(cards.length / 2)) {
      setIsWin(true)
      handleStop()
    }
  }, [winCount, cards.length, isWin])

  let firstCard, secondCard
  let firstCardValue

  const handleClick = (e) => {
    if (e.target.className === 'image') return
    let card = e.target.offsetParent

    //Skip if card already matched or flipped
    if (card.classList.contains('matched')) return
    if (card.classList.contains('flipped')) return

    //flip the cliked card
    card.classList.add('flipped')
    //if it is the firstcard (!firstCard since firstCard is initially false)
    if (!firstCard) {
      //so current card will become firstCard
      firstCard = card
      //current cards value becomes firstCardValue
      firstCardValue = card.getAttribute('data-card-value')
    } else {
      //increment moves since user selected second card
      setMoves((m) => m + 1)
      //secondCard and value
      secondCard = card
      let secondCardValue = card.getAttribute('data-card-value')
      if (firstCardValue === secondCardValue) {
        //if both cards match add matched class so these cards would beignored next time
        firstCard.classList.add('matched')
        secondCard.classList.add('matched')
        //set firstCard to false since next card would be first now
        firstCard = false
        //winCount increment as user found a correct match
        setWinCount((wc) => wc + 1)
      } else {
        //if the cards dont match
        //flip the cards back to normal
        let [tempFirst, tempSecond] = [firstCard, secondCard]
        firstCard = false
        secondCard = false
        setTimeout(() => {
          tempFirst.classList.remove('flipped')
          tempSecond.classList.remove('flipped')
        }, 500)
      }
    }
  }

  const saveReport = async (res) => {
    try {
      await fetch(`${API.Fetchurl}games_data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('session')}`,
        },
        body: JSON.stringify(res),
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='flex-grow-1'>
      <Row className='h-100'>
        <Col lg={8} xl={9} xxl={9} className='h-100 p-0'>
          <div
            className='d-flex align-items-center justify-content-between bg-white shadow-sm p-4 mb-3'
            style={{ height: '75px' }}
          >
            <div className='d-flex align-items-center gap-3'>
              <img src={memorygame} alt='mental-maths' height={48} width={48} />
              <h1 className='m-0 fs-2'>Memory Game</h1>
            </div>
            <div className='d-flex align-items-center gap-4'>
              <div>
                <label htmlFor='type'>Type</label>
                <select
                  id='type'
                  name='type'
                  value={settings.type}
                  onChange={handleChange}
                  required
                  disabled={isPlaying}
                  style={{ width: 'fit-content', padding: 0, height: '25px' }}
                  className='ms-2 border border-2 rounded-pill'
                >
                  <option value='Animals'>Animals</option>
                </select>
              </div>
              <div>
                <label htmlFor='level'>Level</label>
                <select
                  id='level'
                  name='level'
                  value={settings.level}
                  onChange={handleChange}
                  required
                  disabled={isPlaying}
                  style={{ width: 'fit-content', padding: 0, height: '25px' }}
                  className='ms-2 border border-2 rounded-pill'
                >
                  {[1].map((lvl) => (
                    <option key={lvl} value={lvl}>
                      {lvl}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='mx-auto' style={{ maxWidth: '460px' }}>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='d-flex gap-2'>
                {isWin ? (
                  <div className='px-3 py-2 rounded-pill text-white bg-success shadow-sm'>
                    <p className='m-0'>You won in {moves} moves</p>
                  </div>
                ) : (
                  isPlaying && (
                    <>
                      <div className='px-3 py-1 rounded-pill bg-white shadow-sm'>
                        <span className='text-secondary me-2'>Moves</span>
                        {moves}
                      </div>
                      <div className='px-3 py-1 rounded-pill bg-white shadow-sm'>
                        <span className='text-secondary me-2'>Time</span>
                        <Timer isPlaying={isPlaying} minRef={minRef} />
                      </div>
                    </>
                  )
                )}
              </div>
              {isPlaying ? (
                <button className='p-2 px-4 border-0 rounded-3 bg-black' onClick={() => setIsPlaying(false)}>
                  Stop Game
                </button>
              ) : (
                <button className='p-2 px-4 border-0 rounded-3 text-black bg-warning' onClick={handleStart}>
                  Start Game
                </button>
              )}
            </div>

            <div className='dashboard-cards memory-game-game-container p-3'>
              {cards.map((card, i) => (
                <div
                  key={i}
                  className={`${isPlaying ? 'pe-auto' : 'pe-none'} memory-game-card-container`}
                  data-card-value={card.name}
                  onClick={handleClick}
                >
                  <div className='memory-game-card-before'>?</div>
                  <div className='memory-game-card-after'>
                    <img src={card.image} className='image' alt={card.image} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Col>
        <Col lg={4} xl={3} xxl={3} className='h-100 bg-white p-4'>
          <h6>Game Info</h6>
          <table className='table table-borderless table-sm mb-4'>
            <tbody>
              <tr>
                <td>Mode</td>
                <td className='fw-bold'>{settings.type}</td>
              </tr>
              <tr>
                <td>Level</td>
                <td className='fw-bold'>{settings.level}</td>
              </tr>
              <tr>
                <td>Number of Pairs</td>
                <td className='fw-bold'>{settings.no_of_questions}</td>
              </tr>
            </tbody>
          </table>

          <h6>How to play</h6>
          <ul>
            <li>To start the game press start button.</li>
            <li>Click to the flip cards and find the matching pairs</li>
            <li>Game ends once you've found all matching pairs</li>
          </ul>

          <h6>Reports</h6>
          {reports.length === 0 && 'No reports yet'}
          <div style={{ overflowY: 'auto', height: '500px' }}>
            <table className='table table-sm table-hover' style={{ fontSize: '10px' }}>
              <tbody className='table-group-divider align-middle'>
                {reports.map((report, index) => (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{report.type}</td>
                    <td>{report.level}</td>
                    <td>{report.rate}%</td>
                    <td>{report.avg}</td>
                    <td>{report.duration}s</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default MemoryGame

export const Timer = ({ isPlaying, minRef }) => {
  const [seconds, setSeconds] = useState(0)
  const [minutes, setMinutes] = useState(0)

  let interval = useRef()

  //For timer
  useEffect(() => {
    minRef.current = `${minutes}:${seconds}`
    if (seconds >= 60) {
      setMinutes((m) => m + 1)
      setSeconds(0)
    }
  }, [seconds])

  useEffect(() => {
    if (isPlaying) {
      interval.current = setInterval(() => setSeconds((s) => s + 1), 1000)
    } else {
      clearInterval(interval.current)
      setSeconds(0)
      setMinutes(0)
    }

    return () => clearInterval(interval.current)
  }, [isPlaying])

  return (
    <>
      {minutes <= 9 ? `0${minutes}` : minutes} : {seconds <= 9 ? `0${seconds}` : seconds}
    </>
  )
}

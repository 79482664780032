import React, { useEffect, useRef, useState } from 'react'
import Step from '../components/Step'
import StepOne from '../components/edit/StepOne'
import StepTwo from '../components/edit/StepTwo'
import StepThree from '../components/edit/StepThree'
import StepFourA from '../components/edit/StepFourA'
import StepFourB from '../components/edit/StepFourB'
import * as API from '../../Apisurl'
import headersReq from '../../HeadersJson'
import { Spinner } from 'react-bootstrap'

const Edit = () => {
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [validated, setValidated] = useState(false)
  const formRef = useRef()

  const [id, setId] = useState()
  const [module, setModule] = useState()
  const [modCategory, setModCategory] = useState()
  const [category, setCategory] = useState()
  const [flashcard, setFlashcard] = useState([])
  const [education, setEducation] = useState([])

  useEffect(() => {
    const getCatData = async () => {
      const reqJson = headersReq()
      try {
        const response = await fetch(`${API.Fetchurl}module_category_data`, {
          method: 'POST',
          headers: reqJson,
          body: JSON.stringify({
            module: module.module,
            module_category: modCategory.module,
            language: localStorage.getItem('getlang'),
          }),
        })
        const { categories } = await response.json()
        setCategory(categories)
      } catch (error) {
        console.log(error)
      }
    }
    module && modCategory && getCatData()
    setId()
  }, [module, modCategory, module?.module, modCategory?.module])

  useEffect(() => {
    if (id !== undefined) {
      if (modCategory.module === 'Education') {
        setEducation(category[id]?.levels)
      }
      if (modCategory.module === 'Flashcard') {
        setFlashcard(category[id]?.sub_cat)
      }
    }
  }, [modCategory?.module, id, category])

  const StepComponents = [
    <StepOne formRef={formRef} validated={validated} data={module} setData={setModule} />,
    <StepTwo
      formRef={formRef}
      validated={validated}
      data={modCategory}
      setData={setModCategory}
      info={{ mod: module?.module }}
    />,
    <StepThree
      id={id}
      setId={setId}
      data={category}
      setData={setCategory}
      isFlashCard={modCategory?.module === 'Flashcard'}
    />,
    modCategory?.module === 'Flashcard' ? (
      <StepFourA name={id !== undefined && category[id]?.name} data={flashcard} setData={setFlashcard} />
    ) : (
      <StepFourB name={id !== undefined && category[id]?.name} data={education} setData={setEducation} />
    ),
  ]

  const nextStep = (e) => {
    e.preventDefault()
    if (formRef?.current?.checkValidity() === false) {
      e.stopPropagation()
    } else {
      if (step === 4) return finish()
      if (step === 3 && id === undefined) return
      setStep((s) => s + 1)
    }
    setValidated(true)
  }

  //merge all 4 steps data in reverse to create JSON
  const finish = async () => {
    setLoading(true)

    let isFlashcard = modCategory.module === 'Flashcard'

    let extraKey = isFlashcard ? { sub_cat: flashcard } : { levels: education }

    //push -> (edu || flashcard) to categories
    let categories = category.map((c, i) => (i === id ? { ...c, ...extraKey } : c))

    //push -> categories to module-category
    let modcat = {
      ...modCategory,
      categories,
    }

    //make module as key  and remove from object
    let mod = { ...module }
    delete mod.module

    //final JSON [0: [module], 1: [module-category]]
    let json = {}
    json[module.module] = [mod, modcat]

    console.log(json)

    try {
      const response = await fetch(`${API.Fetchurl}add_main_module`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('session')}`,
        },
        body: JSON.stringify({
          user_id: sessionStorage.getItem('session'),
          new_data: json,
        }),
      })

      await response.json()
      setLoading(false)
      reset()
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const reset = () => {
    setId()
    setModule()
    setModCategory()
    setCategory()
    setFlashcard([])
    setEducation([])
    setStep(1)
  }

  return (
    <div className='admin-container content'>
      <h5>Edit Details</h5>
      <p>Edit and Manage your content here</p>
      <div>
        <div className='mt-4 mx-auto dashboard-cards' style={{ width: '1200px' }}>
          {/* --------------- step indicator ------------------------ */}
          <div className='d-flex justify-content-between position-relative'>
            <Step step={1} currentStep={step} title={'Module'} />
            <Step step={2} currentStep={step} title={'Module Category'} />
            <Step step={3} currentStep={step} title={'Category'} />
            <Step step={4} currentStep={step} title={'Upload'} />

            <div
              style={{
                backgroundColor: 'var(--light-gray)',
                position: 'absolute',
                top: '30%',
                height: '2px',
                width: '95%',
                marginInline: '32px',
              }}
            >
              <span
                style={{
                  backgroundColor: 'var(--green-color)',
                  position: 'absolute',
                  height: '100%',
                  width: `${((step - 1) / 3) * 100}%`,
                  transition: 'width 0.5s',
                }}
              ></span>
            </div>
          </div>

          {/* --------------- component render ------------------------ */}
          <div className='my-5'>{StepComponents[step - 1]}</div>

          {/* --------------- back continue btn ------------------------ */}
          <div className='d-flex justify-content-between'>
            <button
              onClick={() => setStep((s) => s - 1)}
              type='button'
              className='d-flex justify-content-center align-items-center py-1 px-3 rounded-pill border border-2 text-secondary'
              style={{ backgroundColor: 'transparent' }}
              disabled={step === 1}
            >
              Back
            </button>

            <button
              onClick={(e) => nextStep(e)}
              type='submit'
              className={`d-flex justify-content-center align-items-center py-1 px-3 rounded-pill text-white`}
              style={{
                backgroundColor: `${loading ? 'var(--secondary-color)' : 'var(--green-color)'}`,
                border: 'none',
              }}
              disabled={loading}
            >
              {loading ? <Spinner animation='border' size='sm' /> : step < 4 ? 'Continue' : 'Finish'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Edit

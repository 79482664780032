import React, { useRef, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'

const Test2 = ({ data, submit, options, limit }) => {
  const [formData, setFormData] = useState({})
  const [validated, setValidated] = useState(false)
  const [step, setStep] = useState(1)
  const formRef = useRef()

  const last_page = data.length / limit

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((old) => ({ ...old, [name]: value }))
  }

  const nextStep = (e) => {
    e.preventDefault()
    if (formRef.current.checkValidity() === false) {
      e.stopPropagation()
    } else {
      if (step === last_page) return finish()
      setStep((s) => s + 1)
    }
    setValidated(true)
  }

  const finish = () => {
    let ans = ''
    let counts = { A: 0, B: 0, C: 0, D: 0 }

    Object.values(formData).forEach((value) => counts[value]++)

    let total = counts.A + counts.B - (counts.C + counts.D)

    ans = total >= 3 ? 'left-dominant' : total <= -3 ? 'right-dominant' : 'hemispheric balance'

    let res = {
      test_name: 'TEST 2',
      ans: Object.values(formData),
      result: ans,
    }

    submit(res)
  }

  return (
    <Container>
      <Row className='my-3 dashboard-cards p-4'>
        <Col>
          <p className='text-black'>
            <strong>
              Instructions: Read the statements carefully. There are 12 groups of 4 statements each. Circle each item
              you select. Mark one item only under each of the 12 numbered items. Choose the activity you prefer even
              though it does not necessarily mean that you have the ability to do it. If you are undecided, make a
              decision anyway by guessing.
            </strong>
          </p>
          <Form noValidate validated={validated} ref={formRef}>
            <ul className='list-unstyled'>
              {data.slice((step - 1) * limit, (step - 1) * limit + limit).map((d) => {
                return (
                  <li key={d.q_no} className='my-3 mx-2 text-secondary'>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex gap-4'>
                        {d.q_no}.
                        <div>
                          {d.choices.map((q, i) => {
                            return (
                              <p key={i} className='m-0'>
                                {q}
                              </p>
                            )
                          })}
                        </div>
                      </div>
                      <div>
                        <Form.Group>
                          {d.choices.map((_, idx) => {
                            return (
                              <Form.Check
                                required
                                key={idx}
                                id={`${options[idx]}_${d.q_no}`}
                                type='radio'
                                label={options[idx]}
                                name={d.q_no}
                                value={options[idx]}
                                checked={formData[d.q_no] === options[idx]}
                                onChange={handleChange}
                                inline
                              />
                            )
                          })}
                          <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
            <div className='d-flex justify-content-between'>
              <button
                onClick={() => setStep((s) => s - 1)}
                type='button'
                className='d-flex justify-content-center align-items-center py-1 px-3 rounded-pill border border-2 text-secondary'
                style={{ backgroundColor: 'transparent' }}
                disabled={step === 1}
              >
                Prev
              </button>
              <button
                onClick={nextStep}
                type='submit'
                className={`d-flex justify-content-center align-items-center py-1 px-3 rounded-pill text-white`}
                style={{ backgroundColor: 'var(--green-color)', border: 'none' }}
              >
                {step !== last_page ? 'Next' : 'Finish'}
              </button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default Test2

import React from "react";
import { useLocation } from "react-router-dom";
const Progress = (props) => {
  let location = useLocation();
  let cat = JSON.stringify(location.state.category);
  let catarr = ["adjectives", "2_phase_mcq", "sentences_mcq"];

  // let sliceData = ;
  let slideData1 =
    catarr == "sentences_mcq"
      ? props.json.slice(parseInt(props.json.length / 3))
      : catarr.includes(cat.replaceAll('"', ""))
      ? props.json.slice(props.json.length / 2)
      : props.json;
  console.log(catarr);
  if (props.json != undefined) {
    return (
      <>
        <div class="wrapper">
          <ol class="ProgressBar">
            {slideData1.map((i, o) => {
              let checkundefine =
                props.getarray[o] == undefined ? "" : props.getarray[o].via;

              return (
                <li
                  className={
                    checkundefine == "correct"
                      ? "is-complete ProgressBar-step"
                      : checkundefine == "incorrect"
                      ? "is-wrong ProgressBar-step"
                      : "ProgressBar-step"
                  }
                >
                  <p className="ProgressBar-icon">{o + 1}</p>
                </li>
              );
            })}
          </ol>
        </div>
      </>
    );
  }
};
export default Progress;

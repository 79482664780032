import React, { useContext, useEffect, useRef, useState } from 'react'
import * as API from '../../Apisurl'
import Context from '../../Context'
import { differenceInSeconds } from 'date-fns'
import { Col, Form, Row } from 'react-bootstrap'
import psyduck from '../../assets/images/psyduck.svg'
import mentalmaths from '../../assets/images/mathematics.png'

const getRandomNumber = (power) => Math.floor(Math.random() * Math.pow(10, power)) + 1
const getRandomOperator = () => {
  const operators = ['+', '-', '*', '/']
  return operators[Math.floor(Math.random() * operators.length)]
}

const MentalMaths = () => {
  const { selectValue } = useContext(Context)
  const [num1, setNum1] = useState()
  const [num2, setNum2] = useState()
  const [operator, setOperator] = useState()

  const [settings, setSettings] = useState({
    type: 'Add',
    level: '1',
    no_of_questions: 10,
    curr_question: 0,
  })

  const [userAnswer, setUserAnswer] = useState('')
  const [correctAnswer, setCorrectAnswer] = useState()
  const [result, setResult] = useState([])

  const [showAnswer, setShowAnswer] = useState(false)
  const [showQuestion, setShowQuestion] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)

  const [validated, setValidated] = useState(false)
  const [reports, setReports] = useState([])

  const questionRef = useRef()
  const answerRef = useRef()

  useEffect(() => {
    const getReports = async () => {
      try {
        const response = await fetch(`${API.Fetchurl}games_data?user_id=${selectValue.user_id}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('session')}` },
        })
        const data = await response.json()

        const modifiedData = data.ques.filter((d) => d[2] === 'Mental Maths').map((d) => JSON.parse(d[6][0]))

        setReports(modifiedData)
      } catch (error) {
        console.log(error)
      }
    }

    getReports()

    return () => {
      clearTimeout(questionRef.current)
      clearTimeout(answerRef.current)
    }
  }, [selectValue.user_id])

  const handleChange = (e) => {
    const { name, value } = e.target
    setSettings((old) => ({ ...old, [name]: value }))
  }

  const saveReport = async (res) => {
    try {
      await fetch(`${API.Fetchurl}games_data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('session')}`,
        },
        body: JSON.stringify(res),
      })
    } catch (error) {
      console.log(error)
    }
  }

  const startNewQuestion = () => {
    if (settings.curr_question === settings.no_of_questions) {
      let duration = 0,
        avg = 0,
        rate = 0

      setResult((oldResult) => {
        for (let i = 0; i < oldResult.length; i++) {
          duration += oldResult[i].time
          rate += oldResult[i].isCorrect ? 1 : 0
        }

        rate = Math.round((rate / oldResult.length) * 100)
        avg = (duration / oldResult.length).toFixed(1)

        saveReport({
          user_id: selectValue.user_id,
          username: selectValue.username,
          bet: 0,
          time: avg,
          category: 'Mental Maths',
          score: rate,
          bet_array: [
            JSON.stringify({
              type: settings.type,
              level: settings.level,
              rate: rate,
              duration: duration,
              avg: avg,
            }),
          ],
        })
        stopGame()
        return oldResult
      })

      return
    }

    setNum1(getRandomNumber(settings.level))
    setNum2(getRandomNumber(settings.level))

    if (settings.type === 'Add') {
      setOperator('+')
    } else if (settings.type === 'Sub') {
      setOperator('-')
    } else if (settings.type === 'Mul') {
      setOperator('*')
    } else if (settings.type === 'Div') {
      setOperator('/')
    } else {
      setOperator(getRandomOperator())
    }
    setSettings({ ...settings, curr_question: settings.curr_question + 1 })

    setUserAnswer('')
    setShowAnswer(false)

    setShowQuestion(true)
    setResult((old) => [...old, { answer: 0, isCorrect: false, time: new Date() }])
    questionRef.current = setTimeout(() => {
      setShowQuestion(false)
    }, 1000)

    setIsPlaying(true)
  }

  const next = (e) => {
    e.preventDefault()
    if (e.currentTarget.checkValidity() === false) {
      e.stopPropagation()
    } else {
      checkAnswerAndNextQuestion()
      setValidated(true)
    }
  }

  const checkAnswerAndNextQuestion = () => {
    clearTimeout(questionRef.current)

    let res

    if (operator === '+') {
      res = num1 + num2
    } else if (operator === '-') {
      res = num1 - num2
    } else if (operator === '*') {
      res = num1 * num2
    } else {
      // Check for divide by zero
      res = num2 !== 0 ? (num1 / num2).toFixed(2) : 'undefined'
    }

    setCorrectAnswer(res)
    setShowAnswer(true)
    setShowQuestion(true)

    const updatedData = [...result]
    const tempData = {
      answer: parseInt(userAnswer),
      isCorrect: parseInt(userAnswer) === res,
      time: differenceInSeconds(new Date(), updatedData[settings.curr_question - 1].time),
    }
    updatedData[settings.curr_question - 1] = tempData
    setResult(updatedData)

    answerRef.current = setTimeout(() => {
      startNewQuestion()
      setShowAnswer(false)
    }, 2000)
  }

  const stopGame = () => {
    setNum1()
    setNum2()
    setOperator()

    setSettings({ ...settings, curr_question: 0 })
    setResult([])

    setUserAnswer('')
    setCorrectAnswer()

    setShowAnswer(false)
    setShowQuestion(false)
    setIsPlaying(false)

    clearTimeout(questionRef.current)
    clearTimeout(answerRef.current)
  }

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <Row className='h-100' style={{ maxHeight: '100%' }}>
        <Col lg={8} xl={9} xxl={9} className='p-0 h-100'>
          <div
            className='d-flex align-items-center justify-content-between bg-white shadow-sm p-4 mb-3'
            style={{ height: '75px' }}
          >
            <div className='d-flex align-items-center gap-3'>
              <img src={mentalmaths} alt='mental-maths' height={48} width={48} />
              <h1 className='m-0 fs-2'>Mental Maths</h1>
            </div>

            <div className='d-flex align-items-center gap-4'>
              <div>
                <label htmlFor='type'>Type</label>
                <select
                  id='type'
                  name='type'
                  value={settings.type}
                  onChange={handleChange}
                  required
                  disabled={isPlaying}
                  style={{ width: 'fit-content', padding: 0, height: '25px' }}
                  className='ms-2 border border-2 rounded-pill'
                >
                  <option value='Add'> + Addition</option>
                  <option value='Sub'> - Subtraction</option>
                  <option value='Mul'> * Multiplication</option>
                  <option value='Div'> / Division</option>
                  <option value='Mix'> +-*/ Mixed</option>
                </select>
              </div>
              <div>
                <label htmlFor='level'>Level</label>
                <select
                  id='level'
                  name='level'
                  value={settings.level}
                  onChange={handleChange}
                  required
                  disabled={isPlaying}
                  style={{ width: 'fit-content', padding: 0, height: '25px' }}
                  className='ms-2 border border-2 rounded-pill'
                >
                  {[1, 2, 3, 4, 5].map((lvl) => (
                    <option key={lvl} value={lvl}>
                      {lvl}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className='mx-auto' style={{ width: '450px' }}>
            <div className='d-flex align-items-center justify-content-between'>
              <p className='m-0'>
                {settings.curr_question} of {settings.no_of_questions}
              </p>
              {!isPlaying ? (
                <button className='p-2 px-4 border-0 rounded-3 text-black bg-warning' onClick={startNewQuestion}>
                  Start
                </button>
              ) : (
                <button className='p-2 px-4 border-0 rounded-3 text-black bg-warning' onClick={stopGame}>
                  Stop
                </button>
              )}
            </div>
            <div className='dashboard-cards'>
              <div
                className='d-flex flex-wrap align-items-center justify-content-center gap-4 display-1 my-5'
                style={{ height: '200px', opacity: showQuestion ? 1 : 0, transition: 'opacity 1s ease' }}
              >
                <span>{num1}</span> <span>{operator}</span> <span>{num2}</span>
              </div>
              <Form className='' noValidate validated={validated} onSubmit={next}>
                <Form.Group className='d-flex align-items-center gap-2'>
                  <Form.Label htmlFor='answer' className='fs-1 mb-0'>
                    =
                  </Form.Label>
                  <Form.Control
                    autoFocus
                    type='text'
                    value={userAnswer}
                    disabled={!isPlaying || showAnswer}
                    id='answer'
                    name='answer'
                    onChange={(e) => setUserAnswer(e.target.value)}
                    className='border border-2 rounded-3'
                  />
                </Form.Group>
                <button disabled={!isPlaying} className='w-100 p-2 px-4 border-0 rounded-3 text-white bg-black'>
                  Next
                </button>
              </Form>
              <div className='text-center' style={{ visibility: showAnswer ? 'visible' : 'hidden' }}>
                Correct : {correctAnswer}
              </div>
            </div>
          </div>
        </Col>
        <Col
          lg={4}
          xl={3}
          xxl={3}
          className='h-100 bg-white p-4'
          style={{ opacity: isPlaying ? 0 : 1, transition: 'opacity 0.2s ease' }}
        >
          <h6>Game Info</h6>
          <table className='table table-borderless table-sm mb-4'>
            <tbody>
              <tr>
                <td>Mode</td>
                <td className='fw-bold'>{settings.type}</td>
              </tr>
              <tr>
                <td>Level</td>
                <td className='fw-bold'>{settings.level}</td>
              </tr>
              <tr>
                <td>Number of questions</td>
                <td className='fw-bold'>{settings.no_of_questions}</td>
              </tr>
            </tbody>
          </table>

          <h6>How to play</h6>
          <ul>
            <li>Mental Math exercise consists of two fields: the expression field and the result field below it.</li>
            <li>
              To start the game press start button. When you have calculated the result enter it into the result field
              and press enter.
            </li>
            <li>You will see if your result was correct or the correct one if it wasn't.</li>
          </ul>

          <h6>Reports</h6>
          {reports.length === 0 && 'No reports yet'}
          <div style={{ overflowY: 'auto', height: '500px' }}>
            <table className='table table-sm table-hover' style={{ fontSize: '10px' }}>
              <tbody className='table-group-divider align-middle'>
                {reports.map((report, index) => (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{report.type}</td>
                    <td>{report.level}</td>
                    <td>{report.rate}%</td>
                    <td>{report.avg}</td>
                    <td>{report.duration}s</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default MentalMaths

import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import { Outlet, useParams, useSearchParams } from 'react-router-dom'
import * as Apiurl from '../../Apisurl'
import Context from '../../Context'
import headersReq from '../../HeadersJson'
import UserJson from '../../Userjson'
import Backbtn from '../BackComp'

const QuizLayout = () => {
  const [score, setScore] = useState([])
  const [overview, setOverview] = useState()
  const [total, setTotal] = useState([])

  const { category } = useParams()
  const searchParams = useSearchParams()[0]
  const id = searchParams.get('id')

  const reqJson = headersReq()
  const userData = UserJson()

  useEffect(() => {
    const getReports = async () => {
      try {
        const response = await fetch(`${Apiurl.Fetchurl + 'manual_report_fc_mcq?serial_num=' + id}`, {
          method: 'GET',
          headers: reqJson,
        })
        if (!response) throw new Error('Something went wrong')

        const data = await response.json()

        setOverview(data.result)
        setScore(data.result.score)
        setTotal(data.result.total)
      } catch (error) {
        console.log(error.message)
      }
    }

    getReports()
  }, [id])

  if (!overview) return <div>Loading...</div>

  return (
    <>
      <Backbtn title={category} />
      <ul className='userInfo-container'>
        <li>Userame:-{userData.username}</li>
        <li>Module:-{userData.modulename1}</li>
        <li>Category:-{userData.modulename}</li>
        <li>Level:-{userData.level}</li>
      </ul>
      <Container>
        <Row>
          <Col style={{ position: 'relative' }}>
            <div className='dashboard-cards stat-container resultCard'>
              <Context.Provider value={{ overview, score, total }}>
                <Outlet />
              </Context.Provider>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default QuizLayout

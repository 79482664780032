import 'chartjs-plugin-datalabels'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { useContext, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Doughnut } from 'react-chartjs-2'
import Context from '../Context'
import Bottomarr from '../assets/images/bottomarr.svg'

const QACharts = () => {
  const selectData = useContext(Context)
  const [active, setActive] = useState(1)

  let numarr1 = active == 1 ? 'flashcard' : 'games'

  if (selectData.pieData) {
    let pieData1 = selectData.pieData.data
    return (
      <>
        <div className='stat-header row'>
          <div className='col-md-8'>
            <h4 className='text-black'>Questions Answered Correctly</h4>
            <div className='bs-pills-container'>
              <ul className='pills-btn'>
                <li className={active === 1 ? 'active' : ''} onClick={() => setActive(1)}>
                  Flashcard
                </li>
                <li className={active === 2 ? 'active' : ''} onClick={() => setActive(2)}>
                  Games
                </li>
              </ul>
            </div>
            <ul class='ledger-container text-black'>
              <li>
                Correct
                <span></span>
              </li>
              <li>
                Incorrect
                <span></span>
              </li>
              <li>
                Unattempted
                <span></span>
              </li>
            </ul>
          </div>
          <div className='col-md-4'>
            <div className='percent-container'>
              <h5>
                <img src={Bottomarr} alt='bottomarr' />
                {pieData1.overall_score.toFixed(2)}%
              </h5>

              <p style={{ color: 'rgba(54, 56, 83, 0.5)' }}>
                Total:-
                <span style={{ color: '#000', fontWeight: '600', marginLeft: '8px' }}>{pieData1.total}</span>
              </p>
              <p>
                Correct:-
                <span style={{ color: '#000', fontWeight: '600', marginLeft: '8px' }}>{pieData1.total_correct}</span>
              </p>
              <p>
                Unaattempted:-
                <span style={{ color: '#000', fontWeight: '600', marginLeft: '8px' }}>
                  {pieData1.total_unattempted}
                </span>
              </p>
            </div>
          </div>
        </div>
        <p style={{ fontSize: '12px', color: '#c4c4c4' }}></p>
        <Row className='chart-container'>
          {Object.keys(pieData1[numarr1]).map((item, index) => {
            let numarr2 = pieData1[numarr1][item]
            if (numarr2 != undefined) {
              return (
                <Col key={index}>
                  <Doughnut
                    plugins={[ChartDataLabels]}
                    data={{
                      datasets: [
                        {
                          data: [numarr2['correct'], numarr2['unattempted'], numarr2['wrong']],
                          backgroundColor: ['#afdc8f', '#c4c4c4', '#fbc3c6'],
                          hoverOffset: 4,
                          plugins: {
                            legend: {
                              display: false,
                            },
                            datalabels: {
                              labels: {
                                value: {
                                  formatter: (value, ctx) => {
                                    return value
                                  },
                                  color: '#3c726e',
                                  display: true,
                                  font: {
                                    weight: 'bold',
                                    size: '20px',
                                  },
                                  anchor: 'center',
                                  offset: 0,
                                },
                              },
                            },
                          },
                        },
                      ],
                    }}
                    options={{ aspectRatio: 1 }}
                    height='10px'
                  />
                  <h5>{item}</h5>
                </Col>
              )
            }
          })}
        </Row>
      </>
    )
  }
}
export default QACharts

import { Col, Container, Row } from 'react-bootstrap'
import Focus from '../../../includes/Focus'
// import Draggable, { DraggableCore } from "react-draggable";
import { Reorder } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { Draggable } from 'react-drag-reorder'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSpeechSynthesis } from 'react-speech-kit'
import * as Currdate from '../../../CurrDate'
import { SocketConfig } from '../../../SocketConfig'
import StartModal from '../../SelectgameStart'

const Rank_value = (props) => {
  let DraggableCore = Draggable.DraggableCore
  const { speak } = useSpeechSynthesis()
  const [score, setScore] = useState(0)
  const location = useLocation()
  const [isActive, setIsActive] = useState(false)
  let initialArray = []
  let moneyDatavalue = props.moneyData
  let moneyDatavalue1 = moneyDatavalue?.ques.split(',')

  // console.log(moneyDatavalue);
  const [items, setItems] = useState(moneyDatavalue1)
  // useEffect(() => {
  // })
  const ans12 = moneyDatavalue?.ans.split(',')
  const [theArray, setTheArray] = useState(initialArray)
  const [mistakes, setMistakes] = useState(0)
  const [userAnswer, setUserAnswer] = useState('')
  const resetButton = useRef(null)
  const [smShow, setsmShow] = useState(false)
  const [parsedata, setparsedata] = useState(false)
  const [page, setPage] = useState(0)

  // const props1 = useContext(UserContext);
  console.log(props.moneyData)
  let datalength = props.datalength
  let date = Currdate.GetCurrentDate()
  props.time(date)
  let datalengthi = datalength
  const [callday, setcallday] = useState(1)
  let navigate = useNavigate()

  useEffect(() => {
    SocketConfig.on('action_data', (data) => {
      // console.log("Datta ", data);
      let parseddata = data.result
      if (parseddata.length != 0) {
        setparsedata(true)
        handleSubmit(parseddata.action, true)
      }
      // console.log(parseddata);
    })
    //
  }, [])
  // populate level text

  let update
  // checkanswers
  let count = 0
  function handleSubmit() {
    let correctAnswer

    if (JSON.stringify(items) == JSON.stringify(ans12)) {
      props.setPage((prev) => {
        props.setTheArray((oldArray) => {
          update = [
            ...oldArray,
            {
              via: 'correct',
              answer: items,
            },
          ]
          localStorage.setItem('useranswer', JSON.stringify(update))
          return update
        })

        if (update.length === datalengthi.length) {
          props.setTheArray([])
          // socketemitdata();
          props.submitResult(date)

          // navigate(
          //   'moneyresult',
          //   {
          //     state: { ...location.state, date: date },
          //   },
          //   1000
          // )
        } else {
          props.setScore((prev) => prev + 1)
          count++
          setItems(datalengthi[prev + 1]?.ques.split(','))

          return prev + 1
        }
      })
    } else {
      props.setPage((prev) => {
        props.setTheArray((oldArray) => {
          update = [
            ...oldArray,
            {
              via: 'incorrect',
              answer: items,
            },
          ]
          localStorage.setItem('useranswer', JSON.stringify(update))
          return update
        })

        if (update.length === datalengthi.length) {
          props.setTheArray([])
          props.submitResult(date)
          // navigate(
          //   'moneyresult',
          //   {
          //     state: { ...location.state, date: date },
          //   },
          //   100
          // )
        } else {
          count++
          setItems(datalengthi[prev + 1]?.ques.split(','))
          console.log(datalengthi, count)

          return prev + 1
        }
      })
    }
  }

  if (moneyDatavalue != undefined) {
    return (
      <>
        <Container fluid className='currencyGame arrangeCoingame'>
          <Row className='colors-main-container comparing-game mt-0'>
            <h1
              style={{
                textAlign: 'center',
                fontWeight: '600',
                textTransform: 'uppercase',
              }}
            ></h1>
            <Col md={12}>
              <figure className='measure-item view-option m-auto'>
                <h3 className='text-center' style={{ fontSize: '40px' }}>
                  Arrange in Asscending Order
                </h3>
              </figure>
              <Focus />
              <div className='addition-container  '></div>
              <div className='content-container'></div>
            </Col>
          </Row>
          <div className='option-mcq-container'>
            {/* <Draggable> */}
            <Reorder.Group axis='x' values={items} onReorder={setItems}>
              {items.map((o, i) => {
                return (
                  <Reorder.Item key={o} value={o}>
                    {/* {console.log(o)} */}
                    <div className='measure-item mcq-item'>
                      <p class='drag-blob'></p>
                      <img src={o} style={{ height: '180px' }} />
                    </div>
                  </Reorder.Item>
                )
              })}
              {/* </Draggable> */}
            </Reorder.Group>
          </div>
          <div className='text-left' style={{ textAlign: 'right' }}>
            <button className='common-btn px-3 py-2 mt-3' onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </Container>
        <StartModal
          calldelay={setcallday}
          socket={SocketConfig}
          type={['action', 'focus']}
          date={date}
          letquesLength={datalength.length}
          calldaynum={callday}
        />
      </>
    )
  }
}
export default Rank_value

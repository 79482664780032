import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { Line } from 'react-chartjs-2'
import { useTimer } from 'react-timer-hook'
import * as API from '../../Apisurl'
import Context from '../../Context'
import { SocketConfig } from '../../SocketConfig'
import coin from '../../assets/audio/coin.mp3'
import rocket from '../../assets/images/rocket2.png'
import './rocket.css'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const Rocket = () => {
  const { selectValue } = useContext(Context)

  const [running, setRunning] = useState(false)
  const [attention, setAttention] = useState(0)
  const [totalAttention, setTotalAttention] = useState([])
  const [score, setScore] = useState(0)

  const [bottom, setBottom] = useState(-4)
  const [showCoin, setShowCoin] = useState(false)
  const [showCoin1, setShowCoin1] = useState(false)
  const [showCoin2, setShowCoin2] = useState(false)

  const [show, setShow] = useState(true)
  const [showT, setShowT] = useState(false)
  const [showR, setShowR] = useState(false)

  const [validated, setValidated] = useState(false)
  const [data, setData] = useState({
    name: '',
    duration: 1,
    bet: 1,
    category: '',
  })
  const { minutes, seconds, restart, isRunning, pause } = useTimer({
    autoStart: false,
    expiryTimestamp: data.duration,
  })

  const { seconds: secs, restart: rstrt, isRunning: isRunningT } = useTimer({
    autoStart: false,
    expiryTimestamp: 10,
    onExpire: () => gameStart(),
  })

  useEffect(() => {
    isRunningT &&
      SocketConfig.on('rocket_data', (data) => {
        if (data && data?.result !== 'pause' && data.data_store !== undefined) {
          setAttention(parseInt(data.data_store))
        }
      })
  }, [isRunningT])

  let interval

  const handleChange = (e) => {
    const { name, value } = e.target
    setData((old) => ({ ...old, [name]: value }))
  }

  const check = (e) => {
    e.preventDefault()
    if (e.currentTarget.checkValidity() === false) {
      e.stopPropagation()
    } else {
      // socket
      SocketConfig.emit('rocket', {
        result: 'connect',
        username: data.name,
        bet: data.bet,
        time: data.duration,
        category: data.category,
      })

      // gameStart()
      setShow(false)
      setShowT(true)

      let time = new Date()
      let expiryTimestamp = time.setSeconds(time.getSeconds() + 10)
      rstrt(expiryTimestamp)
    }
    setValidated(true)
  }

  const gameStart = () => {
    setShowT(false)
    // reset
    setScore(0)
    setTotalAttention([])

    // start game timer
    let time = new Date()
    restart(time.setMinutes(time.getMinutes() + data.duration))
    setRunning(true)
    stars()

    // stop after X mins
    setTimeout(() => {
      gameOver()
    }, [data.duration * (1000 * 60)])
  }

  const saveReport = async (res) => {
    try {
      await fetch(`${API.Fetchurl}games_data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('session')}`,
        },
        body: JSON.stringify(res),
      })
    } catch (error) {
      console.log(error)
    }
  }

  const gameOver = () => {
    SocketConfig.off('rocket_data')

    let report = {
      user_id: selectValue.user_id,
      username: data.name,
      bet: data.bet,
      time: data.duration,
      category: data.category,
      score: score * 3,
      bet_array: totalAttention,
    }

    saveReport(report)

    // stop game n remove stars
    setRunning(false)
    clearInterval(interval)
    removeStars()

    // reset values
    setAttention(0)
    setBottom(-4)
    setShowCoin(false)

    //play again modal
    setShowR(true)
  }

  const stars = () => {
    let count = 100
    let scene = document.querySelector('.scene')
    let i = 0
    while (i < count) {
      let star = document.createElement('i')
      let x = Math.floor(Math.random() * window.innerWidth)
      let duration = Math.random() * 2
      let h = Math.random() * 200
      star.style.left = x + 'px'
      star.style.width = 1.5 + 'px'
      star.style.height = 50 + h + 'px'
      star.style.animationDuration = duration + 's'
      scene.appendChild(star)
      i++
    }
  }

  const removeStars = () => {
    let stars = document.getElementsByTagName('i')
    Array.from(stars).forEach((star) => {
      star.remove()
    })
  }

  useEffect(() => {
    running &&
      SocketConfig.on('rocket_data', (data) => {
        console.log(data)
        if (data?.data_store?.toLowerCase() === 'please wear the headset correctly') {
          pause()
          setRunning(false)
        } else {
          if (data && data?.result !== 'pause' && data.data_store !== undefined) {
            console.log(data.data_store)
            setAttention(parseInt(data.data_store))
            setTotalAttention((old) => {
              return [...old, parseInt(data.data_store)]
            })
          }
        }
      })
  }, [running])

  useEffect(() => {
    if (running && totalAttention.at(-1) >= data.bet) {
      setShowCoin(true)
      setShowCoin1(true)
      setShowCoin2(true)
      let coinSound = new Audio(coin)
      coinSound.play()

      setTimeout(() => {
        setShowCoin(false)
      }, 400)
      setTimeout(() => {
        setShowCoin1(false)
      }, 600)
      setTimeout(() => {
        setShowCoin2(false)
      }, 800)

      let increase = 0

      if (totalAttention.length >= 2) {
        let curr = totalAttention.at(-1),
          prev = totalAttention.at(-2)
        let total = (curr + prev) / 2,
          diff = curr - prev

        let perc = Math.floor((diff / total) * 100)

        increase = perc <= 20 ? 3 : perc <= 60 ? 7 : perc <= 80 ? 12 : 15
      }

      setBottom((old) => {
        if (old >= 80) {
          return 80
        } else {
          return old + increase
        }
      })
      setScore((s) => s + 1)
    }
  }, [totalAttention.length, data.bet, running])

  return (
    <>
      <div className='scene'>
        {data.category && (
          <div className='z-3 position-absolute top-0 start-0 m-2 d-flex gap-2 align-items-center bg-white rounded-3 py-2 px-3'>
            <h4 className='text-capitalize text-secondary m-0'>{data.category}</h4>
            <h4 className='m-0'>{attention}</h4>
          </div>
        )}
        {isRunning ? (
          <div className='z-3 position-absolute bottom-0 end-0 m-2 d-flex gap-2 align-items-center bg-white rounded-3 py-2 px-3'>
            <h4 className='text-secondary m-0'>Time Left</h4>
            <h4 className='m-0'>
              {minutes} : {seconds}
            </h4>
          </div>
        ) : null}

        <div className='z-3 position-absolute top-0 end-0 m-2 d-flex gap-2 align-items-center bg-white rounded-3 py-2 px-3'>
          <h4 className='text-secondary m-0'>Score</h4>
          <h4 className='m-0'>{score * 3}</h4>
        </div>
        <div className='rocket' style={{ bottom: `${bottom}%` }}>
          <img className='rocket-img' src={rocket} alt='rocket' height={187.5} width={75} />
          <div
            className={`${showCoin ? 'd-flex' : 'd-none'} justify-content-between align-items-center rounded-circle`}
            style={{
              position: 'absolute',
              left: '125%',
              top: '25%',
              height: '50px',
              width: '50px',
              background: '#FFDE00',
              boxShadow: 'inset -4px -2px 0px 0px #FF9900',
            }}
          >
            <span className='ms-2' style={{ fontSize: '22px' }}>
              +
            </span>
            <span className='me-3' style={{ fontSize: '32px' }}>
              1
            </span>
          </div>
          <div
            className={`${showCoin1 ? 'd-flex' : 'd-none'} justify-content-between align-items-center rounded-circle`}
            style={{
              position: 'absolute',
              left: '125%',
              top: '60%',
              height: '50px',
              width: '50px',
              background: '#FFDE00',
              boxShadow: 'inset -4px -2px 0px 0px #FF9900',
            }}
          >
            <span className='ms-2' style={{ fontSize: '22px' }}>
              +
            </span>
            <span className='me-3' style={{ fontSize: '32px' }}>
              1
            </span>
          </div>
          <div
            className={`${showCoin2 ? 'd-flex' : 'd-none'} justify-content-between align-items-center rounded-circle`}
            style={{
              position: 'absolute',
              left: '125%',
              top: '95%',
              height: '50px',
              width: '50px',
              background: '#FFDE00',
              boxShadow: 'inset -4px -2px 0px 0px #FF9900',
            }}
          >
            <span className='ms-2' style={{ fontSize: '22px' }}>
              +
            </span>
            <span className='me-3' style={{ fontSize: '32px' }}>
              1
            </span>
          </div>
        </div>
      </div>

      <Modal backdrop='static' keyboard={false} centered show={showT} onHide={setShowT} className='FormModal'>
        <div className='d-flex justify-content-center align-items-center rounded-circle'>
          <h1 className='m-0' style={{ fontSize: '160px' }}>
            {secs}
          </h1>
        </div>
      </Modal>

      {/* Game Start Modal */}
      <Modal size='md' backdrop='static' keyboard={false} centered show={show} onHide={setShow} className='FormModal'>
        <Form noValidate validated={validated} onSubmit={check}>
          <Modal.Header className='px-4'>
            <h2 className='m-0'>Attention Fueled Rocket</h2>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Form.Group className='mb-2' controlId='name'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    name='name'
                    value={data.name}
                    onChange={handleChange}
                    maxLength='30'
                  />
                  <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId='category' className='mb-2'>
                  <Form.Label>Bet on</Form.Label>
                  <Form.Select name='category' value={data.category} onChange={handleChange} required>
                    <option value=''>Select your category of bet</option>
                    <option value='Attention'>Attention</option>
                    <option value='Focus'>Focus</option>
                    <option value='Interest'>Interest</option>
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId='bet'>
                  <Form.Label>
                    Attention Level <span className='text-black'>(1-100)*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type='number'
                    name='bet'
                    value={data.bet}
                    onChange={handleChange}
                    min={1}
                    max={100}
                  />
                  <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId='duration'>
                  <Form.Label>
                    Play for <span className='text-black'>(mins)*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type='number'
                    name='duration'
                    value={data.duration}
                    onChange={handleChange}
                    min={1}
                    max={10}
                  />
                  <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <button
              type='submit'
              className='d-flex justify-content-center align-items-center py-1 px-3 border-0 rounded-pill text-white'
              style={{ backgroundColor: 'var(--green-color)' }}
            >
              Start
            </button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Game Over Modal */}
      <Modal size='lg' centered show={showR} onHide={setShowR} className='FormModal'>
        <Modal.Header closeButton className='px-4'>
          <h2 className='m-0'>Game Over</h2>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={4}>
              <div className='d-flex justify-content-between align-items-center border border-2 border-dark rounded-pill p-1'>
                <h4 className='m-0 ms-2'>Score</h4>
                <h2 className='m-0 rounded-pill bg-info px-3 py-1 text-white'>{score * 3}</h2>
              </div>
            </Col>
            <Col md={8}>
              <div className='d-flex gap-4 justify-content-between align-items-center border border-2 border-dark rounded-pill p-1'>
                <h4 className='m-0 ms-2 text-capitalize'>{data.category}</h4>
                <div className='d-flex gap-2'>
                  <h2 className='m-0 bg-success rounded-pill px-3 py-1 text-white'>
                    H : {Math.max(...totalAttention)}
                  </h2>
                  <h2 className='m-0 bg-danger rounded-pill px-3 py-1 text-white'>L : {Math.min(...totalAttention)}</h2>
                  <h2 className='m-0 bg-warning rounded-pill px-3 py-1 text-white'>
                    A : {(totalAttention.reduce((total, curr) => total + curr, 0) / totalAttention.length).toFixed(0)}
                  </h2>
                </div>
              </div>
            </Col>
            <Col md={12} className='mt-4'>
              <Line data={getChartData(totalAttention, data.category)} options={options} />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setShowR(false)}
            type='button'
            className='d-flex justify-content-center align-items-center py-1 px-3 border rounded-pill bg-transparent'
          >
            Close
          </button>
          <button
            onClick={() => {
              setShowR(false)
              setShow(true)
            }}
            type='submit'
            className='d-flex justify-content-center align-items-center py-1 px-3 border-0 rounded-pill text-white'
            style={{ backgroundColor: 'var(--green-color)' }}
          >
            Play Again
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Rocket

export const colorsUser = ['#6AB04C', '#F0932B', '#BC1CA4', '#22A6B3', '#6AB04C', '#BC1CA4']
export const colorsOthers = ['#558d3d', '#c07622', '#961683', '#1b858f', '#558d3d', '#961683']

export const getChartData = (data, label) => {
  let labels = data.map((_, i) => i + 1)
  return {
    labels: labels,
    datasets: [{ label: label, data: data, borderColor: colorsUser[0], backgroundColor: colorsUser[0] }],
  }
}

export const options = {
  responsive: true,
  scales: {
    x: {
      display: false,
      title: {
        display: true,
        text: 'Attention',
      },
      grid: {
        display: false,
      },
    },
    y: {
      min: 0,
      max: 100,
      ticks: {
        stepSize: 20,
      },
    },
  },

  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      labels: {
        value: {
          anchor: 'end',
          align: 'top',
          display: true,
          color: 'black',
          font: {
            weight: 'bold',
            size: 12,
          },
          offset: 0,
        },
      },
    },
  },
}

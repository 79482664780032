import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import CustomDropzone from '../CustomDropzone'
import '../steps.css'
import * as API from '../../../Apisurl'
import headersReq from '../../../HeadersJson'

const StepTwo = ({ formRef, validated, data, setData, info }) => {
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState()
  const [modCat, setModCat] = useState([])

  useEffect(() => {
    const reqJson = headersReq()
    const getModCat = async () => {
      try {
        const response = await fetch(`${API.Fetchurl}module_category_list`, {
          method: 'POST',
          headers: reqJson,
          body: JSON.stringify({
            module: info.mod,
            language: localStorage.getItem('getlang'),
          }),
        })
        const result = await response.json()
        setModCat(result)
        !data && setData(result[0])
      } catch (error) {
        console.log(error)
      }
    }
    getModCat()
  }, [info.mod, data, setData])

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks
    return () => URL.revokeObjectURL(file)
  }, [file])

  const handleModuleChange = (e) => {
    setFile()
    const { value } = e.target
    let index = value === 'Flashcard' ? 1 : 0
    setData(modCat[index])
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setData((old) => ({ ...old, [name]: value }))
  }

  const upload = async (e) => {
    if (!file) return

    try {
      setLoading(true)

      const formData = new FormData()
      formData.append('user_id', sessionStorage.getItem('session'))
      formData.append('folder_type', 'module_category')
      formData.append('file', file)

      const response = await fetch(`${API.Fetchurl}file_upload_1`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('session'),
        },
      })

      const data = await response.json()
      let imageURL = data.file_path[0]
      setData((old) => ({ ...old, image: imageURL }))
      setFile()

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <Row>
      <Col>
        {/* <h4>Module Category</h4> */}
        <Form className='mt-2' ref={formRef} noValidate validated={validated}>
          <Form.Group className='mb-3' controlId='module-category-title'>
            <Form.Label>Module Category</Form.Label>
            <Form.Select required name='module' value={data?.module} onChange={handleModuleChange}>
              <option value='Education'>Education</option>
              <option value='Flashcard'>Flashcard</option>
            </Form.Select>
            <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3' controlId='module-category-about'>
            <Form.Label>About</Form.Label>
            <Form.Control required type='text' name='text' value={data?.text} onChange={handleChange} />
            <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
          </Form.Group>

          <div className='d-flex gap-1 mb-4'>
            <CustomDropzone setData={setFile} isMultiple={false} />
            <button
              onClick={upload}
              disabled={!file || loading}
              type='button'
              className='d-flex justify-content-center align-items-center py-1 px-3 border-0 rounded-3 text-white'
              style={{ backgroundColor: 'var(--green-color)' }}
            >
              {loading ? <Spinner animation='border' /> : 'Upload'}
            </button>
          </div>
        </Form>
      </Col>
      <Col>
        <div className='d-flex flex-column align-items-center gap-2'>
          <h4>Preview</h4>
          <div className='preview-module-card'>
            <div className='text-center'>
              {!file ? <p>uploaded</p> : <p>preview only, click upload to save</p>}
              <img
                src={file ? URL.createObjectURL(file) : data?.image}
                alt='module-img'
                onLoad={() => URL.revokeObjectURL(file)}
              />
            </div>
            <div className='preview-info'>
              <h4 style={{ fontSize: '20px' }}>{data?.module}</h4>
              <p>{data?.text}</p>
            </div>
            <button class='common-btn'>Start</button>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default StepTwo

import React, { useEffect, useState } from 'react'
import Tree from './Tree'
import './PartsOfTree.css'

const PartsOfTree3 = ({ submit }) => {
  const [options, setOptions] = useState({
    fruit: '',
    leaf: '',
    branch: '',
    trunk: '',
    root: '',
  })

  const [result, setResult] = useState([])

  useEffect(() => {
    if (result.length === Object.keys(options).length) {
      localStorage.setItem('useranswer', JSON.stringify(result))
      submit()
    }
  }, [result])

  const handleChange = (e) => {
    const { name, value } = e.target
    setOptions((old) => ({ ...old, [name]: value }))
  }

  const reset = () => {
    setResult([])
    setOptions({
      fruit: '',
      leaf: '',
      branch: '',
      trunk: '',
      root: '',
    })
  }

  const handleSubmit = () => {
    Object.entries(options).forEach(([ques, ans], i) => {
      let modifiedAns = ans.toLowerCase()
      let isCorrect = ques === modifiedAns
      setResult((old) => [...old, { ques: ques, ans: modifiedAns, via: isCorrect ? 'correct' : 'incorrect' }])
    })
  }

  return (
    <div className='d-flex flex-column align-items-center gap-4'>
      <div className='d-flex align-items-center gap-4'>
        {/* {Object.keys(options).map((op) => {
          return (
            <div className='px-4 py-2 rounded-pill d-flex align-items-center justify-content-center shadow'>
              <h2 className='m-0 p-0 text-capitalize text-black'>{op}</h2>
            </div>
          )
        })} */}
        <button
          className='bg-transparent border-0 px-4 py-2 rounded-pill d-flex align-items-center justify-content-center shadow'
          onClick={reset}
        >
          <h2 className='m-0 p-0 text-capitalize text-secondary'>Reset</h2>
        </button>
        <button
          className='bg-transparent border-0 px-4 py-2 rounded-pill d-flex align-items-center justify-content-center shadow'
          onClick={handleSubmit}
        >
          <h2 className='m-0 p-0 text-capitalize text-secondary'>Submit</h2>
        </button>
      </div>
      <div className='position-relative d-flex justify-content-center' style={{ width: '1000px' }}>
        <div>
          <div className='tree-part fruits'>
            <input
              type='text'
              name='fruit'
              value={options.fruit}
              onChange={handleChange}
              className='border border-4 rounded-pill'
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                width: '115px',
                height: '50px',
              }}
            />
          </div>

          <div className='tree-part leaves'>
            <input
              type='text'
              name='leaf'
              value={options.leaf}
              onChange={handleChange}
              className='border border-4 rounded-pill'
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                width: '140px',
                height: '50px',
              }}
            />
          </div>
        </div>
        <Tree handleClick={() => {}} />
        <div>
          <div className='tree-part branch'>
            <input
              type='text'
              name='branch'
              value={options.branch}
              onChange={handleChange}
              className='border border-4 rounded-pill'
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                width: '145px',
                height: '50px',
              }}
            />
          </div>

          <div className='tree-part trunk'>
            <input
              type='text'
              name='trunk'
              value={options.trunk}
              onChange={handleChange}
              className='border border-4 rounded-pill'
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                width: '115px',
                height: '50px',
              }}
            />
          </div>

          <div className='tree-part roots'>
            <input
              type='text'
              name='root'
              value={options.root}
              onChange={handleChange}
              className='border border-4 rounded-pill'
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                width: '115px',
                height: '50px',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PartsOfTree3

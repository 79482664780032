import { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

const RoleItemInstitution = () => {
  const navigate = useNavigate()
  const [role, setRole] = useState('student')

  const redirectPage = () => {
    localStorage.setItem('role', role)
    navigate('/role/' + role, { state: 1 })
  }

  return (
    <form className='login-form-container parent-container '>
      <h3>Create an Account</h3>
      <p>Please choose a role</p>
      <div class='select-role-container d-flex flex-wrap'>
        <figure
          className={role == 'student' ? 'select-role-item active' : 'select-role-item '}
          onClick={() => setRole('student')}
          data-attr='student'
        >
          <svg
            enable-background='new 0 0 91 91'
            height='91px'
            id='Layer_1'
            version='1.1'
            viewBox='0 0 91 91'
            width='91px'
            xmlSpace='preserve'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
          >
            <g>
              <g>
                <g>
                  <path
                    d='M45.195,55.11c-14.982,0-27.172-12.265-27.172-27.34c0-15.073,12.189-27.337,27.172-27.337      c14.989,0,27.184,12.264,27.184,27.337C72.379,42.846,60.185,55.11,45.195,55.11z M45.195,5.459      c-12.213,0-22.146,10.008-22.146,22.312s9.934,22.314,22.146,22.314c12.219,0,22.158-10.011,22.158-22.314      S57.414,5.459,45.195,5.459z'
                    fill='#363955'
                  />

                  <path
                    d='M75.786,82.201c-1.388,0-2.513-1.125-2.513-2.513V60.13c0-6.277-3.455-10.713-10.561-13.559     c-1.289-0.516-1.914-1.978-1.398-3.267c0.515-1.287,1.977-1.916,3.266-1.398c11.338,4.541,13.719,12.416,13.719,18.224v19.559     C78.299,81.076,77.174,82.201,75.786,82.201z'
                    fill='#363955'
                  />
                  <path
                    d='M15.007,82.198c-1.388,0-2.513-1.125-2.513-2.513V60.13c0-8.317,4.6-14.615,13.299-18.212     c1.283-0.53,2.752,0.08,3.282,1.361c0.53,1.282-0.079,2.752-1.362,3.282C20.854,49.397,17.52,53.836,17.52,60.13v19.556     C17.52,81.073,16.395,82.198,15.007,82.198z'
                    fill='#363955'
                  />

                  <path
                    d='M45.4,90.915c-0.556,0-1.111-0.184-1.57-0.551l-8.668-6.933c-0.736-0.59-1.081-1.542-0.892-2.467      l5.497-26.783c0.24-1.169,1.269-2.008,2.461-2.008h6.253c1.19,0,2.218,0.835,2.46,2.001l5.577,26.783      c0.192,0.926-0.151,1.882-0.89,2.473l-8.658,6.933C46.512,90.731,45.955,90.915,45.4,90.915z M39.502,80.468l5.896,4.717      l5.887-4.712l-4.847-23.273h-2.16L39.502,80.468z'
                    fill='#363955'
                  />
                </g>
              </g>
            </g>
          </svg>

          <figcaption>Student</figcaption>
        </figure>
        <figure
          className={role == 'college' ? 'select-role-item active' : 'select-role-item '}
          onClick={() => setRole('college')}
          data-attr='college'
        >
          <svg
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            stroke='#363955'
            strokeLineCap='round'
            strokeLineJoin='round'
            strokeWidth='1.5px'
          >
            <title />
            <path
              className='a'
              d='M.749,23.243A7.889,7.889,0,0,1,1.4,20.129c.47-.92,2.431-1.558,4.894-2.451.666-.241.556-1.943.261-2.261a4.757,4.757,0,0,1-1.279-3.73A3.2,3.2,0,0,1,8.019,8.265'
            />
            <path
              className='a'
              d='M11.4,13.094a4.194,4.194,0,0,1-1.17,2.323c-.295.318-.4,2.02.262,2.261,2.462.893,4.424,1.531,4.893,2.451a8.027,8.027,0,0,1,.666,3.114'
            />
            <path
              className='a'
              d='M19.367,23.243h2.368v-.817c0-2.894-2-3.361-4.007-4.1-.665-.247-.556-1.984-.261-2.308a4.354,4.354,0,0,0,1.2-2.551'
            />
            <circle className='a' cx='15.749' cy='6.007' r='5.25' />
            <line className='a' x1='23.249' x2='19.461' y1='13.507' y2='9.719' />
          </svg>

          <figcaption>College</figcaption>
        </figure>
      </div>

      <button class='common-btn full-btn' type='button' onClick={redirectPage}>
        Continue
      </button>
      <div className='Login-button'>
        <p className='m-0'>
          Already Have an Account ? <NavLink to={'/loginmain'}>Login</NavLink>
        </p>
      </div>
    </form>
  )
}

export default RoleItemInstitution
